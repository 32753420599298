import { BlockType, CreateBlockType } from "../../types/block/type";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { initialCreatingCalendarBlock } from "./utils/initialCreatingCalendarBlock";
import { useCreateInboxBlockMutationOptions } from "./mutations";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import { calendarQueryKeys } from "../calendar/queries";
import { juneTrack } from "../../utils/june/analytics";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";
import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";
import { InboxHeaderTab } from "../../types/inbox/inbox-headers";

// WARN: 임시로 June을 위해 inbox인지 calendar 호출인지 알기 위해 location을 받음
// TODO: 추후 June 호출 부분 refactoring
export const useCreateInboxBlockMutation = (location: InboxHeaderTab) => {
  const queryClient = useQueryClient();

  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const { findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();
  const defaultVisibility = useRecoilValue(visibilityState);

  return useMutation({
    ...useCreateInboxBlockMutationOptions(),

    onMutate: async (payload: CreateBlockType) => {
      /** June event calls */
      juneTrack(JUNE_EVENT.CREATE_BLOCK, {
        location: location === "Inbox" ? "inbox" : "today_inbox",
        type: payload.blockType,
        knowledge: Boolean(payload.linkData && payload.linkData.length > 0),
        note: true, // 생성할 때는 노트 생성 여부 무조건 true
        space: Boolean(payload.spaceId),
        visibility: payload.visibility,
        repeat: Boolean(payload.recurrence && payload.recurrence.length > 0),
        startDateTime: payload.start ? formatDateTimeForJune(payload.start) : null,
        endDateTime: payload.end ? formatDateTimeForJune(payload.end) : null,
        priority: payload.priority ?? null,
      });

      // inbox-general은 낙관적 업데이트 안함
      if (!payload.start || !payload.end) {
        return;
      }

      // 생성 시에 전역상태, 쿼리상태 업데이트
      const creatingCalendarBlock = {
        ...initialCreatingCalendarBlock,
        ...payload,
      };

      // 캘린더 영역 전역상태 업데이트
      setMobaCalendarList((prev) => [creatingCalendarBlock, ...prev]);

      // inbox 쿼리 캐시 업데이트
      const currentInboxQueryKey = findUpdateInboxQueryKey(creatingCalendarBlock);
      if (!currentInboxQueryKey) return;

      await queryClient.cancelQueries({ queryKey: currentInboxQueryKey });
      const previousData = queryClient.getQueryData<BlockType[]>(currentInboxQueryKey);
      if (previousData) {
        queryClient.setQueryData<BlockType[]>(currentInboxQueryKey, [
          creatingCalendarBlock,
          ...previousData,
        ]);
      }

      return { previousData, currentInboxQueryKey };
    },
    onSuccess: (newData) => {
      // 성공 시 새로받은 데이터로 교체
      // 현재 쿼리데이터가 클라이언트 타입으로 되어있어서 convert해줘야함
      const newClientBlock = convertServerToClientBlockType({
        serverBlock: newData,
        defaultVisibility,
      });

      const currentInboxQueryKey = findUpdateInboxQueryKey(newClientBlock);
      if (!currentInboxQueryKey) return;

      // inbox-general은 쿼리 캐시만 업데이트
      if (!newClientBlock.start || !newClientBlock.end) {
        queryClient.setQueryData(currentInboxQueryKey, (oldData: BlockType[] | undefined) => {
          if (!oldData) return [newClientBlock];
          return [newClientBlock, ...oldData];
        });
        return;
      }

      // 이외 날짜가 있는 경우
      // 캘린더 전역상태 업데이트
      setMobaCalendarList((prev) => {
        return prev.map((block) => (block.id ? block : newClientBlock));
      });
      // inbox 임시 생성 블록 제거 및 갱신
      queryClient.setQueryData(currentInboxQueryKey, (oldData: BlockType[] | undefined) => {
        if (!oldData) return [newClientBlock];
        return oldData.map((block) => (block.id ? block : newClientBlock));
      });
    },

    onError: (error, variables, context) => {
      if (context?.previousData && context?.currentInboxQueryKey) {
        queryClient.setQueryData(context.currentInboxQueryKey, context.previousData);
      }
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
  });
};
