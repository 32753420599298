import { useQueryClient } from "@tanstack/react-query";
import contactsAPI from "../../api/ContactsAPI";
import useApi from "../../services/auth/useApi";

export const contactQueryKeys = {
  all: ["contact"],
  googleContatcs: () => [...contactQueryKeys.all, "google"],
  mobaContacts: () => [...contactQueryKeys.all, "moba"],
};

export const useContactQueryOptions = () => {
  const api = useApi();

  return {
    queryKey: contactQueryKeys.mobaContacts(),
    queryFn: () => contactsAPI.getContacts(api).then((res) => res),
  };
};

export const useGoogleContactsQueryOptions = () => {
  const api = useApi();

  return {
    queryKey: contactQueryKeys.googleContatcs(),
    queryFn: () => contactsAPI.getGoogleContacts(api).then((res) => res),
    // 리로드/로드 시에만 불러오기 때문에 retry 설정
    retry: 1,
    staleTime: Infinity,
    gcTime: Infinity,
  };
};
