import { atom, selectorFamily, selector, DefaultValue } from "recoil";
import { SpaceType } from "../../types/space";
import { isDividerType, isNoSpaceType } from "../../services/space/space.service";
import { SpaceKind, SpaceKindType } from "../../types/space/enum";

/**
 * 전체 Space 상태를 관리하는 Atom.
 * 포함 요소:
 * - SpaceType: Normal, Default, Divider
 * &&
 * - itemStatus: inProgress
 */
export const _inProgressSpaceListState = atom<SpaceType[] | null>({
  key: "inProgressSpaceListState",
  default: null,
});

/** TaskDetail > TaskSetting에서 archived된 space 색상도 보여주기 위한 상태
 * 바로 위에 inProgressSpaceListState가 너무 많은 곳에서 사용되고 있음
 * 아래에서 inProgressSpaceListState를 set할 때 함께 업데이트 해주도록 작업
 * // WARN: 여기는 TaskSetting에서 space 정보 display, 그리고 completed 상태를 저장하는 용도로만 사용하세요
 * author @brandonwie
 */
const _inProgressAndCompletedSpaceListState = atom<SpaceType[]>({
  key: "inProgressAndCompletedSpaceListState",
  default: [], // built-in method를 ? 연산자 없이 사용하기 위해 null 쓰지 않음
});

/** inProgressSpaceListState를 위한 wrapper selector
 * inProgressSpaceListState가 업데이트될 때 inProgressAndCompletedSpaceListState도 함께 업데이트
 * author @brandonwie
 */
export const inProgressSpaceListState = selector<SpaceType[] | null>({
  key: "inProgressSpaceListStateWrapper",
  get: ({ get }) => {
    return get(_inProgressSpaceListState);
  },
  set: ({ set, get }, newValue: SpaceType[] | null | DefaultValue) => {
    // Handle DefaultValue case (when reset is called)
    if (newValue instanceof DefaultValue) {
      set(_inProgressSpaceListState, null);
      set(_inProgressAndCompletedSpaceListState, []);
      return;
    }

    // inProgress는 원래대로 업데이트
    set(_inProgressSpaceListState, newValue);

    // Get current completed spaces and merge with new in-progress spaces
    const currentInProgressAndCompleted = get(_inProgressAndCompletedSpaceListState) ?? [];
    const newInProgress = newValue ?? [];

    // inProgress와 completed가 모두 없는 경우
    if (currentInProgressAndCompleted.length === 0 && newInProgress.length === 0) {
      set(_inProgressAndCompletedSpaceListState, []);
      return;
    }

    // inProgress 업데이트가 될 때 inProgressAndCompleted 업데이트 (자동 업데이트 됨)
    const mergedList = [
      ...newInProgress,
      ...(currentInProgressAndCompleted?.filter(
        (inProgressAndCompletedItem: SpaceType) =>
          !newInProgress.some(
            (inProgress: SpaceType) => inProgress.id === inProgressAndCompletedItem.id
          )
      ) || []),
    ];

    set(_inProgressAndCompletedSpaceListState, mergedList);
  },
});

// Export the completed list atom for direct access when needed
export const inProgressAndCompletedSpaceListState = _inProgressAndCompletedSpaceListState;

/**
 * 전체 Space에서 InProgressSpace List를 반환함.
 */
export const inProgressSpaceIdListSelector = selector({
  key: "spaceIdListSelector",
  get: ({ get }) => {
    const inProgressSpaceList = get(_inProgressSpaceListState) || [];
    return inProgressSpaceList.map((space) => space.id);
  },
});

/**
 * 전체 Space에서 특정 타입들만 필터링하여 반환하는 selectorFamily
 */
export const spaceTypesSelector = selectorFamily<SpaceType[], Array<SpaceKindType>>({
  key: "spaceTypesSelector",
  get:
    (spaceTypes) =>
    ({ get }) => {
      const spaceList = get(_inProgressSpaceListState) || [];

      return spaceList.filter((space) => {
        if (spaceTypes.includes(SpaceKind.Default) && isNoSpaceType(space)) return true;
        if (spaceTypes.includes(SpaceKind.Divider) && isDividerType(space)) return true;
        if (spaceTypes.includes(SpaceKind.Normal) && !isNoSpaceType(space) && !isDividerType(space))
          return true;
        return false;
      });
    },
});

/**
 * NoSpace 타입의 Space ID를 단일 값으로 반환하는 Selector
 * - spaceTypesSelector를 활용해 NoSpace를 찾아 ID 반환
 * - NoSpace가 없는 경우 null 반환
 */
export const noSpaceIdSelector = selector({
  key: "noSpaceIdSelector",
  get: ({ get }) => {
    // spaceTypesSelector를 활용하여 Default(NoSpace) 타입만 가져옴
    const noSpaces = get(spaceTypesSelector([SpaceKind.Default]));

    // 첫 번째 NoSpace의 ID를 반환하거나, 없으면 null 반환
    return noSpaces.length > 0 ? noSpaces[0].id : null;
  },
});

/**
 * deleted space List : query로 직접 사용
 */

/**
 * archived space List : query로 직접 사용
 */
