import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  inProgressAndCompletedSpaceListState,
  noSpaceIdSelector,
  spaceTypesSelector,
} from "../../../recoil/spaces/inProgressSpaceListState";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import SpaceAddModal from "./SpaceAddModal";
import PropertyTitle from "../Common/PropertyTitle";

import { ReactComponent as ProjectIcon } from "../../../assets/TaskDetail/project.svg";
import { ReactComponent as AddIcon } from "../../../assets/Common/add-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";

import clsx from "clsx";

import styles from "./SpaceModal.module.css";
import { createSpacePayload, getSpaceColor } from "../../../services/space/space.service";
import { useCreateSpaceMutation } from "../../../react-query/space/core/useCreateSpaceMutation";
import { SpaceKind } from "../../../types/space/enum";

const ProjectRow = ({ title, spaceId, color, onClick }) => {
  return (
    <div
      className={styles["projectRow-body"]}
      onClick={() => onClick(title, spaceId)}
    >
      <div
        className={styles["projectRow-body-icon"]}
        style={{
          backgroundColor: "" + color,
          boxShadow: "0 0 0 2px " + color + " inset",
        }}
      ></div>
      <div className={styles["projectRow-body-title"]}>{title}</div>
    </div>
  );
};

const AddBtn = ({ text, onClick }) => {
  return (
    <>
      <div
        className={styles["projectRow-add"]}
        onClick={onClick}
      >
        <AddIcon className={styles["projectRow-add-icon"]} />
        <span className={styles["projectRow-add-title"]}>{text}</span>
      </div>
    </>
  );
};

export default function SpaceModal({ expand, projectDropdownRef }) {
  // Recoil states

  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const inProgressSpaceList = useRecoilValue(spaceTypesSelector(SpaceKind.Normal));
  const inProgressAndCompletedSpaceList = useRecoilValue(inProgressAndCompletedSpaceListState);
  const noSpaceId = useRecoilValue(noSpaceIdSelector);

  // React states
  const [isProjectAddModal, setIsProjectAddModal] = useState(false);
  const [isSpaceDropdownOpen, setIsSpaceDropdownOpen] = useState(false);

  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [projectListModalPosition, setProjectListModalPosition] = useState({
    top: 0,
    left: 0,
    width: 320,
  });

  const { mutate: createSpace } = useCreateSpaceMutation();

  // Memoized values
  const data = useMemo(() => taskDetail.data, [taskDetail]);

  // Custom hooks
  const trackCall = useJuneTrackCall();

  // Refs
  const triggerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (
        projectDropdownRef.current &&
        !projectDropdownRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsSpaceDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [projectDropdownRef]);

  const handleScroll = useCallback(() => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  }, [scrollTimeout]);

  useEffect(() => {
    const element = document.querySelector(`.${styles.wrap}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const handleSelectProject = (title, spaceId) => {
    setIsSpaceDropdownOpen(false);
    updateTaskConfig("spaceId", spaceId);
  };

  const updateTaskConfig = (key, value) => {
    setTaskDetail((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  };

  const handleProjectAdd = () => {
    setIsProjectAddModal(true);
  };

  const handleAddProject = (newProjectTitle) => {
    const newItem = createSpacePayload(newProjectTitle, inProgressSpaceList);

    createSpace(newItem, {
      onSuccess: () => {
        trackCall("create_space", { location: "task_page" });
      },
    });
  };

  const handleDeleteProject = (event) => {
    event.stopPropagation();

    setTaskDetail((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        spaceId: noSpaceId,
      },
    }));
  };
  const handleIsPropertyModal = (event) => {
    event.stopPropagation();
    setIsSpaceDropdownOpen(!isSpaceDropdownOpen);
    handleSetModalPosition(event);
  };

  const handleKeyDown = (event) => {
    // taskDetail 꺼지지 않도록 이벤트 전파 막기
    event.stopPropagation();

    if (event.key === "Escape") {
      setIsSpaceDropdownOpen(false);

      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  };

  const handleSetModalPosition = (event) => {
    event.stopPropagation();
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setProjectListModalPosition({
        top: rect.top + rect.height + 5 + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  };
  return (
    <>
      <div
        className={styles["setting-property-btn"]}
        onKeyDown={handleKeyDown}
      >
        <PropertyTitle
          Icon={ProjectIcon}
          label="Projects"
          expand={expand}
        />
        <div
          className={clsx(styles["setting-property"], {
            [styles["setting-property--exapnd"]]: expand,
          })}
          ref={triggerRef}
        >
          {data.spaceId && noSpaceId && data.spaceId !== noSpaceId ? (
            <div
              className={clsx(styles.selected_project, {
                [[styles.selected_project_mini]]: !expand,
              })}
              style={{
                backgroundColor:
                  "" + getSpaceColor(inProgressAndCompletedSpaceList, data.spaceId) + "40",
                border: "1px solid" + getSpaceColor(inProgressAndCompletedSpaceList, data.spaceId),
              }}
            >
              <span
                className={styles["projectRow-body-icon"]}
                style={{
                  backgroundColor:
                    "" + getSpaceColor(inProgressAndCompletedSpaceList, data.spaceId),
                  boxShadow:
                    "0 0 0 2px " +
                    getSpaceColor(inProgressAndCompletedSpaceList, data.spaceId) +
                    " inset",
                }}
              ></span>
              <span
                className={`${styles["setting-property-label"]} ${data.spaceId && styles["label-on"]}`}
              >
                {
                  inProgressAndCompletedSpaceList.filter(
                    (project) => project.id === data.spaceId
                  )[0]?.title
                }
              </span>

              <div
                className={styles.selectedGuestRowDelete}
                onClick={handleDeleteProject}
              >
                <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
              </div>
            </div>
          ) : (
            <button
              className={styles.property__empty_title}
              onClick={handleIsPropertyModal}
            >
              <span>Add a space</span>
            </button>
          )}

          {isSpaceDropdownOpen &&
            createPortal(
              <div
                className={clsx(styles["wrap"], { [styles["showScrollbar"]]: isScrolling })}
                ref={projectDropdownRef}
                style={projectListModalPosition}
              >
                {inProgressSpaceList.map((item) => (
                  <ProjectRow
                    key={item.id}
                    title={item.title}
                    spaceId={item.id}
                    color={item.color}
                    onClick={handleSelectProject}
                  />
                ))}
                <AddBtn
                  text="New Space"
                  onClick={handleProjectAdd}
                />
                {isProjectAddModal && (
                  <SpaceAddModal
                    onSave={(newProjectTitle) => {
                      handleAddProject(newProjectTitle);
                    }}
                    onClose={() => setIsProjectAddModal(false)}
                  />
                )}
              </div>,
              document.body
            )}
        </div>
      </div>
    </>
  );
}
