import { Priority } from "../../types/block/enum";
import { BlockNoteType, BlockType } from "../../types/block/type";
import {
  isChangedBlockProperty,
  normalizeValue,
  parseDate,
} from "../../utils/common/block/checkBlockChangeUtils";
import { areDateTimeEqual } from "../../utils/common/dateTime/areDateTimeEqual";

interface ChangeDetectorParams {
  data: BlockType;
  initialData: BlockType;
  isPendingMeetingCode: boolean;
  isNewBlock: boolean;
}

interface ChangeResult {
  hasChanged: boolean;
  changedFields: Record<string, any>;
}

export const isStartDateChanged = (
  initialStart: Date | null,
  currentStart: Date | null
): boolean => {
  if (!initialStart && !currentStart) return false;
  if (!currentStart) return true;

  return !areDateTimeEqual(initialStart, currentStart);
};

export const isEndDateChanged = (
  initialEnd: Date | string | null | undefined,
  currentEnd: Date | string | null | undefined
): boolean => {
  if (!initialEnd && !currentEnd) return false;
  if (!currentEnd) return true;
  const normalizedInitialEnd = normalizeValue(initialEnd);
  const normalizedCurrentEnd = normalizeValue(currentEnd);

  return new Date(normalizedInitialEnd).getTime() !== new Date(normalizedCurrentEnd).getTime();
};

/**
 * NOTE 노트가 삭제된 경우 아래 세 가지 경우를 비교하기 위해 isNoteEmpty 함수 사용
 *
 * 1. 완전히 빈 배열 []
 * 2. 빈 content를 가진 블록 [{content: []}]
 * 3. 공백만 있는 content [{content: [{text: " "}]}]
 *
 *  */

export const isNoteEmpty = (noteData: BlockNoteType[] | undefined) => {
  if (!noteData || noteData.length === 0) return true;

  return noteData.every(
    (item) => !item.content || item.content.length === 0 || !item.content[0]?.text
  );
};

export const checkIsNoteChanged = (
  initialNote: BlockNoteType[] | undefined,
  updatedNote: BlockNoteType[] | undefined
): boolean => {
  // both empty
  if (isNoteEmpty(initialNote) && isNoteEmpty(updatedNote)) return false;

  // one side empty
  if (isNoteEmpty(initialNote) || isNoteEmpty(updatedNote)) return true;

  return isChangedBlockProperty(initialNote, updatedNote);
};

/**
 * 블록 데이터의 변경 사항을 감지하고 변경된 필드를 반환
 * TODO 중복 로직 제거 필요
 */

export const checkBlockChanges = ({
  data,
  initialData,
  isPendingMeetingCode,
  isNewBlock = false,
}: ChangeDetectorParams): ChangeResult => {
  const changedFields: Partial<BlockType> = {};
  const changedFlags: Partial<Record<keyof BlockType, boolean>> = {};

  const initialStart = parseDate(initialData?.start);
  const currentStart = parseDate(data?.start);
  const initialEnd = parseDate(initialData?.end);
  const currentEnd = parseDate(data?.end);

  const titleChanged = isChangedBlockProperty(initialData?.title ?? "", data?.title ?? "");
  if (titleChanged) changedFields.title = data?.title ?? "";

  // NOTE 기획: 새로 생성된 블록일 경우 blockType이 바뀌어도 save 버튼이 활성화 되지 않고, 이외 변경사항 없을 경우 save 버튼 비활성화
  // 즉, isNewBlock일 경우 blockType만 바뀐건 저장하지 않음
  let blockTypeChanged = isChangedBlockProperty(initialData.blockType, data.blockType);
  if (isNewBlock) blockTypeChanged = false;
  if (blockTypeChanged) changedFields.blockType = data.blockType;

  const visibilityChanged = isChangedBlockProperty(initialData.visibility, data.visibility);
  if (visibilityChanged) changedFields.visibility = data.visibility;

  const transparencyChanged = isChangedBlockProperty(initialData.transparency, data.transparency);
  if (transparencyChanged) changedFields.transparency = data.transparency;

  const priorityChanged = isChangedBlockProperty(
    initialData?.priority ?? Priority.DEFAULT,
    data?.priority ?? Priority.DEFAULT
  );
  if (priorityChanged) changedFields.priority = data.priority;

  const locationChanged = isChangedBlockProperty(initialData.location, data.location);
  if (locationChanged) changedFields.location = data.location;

  const spaceIdChanged = isChangedBlockProperty(initialData?.spaceId, data?.spaceId);
  if (spaceIdChanged) changedFields.spaceId = data.spaceId;

  const startChanged = isStartDateChanged(initialStart, currentStart);
  if (startChanged) changedFields.start = data.start;

  const endChanged = isEndDateChanged(initialEnd, currentEnd);
  if (endChanged) changedFields.end = data.end;

  const allDayChanged = isChangedBlockProperty(initialData?.allDay ?? false, data?.allDay ?? false);
  if (allDayChanged) changedFields.allDay = data.allDay;

  const linksChanged = isChangedBlockProperty(initialData?.linkData ?? [], data?.linkData ?? []);
  if (linksChanged) changedFields.linkData = data.linkData;

  const recurrenceChanged = isChangedBlockProperty(
    initialData?.recurrence ?? [],
    data?.recurrence ?? []
  );
  if (recurrenceChanged) changedFields.recurrence = data.recurrence;

  const attendeesChanged = isChangedBlockProperty(
    initialData?.attendees ?? [],
    data?.attendees ?? []
  );

  if (attendeesChanged) changedFields.attendees = data?.attendees;

  const hangoutLinkChanged = isChangedBlockProperty(
    initialData?.hangoutLink ?? "",
    data?.hangoutLink ?? ""
  );
  if (hangoutLinkChanged) changedFields.hangoutLink = data?.hangoutLink;

  const isNoteChanged = checkIsNoteChanged(initialData?.note ?? [], data?.note ?? []);
  if (isNoteChanged) changedFields.note = data.note;

  const totalVisibilityChanged = visibilityChanged || transparencyChanged;

  if (isPendingMeetingCode) {
    return {
      hasChanged: false,
      changedFields: {},
    };
  }

  const hasChanged =
    blockTypeChanged ||
    totalVisibilityChanged ||
    priorityChanged ||
    locationChanged ||
    titleChanged ||
    spaceIdChanged ||
    startChanged ||
    endChanged ||
    recurrenceChanged ||
    attendeesChanged ||
    hangoutLinkChanged ||
    linksChanged ||
    allDayChanged ||
    isNoteChanged;

  return {
    hasChanged,
    changedFields,
  };
};
