import { Flex } from "@chakra-ui/react";
import CalendarCustom from "../CalendarCustom";
import WikiWrapper from "../Wiki/WikiWrapper";
import { viewModeState } from "../../recoil/view/viewMode";
import { useRecoilValue } from "recoil";

{
  /* NOTE 더 적절한 네이밍 제안 받습니다 */
}

/**
 * @param {object} props
 * @param {boolean} props.hasBlocksSynchronizedWithGoogle - 백엔드가 보내는 block들과 Google Calendar 동기화 여부
 */
export const RightMainContents = ({
  hasBlocksSynchronizedWithGoogle,
}: {
  hasBlocksSynchronizedWithGoogle: boolean;
}) => {
  const viewMode = useRecoilValue(viewModeState);
  const isCalendarMode = viewMode === "calendar";
  return (
    <Flex
      flex={1}
      minW="422px"
      position="relative"
      borderRadius="8px"
      border="1px solid #313435"
      overflow="hidden"
    >
      {isCalendarMode ? (
        <CalendarCustom hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle} />
      ) : (
        <WikiWrapper />
      )}
    </Flex>
  );
};
