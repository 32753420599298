import { CreateSpaceType, SpaceIdType, SpaceType } from "../../types/space";

import { DEFAULT_SPACE_COLOR } from "../../constants/space";
import { DividerTitle, SpaceStatus, SpaceKind } from "../../types/space/enum";
import { PROJECT_LIST_COLOR } from "../../constants/projectFilterConst";

export const getInProgressSpaceList = (projects: SpaceType[]) => {
  return projects.filter((project) => {
    if (!isSpaceType(project)) return true;
    return project.itemStatus === SpaceStatus.InProgress;
  });
};

export const createSpacePayload = (
  newTitle: string,
  projectList: SpaceType[] | null
): CreateSpaceType => {
  return {
    spaceType: SpaceKind.Normal || SpaceKind.Divider,
    title: newTitle,
    color: getNextAvailableSpaceColor(projectList),
    itemStatus: SpaceStatus.InProgress,
  };
};

export const createDividerPayload = (displayOrder?: number): CreateSpaceType => {
  return {
    title: DividerTitle.DividerTitle,
    spaceType: SpaceKind.Divider,
    itemStatus: SpaceStatus.InProgress,
    color: "#667EFF",
    ...(displayOrder !== undefined && { displayOrder: displayOrder }),
  };
};

export const isNoSpaceType = (item: SpaceType): item is SpaceType => {
  return item.spaceType === SpaceKind.Default;
};

export const isDividerType = (item: SpaceType): item is SpaceType => {
  return item.spaceType === SpaceKind.Divider;
};

export const isSpaceType = (item: SpaceType | undefined | null): item is SpaceType => {
  return (
    !!item &&
    typeof item === "object" &&
    "id" in item &&
    "title" in item &&
    "status" in item &&
    "creator" in item
  );
};

// 기획 : no space를 제외한 1개의 space만 선택된 경우 projectId를 삽입
export const extractSpaceId = (spaceIdList: number[] | null) => {
  // NOTE 해당 부분에서는 noSpace일 경우 spaceId 값이 아예 없도록 반환, 그리고 저장 시 noSpaceId값 넣어줌
  // 전역상태를 계속 참조해야 해서 이렇게 처리
  if (!spaceIdList) return {};

  return spaceIdList.length === 1 ? { spaceId: spaceIdList[0] } : {};
};

export const getAllSpaceIds = (projectList: SpaceType[]) => {
  return projectList
    .filter((space): space is SpaceType => !isDividerType(space))
    .map((space) => space.id)
    .filter((id) => id !== undefined);
};

export const isSpaceInProgress = (space: SpaceType): boolean => {
  return space.itemStatus === SpaceStatus.InProgress;
};

export const getNormalSpaceIds = (
  selectedSpaceIds: SpaceIdType[],
  projectList: SpaceType[]
): Set<SpaceIdType> => {
  const activeSpaceIds = new Set<SpaceIdType>([]);

  selectedSpaceIds.forEach((id) => {
    const space = projectList.find((project) => project.id === id);
    if (space && !isDividerType(space) && !isNoSpaceType(space) && isSpaceInProgress(space)) {
      activeSpaceIds.add(id);
    }
  });
  return activeSpaceIds;
};

export const addSelectedSpace = (
  currentSpaces: SpaceIdType[] | null,
  newSpaceId: SpaceIdType | null
): SpaceIdType[] => {
  // NOTE selected space list 없고 select된 spcae 도 없는 경우 빈 배열 반환
  if (!currentSpaces && !newSpaceId) {
    return [];
  }

  // NOTE selected space list 없고 select된 space가 있는 경우 select된 space 반환
  if (!currentSpaces && newSpaceId) {
    return [newSpaceId];
  }

  // NOTE 타입 스크립트에 항상 값이 있는 걸 명시
  const spaces = currentSpaces ?? [];

  // NOTE select된 space가 selected space list에 없는 경우 추가
  if (newSpaceId && !spaces.includes(newSpaceId)) {
    return [...spaces, newSpaceId];
  }

  return spaces;
};

export const getSpaceColor = (
  spaceList: SpaceType[] | null,
  spaceId?: number | null,
  fallbackColor?: string | null
) => {
  // Empty string까지 걸러내기 위해 `||` 사용
  const fallbackColorToUse = fallbackColor || DEFAULT_SPACE_COLOR;
  if (!spaceId) return fallbackColorToUse;
  const space = spaceList?.find((space) => space.id === spaceId);
  return space ? space.color : fallbackColorToUse;
};

export const getUsedColors = (spaces: SpaceType[] | null): string[] => {
  if (!spaces) return [];
  return spaces.map((space) => space?.color).filter((color): color is string => !!color);
};

export const getNextAvailableSpaceColor = (spaces: SpaceType[] | null): string => {
  const assignedColors = getUsedColors(spaces);
  const unusedColors = PROJECT_LIST_COLOR.filter((color) => !assignedColors.includes(color));

  if (unusedColors.length > 0) {
    return unusedColors[0];
  }

  const nextIndex = assignedColors.length % PROJECT_LIST_COLOR.length;
  return PROJECT_LIST_COLOR[nextIndex];
};

export const isNonDeletedSpace = (space: SpaceType) => {
  return (
    isSpaceType(space) &&
    (space.itemStatus === SpaceStatus.InProgress || space.itemStatus === SpaceStatus.Completed)
  );
};
