import styles from "../Wiki.module.css";
import ProjectItem from "../ProjectItem";

export function ProjectList({ projectData, onClick }) {
  return (
    <div className={styles.project__body}>
      <div className={styles.project__container}>
        {projectData &&
          projectData?.map((project) => {
            return <ProjectItem key={project.id} projectRowData={project} onClick={onClick} />;
          })}
      </div>
    </div>
  );
}
