import { ItemStatus, RecurringOptionType } from "../../types/block/enum";
import { BlockType, UpdateBlockType, UpdateRecurrenceBlockType } from "../../types/block/type";
import useHandleClientBlockStateDelete from "../block/useHandleClientBlockStateDelete";
import { handleRecurringOptions } from "../../services/taskDetail/recurring.service";
import { useOpenRecurringPopup } from "../useOpenRecurringPopup";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CANCELED } from "../../services/taskDetail/block.service";

type DeleteMutationParams = {
  id: number | string;
  payload: UpdateBlockType | UpdateRecurrenceBlockType;
  data?: BlockType;
};

type DeleteMutationResponse = AxiosResponse<any, any>;

type DeleteMutation = UseMutationResult<
  DeleteMutationResponse,
  Error,
  DeleteMutationParams,
  unknown
>;

function useHandleInboxBlockDelete() {
  const { handleClientBlockDelete } = useHandleClientBlockStateDelete();
  const { openRecurringPopup } = useOpenRecurringPopup();

  // 공통으로 사용되는 이벤트 업데이트 데이터 생성 함수
  const createEventUpdateData = (block: BlockType, isRecurringBlock: boolean) => {
    const commonUpdateData = {
      itemStatus: ItemStatus.DELETED,
      allDay: block.allDay,
      hangoutLink: block.hangoutLink,
      meetingCode: block.meetingCode,
      linkData: block.linkData,
      attendees: block.attendees,
      location: block.location,
      priority: block.priority,
    };

    const updateData = {
      ...commonUpdateData,
      id: block.id,
    };

    // 반복블록인 경우 payload에 type, originalStart 추가
    const recurringUpdateData = {
      ...commonUpdateData,
      id: block.id,
      originalStart: block.originalStart ?? block.start,
    };

    return isRecurringBlock ? recurringUpdateData : updateData;
  };

  const getFinalPayload = (
    updateData: UpdateBlockType | UpdateRecurrenceBlockType,
    recurringOption: RecurringOptionType | null
  ) => {
    return recurringOption
      ? {
          ...updateData,
          type: recurringOption,
        }
      : updateData;
  };

  const handleDeleteBlock = async (block: BlockType, deleteMutation: DeleteMutation) => {
    const isRecurringBlock =
      ((block.recurrence && block.recurrence?.length > 0) || Boolean(block.originalId)) ?? false;

    try {
      const updateData = createEventUpdateData(block, isRecurringBlock);
      const updateBlockData = { ...block, ...updateData };

      const recurringOption = await handleRecurringOptions({
        blockData: updateBlockData,
        openRecurringPopup,
        initialRecurrence: block?.recurrence,
        initialStart: block?.start,
        initialEnd: block?.end,
      });

      if (recurringOption === CANCELED) {
        // NOTE 추후 cancel 처리 toast 를 띄우거나 다른 안내 방식 추가
        return;
      }

      handleClientBlockDelete({ updateBlockData, recurringOption });
      const { id, ...payload } = updateData;
      const finalPayload = getFinalPayload(payload, recurringOption);
      deleteMutation.mutate({ id, payload: finalPayload, data: block });
    } catch (error) {
      console.error("Event deletion process failed:", error);
    }
  };

  return { handleDeleteBlock };
}

export default useHandleInboxBlockDelete;
