import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { CreateSpaceType, SpaceIdType, SpaceType, UpdateSpaceType } from "../../../types/space";

import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { useRecoilState } from "recoil";
import { isSpaceType } from "../../../services/space/space.service";
import { SpaceStatus } from "../../../types/space/enum";
import { spaceQueryKeys } from "../queries";

export const patchSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: SpaceIdType,
  payload: Partial<CreateSpaceType>
): Promise<SpaceType> => {
  const response = await api.patch(`/spaces/${projectId}`, payload);

  return response.data;
};

export const useUpdateSpaceMutation = (
  options?: UseMutationOptions<
    SpaceType,
    AxiosError,
    { id: SpaceIdType; payload: Partial<UpdateSpaceType> }
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(inProgressSpaceListState);

  return useMutation<SpaceType, AxiosError, { id: SpaceIdType; payload: Partial<UpdateSpaceType> }>(
    {
      mutationFn: ({ id, payload }) => patchSpaceApi(api, id, payload),
      onMutate: async ({ id, payload }) => {
        await queryClient.cancelQueries({
          queryKey: spaceQueryKeys.status(SpaceStatus.InProgress),
        });

        // TODO 가능하다면 추후 전역상태 제거하고 캐시에서 업데이트하는 방식으로 변경
        // 단 전역상태 뗄 수 있는지 spec 확인 필요(select 때문)
        setProjectList((prev: SpaceType[] | null) => {
          if (!prev) return null;
          return prev.map((space) => (space.id === id ? { ...space, ...payload } : space));
        });
        return { projectList };
      },
      onSuccess: (res: SpaceType) => {
        if (projectList) {
          const updatedProjectList = projectList.map((project) => {
            if (!isSpaceType(project)) return project;
            return project.id === res.id ? { ...res } : project;
          });
          setProjectList(updatedProjectList);
        }
        queryClient.invalidateQueries({
          queryKey: spaceQueryKeys.status(SpaceStatus.InProgress),
        });
      },

      ...options,
    }
  );
};
