import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import useApi from "../../services/auth/useApi";
import styles from "./Settings.module.css";

import Account from "./Account";
import Calendar from "./Calendar";
import DeletePopup from "./DeletePopup";
import Integrations from "./Integrations";

import closeImg from "../../assets/Settings/close.png";
import { accountState } from "../../recoil/account/accountStateV2";

const useTabs = (initialTab, allTabs) => {
  const [currentIndex, setCurrentIndex] = useState(initialTab);

  return {
    currentIndex: currentIndex,
    currentItem: allTabs[currentIndex],
    changeItem: setCurrentIndex,
  };
};

export default function Settings({ settingState, onClose, loadData }) {
  const settingsRef = useRef(null);
  const accountData = useRecoilValue(accountState);

  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [loginData, setLoginData] = useState({
    emails: [],
    familyName: "",
    givenName: "",
    photoUrl: "",
    invitees: [],
    role: "",
    invitedBy: "",
  });

  const loadLoginData = () => {
    const invitees = accountData?.invitees ?? [];
    const combinedAccounts = [accountData.email];

    setLoginData({
      emails: combinedAccounts,
      familyName: accountData.familyName ?? "",
      givenName: accountData.givenName ?? "",
      photoUrl: accountData.photoUrl,
      invitees: invitees,
      role: accountData.membership,
    });
  };

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", escKeyModalClose);
    return () => window.removeEventListener("keydown", escKeyModalClose);
  }, [onClose]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (settingsRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    loadLoginData();
  }, [accountData]);

  useEffect(() => {
    loadLoginData();
    if (settingState === "Account") changeItem(0);
    if (settingState === "Integrations") changeItem(1);
  }, []);

  const toggleDeleteAccountPopup = useCallback(() => {
    setIsDeletePopup((current) => !current);
  }, []);

  const tabs = [
    {
      tab: "Account",
      content: (
        <Account
          onDelete={toggleDeleteAccountPopup}
          loginData={loginData}
          onReLoadLoginData={loadData}
        />
      ),
    },
    {
      tab: "Integrations",
      content: <Integrations loginData={loginData} onReLoadLoginData={loadData} />,
    },
    {
      tab: "Calendar",
      content: <Calendar />,
    },
  ];
  const { currentIndex, currentItem, changeItem } = useTabs(0, tabs);

  return (
    <div className={styles.modal__backdrop}>
      <div className={styles.settings} ref={settingsRef} onClick={(e) => e.stopPropagation()}>
        <div className={styles.settings__area}>
          <div style={{ marginBottom: "22px" }}>
            <span className={styles.settings__title}>Settings</span>
          </div>

          {tabs.map((section, index) => (
            <div
              className={`${
                currentIndex === index
                  ? styles.settings__menu__title__selected
                  : styles.settings__menu__title
              }`}
              onClick={() => changeItem(index)}
              key={index}
            >
              {section.tab}
            </div>
          ))}
          <div className={styles.settings__close} onClick={onClose}>
            <img src={closeImg} alt="close"></img>
          </div>
        </div>

        <div className={styles.settings__contents__area}>{currentItem.content}</div>

        {isDeletePopup ? (
          <DeletePopup toggleDeletePopup={toggleDeleteAccountPopup} loginData={loginData} />
        ) : null}
      </div>
    </div>
  );
}
