import React, { useEffect } from "react";
import { Textarea, Text, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { BasicBlockType } from "../../../types/block/enum";

interface TaskDetailTitleProps {
  titleText: string;
  titleRef:
    | React.RefObject<HTMLTextAreaElement>
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
  blockType: BasicBlockType;
  isFullPage: boolean;
  isTitleFocused: boolean;
  setIsTitleFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeLocalTitle: (newTitle: string) => void;
  onSave: (titleText: string) => void;
  updateTaskConfig: (key: string, value: any) => void;
  isNewTask: boolean;
}

const TaskDetailTitle = ({
  titleText,
  titleRef,
  blockType,
  isFullPage,
  isTitleFocused = false,
  setIsTitleFocused,
  handleChangeLocalTitle,
  onSave,
  updateTaskConfig,
  isNewTask,
}: TaskDetailTitleProps) => {
  useEffect(() => {
    // isNewTask이거나 타이틀이 없는 경우 타이틀 포커스
    if (isNewTask || !titleText) {
      handleTitleFocus();
    }
  }, [isNewTask, titleText]);

  const handleResizeTitleHeight = () => {
    if (titleRef?.current) {
      titleRef.current.style.height = "auto";

      if (titleRef.current.scrollHeight < 98) {
        titleRef.current.style.height = titleRef.current.scrollHeight + "px";
      } else {
        titleRef.current.style.height = "98px";
      }
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChangeLocalTitle(e.target.value);
    handleResizeTitleHeight();
  };

  const handleTitleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLDivElement>) => {
    e.stopPropagation();
    setIsTitleFocused(false);
    updateTaskConfig("title", titleText);
  };

  const handleTitleFocus = () => {
    setIsTitleFocused(true);

    requestAnimationFrame(() => {
      if (titleRef?.current instanceof HTMLTextAreaElement) {
        const len = titleRef?.current?.value.length || 0;
        titleRef?.current?.setSelectionRange(len, len);
      }
      handleResizeTitleHeight();
      titleRef?.current?.focus();
    });
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // NOTE 기본 동작(줄바꿈)을 방지
      setIsTitleFocused(false);
      updateTaskConfig("title", titleText);
      onSave(titleText);
    } else if (e.key === "Enter" && e.shiftKey) {
      e.stopPropagation();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      setIsTitleFocused(false);
      titleRef?.current?.blur();
      updateTaskConfig("title", titleText);
    }

    if (e.key === "Backspace") {
      e.stopPropagation();
    }
  };
  return (
    <Flex
      position="relative"
      width="100%"
      height={"max-content"}
      alignItems={isFullPage ? "center" : "flex-start"}
      flex={isFullPage ? "1 0 0" : "initial"}
      alignSelf="stretch"
    >
      {isTitleFocused ? (
        <Textarea
          ref={titleRef as React.RefObject<HTMLTextAreaElement>}
          value={titleText}
          rows={1}
          variant="unstyled"
          fontSize={pxToRem(20)}
          lineHeight={pxToRem(30)}
          fontWeight={500}
          minHeight={pxToRem(38)}
          maxHeight={pxToRem(98)}
          height="auto"
          padding={`${pxToRem(4)} ${pxToRem(6)}`}
          overflow={"hidden"}
          color="white"
          borderRadius={pxToRem(5)}
          placeholder={`New ${blockType}`}
          _placeholder={{ color: "rgba(171, 173, 173, 0.4)" }}
          textOverflow="ellipsis"
          wordBreak={"break-all"}
          noOfLines={3}
          resize={"none"}
          onFocus={handleTitleFocus}
          _focus={{
            noOfLines: "initial",
            overflow: "auto",
            background: "#1a1c1c",
            outline: "none",
            animation: "inputActive 100ms cubic-bezier(0, 0, 1, 1) forwards",
          }}
          onKeyDown={handleTitleKeyDown}
          onBlur={handleTitleBlur}
          onChange={handleTitleChange}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        />
      ) : (
        <Flex
          ref={titleRef as React.RefObject<HTMLDivElement>}
          w={"full"}
        >
          <Text
            textStyle={"title2"}
            cursor="pointer"
            color="white"
            padding={`${pxToRem(4)} ${pxToRem(6)}`}
            borderRadius={pxToRem(5)}
            width="100%"
            noOfLines={3}
            overflow="hidden"
            textOverflow="ellipsis"
            wordBreak={"break-all"}
            onClick={handleTitleFocus}
          >
            {titleText ? titleText : `New ${blockType}`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
export default TaskDetailTitle;
