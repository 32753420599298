import { PopupActionType, PopupActions } from "../../types/block/popup";
import { BlockType } from "../../types/block/type";
import { areDateTimeEqual } from "../../utils/common/dateTime/areDateTimeEqual";
import { CANCELED } from "./block.service";

export const isRecurrencePatternChanged = (
  oldPattern: string[] | null | undefined,
  newPattern: string[] | null | undefined
): boolean => {
  return JSON.stringify(oldPattern) !== JSON.stringify(newPattern);
};

export const isDateChanged = (
  oldStart: Date | null | undefined,
  oldEnd: Date | null | undefined,
  newStart: Date | null | undefined,
  newEnd: Date | null | undefined
): boolean => {
  return (
    !areDateTimeEqual(new Date(oldStart || ""), new Date(newStart || "")) ||
    !areDateTimeEqual(new Date(oldEnd || ""), new Date(newEnd || ""))
  );
};

interface RecurringOptions {
  this: boolean;
  thisAndAfter: boolean;
  all: boolean;
}

export const getRecurringPopupOptions = (
  blockData: BlockType,
  initialRecurrence: string[] | null | undefined,
  initialStart: Date | null | undefined,
  initialEnd: Date | null | undefined
): RecurringOptions => {
  const options: RecurringOptions = {
    this: true,
    thisAndAfter: true,
    all: true,
  };

  const hasRecurrencePatternChanged = isRecurrencePatternChanged(
    initialRecurrence,
    blockData.recurrence
  );

  const hasDateChanged = isDateChanged(initialStart, initialEnd, blockData.start, blockData.end);

  if (hasRecurrencePatternChanged) {
    options.this = false;
  }

  if (hasDateChanged) {
    options.all = false;
    // TODO: 게스트가 있으면 thisAndAfter 안되도록 처리
    options.thisAndAfter = false;
  }

  return options;
};

export const shouldShowRecurringPopup = (
  initialRecurrence: string[] | null | undefined,
  blockData: BlockType
): boolean => {
  return Boolean(initialRecurrence) && Boolean(blockData.recurrence);
};

interface RecurringOptionsProps {
  blockData: BlockType;
  openRecurringPopup: (
    blockData: BlockType,
    action: PopupActionType,
    allEnabled: boolean,
    thisEnabled: boolean
  ) => Promise<any>;
  initialRecurrence: string[] | null | undefined;
  initialStart: Date | null | undefined;
  initialEnd: Date | null | undefined;
}

export const handleRecurringOptions = async ({
  blockData,
  openRecurringPopup,
  initialRecurrence,
  initialStart,
  initialEnd,
}: RecurringOptionsProps) => {
  if (!shouldShowRecurringPopup(initialRecurrence, blockData)) {
    return null;
  }

  const availableOptions = getRecurringPopupOptions(
    blockData,
    initialRecurrence,
    initialStart,
    initialEnd
  );

  return await openRecurringPopup(
    blockData,
    PopupActions.EDIT,
    availableOptions.all,
    availableOptions.this
  );
};
