export const NORMAL_BLOCK_OPACITY = 0.2;
export const MEETWITH_BLOCK_OPACITY = 0.08;

export const getBlockOpacity = ({
  isTaskDone,
  isEventDone,
  isNotGoing,
  isMeetWith,
}: {
  isTaskDone: boolean;
  isEventDone: boolean;
  isNotGoing: boolean;
  isMeetWith: boolean;
}) => {
  let opacity = NORMAL_BLOCK_OPACITY;

  if (isTaskDone || isEventDone || isNotGoing) {
    return opacity;
  }

  if (isMeetWith) {
    opacity = MEETWITH_BLOCK_OPACITY;
    return opacity;
  }

  return opacity;
};

export const getBorderClassName = ({ isNotDoneTask }: { isNotDoneTask: boolean }) => {
  let borderClassName = "eventBorder";
  if (isNotDoneTask) {
    borderClassName += " notDoneTaskBorder";
  }
  return borderClassName;
};
