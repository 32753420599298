import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { draggedEventState } from "../../../recoil/calendar/calendarStateV2";
import { juneTrack, useJuneTrackCall } from "../../../utils/june/analytics";
import TaskListEmptyDropArea from "../TaskListEmptyDropArea";
import TaskRow from "./InboxBlock/TaskRow";
import InboxInput from "./InboxInput";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { DraggedEventType } from "../../../types/block/BlockType";
import { InboxHeaderTab, ToggleVisibilityByTab } from "../../../types/inbox/inbox-headers";
import InboxGeneralList from "./InboxList/InboxGeneralList";
import InboxToggleList from "./InboxList/InboxToggleList";
import { pxToRem } from "../../../utils/styles/size";
import { Flex } from "@chakra-ui/react";
import TaskDividerBlock from "./Divider/TaskDividerBlock";
import { inboxDraggableListState } from "../../../recoil/taskList/inboxTaskListState";
import { TaskListWrapper } from "./TaskListWrapper";
import {
  taskPopupState,
  taskPopupVisibleSelector,
} from "../../../recoil/taskDetail/taskPopupState";
import InboxToCalendarDragLayer from "./InboxToCalendarDragLayer";

import useHandleJuneBlockData from "../../../hooks/june/useHandleJuneBlockdata";
import { inboxQueryKeys, useInboxGeneralQueryOptions } from "../../../react-query/inbox/queries";
import { BlockType } from "../../../types/block/type";
import { InboxTab, InboxToggleTitle } from "../../../types/inbox/enum";
import { BasicBlock, DragKind, ItemStatus, RecurringOption } from "../../../types/block/enum";
import {
  useDeleteInboxBlockMutationOptions,
  useReorderInboxBlockMutationOptions,
  useUpdateInboxBlockMutationOptions,
} from "../../../react-query/inbox/mutations";
import { useDeleteInboxBlockMutation } from "../../../react-query/inbox/useDeleteInboxBlockMutation";
import { useUpdateInboxCheckboxMutation } from "../../../react-query/inbox/useUpdateInboxCheckboxMutation";
import useHandleClientBlockStateDelete from "../../../hooks/block/useHandleClientBlockStateDelete";
import { removeClientOnlyFields } from "../../../utils/common/block/removeClientOnlyFields";
import { useUpdateTimeCalendarBlockMutation } from "../../../react-query/calendar/useUpdateTimeCalendarBlockMutation";
import { blockQueryKey } from "../../../react-query/block/queries";
import { JUNE_EVENT, JUNE_LOCATION } from "../../../hooks/june/juneEvent";
import { useDuplicateInboxBlockMutation } from "../../../react-query/inbox/useDuplicateInboxBlockMutation";

interface TaskListProps {
  activeTab: InboxHeaderTab;
  toggleVisibility: ToggleVisibilityByTab;
  /** 백엔드가 보내는 block들과 Google Calendar 동기화 여부 */
  hasBlocksSynchronizedWithGoogle: boolean;
}

export default function TaskList({
  activeTab,
  toggleVisibility,
  hasBlocksSynchronizedWithGoogle,
}: TaskListProps) {
  const trackCall = useJuneTrackCall();
  const queryClient = useQueryClient();

  const [isTaskListHovered, setIsTaskListHovered] = useState(false);
  const [taskSelectedId, setTaskSelectedId] = useState<number | null>(null);

  const draggedEvent = useRecoilValue(draggedEventState);
  const isTaskDetailVisible = useRecoilValue(taskPopupVisibleSelector);
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  const handleJuneBlockData = useHandleJuneBlockData();

  /** queries */
  // NOTE: Backend block-gcal sync 이후 당겨오기 위해 enable 옵션 추가
  const useInboxGeneralQuery = useQuery(
    useInboxGeneralQueryOptions({
      enabled: hasBlocksSynchronizedWithGoogle,
    })
  );

  /** mutations */
  const inboxGeneralQueryKey = useInboxGeneralQueryOptions({}).queryKey;
  const useReorderInboxBlock = useMutation(useReorderInboxBlockMutationOptions());
  const useUpdateInboxBlock = useMutation(useUpdateInboxBlockMutationOptions());
  const useDuplicateInboxBlock = useDuplicateInboxBlockMutation();
  const useDeleteInboxGeneralBlock = useDeleteInboxBlockMutation(inboxGeneralQueryKey);
  const useDeleteInboxDividerBlock = useMutation(useDeleteInboxBlockMutationOptions());
  const useUpdateInboxCheckbox = useUpdateInboxCheckboxMutation(inboxGeneralQueryKey);
  const useUpdateTimeCalendarBlock = useUpdateTimeCalendarBlockMutation();

  // 캘린더 클라이언트 상태 업데이트 훅
  const { handleClientBlockDelete } = useHandleClientBlockStateDelete();

  const [inboxDraggableList, setInboxDraggableList] = useRecoilState(inboxDraggableListState);
  useEffect(
    function initializeInboxDraggableList() {
      if (!useInboxGeneralQuery.data) return;
      setInboxDraggableList(useInboxGeneralQuery.data);
    },
    [useInboxGeneralQuery.data]
  );

  useEffect(
    function resetTaskSelectedId() {
      // inbox에서 taskDetail을 연 상태에서 calendar의 taskDetail을 열면 inbox의 taskSelectedId 초기화
      const isCurrentInboxBlockOpen = taskDetail.data?.id === taskSelectedId;
      if (taskSelectedId && !isCurrentInboxBlockOpen) {
        handleSaveTaskSelectedId(null);
      }
    },
    [taskSelectedId, taskDetail.data]
  );

  // 해당 함수는 inbox general에만 적용
  const handleDataDelete = async (data: BlockType) => {
    const updateData = {
      itemStatus: ItemStatus.DELETED,
      allDay: data.allDay,
      hangoutLink: data.hangoutLink,
      meetingCode: data.meetingCode,
      linkData: data.linkData,
      attendees: data.attendees,
      location: data.location,
      priority: data.priority,
    };
    useDeleteInboxGeneralBlock.mutate(
      { id: data.id, payload: updateData },
      {
        onSuccess: () => {
          juneTrack(JUNE_EVENT.DELETE_BLOCK, {
            location: activeTab === "Inbox" ? "inbox" : "today_inbox",
            type: BasicBlock.TASK,
          });
          // 휴지통이 열려있는 상태에서 삭제 시 휴지통에 데이터 추가
          queryClient.invalidateQueries({
            queryKey: blockQueryKey.getAllByParams({ itemStatus: ItemStatus.DELETED }),
          });
        },
      }
    );
  };
  const onClickCheckBox = (e: React.MouseEvent, block: BlockType) => {
    e.stopPropagation();
    const isDone = block.itemStatus === ItemStatus.COMPLETED;
    const newStatus = isDone ? ItemStatus.IN_PROGRESS : ItemStatus.COMPLETED;
    const updateData = {
      itemStatus: newStatus,
      allDay: block.allDay,
      hangoutLink: block.hangoutLink,
      meetingCode: block.meetingCode,
      linkData: block.linkData,
      attendees: block.attendees,
      location: block.location,
      priority: block.priority,
    };
    const updateBlock = {
      ...block,
      ...updateData,
    };
    useUpdateInboxCheckbox.mutate({ id: block.id, payload: updateData, data: updateBlock });

    // NOTE INBOX_GENERAL의 경우만 <InboxBlock> / handleClickCheckbox 사용하고 있지 않아서 따로 처리
    juneTrack(newStatus === ItemStatus.COMPLETED ? JUNE_EVENT.DONE_TASK : JUNE_EVENT.UNDONE_TASK, {
      location: "inbox",
    });
  };

  const handleDeleteDivider = (selectedDividerId: number) => {
    useDeleteInboxDividerBlock.mutate(selectedDividerId, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: inboxQueryKeys.all,
        });
        trackCall(JUNE_EVENT.DELETE_DIVIDER, { location: JUNE_LOCATION.INBOX });
      },
    });
  };

  const createNewBlockItem = (data: BlockType, isRecurringBlock: boolean, isDivider: boolean) => {
    if (isDivider) {
      return {
        title: "",
        blockType: BasicBlock.DIVIDER,
        visibility: null,
        transparency: null,
      };
    }
    return removeClientOnlyFields(data, isRecurringBlock);
  };

  const handleDuplicateInboxBlock = (e: React.MouseEvent, data: BlockType) => {
    const isDivider = data.blockType === BasicBlock.DIVIDER;
    const isRecurringBlock = Boolean(data.recurrence?.length || data.originalId);

    const { originalId, originalStart, ...recurringDeletedBlock } = data;

    const newItem = createNewBlockItem(recurringDeletedBlock, isRecurringBlock, isDivider);

    const displayOrderItem = { ...newItem, displayOrder: data.displayOrder };

    useDuplicateInboxBlock.mutate(
      { payload: displayOrderItem, originalBlock: data },
      {
        onSuccess: () => {
          juneTrack(isDivider ? JUNE_EVENT.DUPLICATE_DIVIDER : JUNE_EVENT.DUPLICATE_BLOCK, {
            // NOTE 해당 부분 June 확인 필요
            location: activeTab === "Inbox" ? "inbox" : "today_inbox",
            blockType: BasicBlock.TASK,
          });
        },
      }
    );
  };

  const handleSaveTaskSelectedId = (id: number | null) => {
    setTaskSelectedId(id);
  };

  const handleUpdateCalendarToInbox = async (block: DraggedEventType) => {
    const { hangoutLink, meetingCode, linkData, attendees, location, priority } = block;

    const isRecurringBlock =
      (block.recurrence && block.recurrence?.length > 0) || Boolean(block.originalId);

    const updatePayload = {
      hangoutLink,
      meetingCode,
      linkData,
      attendees,
      location,
      priority,
      start: null,
      end: null,
      allDay: false,
      itemStatus: ItemStatus.IN_PROGRESS,
      ...(isRecurringBlock && { recurrence: null }), // 반복 블록일 경우 recurrence 삭제
    };

    // 캘린더 클라이언트 상태 업데이트(inbox로 이동한 블록 삭제)
    // 반복 블록인 경우 전체 삭제
    handleClientBlockDelete({
      updateBlockData: block,
      recurringOption: isRecurringBlock ? RecurringOption.ALL : undefined,
    });

    useUpdateTimeCalendarBlock.mutate(
      {
        id: block.id,
        payload: updatePayload,
        prevData: block,
      },
      {
        onSettled: () => {
          trackCall("move_task");
        },
        onSuccess: (response) => {
          const originalBlockIds = inboxDraggableList.map((block) => block.id);
          handleReorder([response.id, ...originalBlockIds]);
        },
      }
    );
  };

  const handleReorder = (ids: number[]) => {
    useReorderInboxBlock.mutate({ ids });
  };

  const handleClickInboxBlock = useCallback(
    (e: React.MouseEvent, data: BlockType, ref: React.RefObject<HTMLDivElement>) => {
      if (taskSelectedId === data.id) {
        handleSaveTaskSelectedId(null);
        return resetTaskDetail();
      }

      const { clientX, clientY } = e;
      const rect = ref?.current?.getBoundingClientRect();
      const x = (rect?.right ?? 0) + 8;
      const y = rect?.top ?? 0;

      if (clientX !== 0 || clientY !== 0) {
        setTaskDetail({
          isVisible: true,
          data: data,
          modalPosition: { x: x, y: y },
          targetRect: null,
          onClose: () => {
            handleSaveTaskSelectedId(null);
          },
          handleDataDuplicate: handleDuplicateInboxBlock,
          type: activeTab === "Inbox" ? "inbox" : "today_inbox",
        });
      }
      handleSaveTaskSelectedId(data.id);

      handleJuneBlockData({
        action: "view_block_detail",
        location: activeTab === "Inbox" ? "inbox" : "today_inbox",
        blockType: BasicBlock.TASK,
      });
    },
    [taskSelectedId, handleSaveTaskSelectedId, handleDuplicateInboxBlock]
  );

  const memoizedTasksList = useMemo(() => {
    if (!useInboxGeneralQuery.data) {
      return (
        <TaskListEmptyDropArea
          onTasksReorderChange={handleReorder}
          handleUpdateTaskList={useUpdateInboxBlock.mutate}
          listType={DragKind.INBOX_TASK}
        />
      );
    }

    return inboxDraggableList.map((taskDataRow: BlockType, index: number) => {
      if (taskDataRow.blockType === BasicBlock.DIVIDER) {
        return (
          <TaskDividerBlock
            key={taskDataRow.id}
            index={index}
            data={taskDataRow}
            isSelected={taskSelectedId === taskDataRow.id}
            onDelete={() => handleDeleteDivider(taskDataRow.id)}
            onDuplicate={(e: React.MouseEvent) => handleDuplicateInboxBlock(e, taskDataRow)}
            onReorder={handleReorder}
            onSelect={handleSaveTaskSelectedId}
          />
        );
      }

      return (
        // TODO -> InboxBlock으로 통일 필요
        <TaskRow
          key={taskDataRow.id}
          index={index}
          data={taskDataRow}
          isSelected={taskSelectedId === taskDataRow.id}
          onDataDuplicate={handleDuplicateInboxBlock}
          onSelect={handleSaveTaskSelectedId}
          onDataDelete={handleDataDelete}
          isOverdue={false}
          onReorder={handleReorder}
          onClickBlock={handleClickInboxBlock}
          onClickCheckBox={onClickCheckBox}
        />
      );
    });
  }, [
    // WARN: 사용되지도 않는 selectedSpaceIdList가 포함되어 있음 WHY?
    selectedSpaceIdList,
    taskSelectedId,
    handleSaveTaskSelectedId,
    isTaskDetailVisible,
    handleClickInboxBlock,
    inboxDraggableList,
  ]);

  const flexGrow =
    activeTab === "Inbox"
      ? toggleVisibility.Inbox.overdue ||
        toggleVisibility.Inbox.completed ||
        toggleVisibility.Inbox.planned
        ? 0
        : 1
      : toggleVisibility.Today.overdue || toggleVisibility.Today.completed
        ? 0
        : 1;

  return (
    <TaskListWrapper>
      <InboxInput
        activeTab={activeTab}
        handleSaveTaskSelectedId={handleSaveTaskSelectedId}
      />
      {activeTab === InboxTab.INBOX && (
        <>
          {/* Inbox General 영역 -> 캘린더 요소 여기로 드롭되는 것 설정*/}
          <Flex
            flexGrow={flexGrow}
            gap={pxToRem(6)}
            id="memoizedTasklist"
            borderRadius={"6px"}
            border={isTaskListHovered ? "2px solid" : "2px solid transparent"}
            borderColor={isTaskListHovered ? "whiteAlpha.400" : "transparent"}
            flexDirection={"column"}
            height={"fit-content"}
            // calendar -> inbox dnd
            onMouseEnter={() => {
              if (draggedEvent && draggedEvent?.dragType === DragKind.CALENDAR_TASK) {
                setIsTaskListHovered(true);
              }
            }}
            onMouseLeave={() => {
              setIsTaskListHovered(false);
            }}
            onMouseUp={() => {
              if (draggedEvent && draggedEvent.dragType === DragKind.CALENDAR_TASK) {
                handleUpdateCalendarToInbox(draggedEvent);
                setIsTaskListHovered(false);
              }
            }}
          >
            {memoizedTasksList}
          </Flex>

          {toggleVisibility.Inbox.planned && (
            <InboxToggleList
              title={InboxToggleTitle.PLANNED}
              activeTab={activeTab}
              onDataDuplicate={handleDuplicateInboxBlock}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
              hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
            />
          )}
          {toggleVisibility.Inbox.overdue && (
            <InboxToggleList
              title={InboxToggleTitle.OVERDUE}
              activeTab={activeTab}
              onDataDuplicate={handleDuplicateInboxBlock}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
              hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
            />
          )}
          {toggleVisibility.Inbox.completed && (
            <InboxToggleList
              title={InboxToggleTitle.COMPLETED}
              activeTab={activeTab}
              onDataDuplicate={handleDuplicateInboxBlock}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
              hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
            />
          )}
        </>
      )}

      {activeTab === InboxTab.TODAY && (
        <>
          <InboxGeneralList
            flexGrow={flexGrow}
            activeTab={activeTab}
            onDataDelete={handleDataDelete}
            onDataDuplicate={handleDuplicateInboxBlock}
            taskSelectedId={taskSelectedId}
            onSelect={handleSaveTaskSelectedId}
            onClickInboxBlock={handleClickInboxBlock}
            draggedEvent={draggedEvent}
          />
          {toggleVisibility.Today.overdue && (
            <InboxToggleList
              title={InboxToggleTitle.OVERDUE}
              activeTab={activeTab}
              onDataDuplicate={handleDuplicateInboxBlock}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
              hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
            />
          )}
          {toggleVisibility.Today.completed && (
            <InboxToggleList
              title={InboxToggleTitle.COMPLETED}
              activeTab={activeTab}
              onDataDuplicate={handleDuplicateInboxBlock}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
              hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
            />
          )}
        </>
      )}
      <InboxToCalendarDragLayer draggedItemId={draggedEvent?.id} />
    </TaskListWrapper>
  );
}
