import { Menu, MenuButton, MenuList, MenuProps } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../utils/styles/size";
import { CloseIcon } from "../../../icons";
import { PriorityMenuItem } from "./PriorityMenuItem";
import { priorityOptions } from "../../../constants/block/TaskPriority";
import { PriorityIconWrapper } from "./PriorityIconWrapper";
import { PriorityButton } from "./PriorityButton";
import { Priority, PriorityType } from "../../../types/block/enum";

interface PriorityButtonPopoverProps extends MenuProps {
  children: React.ReactNode;
  menuVisible: boolean;
  handleMenuClose: () => void;
  onChange: (value: string | undefined) => void;
  currentPriorityValue?: PriorityType;
}

export const PriorityButtonPopover: React.FC<PriorityButtonPopoverProps> = ({
  children,
  menuVisible,
  handleMenuClose,
  currentPriorityValue,
  onChange,
  ...props
}) => {
  if (!menuVisible) return children;
  const isNoPriority = !currentPriorityValue || currentPriorityValue === Priority.DEFAULT;
  return (
    <>
      <Menu isOpen={menuVisible} onClose={handleMenuClose} placement={"bottom-end"} {...props}>
        <MenuButton
          as={PriorityButton}
          currentValue={currentPriorityValue}
          isMenuVisible={menuVisible}
          onClick={handleMenuClose}
        />
        <MenuList
          w={pxToRem(120)}
          minW={"initial"}
          bg={"#313435"}
          borderRadius={6}
          py={0}
          border={"1px solid"}
          borderColor={"#3E4042"}
          boxSizing={"border-box"}
        >
          <PriorityMenuItem
            label="No Priority"
            isSelected={isNoPriority}
            onClick={() => onChange(Priority.DEFAULT)}
          >
            <CloseIcon color={isNoPriority ? "#69e3ff" : "#D9D9D9"} />
          </PriorityMenuItem>
          {priorityOptions.map(({ value, label, icon }) => {
            return (
              <PriorityMenuItem
                key={value}
                label={label}
                isSelected={currentPriorityValue === value}
                onClick={() => onChange(value)}
              >
                <PriorityIconWrapper>{icon}</PriorityIconWrapper>
              </PriorityMenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
