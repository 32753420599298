import { useState } from "react";

import { useSetRecoilState, useRecoilValue } from "recoil";
import iconForVisibility from "../../utils/taskDetail/visibility/iconForVisibility";

import { timeFormatState } from "../../recoil/calendar/settingCalendar";
import { useJuneTrackCall } from "../../utils/june/analytics";
import styles from "./Timeblock.module.css";
import { ItemStatus, RecurringOption } from "../../types/block/enum";
import useHandleClientBlockStateChange from "../../hooks/block/useHandleClientBlockStateChange";
import { useUpdateCalendarCheckboxMutation } from "../../react-query/calendar/useUpdateCalendarCheckboxMutation";
import { toastState } from "../../recoil/toast/toastState";
import { useQueryClient } from "@tanstack/react-query";
import { inboxQueryKeys } from "../../react-query/inbox/queries";
import { calendarQueryKeys } from "../../react-query/calendar/queries";
import { BlockType } from "../../types/block/type";

function calculateHeight(
  startDatetime: Date | null | undefined,
  endDatetime: Date | null | undefined
) {
  if (!startDatetime || !endDatetime) return;

  const start = new Date(startDatetime).getTime();
  const end = new Date(endDatetime).getTime();

  const timeInterval = end - start;
  const minTimeInterval = 15 * 60 * 1000;

  if (timeInterval >= 4 * 60 * 60 * 1000) {
    return 320;
  } else if (timeInterval <= minTimeInterval) {
    return 40;
  } else {
    return Math.floor((timeInterval / (30 * 60 * 1000)) * 40);
  }
}

const TimeComponent = ({
  data,
  isAllDay,
}: {
  data: Date | undefined | null;
  isAllDay: boolean;
}) => {
  const timeFormat = useRecoilValue(timeFormatState);

  if (!data) return;
  const date = new Date(data);

  if (isAllDay) {
    return <div>all-day</div>;
  }
  const hours24 = date.getHours();
  let hours12 = hours24 % 12;
  hours12 = hours12 ? hours12 : 12;

  const ampm = hours24 >= 12 ? "pm" : "am";

  const minutes = date.getMinutes();
  const strMinutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime12 = ampm + " " + hours12 + ":" + strMinutes;
  const formattedTime24 = hours24 + ":" + strMinutes;

  return <div>{timeFormat === "12-hour" ? formattedTime12 : formattedTime24}</div>;
};

const TaskRow = ({ data, dotColor }: { data: BlockType; dotColor: string }) => {
  const [isDone, setIsDone] = useState(data?.itemStatus === ItemStatus.COMPLETED);
  const setToast = useSetRecoilState(toastState);

  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();
  const updateCalendarCheckbox = useUpdateCalendarCheckboxMutation();

  const [hover, setHover] = useState(false);
  const [rowIcon, setRowIcon] = useState(true);
  const trackCall = useJuneTrackCall();
  const queryClient = useQueryClient();

  const height = calculateHeight(data.start, data.end);

  const style = {
    borderRadius: "6px",
    border: `1px solid ${dotColor}`,
    background: `linear-gradient(0deg, ${dotColor}1A 0%, ${dotColor}1A 100%), #242626`,
    height: `${height}px`,
  };

  const bodyIconStyle = {
    backgroundColor: hover
      ? `${dotColor}`
      : `${dotColor}${isDone ? (rowIcon ? "" : "26") : rowIcon ? "26" : ""} `,
    boxShadow: `0 0 0 2px ${dotColor} inset`,
  };

  const handleClickCheckbox = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDone((current) => !current);

    const isDone = data.itemStatus === ItemStatus.COMPLETED;
    const convertData = {
      allDay: data.allDay,
      hangoutLink: data.hangoutLink,
      meetingCode: data.meetingCode,
      linkData: data.linkData,
      attendees: data.attendees,
      location: data.location,
      priority: data.priority,
      itemStatus: !isDone ? ItemStatus.COMPLETED : ItemStatus.IN_PROGRESS,
    };
    const isRecurringBlock = (data?.recurrence && data?.recurrence?.length > 0) || data.originalId;

    const updateBlock = {
      ...data,
      ...convertData,
    };
    // this로 반복이벤트 수정시에는 꼭 originalStart를 넣어줘야함
    const updatePayload = isRecurringBlock
      ? { ...convertData, type: RecurringOption.THIS, originalStart: data.start }
      : convertData;

    // Invalidate가 빠르게 되지 않을 경우를 대비해 남겨둠
    isRecurringBlock
      ? handleClientRecurringBlockChange({
          prevBlockData: data,
          updateBlockData: updateBlock,
        })
      : handleClientBasicBlockChange(updateBlock);

    updateCalendarCheckbox.mutate(
      {
        id: data.id,
        payload: updatePayload,
        prevData: data,
      },

      {
        onSuccess: () => {
          !isDone && trackCall("done_block", { location: "wiki" });
          queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
          queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
        },
        onError: (error) => {
          setToast({
            type: "Error",
            isVisible: true,
            message: `Failed to ${isDone ? "undo" : "complete"}. Please retry`,
          });
        },
      }
    );
  };

  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  return (
    <>
      <div
        className={styles.timeBlockBox}
        style={style}
      >
        <div
          className={styles.taskList__body}
          key={data.id}
        >
          {data.hangoutLink == null ? (
            <div
              className={`${styles.taskListBodyIcon} ${
                styles[`${isDone ? "checkboxCompleted" : null}`]
              }`}
              style={bodyIconStyle}
              onMouseEnter={handleIconEnter}
              onMouseLeave={handleIconLeave}
              onClick={handleClickCheckbox}
            ></div>
          ) : (
            <div className={styles.googleMeetIcon}></div>
          )}
          <div className={styles.taskList__body__title}>{data.title || "No Title"}</div>
          {data.visibility && <div>{iconForVisibility(data.visibility, data.transparency)}</div>}
        </div>
      </div>
    </>
  );
};

function TimeLineDot({ color }: { color: string }) {
  return (
    <div
      className={styles.dot}
      style={{ backgroundColor: color }}
    ></div>
  );
}

function Block({ dotColor, data }: { dotColor: string; data: BlockType }) {
  return (
    <>
      <div className={styles.timeBlockOnly}>
        <div className={styles.dateTimeArea}>
          <TimeComponent
            data={data?.start}
            isAllDay={data?.allDay ?? false}
          />
        </div>
        <TimeLineDot color={dotColor} />
        <TaskRow
          key={data.id}
          data={data}
          dotColor={dotColor}
        />
      </div>
    </>
  );
}

export default function Timeblock({ dotColor, data }: { dotColor: string; data: BlockType }) {
  return (
    <Block
      dotColor={dotColor}
      data={data}
    />
  );
}
