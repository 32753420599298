import { useJuneTrackCall } from "../../utils/june/analytics";
import { BasicBlockType } from "../../types/block/enum";

export const BlockAction = {
  create_block: "create_block",
  duplicate_block: "duplicate_block",
  update_block: "update_block",
  delete_block: "delete_block",
  done_task: "done_task",
  view_block_detail: "view_block_detail",
  duplicate_divider: "duplicate_divider",
} as const;

type BlockActionType = (typeof BlockAction)[keyof typeof BlockAction];

export const BlockLocation = {
  inbox: "inbox",
  today_inbox: "today_inbox",
} as const;
type BlockLocationType = (typeof BlockLocation)[keyof typeof BlockLocation];

interface JuneBlockDataType {
  action: BlockActionType;
  location: BlockLocationType;
  blockType?: BasicBlockType;
}

const useHandleJuneBlockData = () => {
  const trackCall = useJuneTrackCall();

  return (juneBlockData: JuneBlockDataType) =>
    trackCall(juneBlockData.action, {
      location: juneBlockData.location,
      blockType: juneBlockData.blockType,
    });
};

export default useHandleJuneBlockData;
