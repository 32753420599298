/** API Path Generator Class */
export class ApiPathComposer {
  static API_BASE_PATH = {
    AUTH: "/auth",
    USERS: "/users",
    SPACES: "/spaces",
    BLOCKS: "/blocks",
    GOOGLE: "/google",
    CONTACTS: "/contacts",
    // https://www.npmjs.com/package/open-graph-scraper 관련
    OGS: "/etc/ogs",
  } as const;

  /** Auth */
  static get googleSignIn() {
    return `${this.API_BASE_PATH.AUTH}/google`;
  }

  static get logout() {
    return `${this.API_BASE_PATH.AUTH}/logout`;
  }

  /** User */
  static get users() {
    return this.API_BASE_PATH.USERS;
  }

  static get me() {
    return `${this.API_BASE_PATH.USERS}/me`;
  }

  /** Block */
  static get blocks() {
    return this.API_BASE_PATH.BLOCKS;
  }

  static get blockOrder() {
    return `${this.API_BASE_PATH.BLOCKS}/orders`;
  }

  static get blockSync() {
    return `${this.API_BASE_PATH.BLOCKS}/sync`;
  }

  /** Methods that require parameters */
  static blockWithId(id: number) {
    return `${this.blocks}/${id}`;
  }

  static recurrenceBlockWithId(id: number) {
    return `${this.blockWithId(id)}/recurrence`;
  }

  /** Space */
  static get spaces() {
    return this.API_BASE_PATH.SPACES;
  }

  static spaceWithId(id: number) {
    return `${this.spaces}/${id}`;
  }

  static get spaceOrder() {
    return `${this.spaces}/orders`;
  }
}

export class AppPathComposer {
  static APP_BASE_PATH = {
    LOGIN: "/login",
    JOIN: "/join",
    JOIN_WAIT_LIST: "/joinWaitList",
    MAIN: "/main",
  } as const;

  static get login() {
    return this.APP_BASE_PATH.LOGIN;
  }

  static get join() {
    return this.APP_BASE_PATH.JOIN;
  }

  static get joinWaitList() {
    return this.APP_BASE_PATH.JOIN_WAIT_LIST;
  }

  static get main() {
    return this.APP_BASE_PATH.MAIN;
  }
}
