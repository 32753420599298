import { Flex, Text } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import { InboxBlockType } from "../../../../types/block/BlockType";

type TaskBlockSummarySubset = Pick<InboxBlockType, "title" | "links" | "previewNote">;

interface InboxBlockSummaryProps extends TaskBlockSummarySubset {
  isCompleted: boolean;
}
const InboxBlockSummary = ({ title, isCompleted }: InboxBlockSummaryProps) => {
  return (
    <Flex
      flexDir={"column"}
      gap={pxToRem(2)}
      flex={1}
      id={"inbox-block-summary"}
      justifyItems={"flex-start"}
      minWidth={0}
    >
      <Text
        color={isCompleted ? "#ABADAD" : "white"}
        textStyle="body2"
        textDecoration={isCompleted ? "line-through" : "none"} // 취소선 추가
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {title.length > 0 ? title : "No title"}
      </Text>
      {/* 링크와 노트는 다음 스프린트에 추가하는 걸로 */}
      {/* <Flex alignItems="center" gap={pxToRem(6)}>
        {links && links.length > 0 && (
          <Flex
            width={pxToRem(14)}
            height={pxToRem(14)}
            flexShrink={0}
            my={pxToRem(1.5)} // 패딩으로 전체 높이 17px로
            backgroundImage={`url(https://www.google.com/s2/favicons?sz=64&domain_url=${links[0]?.url ?? ""})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
        )}

        <Flex noOfLines={1}>
          <Text textStyle="body3" textColor="#7C8183" lineHeight={"none"}>
            {previewNote}
          </Text>
        </Flex>
      </Flex> */}
    </Flex>
  );
};

export default InboxBlockSummary;
