import { RecurringOption } from "./../../types/block/enum";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { expandRecurringEvent } from "../../utils/calendar/convertRruletoDates";
import useHandleClientBlockStateDelete from "./useHandleClientBlockStateDelete";
import { BlockResponseType, BlockType } from "../../types/block/type";
import { calendarLastMonthSelector } from "../../recoil/calendar/calendarStateV2";
import { getLastDayOfTwoMonthsLater } from "../../utils/date-format/dateUtils";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { calendarQueryKeys } from "../../react-query/calendar/queries";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";

function useHandleClientBlockStateChange() {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const { handleClientBlockDelete } = useHandleClientBlockStateDelete();
  const calendarLastMonth = useRecoilValue(calendarLastMonthSelector);
  const defaultVisibility = useRecoilValue(visibilityState);
  const queryClient = useQueryClient();

  const handleClientBasicBlockChange = (updateBlockData: BlockType) => {
    // 반복이 아니였던 블록을 반복으로 바꾸는 경우
    const isRecurrence = updateBlockData?.recurrence && updateBlockData.recurrence.length > 0;
    if (isRecurrence) {
      setMobaCalendarList((current) => {
        const removedBlockList = current.filter((block) => block.id !== updateBlockData.id);
        const recurringEnd = calendarLastMonth
          ? getLastDayOfTwoMonthsLater(calendarLastMonth)
          : new Date();
        const expandedEvent = expandRecurringEvent(
          updateBlockData,
          updateBlockData.start,
          recurringEnd
        );
        return [...removedBlockList, ...expandedEvent];
      });
      return;
    }

    setMobaCalendarList((prev) =>
      prev.map((block) => (block.id === updateBlockData.id ? updateBlockData : block))
    );
  };

  const handleClientRecurringBlockChange = ({
    prevBlockData,
    updateBlockData,
  }: {
    prevBlockData: BlockType;
    updateBlockData: BlockType;
  }) => {
    const selectedOption = updateBlockData.type;
    const recurringEnd = calendarLastMonth
      ? getLastDayOfTwoMonthsLater(calendarLastMonth)
      : new Date();

    // 반복이였던 블록을 일정이 없는 task블록으로 바꾸는 경우(all)
    if (updateBlockData.start === null) {
      handleClientBlockDelete({ updateBlockData, recurringOption: RecurringOption.ALL });
      return;
    }

    // 반복이였던 블록을 반복만 없애는 경우 처리(해당 블록만 일반블록으로 변경, 나머지는 전체 삭제)
    if (updateBlockData.recurrence === null) {
      handleClientBlockDelete({ updateBlockData, recurringOption: RecurringOption.ALL });
      setMobaCalendarList((prev) => [...prev, updateBlockData]);
      return;
    }

    // 이외에는 기존 반복 블록들 삭제 후 다시 인스턴스 생성
    if (selectedOption === RecurringOption.ALL) {
      // 해당 블록의 캘린더 쿼리 데이터에서 모체 블록 찾음
      if (
        !prevBlockData.start ||
        !updateBlockData.start ||
        !prevBlockData.end ||
        !updateBlockData.end
      ) {
        return;
      }
      const currentMonth = moment(new Date(prevBlockData.start)).format("YYYY-MM");
      const calendarQueryKey = currentMonth && calendarQueryKeys.yearAndMonth(currentMonth);
      const calendarData = calendarQueryKey
        ? queryClient.getQueryData<BlockResponseType[]>(calendarQueryKey)
        : null;
      const originalRecurringBlock = calendarData
        ? calendarData.find((event) => event.id === updateBlockData.originalId)
        : null;

      if (!originalRecurringBlock) {
        return;
      }

      // clientType으로 convert를 해야함
      const clientOriginalRecurringBlock = convertServerToClientBlockType({
        serverBlock: originalRecurringBlock,
        defaultVisibility,
      });
      if (!clientOriginalRecurringBlock.start || !clientOriginalRecurringBlock.end) {
        return;
      }
      const daysDifferenceStart =
        new Date(updateBlockData.start).getTime() - new Date(prevBlockData.start).getTime();
      const daysDifferenceEnd =
        new Date(updateBlockData.end).getTime() - new Date(prevBlockData.end).getTime();
      const combineBlock = {
        ...updateBlockData,
        id: clientOriginalRecurringBlock.id,
        start: new Date(clientOriginalRecurringBlock.start.getTime() + daysDifferenceStart),
        end: new Date(clientOriginalRecurringBlock.end.getTime() + daysDifferenceEnd),
      };

      handleClientBlockDelete({ updateBlockData, recurringOption: selectedOption });
      setMobaCalendarList((current) => {
        const expandedEvent = expandRecurringEvent(combineBlock, combineBlock.start, recurringEnd);

        return [...current, ...expandedEvent];
      });
    }

    if (selectedOption === RecurringOption.THISANDAFTER) {
      handleClientBlockDelete({ updateBlockData, recurringOption: selectedOption });
      setMobaCalendarList((current) => {
        const expandedEvent = expandRecurringEvent(
          updateBlockData,
          updateBlockData.start,
          recurringEnd
        );

        return [...current, ...expandedEvent];
      });
    }

    // this 일 때
    setMobaCalendarList((prev) =>
      prev.map((block) => (block.id === updateBlockData.id ? updateBlockData : block))
    );
  };

  return { handleClientBasicBlockChange, handleClientRecurringBlockChange };
}

export default useHandleClientBlockStateChange;
