import { BasicBlock, ItemStatus } from "../../types/block/enum";
import { BlockType } from "../../types/block/type";
import { getDayStart } from "../../utils/date-format/dateUtils";
import {
  inboxQueryKeys,
  useInboxCompletedQueryOptions,
  useInboxGeneralQueryOptions,
  useInboxOverdueQueryOptions,
  useInboxPlannedQueryOptions,
} from "../../react-query/inbox/queries";

const useFindUpdateInboxQueryKey = () => {
  // TODO: refactoring
  const inboxGeneralQueryKey = useInboxGeneralQueryOptions({}).queryKey;
  const inboxCompletedQueryKey = useInboxCompletedQueryOptions().queryKey;
  const inboxOverdueQueryKey = useInboxOverdueQueryOptions().queryKey;
  const inboxPlannedQueryKey = useInboxPlannedQueryOptions().queryKey;

  const findUpdateInboxQueryKey = (updateBlock: BlockType) => {
    const TODAY = new Date();
    const TODAY_START = getDayStart(TODAY); // 오늘 00:00:00

    // inbox는 TASK, DIVIDER에만 해당하므로 EVENT는 얼리 리턴
    if (updateBlock.blockType === BasicBlock.EVENT) {
      return null;
    }

    // Completed 태스크
    if (updateBlock.itemStatus === ItemStatus.COMPLETED) {
      return inboxCompletedQueryKey;
    }

    // inbox general
    if (!updateBlock.start || !updateBlock.end) {
      return inboxGeneralQueryKey;
    }

    // Planned 태스크 (end 날짜가 TODAY_START 이후인 경우)
    if (new Date(TODAY_START).getTime() < new Date(updateBlock.end).getTime()) {
      return inboxPlannedQueryKey;
    }

    // Overdue 태스크 (end 날짜가 todayStart 이전인 경우)
    return inboxOverdueQueryKey;
  };

  // 두 블록의 쿼리 키가 동일한지 비교하는 함수 추가
  const isSameInboxQueryKey = (
    queryKey1: ReturnType<typeof inboxQueryKeys.inbox> | null,
    queryKey2: ReturnType<typeof inboxQueryKeys.inbox> | null
  ) => {
    if (!queryKey1 || !queryKey2) {
      return false;
    }

    // 두 쿼리 키가 동일한지 비교
    return JSON.stringify(queryKey1) === JSON.stringify(queryKey2);
  };

  return {
    findUpdateInboxQueryKey,
    isSameInboxQueryKey,
  };
};

export default useFindUpdateInboxQueryKey;
