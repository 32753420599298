import useApi from "../../services/auth/useApi";
import {
  BlockType,
  CreateBlockType,
  UpdateBlockType,
  UpdateRecurrenceBlockType,
} from "../../types/block/type";
import blockAPI from "../../api/BlockAPI";
import { useQueryClient } from "@tanstack/react-query";
import { useInboxGeneralQueryOptions } from "./queries";
import { convertClientToServerBlockType } from "../../utils/common/block/type-converter";

export const useCreateInboxBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: (payload: CreateBlockType) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: false,
      });
      return blockAPI.createBlock(api, serverPayload).then((res) => res.data);
    },
  };
};
export const useDuplicateInboxBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({
      payload,
      originalBlock,
    }: {
      payload: CreateBlockType;
      originalBlock: BlockType; // 낙관적 업데이트 위해서 받도록 함
    }) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: false,
      });
      return blockAPI.createBlock(api, serverPayload).then((res) => res.data);
    },
  };
};

export const useUpdateInboxBasicBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({ id, payload }: { id: number; payload: UpdateBlockType }) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: true,
      });
      return blockAPI.updateBlockById(api, id, serverPayload);
    },
  };
};

export const useUpdateRecurrenceInboxBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({ id, payload }: { id: number; payload: UpdateRecurrenceBlockType }) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: true,
      });
      return blockAPI.updateRecurrenceBlockById(api, id, serverPayload);
    },
  };
};

export const useDeleteInboxBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: (id: number) => blockAPI.deleteBlockById(api, id),
  };
};

export const useReorderInboxBlockMutationOptions = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const inboxGeneralQueryKey = useInboxGeneralQueryOptions({}).queryKey;

  return {
    mutationFn: (payload: { ids: number[] }) => blockAPI.updateBlocksOrder(api, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: inboxGeneralQueryKey,
      });
    },
    onError: () => {
      // inboxGeneral은 dnd때문에 전역상태를 사용하고 있어서 에러 시 invalidate함
      queryClient.invalidateQueries({
        queryKey: inboxGeneralQueryKey,
      });
    },
  };
};

export const useUpdateInboxBlockMutationOptions = () => {
  const regularUpdateMutation = useUpdateInboxBasicBlockMutationOptions();
  const recurrenceUpdateMutation = useUpdateRecurrenceInboxBlockMutationOptions();

  return {
    mutationFn: async ({
      id,
      payload,
      data,
    }: {
      id: number | string;
      payload: UpdateBlockType | UpdateRecurrenceBlockType;
      data?: BlockType;
    }) => {
      // id를 원블록의 id형태로 변경(this로 수정된 경우는 그대로 id 사용)
      // 가상블록의 'id_날짜' 형태에서 id만 추출
      // 일반블록의 number 형태도 그대로 반환됨
      const convertedId = Number(id.toString().split("_")[0]);

      // recurrence type 값이 있는 경우
      if ("type" in payload) {
        return recurrenceUpdateMutation.mutationFn({
          id: convertedId,
          payload: payload as UpdateRecurrenceBlockType,
        });
      }

      // 일반 업데이트
      return regularUpdateMutation.mutationFn({
        id: convertedId,
        payload: payload as UpdateBlockType,
      });
    },
  };
};
