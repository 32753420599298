import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { BlockType } from "../../types/block/type";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BlockRequests, RequestPayloadGetBlockById, RequestPayloadGetBlocks } from "./requests";
import { ResponseError } from "../../api/types";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { useRecoilValue } from "recoil";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";

interface BlockQueryOptions<TResponseData = unknown, TPayload = undefined> {
  /** axios instance */
  instance: AxiosInstance;
  /** 요청을 보낼 때 필요한 정보 */
  payload: TPayload;
  /** axios config */
  config?: AxiosRequestConfig;
  /** queryKey와 queryFn을 제외한 쿼리 옵션 */
  options?: Omit<UseQueryOptions<TResponseData, ResponseError>, "queryKey" | "queryFn">;
}

// TODO: id 이외 추가적인 param을 정의할 수 있을 것 같음 (e.g. start, end)
export const blockQueryKey = {
  all: ["block"],
  // REVIEW: 키가 시나리오에 따라 각각의 entity에서 서로 다른 방식으로 적용되면 혼선을 가져올 수 있음
  // key에도 요청 context에 대한 힌트를 줌으로써 좀 더 직관적으로 invalidation을 처리할 수 있음
  // - get one의 경우에는 늘 ID가 들어가고, getAll의 경우에는 아이디가 필요없음
  // - get all의 경우에는 검색할 수 있는 query param이 들어감
  getById: (id: number) => [...blockQueryKey.all, { id }],
  getAllByParams: (params: RequestPayloadGetBlocks["params"]) => [...blockQueryKey.all, params],
} as const;

/**
 * 완료된 Block을 조회하는 hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 완료된 Block들을 조회
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns 완료된 Block 조회를 위한 React Query hook
 */
export const useGetBlocksQuery = ({
  instance,
  payload: params,
  config,
  options,
}: BlockQueryOptions<BlockType[], RequestPayloadGetBlocks["params"]>) => {
  const visibility = useRecoilValue(visibilityState);
  return useQuery<BlockType[], ResponseError>({
    queryKey: blockQueryKey.getAllByParams(params),
    queryFn: async () => {
      const response = await BlockRequests.getBlocks({ instance, params, ...config });
      return response.data.map((item) =>
        convertServerToClientBlockType({
          serverBlock: item,
          defaultVisibility: visibility,
        })
      );
    },
    ...options,
  });
};

export const useGetBlockQuery = ({
  instance,
  payload: id,
  config,
  options,
}: BlockQueryOptions<BlockType, RequestPayloadGetBlockById["id"]>) => {
  const visibility = useRecoilValue(visibilityState);
  return useQuery<BlockType, ResponseError>({
    queryKey: blockQueryKey.getById(id),
    queryFn: async () => {
      const response = await BlockRequests.getBlockById({ instance, id, ...config });
      return convertServerToClientBlockType({
        serverBlock: response.data,
        defaultVisibility: visibility,
      });
    },
    ...options,
  });
};
