import { Flex } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { pxToRem } from "../../../../utils/styles/size";
import { TimeBlockCheckIconDone } from "../InboxBodyIcon";
import { useState } from "react";
import { inProgressAndCompletedSpaceListState } from "../../../../recoil/spaces/inProgressSpaceListState";
import { DEFAULT_SPACE_COLOR } from "../../../../constants/space";
import { getSpaceColor } from "../../../../services/space/space.service";

interface InboxBlockCheckboxProps {
  isCompleted: boolean;
  projectId: string | null | any;
  onClick: (e: React.MouseEvent) => void;
}

const InboxBlockCheckbox = ({ isCompleted, projectId, onClick }: InboxBlockCheckboxProps) => {
  const [isHovered, setIsHovered] = useState(false);
  // NOTE: 완료된(아카이스된) 스페이스도 색상을 표시해야 하기 때문
  const inProgressAndCompletedSpaces = useRecoilValue(inProgressAndCompletedSpaceListState);

  // TODO 이 부분 이렇게 구현하는거 맞는지 확인 필요
  const baseColor = projectId
    ? getSpaceColor(inProgressAndCompletedSpaces, projectId)
    : DEFAULT_SPACE_COLOR;
  const backgroundColor = isCompleted || isHovered ? baseColor : `${baseColor}33`;

  return (
    <Flex
      position="relative"
      w={pxToRem(14)}
      h={pxToRem(14)}
      margin={"2px 0"}
      flexShrink={0}
      borderRadius={pxToRem(3)}
      backgroundColor={backgroundColor}
      boxShadow={`0 0 0 1px ${baseColor} inset`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      alignItems="center"
      justifyContent="center"
      overflow={"hidden"}
    >
      {(isCompleted || isHovered) && (
        <TimeBlockCheckIconDone
          display={"flex"}
          color="currentColor"
          width={"full"}
        />
      )}
    </Flex>
  );
};

export default InboxBlockCheckbox;
