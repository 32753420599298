import useApi from "../services/auth/useApi";

class GoogleAPI {
  createGoogleMeetCode(AxiosInstance: ReturnType<typeof useApi>) {
    return AxiosInstance.post<{ meetingCode: string }>("/google/meets").then(
      (res) => res.data.meetingCode
    );
  }
}

const googleAPI = new GoogleAPI();
export default googleAPI;
