import { FlagIcon } from "../../icons";
import { Priority } from "../../types/block/enum";

export const priorityOptions = [
  {
    value: Priority.HIGH,
    label: "High",
    icon: <FlagIcon color={"#FF5D5D"} />,
  },
  {
    value: Priority.MEDIUM,
    label: "Medium",
    icon: <FlagIcon color={"#FFB65F"} />,
  },
  {
    value: Priority.LOW,
    label: "Low",
    icon: <FlagIcon color={"#48D27F"} />,
  },
];
