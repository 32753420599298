import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";

import { useRecoilState } from "recoil";
import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { SpaceIdType } from "../../../types/space";
import { SpaceStatus } from "../../../types/space/enum";
import { spaceQueryKeys } from "../queries";

export const markSpaceDoneApi = async (
  api: ReturnType<typeof useApi>,
  projectId: SpaceIdType
): Promise<void> => {
  await api.patch(`/spaces/${projectId}`, { itemStatus: SpaceStatus.Completed });
};

export const useMarkSpaceDoneMutation = (
  options?: UseMutationOptions<void, AxiosError, SpaceIdType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [inProgressSpaces, setInProgressSpaces] = useRecoilState(inProgressSpaceListState);

  return useMutation<void, AxiosError, SpaceIdType>({
    mutationFn: (projectId: SpaceIdType) => markSpaceDoneApi(api, projectId),
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.Completed) });

      if (inProgressSpaces) {
        const updatedProjectList = inProgressSpaces.filter((project) => project.id !== projectId);
        // setter에서 inProgress, completed 모두 업데이트 됨
        setInProgressSpaces(updatedProjectList);
      }

      return { projectId, previousProjectList: inProgressSpaces };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.Completed) });
    },
    ...options,
  });
};
