import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import styles from "./Wiki.module.css";

import { useJuneTrackCall } from "../../utils/june/analytics";
import { ProejctListEmpty } from "./Project/ProejctListEmpty";

import { ProjectList } from "./Project/ProjectList";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { filterTasks } from "../../services/task/task.service";

import { useFetchArchivedSpaceQuery } from "../../react-query/space/core/useFetchSpaceQuery";
import { SpaceKind, SpaceStatus } from "../../types/space/enum";
import { spaceTypesSelector } from "../../recoil/spaces/inProgressSpaceListState";

export default function WikiList({ onProjectAddClick }) {
  const trackCall = useJuneTrackCall();

  const [currentStatusSeleted, setCurrentStatusSeleted] = useState(SpaceStatus.InProgress);
  const selectedSpaces = useRecoilValue(selectedSpaceIdListState);
  const inProgressNormalOnlySpaces = useRecoilValue(spaceTypesSelector([SpaceKind.Normal]));
  const { data: archivedSpace } = useFetchArchivedSpaceQuery();

  const memoizedProjectList = useMemo(() => {
    const displayedSpaces =
      currentStatusSeleted === SpaceStatus.InProgress
        ? inProgressNormalOnlySpaces.filter((event) => filterTasks(selectedSpaces, event.id))
        : archivedSpace;

    if (!displayedSpaces || displayedSpaces?.length === 0) {
      return (
        <ProejctListEmpty
          onClick={onProjectAddClick}
          isDone={currentStatusSeleted !== SpaceStatus.InProgress}
        />
      );
    } else {
      return <ProjectList projectData={displayedSpaces} onClick={onProjectAddClick} />;
    }
  }, [
    selectedSpaces,
    currentStatusSeleted,
    inProgressNormalOnlySpaces,
    archivedSpace,
    onProjectAddClick,
  ]);

  useEffect(() => {
    trackCall("view_space_list");
  }, []);

  return (
    <div className={styles.wiki__body}>
      <div className={styles.wiki__header}>
        <div className={styles.headerInprogessAndDoneArea}>
          <div
            onClick={() => {
              setCurrentStatusSeleted(SpaceStatus.InProgress);
              trackCall("click_wiki_status", { status: "in progress" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === SpaceStatus.InProgress ? styles.titleSelected : null
            }`}
          >
            In Progress
          </div>
          <div
            onClick={() => {
              setCurrentStatusSeleted(SpaceStatus.Completed);
              trackCall("click_wiki_status", { status: "done" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === SpaceStatus.Completed ? styles.titleSelected : null
            }`}
          >
            Archived
          </div>
        </div>
        <div
          className={styles.headerCreateProject}
          onClick={() => {
            onProjectAddClick(true, null);
            trackCall("create_space", { location: "wiki" });
          }}
        >
          Create Space
        </div>
      </div>
      {memoizedProjectList}
    </div>
  );
}
