import { NotificationType, RecurringOptionType } from "../../types/block/enum";
import { BlockType } from "../../types/block/type";

export const prepareFinalPayload = (
  blockData: BlockType,
  recurringOption: RecurringOptionType | null | undefined,
  guestNotification: NotificationType | null | undefined
): BlockType => {
  return {
    ...blockData,
    ...(guestNotification && { notification: guestNotification }),
    ...(recurringOption && { type: recurringOption }),
  };
};

export const CANCELED = Symbol("user-canceled");
