import { useRef, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { toastState } from "../../recoil/toast/toastState";
import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";
import { useDrop } from "react-dnd";
import { createPortal } from "react-dom";
import SidebarModal from "../TaskInbox/SidebarModal";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { DragAccept, ItemStatus } from "../../types/block/enum";
import { PopupActions } from "../../types/block/popup";

import useHandleClientBlockStateDelete from "../../hooks/block/useHandleClientBlockStateDelete";
import { CANCELED } from "../../services/taskDetail/block.service";
import { useDeleteCalendarBlockMutation } from "../../react-query/calendar/useDeleteCalendarBlockMutation";
import { juneTrack } from "../../utils/june/analytics";
import { JUNE_EVENT, JUNE_LOCATION } from "../../hooks/june/juneEvent";

/**
 * MyCalendar.js -> DragAndDropCalendar 컴포넌트에 사용 (react-big-calendar를 내재된 drag-and-drop wrapper 함수로 감싼 것)
 * MyCalendar.js -> monthly_view + show_more 상태에서 createPortal 선언 시에 ShowMorePopup의 children으로 사용
 *
 * @param {Object} props - 컴포넌트 props
 * @param {Object} props.event - 이벤트 데이터 객체
 * @param {ReactNode} props.children - 자식 컴포넌트 (일반적으로 CustomEvent)
 * @param {Function} props.onClick - 이벤트 클릭 핸들러
 * @param {Function} props.onDataDuplicate - 이벤트 복제 핸들러
 * @param {number} props.taskDetailId - taskDetail state에서 가져온 현재 선택된 task의 ID
 * @param {boolean} props.taskDetailIsVisible - taskDetail 팝업 표시 여부
 * @param {boolean} props.taskDetailIsNewBlock - 새로 생성하는 블록인지 여부
 */
export function CustomEventWrapper({ event, children, onDataDuplicate, onClick }) {
  const taskDetail = useRecoilValue(taskPopupState);

  const taskDetailId = taskDetail?.data?.id;
  const taskDetailIsVisible = taskDetail?.isVisible;
  const taskDetailIsNewBlock = taskDetail?.isNewBlock;

  // Recoil 상태 리셋 함수 - 이벤트 선택 취소시 사용
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  // 로컬 상태
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);

  // Toast 알림을 위한 Recoil 상태 setter
  const setToast = useSetRecoilState(toastState);

  // 커스텀 훅
  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();

  // API 관련 hooks
  const useDeleteCalendarBlock = useDeleteCalendarBlockMutation({
    location: JUNE_LOCATION.CALENDAR,
  });

  const { handleClientBlockDelete } = useHandleClientBlockStateDelete();

  // DOM refs 및 react-dnd
  const wrapRef = useRef(null);
  const [, drop] = useDrop({
    accept: [DragAccept.INBOX],
    drop: (event) => {},
  });

  /**
   * 컨텍스트 메뉴(우클릭 메뉴) 핸들러
   *
   * @param {MouseEvent} e - 마우스 이벤트
   * @description
   * 우클릭 위치에 사이드바 모달(메뉴) 표시
   */
  const handleItemContextMenu = (e) => {
    e.preventDefault();
    const { clientX, clientY } = e;

    // allDay 영역은 Fixed position이 부모 Div에서 Translate 옵션으로 설정되어 있어
    // 웹페이지 전체가 아닌 부모에 상대적으로 위치가 지정됨
    setModalPosition({ x: clientX, y: clientY });
    setIsSidebarModalOn(true);
  };

  // API 호출을 위한 공통 함수
  const handleBlockDeleteMutation = ({ id, payload, prevData }) => {
    useDeleteCalendarBlock.mutate(
      { id, payload, prevData },
      {
        onSuccess: () => {
          setToast({
            type: "Delete",
            isVisible: true,
            message: "Event has been deleted",
          });
        },
        onError: () => {
          setToast({
            type: "Error",
            isVisible: true,
            message: "Failed to delete. Please retry",
          });
        },
      }
    );
  };

  /**
   * 이벤트 삭제 처리 함수
   *
   * @description
   * 이벤트 삭제 처리 로직:
   * 1. 반복 이벤트인지 확인
   * 2. 반복 이벤트는 팝업으로 삭제 옵션 선택
   * 3. 게스트가 있으면 알림 여부 선택
   * 4. 클라이언트 상태 및 서버 상태 업데이트
   */
  const handleDelete = async () => {
    /**
     * 이벤트 업데이트 데이터 생성 함수
     *
     * @param {Object} event - 이벤트 객체
     * @param {boolean} notifyGuests - 게스트에게 알림 여부
     * @param {string} recurringOption - 반복 이벤트 처리 옵션
     * @returns {Object} 서버에 전송할 업데이트 데이터
     */

    const createEventUpdateData = (event, notifyGuests, recurringOption = null) => ({
      id: event.id,
      start: event.start,
      end: event.end,
      originalStart: event.originalStart ?? event.start,
      itemStatus: ItemStatus.DELETED,
      type: recurringOption,
      notification: notifyGuests ? Notification.ALL : Notification.NONE,
      allDay: event.allDay,
      hangoutLink: event.hangoutLink,
      meetingCode: event.meetingCode,
      linkData: event.linkData,
      attendees: event.attendees,
      location: event.location,
      priority: event.priority,
    });

    try {
      setIsSidebarModalOn(false);

      // 반복 이벤트 여부 확인
      const isRecurringBlock = event.originalId || event.recurrence?.length > 0;

      if (isRecurringBlock) {
        const selectedRecurringOption = await openRecurringPopup(event, PopupActions.DELETE);
        if (selectedRecurringOption === CANCELED) return;
        const notifyGuests =
          event?.attendees?.length > 0 ? await openGuestPopup(event, PopupActions.DELETE) : false;
        if (notifyGuests === CANCELED) return;

        // 실제 업데이트 시 사용할 데이터(id, payload)
        const updateData = createEventUpdateData(event, notifyGuests, selectedRecurringOption);

        // 블록 클라이언트 업데이트 시 사용할 테이터(block 형태)
        const updateBlockData = { ...event, ...updateData };

        // 클라이언트 상태 업데이트
        handleClientBlockDelete({
          updateBlockData,
          recurringOption: selectedRecurringOption,
        });

        // 서버 API 호출을 위한 데이터 준비 및 호출
        const { id, ...payload } = updateData;
        handleBlockDeleteMutation({ id, payload, prevData: event });
        return;
      }
      // 일반 이벤트 삭제 처리
      else {
        const notifyGuests =
          event?.attendees?.length > 0 ? await openGuestPopup(event, PopupActions.DELETE) : false;
        if (notifyGuests === CANCELED) return;

        // 실제 업데이트 시 사용할 데이터(id, payload)
        const updateData = createEventUpdateData(event, notifyGuests);

        // 블록 클라이언트 업데이트 시 사용할 테이터(block 형태)
        const updateBlockData = { ...event, ...updateData };
        handleClientBlockDelete({ updateBlockData });
        const { id, type, originalStart, ...payload } = updateData;
        handleBlockDeleteMutation({ id, payload, prevData: event });
      }
    } catch (error) {
      console.error("Failed to delete event", error);
    }
  };

  const handleDataDuplicate = (e) => {
    onDataDuplicate(e, event);
    setIsSidebarModalOn(false);
  };

  const handleClickEvent = (e, event, refBound) => {
    if (taskDetailId === event.id) {
      return resetTaskDetail();
    }
    onClick(e, event, refBound);
  };

  return (
    <div
      ref={drop}
      key={event.id}
    >
      <div
        ref={wrapRef}
        onContextMenu={handleItemContextMenu}
        onClick={(e) => {
          handleClickEvent(e, event);
        }}
        onMouseDown={(e) => {
          // 블록이 이미 선택된 상태에서 다른 블록을 클릭할 때 전파 중지
          // 이벤트 버블링 방지로 캘린더 전체 선택되는 것 방지
          const isBlockClickOutSide = !taskDetailIsNewBlock && taskDetailIsVisible;
          isBlockClickOutSide && e.stopPropagation();
        }}
      >
        {children}
      </div>

      {/* 컨텍스트 메뉴(우클릭 메뉴) 모달 */}
      {isSidebarModalOn &&
        createPortal(
          <SidebarModal
            x={modalPosition.x}
            y={modalPosition.y}
            onDelete={handleDelete}
            onDuplicate={handleDataDuplicate}
            onClose={() => setIsSidebarModalOn(false)}
          />,
          document.body
        )}
    </div>
  );
}
