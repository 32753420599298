import { AxiosInstance } from "axios";

class SpaceAPI {
  getSpaceDetailById(AxiosInstance: AxiosInstance, id: number) {
    return AxiosInstance.get(`spaces/${id}`).then((res) => res);
  }
}

const spaceAPI = new SpaceAPI();
export default spaceAPI;
