import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { juneTrack, useJuneTrackCall } from "../../utils/june/analytics";
import typeForVisibility from "../../utils/taskDetail/visibility/typeForVisibility";
import { handlePopupKeyEvent } from "../../utils/taskDetail/handlePopupKeyEvent";

import useCreateMeetingCode from "../../react-query/block/useCreateMeetingCode";

import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { toastState } from "../../recoil/toast/toastState";
import { guestPopupState } from "../../recoil/popup/guestPopupState";
import { recurringPopupState } from "../../recoil/popup/recurringPopupState";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import { taskDetailHeightState } from "../../recoil/taskDetail/taskDetailHeightState";

import Header from "./Header/Header";
import TaskSetting from "./TaskSetting";

import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";

import { BasicBlock, ItemStatus, Priority } from "../../types/block/enum";

import clsx from "clsx";

import styles from "./style.module.css";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RSVPMenu } from "./RSVP/RSVPMenu";

import { getBlockTypeFlags } from "../../utils/common/block/getBlockTypeFlags";
import { useCreateCalendarBlockMutationOptions } from "../../react-query/calendar/mutations";
import { checkBlockChanges } from "../../services/taskDetail/taskDetail.service";
import { CANCELED, prepareFinalPayload } from "../../services/taskDetail/block.service";
import { useSelectBlockUpdateMutation } from "../../react-query/block/useSelectBlockUpdateMutation";
import { PopupActions } from "../../types/block/popup";
import { calendarQueryKeys } from "../../react-query/calendar/queries";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { handleAttendeesNotification } from "../../services/taskDetail/guest.service";
import { handleRecurringOptions } from "../../services/taskDetail/recurring.service";
import { removeClientOnlyFields } from "../../utils/common/block/removeClientOnlyFields";
import useHandleClientBlockStateChange from "../../hooks/block/useHandleClientBlockStateChange";
import useHandleClientBlockStateDelete from "../../hooks/block/useHandleClientBlockStateDelete";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { addSelectedSpace } from "../../services/space/space.service";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { noSpaceIdSelector } from "../../recoil/spaces/inProgressSpaceListState";
import { creatingCalendarBlockState } from "../../recoil/calendar/creatingCalendarBlockState";
import { initialCreatingCalendarBlock } from "../../react-query/inbox/utils/initialCreatingCalendarBlock";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { inboxQueryKeys } from "../../react-query/inbox/queries";
import { useDeleteCalendarBlockMutation } from "../../react-query/calendar/useDeleteCalendarBlockMutation";
import { trackBlockEvents } from "../../utils/june/juneUtils";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";

const TaskDetail = ({ guestPopupRef, recurringPopupRef }) => {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const setSelectedSpaces = useSetRecoilState(selectedSpaceIdListState);
  const noSpaceId = useRecoilValue(noSpaceIdSelector);
  const queryClient = useQueryClient();
  const setCreatingCalendarBlock = useSetRecoilState(creatingCalendarBlockState);

  const defaultVisibility = useRecoilValue(visibilityState);

  const setToast = useSetRecoilState(toastState);
  const setTaskDetailModalHeight = useSetRecoilState(taskDetailHeightState);
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  const [guestPopup, setGuestPopup] = useRecoilState(guestPopupState);
  const [recurringPopup, setRecurringPopup] = useRecoilState(recurringPopupState);

  // REVIEW: @brandonwie `typeForVisibility`가 null 처리를 하도록 되어있는데, null이 아닐 때에만 넣는게 맞는지?
  const [selectedVisibilityType, setSelectedVisibilityType] = useState(
    taskDetail.data && taskDetail.data.visibility
      ? typeForVisibility(taskDetail.data.visibility, taskDetail.data.transparency)
      : defaultVisibility
  );

  const [toggleExpand, setToggleExpand] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: taskDetail.modalPosition.y,
    left: taskDetail.modalPosition.x,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const [isVisibilityClick, setIsVisibilityClick] = useState(false);
  const [isModalNoteClicked, setIsModalNoteClicked] = useState(false);

  const [localTitle, setLocalTitle] = useState(taskDetail.data?.title);

  const mainRef = useRef(null);
  const titleRef = useRef(null);

  const taskDetailRef = useRef(null);

  const initialBlockData = useRef(null);

  const isChangeNote = useRef(false);

  const moreModalRef = useRef(null);
  const linkMoreModalRef = useRef(null);
  const videoCreateModalRef = useRef(null);
  const repeatModalRef = useRef(null);
  const visibilityDropdownRef = useRef(null);
  const guestDropdownRef = useRef(null);
  const locationDropdownRef = useRef(null);
  const repeatAddModalRef = useRef(null);

  const projectDropdownRef = useRef(null);

  const startDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endDateRef = useRef(null);
  const endTimeRef = useRef(null);

  const [isDateStart, setIsDateStart] = useState(false);
  const [isDateEnd, setIsDateEnd] = useState(false);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isTimeStart, setIsTimeStart] = useState(false);

  const trackCall = useJuneTrackCall();
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();
  const { isTask, isEvent } = getBlockTypeFlags(taskDetail.data?.blockType);

  const { mutateMeetingCode, meetingCode, isPendingMeetingCode } = useCreateMeetingCode();
  // explicitly set to undefined
  const currentRSVPValue =
    taskDetail.data?.attendees?.find((attendee) => attendee?.self)?.responseStatus ?? undefined;

  const { findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();
  const useDeleteCalendarBlock = useDeleteCalendarBlockMutation({ location: taskDetail.type });

  const handleBlockDeleteMutation = ({ id, payload, prevData }) => {
    handleClose();

    useDeleteCalendarBlock.mutate({ id, payload, prevData });
  };

  const createBlockMutation = useMutation({
    ...useCreateCalendarBlockMutationOptions(),
    onMutate: async ({ payload }) => {
      const creatingCalendarBlock = {
        ...initialCreatingCalendarBlock,
        ...payload,
      };
      // TODO: 반복이벤트는 id가 없는데 인스턴스를 어떻게 만들어야할지..?(비활성 처리했다가 onSuccess에서 활성화 처리??)
      setMobaCalendarList((current) => [...current, creatingCalendarBlock]);
      setCreatingCalendarBlock(null);
      handleClose();

      // inbox 해당 블록 업데이트
      const updateInboxQueryKey = findUpdateInboxQueryKey(creatingCalendarBlock);

      if (updateInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        const previousData = queryClient.getQueryData(updateInboxQueryKey);
        if (previousData) {
          queryClient.setQueryData(updateInboxQueryKey, [creatingCalendarBlock, ...previousData]);
        }
        return { previousData, updateInboxQueryKey };
      }
    },
    onSuccess: (data, variables, context) => {
      const clientConvertedBlock = convertServerToClientBlockType({
        serverBlock: data,
        defaultVisibility,
      });

      const isRecurrence = data.recurrence && data.recurrence.length > 0;
      if (isRecurrence) {
        // 반복인 경우 invalidate 처리
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      } else {
        // 캘린더 클라이언트 상태 업데이트
        setMobaCalendarList((current) =>
          current.map((block) => (!block.id ? clientConvertedBlock : block))
        );
        // inbox 클라이언트 상태 업데이트
        if (context?.updateInboxQueryKey) {
          queryClient.setQueryData(context.updateInboxQueryKey, (oldData) => {
            if (!oldData) return [clientConvertedBlock];
            const originData = oldData.filter((block) => block.id);
            return [clientConvertedBlock, ...originData];
          });
        }
      }
    },
    onError: (error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      throw error;
    },
  });

  // NOTE TaskDetail 모달 위치 기본 설정 로직
  const calculateModalStyle = useCallback(() => {
    // NOTE TaskDetail 모달 위치 기본 설정 로직

    const targetRect = taskDetail.targetRect;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const modalWidth = 362;
    let modalHeight = taskDetail.data ? taskDetail.data.modalHeight : 600;

    if (taskDetailRef.current) {
      modalHeight = taskDetailRef.current.offsetHeight + 2;
    }

    // targetRect의 속성들을 양수로 조정
    const adjustedRect = targetRect
      ? {
          x: targetRect.x < 0 ? targetRect.x : targetRect.x,
          y: targetRect.y < 0 ? targetRect.y : targetRect.y,
          top: targetRect.top < 0 ? targetRect.top : targetRect.top,
          bottom: targetRect.bottom < 0 ? targetRect.bottom : targetRect.bottom,
          right: targetRect.right,
          left: targetRect.left,
        }
      : null;

    let x = adjustedRect ? adjustedRect.right + 5 : taskDetail.modalPosition.x;
    let y = adjustedRect ? adjustedRect.top : taskDetail.modalPosition.y;

    // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
    if (x + modalWidth > windowWidth) {
      x = adjustedRect ? adjustedRect.left - modalWidth - 5 : Math.max(windowWidth - modalWidth, 0);
    }

    // 모달이 화면의 아래쪽을 벗어나면 아래쪽에 맞춤
    if (y + modalHeight > windowHeight) {
      y = windowHeight - modalHeight - 20;
    } else if (y < 110) {
      y = 110;
    }

    return { top: `${y}px`, left: `${x}px` };
  }, []);

  // #region useEffect
  /** Block body(현 이름: TaskSetting)에 scroll 이벤트 assign */
  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      setIsScrolling(true);
      const timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 2000);

      setScrollTimeout(timeout);
    };

    const blockBodyWrapper = document.querySelector("#block-body-wrapper");

    blockBodyWrapper.addEventListener("scroll", handleScroll);

    return () => {
      blockBodyWrapper.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskDetailRef.current) {
      setModalPosition(calculateModalStyle());
      setTaskDetailModalHeight(taskDetailRef.current.offsetHeight + 2);
    }
  }, [taskDetailRef.current, taskDetail.data]);

  const handleClose = () => {
    // NOTE onSelect 꺼지도록 설정
    taskDetail?.onClose && taskDetail.onClose();

    setLocalTitle("");
    initialBlockData.current = null;

    resetTaskDetail();
  };

  const { handleClientBlockDelete } = useHandleClientBlockStateDelete();
  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();

  const { selectAndCallProperApi } = useSelectBlockUpdateMutation(handleClose);

  // NOTE TaskDetail 처음 창 열었을 때 동작하는 로직
  useEffect(() => {
    // NOTE 초기값 ref에 저장, 변경사항 체크할 때 사용
    initialBlockData.current = taskDetail.data;

    // initialVideo.current = data.hangoutLink ? data.hangoutLink : null

    // calendar만 여기서 june 처리
    let trackObject = { location: taskDetail.type };
    if (taskDetail.data?.attendees?.length > 0) {
      trackObject = { ...trackObject, type: "meeting" };
    } else {
      trackObject = { ...trackObject, type: "task" };
    }
    taskDetail.type === "calendar" && trackCall("view_block_detail", trackObject);
  }, []);

  // NOTE TaskDetail 바깥 클릭 시 저장 또는 닫기
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        // linkModal의 more context menu 클릭 시 창 닫히지 않도록 설정
        (!linkMoreModalRef.current || !linkMoreModalRef.current.contains(event.target)) &&
        !videoCreateModalRef.current &&
        // repeatModal 닫히지 않도록 설정
        !repeatModalRef.current &&
        !projectDropdownRef.current &&
        !recurringPopupRef.current &&
        !guestPopupRef.current &&
        !guestDropdownRef.current &&
        !locationDropdownRef.current
      ) {
        return onSave(localTitle);
      }
    }
    if (mainRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [taskDetail.data, isPendingMeetingCode, localTitle, guestPopupRef, recurringPopupRef, onSave]);

  const hasBlockDetailsChanged = useMemo(() => {
    if (!taskDetail?.data) return;

    // NOTE Duplicate 블록인 경우에 바로 저장필요하기 때문에 무조건 save 버튼 활성화
    if (taskDetail.isDuplicateBlock) return true;

    const hasRecurrence = taskDetail.data?.recurrence?.length > 0;
    const hasOriginalId = !!taskDetail.data?.originalId;
    const isRecurringBlock = hasRecurrence || hasOriginalId;

    const serverFieldOnlyBlockData = removeClientOnlyFields(taskDetail.data, isRecurringBlock);
    const finalData = { ...serverFieldOnlyBlockData, title: localTitle };
    const { hasChanged } = checkBlockChanges({
      data: finalData,
      initialData: initialBlockData.current ?? {},
      isPendingMeetingCode,
      isNewBlock: taskDetail.isNewBlock,
    });

    return hasChanged;
  }, [taskDetail.data, localTitle, initialBlockData.current, isPendingMeetingCode]);

  const createBlock = async (blockData) => {
    let payload = { ...blockData };

    // TODO 추후 hasAttendeesNotification으로 통합
    if (blockData.attendees?.length > 0) {
      try {
        const guestNotificationResult = await openGuestPopup(payload, PopupActions.CREATE);
        if (guestNotificationResult === CANCELED) return { success: false, canceled: true };
        payload = {
          ...payload,
          notification: guestNotificationResult,
        };
      } catch (error) {
        return { success: false, canceled: true };
      }
    }
    try {
      const result = await createBlockMutation.mutateAsync({ payload });
      return { success: true, data: result };
    } catch (error) {
      console.error("블록 생성 실패:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * 변경된 필드가 detail 관련 필드를 포함하는 경우,
   * 모든 detail 필드를 포함하는 확장된 변경 필드 객체를 반환합니다.
   * @param {Object} changedFields - 변경된 필드 객체
   * @param {Object} blockData - 블록 데이터 객체
   * @returns {Object} 확장된 변경 필드 객체
   */
  const enhanceChangedFieldsWithDetailFields = (changedFields, blockData) => {
    // detail 관련 필드들 정의
    const detailFields = [
      "allDay",
      "hangoutLink",
      "meetingCode",
      "linkData",
      "attendees",
      "location",
      "priority",
    ];

    // changedFields에 detailFields 중 하나라도 있는지 확인
    const hasDetailFieldsChanged = detailFields.some((field) => field in changedFields);

    // 변경사항이 없으면 그대로 반환
    if (!hasDetailFieldsChanged) {
      return changedFields;
    }

    // 결과 객체 생성
    const detailAddedChangedFields = { ...changedFields };

    // detail 필드 중 하나라도 변경되었다면, changedFields에 없는 나머지 detail 필드만 blockData에서 가져옴
    detailFields.forEach((field) => {
      // changedFields에 이미 있는 필드는 건너뜀 (원래 값 유지)
      if (field in changedFields) return;

      if (field === "linkData" && blockData.linkData) {
        // linkData에서 isLoading 속성 제거
        const filteredLinkData = blockData.linkData?.map(({ isLoading, ...rest }) => rest);
        detailAddedChangedFields.linkData = filteredLinkData;
      } else if (blockData[field] !== undefined) {
        detailAddedChangedFields[field] = blockData[field];
      }
    });

    return detailAddedChangedFields;
  };

  const updateBlock = async (blockData, blockId) => {
    try {
      const initialData = initialBlockData.current ?? {};

      const { changedFields } = checkBlockChanges({
        data: blockData,
        initialData,
        isPendingMeetingCode,
        isNewBlock: false,
      });

      const updateBlockData = { ...blockData, id: blockId };
      const detailAddedChangedFields = enhanceChangedFieldsWithDetailFields(
        changedFields,
        blockData
      );

      const recurringOption = await handleRecurringOptions({
        blockData: updateBlockData,
        openRecurringPopup,
        initialRecurrence: initialData?.recurrence,
        initialStart: initialData?.start,
        initialEnd: initialData?.end,
      });

      if (recurringOption === CANCELED)
        return { success: false, canceled: true, stage: "recurring" };

      const guestNotification = await handleAttendeesNotification({
        blockData: updateBlockData,
        openGuestPopup,
        initialTitle: initialData?.title,
        initialStart: initialData?.start,
        initialEnd: initialData?.end,
        initialGuest: initialData?.attendees,
      });
      if (guestNotification === CANCELED) return { success: false, canceled: true, stage: "guest" };

      const updateFields = recurringOption
        ? {
            ...detailAddedChangedFields,
            originalStart: initialData.originalStart
              ? initialData.originalStart
              : initialData.start,
          }
        : detailAddedChangedFields;

      const finalPayload = prepareFinalPayload(updateFields, recurringOption, guestNotification);

      // ui 업데이트
      recurringOption
        ? handleClientRecurringBlockChange({
            prevBlockData: initialData,
            updateBlockData: {
              ...updateBlockData,
              type: recurringOption,
            },
          })
        : handleClientBasicBlockChange(updateBlockData);

      const result = await selectAndCallProperApi(updateBlockData.id, finalPayload, initialData);

      return { success: true, data: result };
    } catch (error) {
      console.error("블록 수정 실패:", error);
      handleClose();
      return { success: false, error: error.message };
    }
  };

  const handleBlockDelete = async () => {
    const createEventUpdateData = (event, notifyGuests, recurringOption = null) => ({
      id: event.id,
      itemStatus: ItemStatus.DELETED,
      originalStart: event.originalStart ?? event.start,
      type: recurringOption,
      notification: notifyGuests ? Notification.ALL : Notification.NONE,
    });

    juneTrack(JUNE_EVENT.DELETE_BLOCK, {
      location: taskDetail.type,
      type: initialBlockData.current?.blockType,
    });

    try {
      setIsSidebarModalOn(false);

      // 삭제 시 수정중인 데이터가 아닌 기존 데이터를 기준으로 해야함
      const initialData = initialBlockData.current ?? {};

      const isRecurringBlock = initialData.recurrence?.length > 0 || initialData.originalId;

      if (isRecurringBlock) {
        const selectedRecurringOption = await openRecurringPopup(initialData, PopupActions.DELETE);
        if (selectedRecurringOption === CANCELED) return;
        const notifyGuests =
          initialData.attendees?.length > 0
            ? await openGuestPopup(initialData, PopupActions.DELETE)
            : false;

        if (notifyGuests === CANCELED) return;
        const updateData = createEventUpdateData(
          initialData,
          notifyGuests,
          selectedRecurringOption
        );
        const updateBlockData = { ...initialData, ...updateData };

        handleClientBlockDelete({ updateBlockData, recurringOption: selectedRecurringOption });
        const { id, ...payload } = updateData;
        handleBlockDeleteMutation({ id, payload, prevData: initialData });
        return;
      }

      const notifyGuests =
        initialData.attendees?.length > 0
          ? await openGuestPopup(initialData, PopupActions.DELETE)
          : false;
      if (notifyGuests === CANCELED) return;
      const updateData = createEventUpdateData(initialData, notifyGuests);
      const updateBlockData = { ...initialData, ...updateData };

      handleClientBlockDelete({ updateBlockData });
      const { id, type, originalStart, ...payload } = updateData;
      handleBlockDeleteMutation({ id, payload, prevData: initialData });
    } catch (error) {
      console.error("Event deletion process failed:", error);
      handleClose();
    }
  };

  const handleCancelSaveBlock = (isNewBlock) => {
    if (isNewBlock) {
      // NOTE NewBlock이라면 클라이언트 상태에 추가한 블록 제거
      setCreatingCalendarBlock(null);
    }

    handleClose();
    setIsSaving(false);
  };

  async function onSave(localTitle) {
    if (isSaving) return;
    setIsSaving(true);

    let result;
    try {
      const isRecurringBlock =
        taskDetail.data?.recurrence?.length > 0 || taskDetail.data?.originalId;

      const isDuplicateBlock = taskDetail.isDuplicateBlock;

      const serverFieldOnlyBlockData = {
        ...removeClientOnlyFields(taskDetail.data, isRecurringBlock),
        title: localTitle,
      };

      const { changedFields, hasChanged } = checkBlockChanges({
        data: serverFieldOnlyBlockData,
        initialData: initialBlockData.current ?? {},
        isPendingMeetingCode,
        isNewBlock: taskDetail.isNewBlock,
      });

      // NOTE: 변경된 경우 or duplicate된 경우 저장 진행(duplicate의 경우 변경사항 없어도 저장)
      const shouldSave = hasChanged || isDuplicateBlock;

      if (!shouldSave) {
        handleCancelSaveBlock(taskDetail.isNewBlock);

        return { success: true };
      }
      if (taskDetail.isNewBlock) {
        result = await createBlock(serverFieldOnlyBlockData);
      } else {
        const blockId = taskDetail.data?.id;
        result = await updateBlock(serverFieldOnlyBlockData, blockId);
      }

      // NOTE 중간에 popup내에서 disacrd/cancel한 경우
      if (result.canceled) {
        handleCancelSaveBlock(taskDetail.isNewBlock);
        return;
      }
      // 성공 시 공통 후처리
      if (result.success) {
        setSelectedSpaces((prev) => addSelectedSpace(prev, taskDetail.data?.spaceId ?? noSpaceId));
        setToast({
          type: "Success",
          isVisible: true,
          message: `Event ${taskDetail.isNewBlock ? "created" : "updated"} successfully`,
        });

        trackBlockEvents({ taskDetail, changedFields, toggleExpand });
      }

      return result;
    } catch (error) {
      console.error("저장 실패:", error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const blockNoteEl = document.querySelector(".bn-suggestion-menu");
      if (blockNoteEl) return;

      const bnDragHandleMenu = document.querySelector(".bn-drag-handle-menu");
      if (bnDragHandleMenu && event.key === "Escape") {
        return;
      }

      const bnToolbar = document.querySelector(".bn-toolbar");
      if (bnToolbar && event.key === "Escape") {
        return;
      }

      const isCmdOrCtrl = event.metaKey || event.ctrlKey;

      if (handlePopupKeyEvent(guestPopupRef, setGuestPopup, guestPopup, event)) return;
      if (handlePopupKeyEvent(recurringPopupRef, setRecurringPopup, recurringPopup, event)) return;

      if (moreModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsSidebarModalOn(false);
      }

      if (titleRef.current && (event.key === "Delete" || event.key === "Backspace")) {
        const titleElement = titleRef.current;
        if (titleElement.contains(document.activeElement)) {
          event.stopPropagation();
          return;
        }
      }
      if (startTimeRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsTimeStart(false);
      }
      if (startDateRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsDateStart(false);
      }
      if (endTimeRef.current && event.key === "Escape") {
        return setIsTimeEnd(false);
      }
      if (endDateRef.current && event.key === "Escape") {
        return setIsDateEnd(false);
      }
      if (visibilityDropdownRef.current && event.key === "Escape") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsVisibilityClick(false);
      }
      if (repeatAddModalRef.current && event.key === "Enter") {
        event.stopPropagation();
        return;
      }
      if (repeatAddModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        return;
      }
      if (projectDropdownRef.current && event.key === "Enter") {
        event.stopPropagation();
      }

      if (locationDropdownRef.current && event.key === "Enter") {
        event.stopPropagation();
        return;
      }

      if (
        (!startTimeRef.current &&
          !videoCreateModalRef.current &&
          !projectDropdownRef.current &&
          !moreModalRef.current &&
          (!repeatModalRef.current || !repeatAddModalRef.current) &&
          !guestDropdownRef.current &&
          !guestPopupRef.current &&
          !recurringPopupRef.current &&
          !locationDropdownRef.current &&
          event.key === "Escape") ||
        event.key === "Enter" ||
        (event.key === isCmdOrCtrl && event.key === "Enter")
      ) {
        event.stopPropagation();

        return onSave(localTitle);
      }

      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        (event.key === "Backspace" && event.code === "Delete")
      ) {
        !toggleExpand && event.target.tagName !== "INPUT" && handleBlockDelete();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    titleRef,
    startTimeRef,
    setIsTitleFocused,
    taskDetail.data,
    repeatAddModalRef,
    toggleExpand,
    isPendingMeetingCode,
  ]);

  const handleVisibilityClick = () => {
    setIsVisibilityClick((current) => !current);
  };

  const handleVisibilityDropdownItemClick = (type) => {
    setIsVisibilityClick(false);
    setSelectedVisibilityType(type);

    const updatedVisibility = type === "public" ? "public" : "private";
    const updatedTransparency = type === "invisible" ? "transparent" : "opaque";

    // 상태를 직접 업데이트
    setTaskDetail((prevDetail) => {
      const updatedDetail = {
        ...prevDetail,
        data: {
          ...prevDetail.data,
          visibility: updatedVisibility,
          transparency: updatedTransparency,
        },
      };
      return updatedDetail;
    });
  };

  const handleChangePriority = (priority) => {
    setTaskDetail((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        priority,
      },
    }));
  };

  const handleChangeRSVP = (attendResponse) => {
    setTaskDetail((prev) => {
      const attendeesAfterSelfResponseUpdate = prev.data?.attendees?.map((attendee) =>
        attendee?.self ? { ...attendee, responseStatus: attendResponse } : attendee
      );

      return {
        ...prev,
        data: {
          ...prev.data,
          attendees: attendeesAfterSelfResponseUpdate,
        },
      };
    });
  };

  const handleChangeLocation = (location) => {
    setTaskDetail((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          location: location,
        },
      };
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (visibilityDropdownRef.current && !visibilityDropdownRef.current.contains(event.target)) {
        setIsVisibilityClick(false);
      }

      if (moreModalRef.current && !moreModalRef.current.contains(event.target)) {
        setIsSidebarModalOn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMoreButtonClick = (event) => {
    setIsSidebarModalOn((prev) => !prev);
  };

  /** 원래는 tab 변경에 반응하도록 만들어진 effect이지만, 최초 mount에서 실행된다. */
  useEffect(() => {
    if (isTask) {
      // event에서 task로 바뀌었다면 attendees 초기화 및 video 초기화
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          attendees: null, // attendees 초기화
          hangoutLink: null, // hangoutLink 초기화
          blockType: BasicBlock.TASK,
          meetingCode: "",
          // 첫 생성인 경우 default, 존재하는 경우에는 그대로 적용
          priority: prev.data?.priority ?? Priority.DEFAULT,
          // TODO 여기 location, going도 초기화 필요
        },
      }));
    } else if (isEvent) {
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          blockType: BasicBlock.EVENT,
          // 이벤트로 넘어가는 경우에는 default로 초기화
          priority: Priority.DEFAULT,
        },
      }));
    }
    if (taskDetail.isNewBlock) {
      setIsTitleFocused(true);
    }
    // 최초, 블록타입 변경시에만 발동
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetail.data?.blockType]);

  const handleChangeLocalTitle = (newLocalTitle) => {
    // 현재 hasChanged 값에 localTitle이 필요해서 여기에 상태 생성
    setLocalTitle(newLocalTitle);
  };

  return (
    <>
      <div
        className={clsx(styles.detail__wrap, {
          [styles["detail__wrap-mini"]]: !toggleExpand,
          [styles["detail__wrap-expand"]]: toggleExpand,
        })}
        style={!toggleExpand ? modalPosition : {}}
        ref={taskDetailRef}
      >
        <div
          className={clsx(styles.main, {
            [styles["main-mini"]]: !toggleExpand,
            [styles["main-expand"]]: toggleExpand,
          })}
          ref={mainRef}
        >
          <Header
            // Refs
            moreModalRef={moreModalRef}
            visibilityDropdownRef={visibilityDropdownRef}
            // States
            toggleExpand={toggleExpand}
            setToggleExpand={setToggleExpand}
            isDisabled={Boolean(taskDetail?.isNewBlock)}
            isVisibilityClick={isVisibilityClick}
            isSidebarModalOn={isSidebarModalOn}
            selectedVisibilityType={selectedVisibilityType}
            isModalNoteClicked={isModalNoteClicked}
            setIsModalNoteClicked={setIsModalNoteClicked}
            // Functions
            onChangePriority={handleChangePriority}
            handleVisibilityClick={handleVisibilityClick}
            handleVisibilityDropdownItemClick={handleVisibilityDropdownItemClick}
            handleTaskDelete={handleBlockDelete}
            handleChangeRSVP={handleChangeRSVP}
            currentRSVPValue={currentRSVPValue}
            handleMoreButtonClick={handleMoreButtonClick}
          />
          <div
            id="block-body-wrapper"
            className={`${toggleExpand ? styles.body : styles.bodyMini} ${isScrolling && styles.showScrollbar}`}
          >
            <TaskSetting
              initialData={initialBlockData.current}
              titleRef={titleRef}
              localTitle={localTitle}
              handleChangeLocalTitle={handleChangeLocalTitle}
              expand={toggleExpand}
              onSave={onSave}
              onClose={handleClose}
              guestError={guestError}
              setGuestError={setGuestError}
              setVisibilityType={handleVisibilityDropdownItemClick}
              isChangeNote={isChangeNote}
              isDisabled={!!taskDetail?.isNewBlock}
              linkMoreModalRef={linkMoreModalRef}
              videoCreateModalRef={videoCreateModalRef}
              repeatModalRef={repeatModalRef}
              startTimeRef={startTimeRef}
              endTimeRef={endTimeRef}
              startDateRef={startDateRef}
              endDateRef={endDateRef}
              isTimeStart={isTimeStart}
              setIsTimeStart={setIsTimeStart}
              isTimeEnd={isTimeEnd}
              setIsTimeEnd={setIsTimeEnd}
              isDateStart={isDateStart}
              setIsDateStart={setIsDateStart}
              isDateEnd={isDateEnd}
              setIsDateEnd={setIsDateEnd}
              isTitleFocused={isTitleFocused}
              setIsTitleFocused={setIsTitleFocused}
              projectDropdownRef={projectDropdownRef}
              guestDropdownRef={guestDropdownRef}
              locationDropdownRef={locationDropdownRef}
              repeatAddModalRef={repeatAddModalRef}
              mutateMeetingCode={mutateMeetingCode}
              isPendingMeetingCode={isPendingMeetingCode}
              meetingCode={meetingCode}
              setIsModalNoteClicked={setIsModalNoteClicked}
              isModalNoteClicked={isModalNoteClicked}
              onChangeLocation={handleChangeLocation}
              currentLocation={taskDetail.data.location}
            />
          </div>
          {!isModalNoteClicked && (
            <div
              className={clsx(styles.footer, {
                // NOTE EventBlock이 아니면 되는건지 TaskBlock이어야 하는건지 확인 필요
                [styles.footerExpand]: toggleExpand || isTask,
              })}
            >
              {!toggleExpand && isEvent && taskDetail.data?.attendees?.length > 0 ? (
                <RSVPMenu
                  currentValue={currentRSVPValue}
                  onChangeValue={handleChangeRSVP}
                />
              ) : (
                <div></div>
              )}
              <button
                className={clsx(styles["setting-save--disabled"], {
                  [styles["setting-save"]]: hasBlockDetailsChanged,
                })}
                onClick={() => onSave(localTitle)}
                // NOTE Guest Modal에서 Save 버튼 누를 때에만 onBlur 처리 방지하도록 추가
                onMouseDown={(e) => e.preventDefault()}
              >
                <p>Save</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TaskDetail;
