import {
  BlackCheckCircleIcon,
  GrayCheckCircleIcon,
  GrayQuestionMarkCircle,
  RedXCircleIcon,
} from "../../icons";
import { AttendResponse } from "../../types/block/enum";

export const RSVPOptions = [
  { value: AttendResponse.ACCEPTED, label: "Yes", icon: BlackCheckCircleIcon },
  { value: AttendResponse.DECLINED, label: "No", icon: RedXCircleIcon },
  { value: AttendResponse.TENTATIVE, label: "Maybe", icon: GrayQuestionMarkCircle },
];
export const DefaultRSVPOption = {
  value: AttendResponse.NEEDS_ACTION,
  label: "Going",
  icon: GrayCheckCircleIcon,
};
