import { Text } from "@chakra-ui/react";

interface CalendarBlockTitleProps {
  title: string;
  blockType?: string;
}

const CalendarBlockTitle = ({ title, blockType }: CalendarBlockTitleProps) => (
  <Text color="white" textStyle="body2" lineHeight={1.38} noOfLines={1} w={"full"}>
    {title || `New ${blockType}`}
  </Text>
);

export default CalendarBlockTitle;
