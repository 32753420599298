import { atom } from "recoil";

import { selector } from "recoil";
import moment from "moment";

import { BlockType } from "../../types/block/type";

// react big 캘린더에서 보여주는 값들을 저장하는 전역 Array
export const mobaCalendarListState = atom<BlockType[] | []>({
  key: "mobaEventListState",
  default: [],
});

export const threeMonthsEventsSelector = selector<BlockType[]>({
  key: "threeMonthsEventsSelector",
  get: ({ get }) => {
    const allEvents = get(mobaCalendarListState);

    // 현재 날짜를 기준으로 이전달(지난달), 이번달, 다음달 범위 설정
    const lastMonthStart = moment().subtract(1, "month").startOf("month");
    const nextMonthEnd = moment().add(1, "month").endOf("month");

    // 이벤트의 start 날짜가 이전달 첫날 ~ 다음달 마지막날 사이인지 필터링
    const filteredEvents = allEvents.filter((event) => {
      const eventDate = moment(event.start);
      return eventDate.isBetween(lastMonthStart, nextMonthEnd, null, "[]");
    });

    return filteredEvents;
  },
});
