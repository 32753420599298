/** 반복 사용되는 const 모음
 * @author brandonwie
 * - 추후에 위치 등 변경
 */

import { ItemStatus, ItemStatusType } from "../types/block/enum";
import { logger } from "../utils/logger";

/** 개발 환경 여부 */
export const isDevelopment = process.env.NODE_ENV !== "production";
/** JWT 종류 */
export const TOKEN_TYPE = {
  M_ACCESS_TOKEN: "mAccessToken",
  M_REFRESH_TOKEN: "mRefreshToken",
  ACCESS_TOKEN: "accessToken",
} as const;
/** 토큰 실패 query param key */
export const TOKEN_FAIL_PARAM = {
  FAIL_REASON: "failReason",
  FAIL_CODE: "failCode",
} as const;

/** Block의 `itemStatus` 값을 next expected 상태로 toggle합니다.
 * Checkbox와 같은 경우 이외 삭제된 아이템을 복구하는 용도에도 사용할 수 있습니다.
 * @param {ItemStatusType}itemStatus - 아이템 상태
 * @default inProgress
 * @returns completed -> inProgress, inProgress -> completed, deleted -> inProgress
 */
export function toggleItemStatus(itemStatus: ItemStatusType) {
  if (!itemStatus) {
    logger.error(`Failed to toggle itemStatus: ${itemStatus} - falls back to inProgress`);
  }
  switch (itemStatus) {
    case ItemStatus.COMPLETED:
      return ItemStatus.IN_PROGRESS;
    case ItemStatus.IN_PROGRESS:
      return ItemStatus.COMPLETED;
    case ItemStatus.DELETED:
      return ItemStatus.IN_PROGRESS;
    default:
      return ItemStatus.IN_PROGRESS;
  }
}
