import useApi from "../services/auth/useApi";
import { ContactsType, ResponseContactsType } from "../types/contacts";

class ContactsAPI {
  createContacts(AxiosInstance: ReturnType<typeof useApi>, contactInfo: ContactsType) {
    return AxiosInstance.post("/contacts", contactInfo).then((res) => res.data);
  }
  getContacts(AxiosInstance: ReturnType<typeof useApi>): Promise<ResponseContactsType[]> {
    return AxiosInstance.get("/contacts").then((res) => {
      return res.data;
    });
  }
  getGoogleContacts(AxiosInstance: ReturnType<typeof useApi>) {
    return AxiosInstance.get("/google/contacts").then((res) => res.data);
  }
}

const contactsAPI = new ContactsAPI();
export default contactsAPI;
