import { Menu, MenuButton, MenuList, MenuProps } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../utils/styles/size";
import { RSVPMenuItem } from "./RSVPMenuItem";

import { RSVPOptions } from "../../../constants/block/RSVP";
import { RSVPButton } from "./RSVPButton";
import { AttendResponseType } from "../../../types/block/enum";

interface RSVPButtonPopoverProps extends MenuProps {
  children: React.ReactNode;
  menuVisible: boolean;
  onMenuClose: () => void;
  onChange: (rsvpResponse: AttendResponseType) => void;
  onClickButton: () => void;
  currentValue?: AttendResponseType;
}

export const RSVPButtonPopover: React.FC<RSVPButtonPopoverProps> = ({
  children,
  menuVisible,
  onMenuClose,
  currentValue,
  onClickButton,
  onChange,
  ...props
}) => {
  if (!menuVisible) return children;

  return (
    <>
      <Menu isOpen={menuVisible} onClose={onMenuClose} {...props}>
        <MenuButton
          as={RSVPButton}
          menuVisible={menuVisible}
          currentValue={currentValue}
          onClick={onMenuClose}
        />
        <MenuList
          w={pxToRem(120)}
          minW={"initial"}
          bg={"#313435"}
          borderRadius={6}
          py={0}
          border={"1px solid"}
          borderColor={"#3E4042"}
          boxSizing={"border-box"}
        >
          {RSVPOptions.map(({ value, label, icon }) => {
            return (
              <RSVPMenuItem
                key={value}
                label={label}
                isSelected={value === currentValue}
                onClick={() => onChange(value)}
                icon={icon}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
