import blockAPI from "../../api/BlockAPI";
import useApi from "../../services/auth/useApi";
import {
  BlockType,
  CreateBlockType,
  UpdateBlockType,
  UpdateRecurrenceBlockType,
} from "../../types/block/type";
import { convertClientToServerBlockType } from "../../utils/common/block/type-converter";

interface CreateCalendarBlockParams {
  payload: CreateBlockType;
}

interface UpdateCalendarBlockParams {
  id: number;
  payload: UpdateBlockType;
}

export const useCreateCalendarBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({ payload }: CreateCalendarBlockParams) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: false,
      });
      return blockAPI.createBlock(api, serverPayload).then((res) => res.data);
    },
  };
};

export const useUpdateCalendarBasicBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({ id, payload }: UpdateCalendarBlockParams) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: true,
      });

      return blockAPI.updateBlockById(api, id, serverPayload).then((res) => res.data);
    },
  };
};

export const useUpdateRecurrenceCalendarBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: ({ id, payload }: { id: number; payload: UpdateRecurrenceBlockType }) => {
      const serverPayload = convertClientToServerBlockType({
        clientBlock: payload,
        isUpdate: true,
      });
      return blockAPI.updateRecurrenceBlockById(api, id, serverPayload);
    },
  };
};

export const useDeleteCalendarBlockMutationOptions = () => {
  const api = useApi();
  return {
    mutationFn: (id: number) => blockAPI.deleteBlockById(api, id).then((res) => res.data),
  };
};

export const useUpdateCalendarBlockMutationOptions = () => {
  const regularUpdateMutation = useUpdateCalendarBasicBlockMutationOptions();
  const recurrenceUpdateMutation = useUpdateRecurrenceCalendarBlockMutationOptions();

  return {
    mutationFn: async ({
      id,
      payload,
      prevData,
    }: {
      id: number | string;
      payload: UpdateBlockType | UpdateRecurrenceBlockType;
      prevData: BlockType; // 낙관적 업데이트 시 사용
    }) => {
      // id를 원블록의 id형태로 변경(this로 수정된 경우는 그대로 id 사용)
      // 가상블록의 'id_날짜' 형태에서 id만 추출
      // 일반블록의 number 형태도 그대로 반환됨
      const convertedId = Number(id.toString().split("_")[0]);

      // recurrence type 값이 있는 경우
      if ("type" in payload) {
        return recurrenceUpdateMutation.mutationFn({
          id: convertedId,
          payload: payload as UpdateRecurrenceBlockType,
        });
      }

      // 일반 업데이트
      return regularUpdateMutation.mutationFn({
        id: convertedId,
        payload: payload as UpdateBlockType,
      });
    },
  };
};
