import { SpaceIdType } from "../../types/space";

export const filterTasks = (
  selectedSpaceIdList: SpaceIdType[] | null,
  targetId?: SpaceIdType | null
) => {
  if (!selectedSpaceIdList) return true;
  if (!targetId) return true;

  return selectedSpaceIdList.includes(targetId);
};
