import { Flex } from "@chakra-ui/react";
import {
  DraggedEventType,
  InboxBlockPositionType,
  InboxBlockStatusType,
} from "../../../../types/block/BlockType";
import InboxBlock from "../InboxBlock/InboxBlock";
import { InboxHeaderTab } from "../../../../types/inbox/inbox-headers";
import { pxToRem } from "../../../../utils/styles/size";
import TaskDividerBlock from "../Divider/TaskDividerBlock";
import { useState } from "react";
import {
  useInboxGeneralQueryOptions,
  useTodayGeneralQueryOptions,
} from "../../../../react-query/inbox/queries";
import { BlockType } from "../../../../types/block/type";
import { useQuery } from "@tanstack/react-query";
import { InboxTab } from "../../../../types/inbox/enum";
import { BasicBlock, DragKind } from "../../../../types/block/enum";
import { getDayBoundaries } from "../../../../utils/date-format/dateUtils";
import useHandleInboxBlockDelete from "../../../../hooks/inbox/useHandleInboxBlockDelete";
import useHandleJuneBlockData from "../../../../hooks/june/useHandleJuneBlockdata";
import { useDeleteInboxBlockMutation } from "../../../../react-query/inbox/useDeleteInboxBlockMutation";
import { useUpdateInboxCheckboxMutation } from "../../../../react-query/inbox/useUpdateInboxCheckboxMutation";
import useHandleUpdateInboxCheckbox from "../../../../hooks/inbox/useHandleUpdateInboxCheckbox";
import useHandleClientBlockStateChange from "../../../../hooks/block/useHandleClientBlockStateChange";
import { useUpdateTimeCalendarBlockMutation } from "../../../../react-query/calendar/useUpdateTimeCalendarBlockMutation";
import { juneTrack } from "../../../../utils/june/analytics";
import { JUNE_EVENT } from "../../../../hooks/june/juneEvent";

interface InboxGeneralListProps {
  activeTab: InboxHeaderTab;
  onDataDelete: (data: BlockType) => void;
  onDataDuplicate: (e: React.MouseEvent, data: BlockType) => void;
  taskSelectedId: number | null;
  onSelect: (id: number | null) => void;
  flexGrow: 0 | 1;
  onClickInboxBlock: (
    e: React.MouseEvent,
    data: BlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
  draggedEvent: DraggedEventType | null;
}
const InboxGeneralList = ({
  activeTab,
  onDataDelete,
  onDataDuplicate,
  taskSelectedId,
  onSelect,
  flexGrow,
  onClickInboxBlock,
  draggedEvent,
}: InboxGeneralListProps) => {
  const handleJuneBlockData = useHandleJuneBlockData();

  const [isTodayGeneralListHovered, setIsTodayListHovered] = useState<boolean>(false);

  const queryOptions =
    activeTab === InboxTab.INBOX ? useInboxGeneralQueryOptions : useTodayGeneralQueryOptions;
  const inboxGeneralQueryKey = queryOptions({}).queryKey;
  const useGeneralQuery = useQuery(queryOptions({}));

  const useDeleteInboxBlock = useDeleteInboxBlockMutation(inboxGeneralQueryKey);
  const useUpdateInboxCheckbox = useUpdateInboxCheckboxMutation(inboxGeneralQueryKey);
  const useUpdateTimeCalendarBlock = useUpdateTimeCalendarBlockMutation();

  const { handleDeleteBlock } = useHandleInboxBlockDelete();
  const { handleClickCheckBox } = useHandleUpdateInboxCheckbox();

  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();

  const handleDelete = (block: BlockType) => {
    handleDeleteBlock(block, useDeleteInboxBlock);
    juneTrack(JUNE_EVENT.DELETE_BLOCK, {
      location: activeTab === "Inbox" ? "inbox" : "today_inbox",
      type: BasicBlock.TASK,
    });
  };

  const handleUpdateCalendarToToday = (block: DraggedEventType) => {
    const TODAY = new Date();
    const { START_OF_DAY, END_OF_DAY } = getDayBoundaries({ day: TODAY, isAllday: true });

    const isRecurringBlock =
      (block.recurrence && block.recurrence?.length > 0) || Boolean(block.originalId);

    const timeChangedTodayBlock = {
      allDay: true,
      start: START_OF_DAY,
      end: END_OF_DAY,
      hangoutLink: block.hangoutLink,
      meetingCode: block.meetingCode,
      linkData: block.linkData,
      attendees: block.attendees,
      location: block.location,
      priority: block.priority,
      ...(isRecurringBlock && { recurrence: null }), // 반복 블록일 경우 recurrence 삭제
    };

    // 캘린더 클라이언트 상태 업데이트(today-general로 이동한 블록 업데이트)
    // 반복 블록인 경우 전체 삭제 후 일반 블록으로 변경
    isRecurringBlock
      ? handleClientRecurringBlockChange({
          prevBlockData: block,
          updateBlockData: { ...block, ...timeChangedTodayBlock },
        })
      : handleClientBasicBlockChange({ ...block, ...timeChangedTodayBlock });

    useUpdateTimeCalendarBlock.mutate({
      id: block.id,
      payload: timeChangedTodayBlock,
      prevData: block,
    });
  };

  const onClickCheckBox = (e: React.MouseEvent, data: BlockType) => {
    e.stopPropagation();

    handleClickCheckBox(
      data,
      useUpdateInboxCheckbox,
      activeTab === "Inbox" ? "inbox" : "today_inbox"
    );
  };

  if (useGeneralQuery.isError) return null;

  return (
    <Flex
      flexGrow={flexGrow}
      w={"100%"}
      height={"fit-content"}
      flexDir={"column"}
      gap={pxToRem(6)}
      borderRadius={"6px"}
      border={"2px solid"}
      borderColor={isTodayGeneralListHovered ? "whiteAlpha.400" : "transparent"}
      onMouseEnter={() => {
        if (draggedEvent && draggedEvent?.dragType === DragKind.CALENDAR_TASK) {
          setIsTodayListHovered(true);
        }
      }}
      onMouseLeave={() => {
        setIsTodayListHovered(false);
      }}
      onMouseUp={() => {
        if (draggedEvent && draggedEvent.dragType === DragKind.CALENDAR_TASK) {
          handleUpdateCalendarToToday(draggedEvent);
          setIsTodayListHovered(false);
        }
      }}
    >
      {/* NOTE GeneralList에는 isOverdue 항상 false */}
      {activeTab === InboxTab.INBOX &&
        useGeneralQuery.data?.map((task, index) =>
          task.blockType === BasicBlock.TASK ? (
            <InboxBlock
              data={task}
              key={task.id}
              index={index}
              onDataDelete={onDataDelete}
              onDataDuplicate={onDataDuplicate}
              status={InboxBlockStatusType.Inbox}
              position={InboxBlockPositionType.General}
              isSelected={taskSelectedId === task.id}
              onSelect={onSelect}
              onClickBlock={onClickInboxBlock}
              onClickCheckBox={onClickCheckBox}
            />
          ) : (
            <TaskDividerBlock
              key={task.id}
              index={index}
              data={task}
              onDelete={() => {}}
              onDuplicate={() => {}}
              onReorder={() => {}}
              isSelected={taskSelectedId === task.id}
              onSelect={onSelect}
            />
          )
        )}

      {activeTab === InboxTab.TODAY &&
        useGeneralQuery.data?.map((task, index) => (
          <InboxBlock
            data={task}
            index={index}
            key={task.id}
            onDataDelete={handleDelete}
            onDataDuplicate={onDataDuplicate}
            isTodayGeneral={true}
            status={InboxBlockStatusType.Today}
            position={InboxBlockPositionType.General}
            isSelected={taskSelectedId === task.id}
            onSelect={onSelect}
            onClickBlock={onClickInboxBlock}
            onClickCheckBox={onClickCheckBox}
          />
        ))}
    </Flex>
  );
};

export default InboxGeneralList;
