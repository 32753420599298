export const Operation = {
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  GREATER_THAN_OR_EQUAL: "gte",
  LESS_THAN_OR_EQUAL: "lte",
  NOT: "null",
} as const;
export type OperationType = (typeof Operation)[keyof typeof Operation];

export const BlockSort = {
  COMPLETE: "complete",
  OVERDUE: "overdue",
} as const;
export type BlockSortType = (typeof BlockSort)[keyof typeof BlockSort];

// Server Visibility
export const Visibility = {
  DEFAULT: "default",
  PUBLIC: "public",
  PRIVATE: "private",
} as const;
export type VisibilityType = (typeof Visibility)[keyof typeof Visibility];

export const Transparency = {
  OPAQUE: "opaque",
  TRANSPARENT: "transparent",
} as const;
export type TransparencyType = (typeof Transparency)[keyof typeof Transparency];

// Client Visibility
export const BlockVisibility = {
  PUBLIC: "public",
  DEEPWORK: "deepwork",
  INVISIBLE: "invisible",
} as const;

export type BlockVisibilityType = (typeof BlockVisibility)[keyof typeof BlockVisibility];

export const BasicBlock = {
  TASK: "task",
  EVENT: "event",
  DIVIDER: "divider",
} as const;
export type BasicBlockType = (typeof BasicBlock)[keyof typeof BasicBlock];

export const BlockRequestQueryTypeParam = {
  TASK: "task",
  EVENT: "event",
  INBOX: "inbox",
  CALENDAR: "calendar",
  TRASH: "trash",
} as const;
export type BlockRequestQueryTypeParamType =
  (typeof BlockRequestQueryTypeParam)[keyof typeof BlockRequestQueryTypeParam];

export const ItemStatus = {
  COMPLETED: "completed",
  IN_PROGRESS: "inProgress",
  DELETED: "deleted",
} as const;
export type ItemStatusType = (typeof ItemStatus)[keyof typeof ItemStatus];

export const Priority = {
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
  DEFAULT: "default",
} as const;
export type PriorityType = (typeof Priority)[keyof typeof Priority];

export const AttendResponse = {
  DECLINED: "declined",
  TENTATIVE: "tentative",
  ACCEPTED: "accepted",
  NEEDS_ACTION: "needsAction",
} as const;
export type AttendResponseType = (typeof AttendResponse)[keyof typeof AttendResponse];

export const Notification = {
  ALL: "all",
  NONE: "none",
  EXTERNAL_ONLY: "externalOnly",
} as const;
export type NotificationType = (typeof Notification)[keyof typeof Notification];

export const BlockLocation = {
  INBOX: "inbox",
  CALENDAR: "calendar",
} as const;
export type BlockLocationType = (typeof BlockLocation)[keyof typeof BlockLocation];

export const DragAccept = {
  MORE_TASK: "moreTask",
  INBOX: "inbox",
} as const;
export type DragAcceptType = (typeof DragAccept)[keyof typeof DragAccept];

export const DragKind = {
  INBOX_ITEM: "inboxItem",
  INBOX_TASK: "inboxTask",
  INBOX_CALENDAR_ITEM: "inbox-calendar-item",
  CALENDAR_TASK: "calendarTask",
  MORE_TASK: "moreTask",
} as const;
export type DragKindType = (typeof DragKind)[keyof typeof DragKind];

export const RecurringOption = {
  THIS: "this",
  THISANDAFTER: "thisAndAfter",
  ALL: "all",
} as const;
export type RecurringOptionType = (typeof RecurringOption)[keyof typeof RecurringOption];
