import { AxiosRequestConfig } from "axios";
import spaceAPI from "../../api/SpaceAPI";
import { useInitialCalendarBlocks } from "../../hooks/calendar/useInitialCalendarBlocks";
import useApi from "../../services/auth/useApi";
import { SpaceType } from "../../types/space";
import { SpaceStatusType } from "../../types/space/enum";
import { organizeBlocksByDate, sortBlocksByStartTime } from "../../utils/wiki/initializeWikiBlocks";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ResponseError } from "../../api/types";
import { RequestPayloadGetSpaces, RequestPayloadGetSpaceWithId, SpaceRequests } from "./requests";

import { BlockResponseType } from "../../types/block/type";
import { ItemStatus } from "../../types/block/enum";

interface SpaceQueryOptions<TResponseData = unknown, TPayload = undefined> {
  /** 요청을 보낼 때 필요한 정보 */
  payload: TPayload;
  /** axios config */
  config?: AxiosRequestConfig;
  /** queryKey와 queryFn을 제외한 쿼리 옵션 */
  options?: Omit<UseQueryOptions<TResponseData, ResponseError>, "queryKey" | "queryFn">;
}

export const spaceQueryKeys = {
  all: ["space"],
  // REVIEW: 키가 시나리오에 따라 각각의 entity에서 서로 다른 방식으로 적용되면 혼선을 가져올 수 있음
  // key에도 요청 context에 대한 힌트를 줌으로써 좀 더 직관적으로 invalidation을 처리할 수 있음
  // - get one의 경우에는 늘 ID가 들어가고, getAll의 경우에는 아이디가 필요없음
  // - get all의 경우에는 검색할 수 있는 query param이 들어감
  getById: (id: number) => [...spaceQueryKeys.all, { id }],
  getAllByParams: (params: RequestPayloadGetSpaces["params"]) => [...spaceQueryKeys.all, params],
  // ASIS
  byId: (id: number) => [...spaceQueryKeys.all, { id }],
  status: (itemStatus: SpaceStatusType) => [...spaceQueryKeys.all, { itemStatus }],
} as const;

export const useGetSpaces = ({
  payload: params,
  options,
  config,
}: SpaceQueryOptions<SpaceType[], RequestPayloadGetSpaces["params"]>) => {
  return useQuery<SpaceType[], ResponseError>({
    queryKey: spaceQueryKeys.getAllByParams(params),
    queryFn: async () => {
      const response = await SpaceRequests.getSpaces({
        params,
        ...config,
      });
      return response.data;
    },
    ...options,
  });
};

export const useGetSpaceById = ({
  payload: id,
  options,
  config,
}: SpaceQueryOptions<SpaceType, RequestPayloadGetSpaceWithId["id"]>) => {
  return useQuery<SpaceType, ResponseError>({
    queryKey: spaceQueryKeys.byId(id),
    queryFn: async () => {
      const response = await SpaceRequests.getSpaceById({ id, ...config });
      return response.data;
    },
    ...options,
  });
};

// ASIS Ellie 작업내용
export const useGetSpaceByIdQueryOptions = (id: number) => {
  const { processInitialCalendarBlocks } = useInitialCalendarBlocks();
  const api = useApi();

  // TEMP 임시로 기획과 협의한 날짜
  const wikiLastMonth = "2025-12";

  return {
    queryKey: spaceQueryKeys.byId(id),
    enabled: !!id,
    queryFn: () =>
      spaceAPI.getSpaceDetailById(api, id).then((res) => {
        const data = res.data;
        if (!data) return data;

        // NOTE 1. 반복 이벤트 처리
        const processedBlocks = processInitialCalendarBlocks(data.blocks, wikiLastMonth);

        // NOTE 2. 삭제된 블록 제외
        const exceptDeletedBlocks = processedBlocks.filter(
          (block: BlockResponseType) =>
            block.itemStatus !== ItemStatus.DELETED || !block.start || !block.end
        );

        // NOTE 2. 블록 날짜별 그룹화
        const blocksByDate = organizeBlocksByDate(exceptDeletedBlocks);

        // NOTE 3. 블록 시간 순 정렬
        const sortedBlocksByDate = sortBlocksByStartTime(blocksByDate);

        return {
          ...data,
          blocks: sortedBlocksByDate,
        };
      }),
  };
};
