import { atom } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const startOfWeekState = atom({
  key: "startOfWeekState",
  default: "Sunday",
  effects: [localStorageEffect("startOfWeek")],
});

export const timeFormatState = atom({
  key: "timeFormatState",
  default: "12-hour",
  effects: [localStorageEffect("timeFormat")],
});

export const defaultDurationState = atom({
  key: "defaultDurationState",
  default: "15",
  effects: [localStorageEffect("defaultDuration")],
});
