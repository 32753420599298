import { log } from "console-log-colors";

/**
 * Production에서는 자동으로 출력되지 않는 logger 함수입니다. console.log 대신에 사용하세요.
 * @remarks
 * - Only logs in development environment
 * - Uses console-log-colors for colored output
 * - Properly formats objects and arrays
 *
 * @param colorFn - Color function from console-log-colors
 * @returns Logger function that applies color to messages
 */
function createLogger(colorFn: any) {
  return (...messages: any[]) => {
    if (process.env.NODE_ENV === "development") {
      // Format each message properly
      const formattedMessages = messages.map((msg) =>
        typeof msg === "object" ? JSON.stringify(msg, null, 2) : String(msg)
      );

      // Join messages with space and apply color
      if (formattedMessages.length > 0) {
        const coloredMessage = colorFn(formattedMessages.join(" "));
        if (coloredMessage) {
          // eslint-disable-next-line no-console
          console.log(...coloredMessage);
        }
      }
    }
  };
}

export const logger = {
  success: createLogger(log.greenBright),
  warn: createLogger(log.yellowBright),
  error: createLogger(log.redBright),
  info: createLogger(log.blueBright), // typically info is blue
  log: createLogger(log.white), // normal log messages with no color
  debug: createLogger(log.gray),
};
