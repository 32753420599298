import { useSetRecoilState } from "recoil";
import { UseMutateFunction, useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import useApi from "../../services/auth/useApi";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { tokenState } from "../../recoil/auth/tokenState";
import googleAPI from "../../api/GoogleAPI";

interface useCreateMeetingCodeResult {
  mutateMeetingCode: UseMutateFunction<string, Error, string, unknown>;
  meetingCode: string | null;
  isPendingMeetingCode: boolean;
  error: Error | null;
}

// TODO @9ellie 옵션만 반환하도록 수정 ㄴ필요
const useCreateMeetingCode = (): useCreateMeetingCodeResult => {
  const api = useApi();
  const navigate = useNavigate();
  const setTaskDetail = useSetRecoilState(taskPopupState);
  const setToken = useSetRecoilState(tokenState);

  const mutation: UseMutationResult<string, Error, string, unknown> = useMutation<
    string,
    Error,
    string
  >({
    mutationFn: async (): Promise<string> => await googleAPI.createGoogleMeetCode(api),
    onSuccess: (meetingCode: string) => {
      setTaskDetail((prevState: any) => {
        return {
          ...prevState,
          data: {
            ...(prevState.data as any),
            meetingCode: meetingCode,
            hangoutLink: `https://meet.google.com/${meetingCode}`,
          },
        };
      });
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 400) {
        setToken({
          mAccessToken: null,
          mRefreshToken: null,
          accessToken: null,
        });
        navigate("/");
      }
      throw error;
    },
  });

  return {
    mutateMeetingCode: mutation.mutate,
    meetingCode: mutation.data || null,
    isPendingMeetingCode: mutation.isPending,
    error: mutation.error,
  };
};

export default useCreateMeetingCode;
