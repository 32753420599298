import styles from "./style.module.css";

export default function RadioBtn({ onClick, name, label, labelWidth, checked }) {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(label);
  };
  return (
    <label className={styles["radio"]} onClick={handleClick}>
      <input className={styles["radio-input"]} type="radio" name={name} checked={checked}></input>
      <span className={styles["radio-control"]}></span>
      <span className={styles["radio-label"]} style={{ width: labelWidth || "auto" }}>
        {label}
      </span>
    </label>
  );
}
