import { IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react";

import InboxHeaderFilterMenuItem from "./InboxHeaderFilterMenuItem";
import { InboxFilterIcon } from "./InboxHeaderIcon";

import { pxToRem } from "../../../utils/styles/size";
import { HandleTabToggleVisibility } from "..";
import { InboxHeaderTab, ToggleVisibilityByTab } from "../../../types/inbox/inbox-headers";

/** Inbox area header 우측 필터 버튼 */
interface InboxHeaderFilterButtonProps {
  activeTab: InboxHeaderTab;
  onToggleVisibility: HandleTabToggleVisibility;
  toggleVisibility: ToggleVisibilityByTab;
}
const InboxHeaderFilterButton = ({
  activeTab,
  toggleVisibility,
  onToggleVisibility,
}: InboxHeaderFilterButtonProps) => {
  return (
    <Menu
      placement="bottom-end"
      isLazy
    >
      <MenuButton
        as={IconButton}
        size={pxToRem(28)}
        w={pxToRem(28)}
        h={pxToRem(28)}
        background={"transparent"}
        borderRadius={"5px"}
        aria-label="inbox-filter"
        icon={<InboxFilterIcon color={"#abadad"} />}
        _hover={{
          background: "whiteAlpha.200",
          svg: {
            color: "#fff",
          },
        }}
        _active={{
          background: "whiteAlpha.200",
        }}
      />
      {/* 버튼 클릭 시 뜨는 필터 메뉴 목록 */}
      <MenuList
        minW={"initial"}
        margin={0}
        p={0}
        overflow={"hidden"}
        borderRadius={"6px"}
        color={"white"}
        border={"1px solid"}
        borderColor={"whiteAlpha.200"}
        backgroundColor={"#2F3131"}
      >
        {activeTab === "Inbox" && (
          <InboxHeaderFilterMenuItem
            title="planned"
            activeTab={activeTab}
            onToggleVisibility={onToggleVisibility}
            toggleVisibility={toggleVisibility}
          />
        )}
        <InboxHeaderFilterMenuItem
          title="overdue"
          activeTab={activeTab}
          onToggleVisibility={onToggleVisibility}
          toggleVisibility={toggleVisibility}
        />
        <InboxHeaderFilterMenuItem
          title="completed"
          activeTab={activeTab}
          onToggleVisibility={onToggleVisibility}
          toggleVisibility={toggleVisibility}
        />
      </MenuList>
    </Menu>
  );
};

export default InboxHeaderFilterButton;
