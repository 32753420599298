export const InboxTab = {
  INBOX: "Inbox",
  TODAY: "Today",
} as const;

export type InboxTabType = (typeof InboxTab)[keyof typeof InboxTab];

export const InboxToggleTitle = {
  PLANNED: "Planned",
  OVERDUE: "Overdue",
  COMPLETED: "Completed",
} as const;

export type InboxToggleTitleType = (typeof InboxToggleTitle)[keyof typeof InboxToggleTitle];
