import { useQuery } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";
import { AxiosError } from "axios";
import { useEffect } from "react";

export const fetchPageName = async (
  api: ReturnType<typeof useApi>,
  url: string
): Promise<string | null> => {
  try {
    const response = await api.get(`/etc/ogs`, {
      params: { url },
    });
    return response.data.title;
  } catch (error) {
    console.error("Failed to fetch page name:", error);
    return null;
  }
};

export const useFetchPageNameFromURL = (url: string) => {
  const api = useApi();

  const { data, isLoading } = useQuery<string | null, AxiosError>({
    queryKey: ["fetchPageName", url],
    queryFn: () => fetchPageName(api, url),
    enabled: !!url,
  });

  return { pageName: data, isLoading };
};
