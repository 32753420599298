import { logger } from "../logger";

// 로컬 스토리지에 데이터 저장
export function saveToLocalStorage<T = any>(key: string, value: T) {
  try {
    const serializedValue: string = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (err) {
    logger.error("로컬 스토리지에 저장하는데 실패했습니다.", err);
  }
}

/**
 * 로컬 스토리지에서 데이터 불러오기
 * @param {string} key 로컬 스토리지 키
 * @returns {T} 로컬 스토리지 데이터
 * '값이 없음'을 명시적으로 표현하기 위해 null을 반환
 */
export function loadFromLocalStorage<T = any>(key: string): T | null {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    return JSON.parse(serializedValue);
  } catch (err) {
    logger.error("로컬 스토리지에서 불러오는데 실패했습니다.", err);
    return null;
  }
}
