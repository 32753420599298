// queries와 mutation에 쓰일 일반 axios request 함수를 정의합니다.
// - 직접 사용할 수도 있습니다.

import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SpaceKindType, SpaceStatusType } from "../../types/space/enum";
import { CreateSpaceType, SpaceType, UpdateSpaceType } from "../../types/space";
import { ApiPathComposer } from "../../api/genPath";
import axiosClient from "../../api/axiosClient";

// TODO: 공용으로 다른 곳에서도 사용할 수 있음
interface SpaceRequest {
  // TODO: instance 주입 방식 삭제 필요
  config?: AxiosRequestConfig;
}

export interface SpaceQueryParams {
  spaceType?: SpaceKindType;
  itemStatus?: SpaceStatusType;
}

/** Space 조회 요청 시 payload */
export interface RequestPayloadGetSpaces extends SpaceRequest {
  // query params
  params: SpaceQueryParams;
}

/** Space 상세 조회 요청 시 payload */
export interface RequestPayloadGetSpaceWithId extends SpaceRequest {
  id: number;
}

/** Space 생성 요청 시 payload */
export interface RequestPayloadCreateSpace extends SpaceRequest {
  data: CreateSpaceType;
}

/** Space 수정 요청 시 payload */
export interface RequestPayloadUpdateSpace extends SpaceRequest {
  id: number;
  data: UpdateSpaceType;
}

/** Space 삭제 요청 시 payload */
export interface RequestPayloadDeleteSpace extends SpaceRequest {
  id: number;
}

/** Space 순서 수정 요청 시 payload */
export interface RequestPayloadUpdateSpaceOrder extends SpaceRequest {
  data: {
    ids: number[];
  };
}

// WARN: instance 주입 방식은 삭제되어야 합니다.
export class SpaceRequests {
  static getSpaces({
    params,
    config,
  }: RequestPayloadGetSpaces): Promise<AxiosResponse<SpaceType[]>> {
    return axiosClient.get<SpaceType[]>(ApiPathComposer.spaces, {
      ...config,
      params: {
        ...config?.params,
        ...params, // external param state으로 직접 주입한 params가 우선권
      },
    });
  }

  static getSpaceById({
    id,
    config,
  }: RequestPayloadGetSpaceWithId): Promise<AxiosResponse<SpaceType>> {
    return axiosClient.get<SpaceType>(ApiPathComposer.spaceWithId(id), config);
  }

  static createSpace({
    data,
    config,
  }: RequestPayloadCreateSpace): Promise<AxiosResponse<SpaceType>> {
    return axiosClient.post(ApiPathComposer.spaces, data, config);
  }

  static updateSpaceById({
    id,
    data,
    config,
  }: RequestPayloadUpdateSpace): Promise<AxiosResponse<SpaceType>> {
    return axiosClient.put(ApiPathComposer.spaceWithId(id), data, config);
  }

  static deleteSpaceById({ id, config }: RequestPayloadDeleteSpace): Promise<AxiosResponse<void>> {
    return axiosClient.delete(ApiPathComposer.spaceWithId(id), config);
  }

  static updateSpaceOrder({
    data,
    config,
  }: RequestPayloadUpdateSpaceOrder): Promise<AxiosResponse<void>> {
    return axiosClient.put(ApiPathComposer.spaceOrder, data, config);
  }
}
