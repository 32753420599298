import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { calendarViewState, showMorePopupState } from "../../recoil/calendar/calendarState";
import { selectedSpaceIdListStateSelector } from "../../recoil/spaces/selectedSpaceIdListState";
import {
  defaultDurationState,
  startOfWeekState,
  timeFormatState,
} from "../../recoil/calendar/settingCalendar";
import { toastState } from "../../recoil/toast/toastState";
import useFetchCalendarEvents from "../../hooks/useFetchCalendarEvents";

import {
  calendarLastMonthSelector,
  draggedEventState,
} from "../../recoil/calendar/calendarStateV2";
import moment from "moment";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import CustomDay from "./CustomDay";
import { loadFromLocalStorage, saveToLocalStorage } from "../../utils/localStorage/localStorage";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { CalendarViewType, MeetWithColors } from "../../constants";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { accountState } from "../../recoil/account/accountStateV2";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import useClickOutside from "../../hooks/useClickOutside";
import { juneTrack } from "../../utils/june/analytics";
import useCreateMeetingCode from "../../react-query/block/useCreateMeetingCode";
import { useCalendarEventQueries } from "../../queries/Calendar";
import { filterTasks } from "../../services/task/task.service";
import { areDateEqual } from "../../utils/common/dateTime/areDateEqual";

import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";
import UserCancelledPopupError from "../../errors/UserCancelledPopupError";

import { extractSpaceId } from "../../services/space/space.service";
import CalendarHeader from "./header";
import overlap from "react-big-calendar/lib/utils/layout-algorithms/overlap";
import ShowMoreTrigger from "./ShowMorePopup/ShowMoreTrigger";
import { createPortal } from "react-dom";
import ShowMorePopup from "./ShowMorePopup";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { MyTimeGutterHeader } from "./MyTimeGutterHeader";
import { MyDayHeader } from "./MyDayHeader";
import { MyDayWeekHeader } from "./MyDayWeekHeader";
import { MyTimeGutterWrapper } from "./MyTimeGutterWrapper";

import { CustomEvent } from "./CustomEvent";
import { CustomEventWrapper } from "./CustomEventWrapper";

import useHandleClientBlockStateChange from "../../hooks/block/useHandleClientBlockStateChange";
import { useInitialCalendarBlocks } from "../../hooks/calendar/useInitialCalendarBlocks";
import { useUpdateTimeCalendarBlockMutation } from "../../react-query/calendar/useUpdateTimeCalendarBlockMutation";

import { BasicBlock, DragKind, Notification } from "../../types/block/enum";
import { useCreateCalendarBlockMutationOptions } from "../../react-query/calendar/mutations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { calendarQueryKeys } from "../../react-query/calendar/queries";
import {
  defaultDurationCalculatedEndTime,
  isAllDayEvent,
} from "../../utils/calendar/calculateDropEvent";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import { PopupActions } from "../../types/block/popup";
import { getLastDayOfTwoMonthsLater } from "../../utils/date-format/dateUtils";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { removeClientOnlyFields } from "../../utils/common/block/removeClientOnlyFields";
import { creatingCalendarBlockState } from "../../recoil/calendar/creatingCalendarBlockState";
import { JUNE_EVENT, JUNE_LOCATION } from "../../hooks/june/juneEvent";
import { CANCELED } from "../../services/taskDetail/block.service";
import { logger } from "../../utils/logger";

moment.updateLocale("en", {
  week: {
    dow: 0,
    doy: 1,
  },
});

/**
 * @param {object} props
 * @param {boolean} props.hasBlocksSynchronizedWithGoogle - 백엔드가 보내는 block들과 Google Calendar 동기화 여부
 */
export const MyCalendar = ({ hasBlocksSynchronizedWithGoogle }) => {
  // 1. Local States (UI 관련 상태)
  // NOTE: dayjs localizer도 제공함 바꾸면서 추후 수정하면 될 듯
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [showMoreEvents, setShowMoreEvents] = useState(null);
  const [previewSlot, setPreviewSlot] = useState(null);
  const [meetWithEvents, setMeetWithEvents] = useState([]);

  // 2. Refs
  const currentMoreTriggerRef = useRef(null);
  const clickListenerRef = useRef(null);
  const showMorePopupPos = useRef(null);
  const calendarRef = useRef(null);
  const isDragging = useRef();

  // 3. Recoil States - 읽기/쓰기
  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const [mobaCalendarList, setMobaCalendarList] = useRecoilState(mobaCalendarListState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [showMorePopup, setShowMorePopup] = useRecoilState(showMorePopupState);
  const [meetWithAccounts, setMeetWithAccounts] = useRecoilState(meetWithAccountsState);
  const [creatingCalendarBlock, setCreatingCalendarBlock] = useRecoilState(
    creatingCalendarBlockState
  );

  const timeFormat = useRecoilValue(timeFormatState);

  // 4. Recoil States - 쓰기 전용
  const setToast = useSetRecoilState(toastState);

  // 5. Recoil States - 읽기 전용
  const startOfWeek = useRecoilValue(startOfWeekState);
  const defaultVisibility = useRecoilValue(visibilityState);
  const calendarLastMonth = useRecoilValue(calendarLastMonthSelector);
  const account = useRecoilValue(accountState);
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);
  const defaultDuration = useRecoilValue(defaultDurationState);

  // 6. Custom Hooks
  const { processInitialCalendarBlocks } = useInitialCalendarBlocks();
  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();
  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();

  // 7. Queries
  const queryClient = useQueryClient();
  const { result: fetchCalendarEvents } = useFetchCalendarEvents({
    enabled: hasBlocksSynchronizedWithGoogle,
  });
  const { targetRef, triggerRef } = useClickOutside(() => {
    setShowMorePopup(false);
  });
  const calendarEventQueries = useCalendarEventQueries({
    emailList: meetWithAccounts.map((account) => account.email),
    enabled: hasBlocksSynchronizedWithGoogle,
  });

  // 8. Mutations
  const { mutateMeetingCode } = useCreateMeetingCode();
  const updateTimeCalendarBlock = useUpdateTimeCalendarBlockMutation();
  const createCalendarBlockMutation = useMutation(useCreateCalendarBlockMutationOptions());

  // 9. Utils & Others
  const email = account?.email ?? "";

  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
        day3: CustomDay,
      },
    }),
    []
  );

  // #region useEffects
  // Q. Why useLayoutEffect? Was it really needed to block visual update?
  // EFFECT: Setup moment localizer, use for react-big-calendar
  useLayoutEffect(() => {
    const updateLocale = (locale, dow) => {
      moment.updateLocale(locale, {
        week: {
          dow, // week: 0 (일요일부터) or 1 (월요일부터)
          doy: 1,
        },
      });
    };

    if (startOfWeek === "Monday") {
      // COMMENT: 월요일부터 시작하면 왜 `ko`로 되어있는지?
      updateLocale("ko", 1);
    } else {
      updateLocale("en", 0);
    }

    setLocalizer(momentLocalizer(moment));
  }, [startOfWeek]);

  // EFFECT: Set `mobaCalendarList` recoil state
  useEffect(() => {
    if (!fetchCalendarEvents.data) return;

    const recurringEnd = calendarLastMonth
      ? getLastDayOfTwoMonthsLater(calendarLastMonth)
      : new Date();

    const initialProcessedCalendarBlocks = processInitialCalendarBlocks(
      fetchCalendarEvents.data,
      recurringEnd
    );

    setMobaCalendarList(initialProcessedCalendarBlocks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCalendarEvents.data, processInitialCalendarBlocks, calendarLastMonth]);

  // EFFECT: Set `showMorePopup` recoil state
  useEffect(() => {
    const targetNode = currentMoreTriggerRef.current;

    if (targetNode) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            if (!document.contains(targetNode)) {
              currentMoreTriggerRef.current = null;
              setShowMorePopup(false);
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
    // NOTE: 이 함수에 영향을 주지 않는 showMorePopup 상태를 쓰는 이유를 몰라 그대로 놔둠
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMoreTriggerRef]);

  // EFFECT: Set `meetWithEvents` recoil state
  useEffect(() => {
    if (!calendarEventQueries.data || calendarEventQueries.pending || !account) return;

    const convertMobaCalendar = calendarEventQueries.data.map((accountEvent, idx) => {
      const creator = account.email;
      const currentMeetWithAccount = meetWithAccounts[idx].email;

      return (
        (accountEvent &&
          (creator !== currentMeetWithAccount
            ? accountEvent.map((singleEvent) =>
                convertServerToClientBlockType({
                  serverBlock: singleEvent,
                  defaultVisibility: defaultVisibility,
                })
              )
            : [])) ??
        []
      );
    });

    const meetWith = convertMobaCalendar.flatMap((accountEvent, idx) =>
      accountEvent.flatMap((items) => ({
        ...items,
        backgroundColor: MeetWithColors[idx % MeetWithColors.length],
        isMeetWith: true,
      }))
    );

    setMeetWithEvents(meetWith);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEventQueries.data, account]);

  // EFFECT: Set `meetWithAccounts` recoil state
  useEffect(() => {
    calendarEventQueries.error.forEach(
      (error, errorIdx) =>
        error &&
        setMeetWithAccounts((account) =>
          account.map((item, idx) => (errorIdx === idx ? { ...item, isAccess: false } : item))
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEventQueries.error]);

  // EFFECT: Set `timeIndicator`
  useEffect(() => {
    const initialTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        createTimeIndicator();
      }, 200);

    const updateTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setInterval(() => {
        const timeTextEl = document.querySelector(`.time-indicator span`);
        if (!timeTextEl) {
          return handleTimeIndicator();
        }
        if (
          timeTextEl &&
          timeTextEl.textContent !==
            localizer.format(new Date(), timeFormat === "24-hour" ? "HH:mm" : "hh:mm a")
        ) {
          return handleTimeIndicator();
        }
      }, 1000);

    return () => {
      clearTimeout(initialTimeIndicator);
      clearInterval(updateTimeIndicator);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView]);

  // EFFECT: Set `draggedEvent` recoil state
  useEffect(() => {
    const handleMouseUp = () => {
      setDraggedEvent(null);
      const targetEl = document.querySelector(".rbc-calendar");
      if (targetEl) {
        targetEl.classList.remove("rbc-addons-dnd-is-dragging");
      }
    };
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // EFFECT: Scroll time indicator to top
  useEffect(() => {
    calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);

    const calenderWrapper = calendarRef.current;
    const resizeObserver = new ResizeObserver(() => {
      resizeTimeIndicator();
    });
    resizeObserver.observe(calenderWrapper);

    return () => {
      resizeObserver.unobserve(calenderWrapper);
    };
    // `resizeTimeIndicator`는 변경되지 않으므로 deps에서 제외
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView]);
  // #endregion useEffects

  // #region Memoized handlers
  // FUN: Set `eventPropGetter`
  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...{
        className: `isDraggable ${event.allDay ? "allDay-event" : ""} ${event?.isMeetWith ? "meetWith-event" : ""}`,
      },
    };
  }, []);

  // FUN: withDragAndDrop wrapped react-big-calendar component's `onView` handler
  const handleView = useCallback((newView) => {
    const timeViewMap = {
      day: "1day",
      day3: "3days",
      week: "weekly",
      month: "monthly",
    };
    newView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);

        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);

    setCalendarView(newView);
    saveToLocalStorage("calendarViewType", newView);

    juneTrack(JUNE_EVENT.CHANGE_CALENDAR_VIEW, { type: timeViewMap[newView] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUN: onDuplicate handler, added to taskDetail state
  // - COMMENT: Why assigned to taskDetail state manually?
  const handleDataDuplicate = useCallback((e, rowData) => {
    const { id, sourceResource, ...rowDataWithoutId } = rowData;

    juneTrack(JUNE_EVENT.DUPLICATE_BLOCK, {
      location: JUNE_LOCATION.CALENDAR,
      type: rowData.blockType,
    });

    // 기본 중복 데이터 생성
    let newItem = {
      ...rowDataWithoutId,
    };

    //meetingCode 값이 있어야 hangoutLink가 생성됨
    if (rowData.hangoutLink) {
      newItem.meetingCode = rowData.hangoutLink.replace("https://meet.google.com/", "");
    }

    // 이벤트 유형에 따른 처리
    const hasGuests = newItem?.attendees?.length > 0;

    const hasRecurrence = newItem?.recurrence?.length > 0;
    const hasOriginalId = !!newItem.originalId;
    const isRecurring = hasRecurrence || hasOriginalId;

    // 단순 이벤트(게스트 없고 반복 없음)는 바로 저장
    if (!hasGuests && !isRecurring) {
      const clientOnlyFieldRemovedBlock = removeClientOnlyFields(newItem, isRecurring);

      // TODO 추후 클라이언트 handling 함수로 변경 필요
      setMobaCalendarList((current) => [...current, newItem]);
      setTaskDetail((prevState) => ({ ...prevState, isVisible: false }));

      createCalendarBlockMutation.mutate(
        { payload: clientOnlyFieldRemovedBlock },
        {
          onSuccess: () => {
            setToast({
              type: "Success",
              isVisible: true,
              message: `${rowData.blockType === BasicBlock.TASK ? "Task" : "Event"} Created successfully`,
            });
          },
        }
      );
    } else {
      // guest 있거나 반복일 경우에는 팝업 띄워서 처리
      const { clientX, clientY } = e;

      const { originalId, originalStart, ...restItem } = newItem;

      setTaskDetail((prevState) => ({
        ...prevState,
        isVisible: true,
        data: restItem,
        isNewBlock: true,
        isDuplicateBlock: true,
        modalPosition: { x: clientX, y: clientY },
        // NOTE: 재귀함수가 되어 확인 필요
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
      }));

      // 미리 리스트에 추가 (시각적 피드백)
      setMobaCalendarList((current) => [...current, newItem]);
    }
    // 함수 바깥 scope에서 reference value를 가져와 사용하는 것은 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUN: Used in `handleDragOver`
  const getSlotAtXAndY = useCallback((x, y) => {
    const timeSlotElements = Array.from(document.querySelectorAll(".rbc-time-slot"));
    const foundElement = timeSlotElements.find((element) => {
      const rect = element.getBoundingClientRect();
      return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
    });

    if (foundElement) {
      const slotTime = new Date(foundElement.getAttribute("data-datetime"));
      return slotTime;
    }
    return null;
  }, []);

  // FUN: withDragAndDrop wrapped react-big-calendar component's `onDragOver` handler
  const handleDragOver = useCallback(
    (dragEvent) => {
      if (draggedEvent == null) {
        return;
      }
      if (draggedEvent !== "undroppable") {
        dragEvent.preventDefault();
      }

      // 슬롯 정보 가져오기
      const x = dragEvent.clientX;
      const y = dragEvent.clientY;
      const slot = getSlotAtXAndY(x, y);

      // COMMENT: Conditional information
      if (slot && (!previewSlot || slot.getTime() !== previewSlot[0].getTime())) {
        const slots = [];
        const slotDuration = 15; // 기본 슬롯 시간 (분)
        const numberOfSlots = defaultDuration / slotDuration;

        for (let i = 0; i < numberOfSlots; i++) {
          const newSlot = new Date(slot.getTime() + i * slotDuration * 60000);
          slots.push(newSlot);
        }
        setPreviewSlot(slots);
      } else if (!slot && previewSlot) {
        setPreviewSlot(null);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draggedEvent, previewSlot]
  );

  // FUN: Used in `handleDragOver`
  const handleSlotPropGetter = useCallback(
    (date) => {
      const formattedDate = date.toISOString();
      const slotStyle =
        previewSlot && previewSlot.some((slot) => date?.getTime() === slot.getTime())
          ? { backgroundColor: "rgba(255, 255, 255, 0.04)" }
          : {};

      return {
        className: date.toISOString(),
        style: slotStyle,
        "data-datetime": formattedDate, // 슬롯에 data-datetime 속성 추가
      };
    },
    [previewSlot]
  );

  // FUN: Used in `dragFromOutsideItem` of withDragAndDrop wrapped react-big-calendar component
  const handleDragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  // FUN: Resize time indicator
  // - resizeTimeIndicator를 별도의 useCallback으로 분리
  const resizeTimeIndicator = useCallback(() => {
    const wrapper = document.querySelector(`.rbc-time-content`);
    const timeEl = document.querySelector(`.time-indicator`);
    if (!wrapper || !timeEl) return;

    const wrapperBound = wrapper.getBoundingClientRect();
    timeEl.style.width = `${wrapperBound.width - 60}px`;
  }, []);

  const createTimeIndicator = useCallback(() => {
    const wrapper = document.querySelector(`.rbc-time-content`);
    if (!wrapper) return;

    const wrapperBound = wrapper.getBoundingClientRect();
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);

    const newTimeElWrapper = document.querySelector(".rbc-time-gutter");
    if (!newTimeElWrapper) return;

    const newTimeEl = document.createElement("div");
    newTimeEl.classList.add("time-indicator");
    newTimeEl.style.top = originTimeEl?.style?.top;
    newTimeEl.style.width = `${wrapperBound.width - 60}px`;

    const newTimeTextEl = document.createElement("span");
    if (newTimeTextEl) {
      newTimeTextEl.textContent = localizer.format(
        new Date(),
        timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
      );

      newTimeElWrapper.appendChild(newTimeEl);
      newTimeEl.appendChild(newTimeTextEl);
    }
  }, [timeFormat, localizer]);

  const handleTimeIndicator = useCallback(() => {
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);
    const timeEl = document.querySelector(`.time-indicator`);
    const timeTextEl = document.querySelector(`.time-indicator span`);

    if (timeTextEl) {
      timeTextEl.textContent = localizer.format(
        new Date(),
        timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
      );
    }

    if (!timeEl) {
      createTimeIndicator();
    } else {
      timeEl.style.top = originTimeEl
        ? originTimeEl.style.top
        : `${Number(timeEl.style.top.split("%")[0]) + 0.06945}%`;
    }
  }, [createTimeIndicator, localizer, timeFormat]);

  // handleUpdateTimeCalendarBlock 함수도 useCallback으로 래핑
  const handleUpdateTimeCalendarBlock = useCallback(
    async (prevBlock, updatePayload, onSuccess) => {
      const restoreOriginalTime = () => {
        const oldStart = prevBlock.start;
        const oldEnd = prevBlock.end;
        setMobaCalendarList((prev) =>
          prev.map((block) =>
            block.id === prevBlock.id
              ? { ...block, start: oldStart, end: oldEnd, allDay: prevBlock.allDay }
              : block
          )
        );
      };

      const getRecurringBlockUpdateParam = async () => {
        // 1. RecurringPopup에서 반복 이벤트 옵션 선택
        const oldStart = prevBlock.start;
        const oldEnd = prevBlock.end;
        const isDateEqual =
          oldStart && oldEnd
            ? areDateEqual(new Date(oldStart), new Date(updatePayload.start)) &&
              areDateEqual(new Date(oldEnd), new Date(updatePayload.end))
            : true;

        const selectedRecurringOption = await openRecurringPopup(
          prevBlock,
          PopupActions.EDIT,
          isDateEqual
        );

        if (selectedRecurringOption === CANCELED) return CANCELED;

        // 2. 게스트가 있을 경우 GuestPopup에서 알림 여부 선택
        const notifyGuests =
          prevBlock.attendees?.length > 0
            ? await openGuestPopup(prevBlock, PopupActions.EDIT)
            : false;

        if (notifyGuests === CANCELED) return CANCELED;

        return {
          id: prevBlock.id,
          payload: {
            ...updatePayload,
            originalStart: prevBlock.start,
            type: selectedRecurringOption,
            notification: notifyGuests ? Notification.ALL : Notification.NONE,
          },
          prevData: prevBlock,
        };
      };

      // TODO 추후 해당 로직 hook으로 분리하면 좋을 듯
      const getBasicBlockUpdateParam = async () => {
        // 일반 block 업데이트인 경우
        // NOTE 반복 없고 게스트 있을 경우 캘린더에서 블록 이동 시 guest popup
        const notifyGuests =
          prevBlock.attendees?.length > 0
            ? await openGuestPopup(prevBlock, PopupActions.EDIT)
            : false;

        if (notifyGuests === CANCELED) return CANCELED;

        return {
          id: prevBlock.id,
          payload: {
            ...updatePayload,
            notification: notifyGuests ? Notification.ALL : Notification.NONE,
          },
          prevData: prevBlock,
        };
      };

      // 메인로직
      try {
        const isRecurringBlock = prevBlock.recurrence?.length > 0 || prevBlock.originalId;
        const updateBlockParam = isRecurringBlock
          ? await getRecurringBlockUpdateParam()
          : await getBasicBlockUpdateParam();

        if (updateBlockParam === CANCELED) {
          restoreOriginalTime();
          return;
        }

        // NOTE Client state update
        const updateBlock = { ...prevBlock, ...updateBlockParam.payload };

        isRecurringBlock
          ? handleClientRecurringBlockChange({
              prevBlockData: prevBlock,
              updateBlockData: updateBlock,
            })
          : handleClientBasicBlockChange(updateBlock);

        // NOTE api update
        updateTimeCalendarBlock.mutate(updateBlockParam, {
          onSuccess,
          onError: (error) => {
            queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
            setToast({
              type: "Error",
              isVisible: true,
              message: "Failed to update. Please retry",
            });
            // 아래 catch에서 에러 잡을 수 있도록 throw
            throw error;
          },
        });
      } catch (error) {
        if (error instanceof UserCancelledPopupError) {
          // 사용자가 팝업을 취소한 경우의 처리
          restoreOriginalTime();
        } else {
          logger.error("Event deletion cancelled or failed", error);
        }
      }
    },
    // setter 함수들, mutation 함수들은 deps에서 제외
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient]
  );

  const moveEvent = useCallback(
    async ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const prevBlock = {
        ...event,
      };

      const calculateEventTimes = (event, start, end, isAllDay) => {
        if (isAllDay) {
          // allday가 true -> true로 변경되는 경우는 처리 안해줘도 됨
          // allday가 false -> true로 변경되는 경우는 +1일 처리 해줘야함
          const newEnd = event.allDay
            ? moment(end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
            : moment(start)
                .add(1, "day")
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toDate();

          return {
            // NOTE React Big Calendar에서 가져오는 값이 dateTime이라
            // 혼선을 막기 위해 date로 변경
            start: moment(start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
            end: newEnd,
            allDay: true,
          };
        }

        // allday가 true -> false로 변경되는 경우는 start에 defaultDuration 더해줘야함
        const newEnd =
          calendarView !== CalendarViewType.MONTH.type && event.allDay
            ? moment(start).add(defaultDuration, "minutes").toDate()
            : new Date(end);

        return {
          start: new Date(start),
          end: newEnd,
          allDay: false,
        };
      };

      const createUpdateBlockPayload = (event, { start, end, allDay }) => ({
        hangoutLink: event.hangoutLink,
        meetingCode: event.meetingCode,
        linkData: event.linkData,
        attendees: event.attendees,
        location: event.location,
        priority: event.priority,
        allDay,
        start,
        end,
      });

      const createTrackData = (event, times, wasAllDay) => {
        if (droppedOnAllDaySlot) {
          return {
            previous_start_datetime: formatDateTimeForJune(event.start),
            previous_end_datetime: formatDateTimeForJune(event.end),
            new_start_datetime: formatDateTimeForJune(new Date(times.start).setHours(0, 0, 0, 0)),
            new_end_datetime: formatDateTimeForJune(new Date(times.end).setHours(0, 0, 0, 0)),
            allDay: true,
          };
        }

        if (wasAllDay) {
          return {
            previous_start_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            previous_end_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            new_start_datetime: formatDateTimeForJune(start),
            new_end_datetime: formatDateTimeForJune(
              new Date(new Date(start).setMinutes(new Date(start).getMinutes() + defaultDuration))
            ),
            allDay: false,
          };
        }

        return {
          previous_start_datetime: formatDateTimeForJune(event.start),
          previous_end_datetime: formatDateTimeForJune(event.end),
          new_start_datetime: formatDateTimeForJune(start),
          new_end_datetime: formatDateTimeForJune(end),
          allDay: false,
        };
      };

      const wasAllDay = event.allDay;
      const times = calculateEventTimes(event, start, end, droppedOnAllDaySlot);
      const updatePayload = createUpdateBlockPayload(event, times);
      const trackData = createTrackData(event, times, wasAllDay);

      // event ui 업데이트
      event.start = times.start;
      event.end = times.end;
      event.allDay = times.allDay;

      const onSuccess = () => {
        // TODO: calendar invalidate(기존 블록 month, drop한 month)
        juneTrack(JUNE_EVENT.MOVE_BLOCK, trackData);
      };

      // 업데이트 로직 처리
      handleUpdateTimeCalendarBlock(prevBlock, updatePayload, onSuccess);
    },
    // 'handleUpdateTimeCalendarBlock'는 queryClient에 함께 종속되어 있어서 deps에서 제외
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarView, defaultDuration, queryClient]
  );

  // FUN: Used in `onEventResize` of withDragAndDrop wrapped react-big-calendar component
  const handleOnEventResize = useCallback(
    ({ event, start, end }) => {
      // TODO: allday에서 리사이즈 안되는 이슈 파악 필요
      const prevBlock = {
        ...event,
      };

      const MS15MIN = 900000;
      const calculateEventTimes = (start, end) => ({
        start,
        end:
          new Date(start).getTime() === new Date(end).getTime()
            ? new Date(new Date(end).getTime() + MS15MIN)
            : end,
      });

      const createUpdateBlockPayload = (event, { start, end }) => ({
        hangoutLink: event.hangoutLink,
        meetingCode: event.meetingCode,
        linkData: event.linkData,
        attendees: event.attendees,
        location: event.location,
        priority: event.priority,
        allDay: event.allDay,
        start,
        end,
      });

      const times = calculateEventTimes(start, end);
      const updatePayload = createUpdateBlockPayload(event, times);
      const trackData = {
        previous_start_datetime: formatDateTimeForJune(event.start),
        previous_end_datetime: formatDateTimeForJune(event.end),
        new_start_datetime: formatDateTimeForJune(start),
        new_end_datetime: formatDateTimeForJune(end),
      };

      // event ui 업데이트
      event.start = times.start;
      event.end = times.end;

      const onSuccess = () => {
        // NOTE: 외부 참조 변수인데, hook 안으로 넣는 방향으로 변경이 나을 듯
        juneTrack(JUNE_EVENT.RESIZE_BLOCK, trackData);
      };

      handleUpdateTimeCalendarBlock(prevBlock, updatePayload, onSuccess);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient]
  );

  // FUN: Used in `onDropFromOutside` of withDragAndDrop wrapped react-big-calendar component
  const handleOnDropFromOutside = useCallback(
    ({ start, end }) => {
      if (!draggedEvent || draggedEvent === "undroppable") {
        setDraggedEvent(null);
        return;
      }
      setPreviewSlot(null);

      // endTime === 00:00 인 경우 11:59로 변경
      // ex.23:45분으로 dnd한 경우 end 11:59로 변경
      const allDay = isAllDayEvent(start, end);
      const newEnd = allDay
        ? moment(end).format("YYYY-MM-DD")
        : defaultDurationCalculatedEndTime(start, defaultDuration);

      const { kind, index, dragType, color, ...rest } = draggedEvent;
      const changedItem = {
        hangoutLink: draggedEvent.hangoutLink,
        meetingCode: draggedEvent.meetingCode,
        linkData: draggedEvent.linkData,
        attendees: draggedEvent.attendees,
        location: draggedEvent.location,
        priority: draggedEvent.priority,
        allDay,
        start: start,
        end: newEnd,
      };

      // inbox general에서 온 block
      if (draggedEvent.dragType === DragKind.INBOX_ITEM) {
        const prevBlock = { ...rest };
        const newBlock = { ...rest, ...changedItem };

        // 캘린더 클라이언트 상태에 블록 추가
        setMobaCalendarList((prev) => [...prev, newBlock]);
        const onSuccess = () => {
          setToast({
            type: "Success",
            isVisible: true,
            message: "Task planned successfully",
          });
        };
        handleUpdateTimeCalendarBlock(prevBlock, changedItem, onSuccess);
      }

      // inbox 토글, today general에서 온 block
      if (draggedEvent.dragType === DragKind.INBOX_CALENDAR_ITEM) {
        const prevBlock = { ...rest };
        const updatePayload = changedItem;
        const onSuccess = () => {
          setToast({
            type: "Success",
            isVisible: true,
            message: "Task updated successfully",
          });
        };
        // 캘린더 클라이언트 상태 업데이트
        handleUpdateTimeCalendarBlock(prevBlock, updatePayload, onSuccess);
      }

      // showMore 팝업에서 온 block
      if (draggedEvent.dragType === DragKind.MORE_TASK) {
        const timeDiff = new Date(start).getTime() - new Date(draggedEvent.start).getTime();
        const newStart = new Date(new Date(draggedEvent.start).getTime() + timeDiff);
        const newEnd = new Date(new Date(draggedEvent.end).getTime() + timeDiff);
        const showMoreEvent = {
          ...changedItem,
          start: newStart,
          end: newEnd,
        };

        updateTimeCalendarBlock.mutate(
          { id: draggedEvent.id, payload: changedItem },
          {
            // TODO: calendar invalidate(기존 블록 month, drop한 month)
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
            },
          }
        );

        setShowMoreEvents(({ events, date }) => {
          return { date, events: events.filter((item) => item.id !== showMoreEvent.id) };
        });
      }

      setDraggedEvent(null);
      const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });
    },
    // juneTrack, setShowMoreEvents, handleUpdateTimeCalendarBlock, updateCalendarBlock, updateInboxGeneralToCalendar 제외
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draggedEvent, defaultDuration, defaultVisibility, queryClient]
  );

  // FUN: Calculate modal position
  const calculateModalPosition = useCallback((element, bounds, box, isMonthView = false) => {
    if (!element && !bounds && !box) return { x: 0, y: 0 };

    const modalWidth = 362;
    let x, y;

    if (isMonthView && (bounds || box)) {
      x = bounds ? bounds.x : box.x + 5;
      y = bounds ? bounds.y : box.y + 5;

      const clickedElement = document.elementFromPoint(x, y);
      const rbcDayBgElement = clickedElement?.closest(".rbc-day-bg");

      if (rbcDayBgElement) {
        const rect = rbcDayBgElement.getBoundingClientRect();
        if (rect.right + modalWidth > window.innerWidth) {
          x = rect.left - modalWidth - 5;
        } else if (rect.left - modalWidth < 0) {
          x = rect.right + 5;
        } else {
          x = rect.right;
        }
        y = rect.y;
      }
    } else if (element) {
      const rect = element.getBoundingClientRect();
      x = rect.right;
      y = rect.top;

      if (x + modalWidth > window.innerWidth) {
        x = rect.left - modalWidth - 5;
      }
      if (x < 0) {
        x = 48;
      }
    }

    return { x, y };

    // 외부 var을 사용하지 않고 있어서 deps 없음
  }, []);

  const clickTaskDetail = useCallback(
    (e, data) => {
      const wrapElement = e.target.closest(".event_wrapper");
      if (!wrapElement) {
        return; // 'wrap' 클래스를 가진 요소가 없으면 함수를 종료합니다.
      }
      const { sourceResource, ...restData } = data;

      setTaskDetail({
        isVisible: true,
        data: restData,
        modalPosition: calculateModalPosition(wrapElement, null, null),
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
        isNewBlock: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // TODO Today 여기 고쳐야 됨 ...
  const handleNavigate = useCallback((date, view) => {
    // gotoPrev, gotoNext에서 처리해서 해당 함수에서 처리할 부분 없어짐
  }, []);

  // 2. 이벤트 객체 생성 함수 메모이제이션
  // brandonwie 함수 반환은 useCallback이 사용목적에 적합하다고 판단
  const createEventObject = useCallback(
    (selectedSpaces) => {
      return {
        title: "",
        creator: email,
        visibility: defaultVisibility === "public" ? "public" : "private",
        transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
        attendees:
          meetWithAccounts.length > 0
            ? [{ email, organizer: true }, ...meetWithAccounts]
            : undefined,
        blockType: meetWithAccounts.length > 0 ? BasicBlock.EVENT : BasicBlock.TASK,
        ...extractSpaceId(selectedSpaces),
      };
    },
    // NOTE: 외부 참조 변수들을 calling하는 시점에서 넣어주는 방향으로 재작업하면 좋을 것 같음
    // - WHY? ASIS처럼 쓰면 deps variables가 변경될 때마다 callback을 다시 render하기 때문에
    [email, defaultVisibility, meetWithAccounts]
  );

  const cleanupEventListener = useCallback(() => {
    if (clickListenerRef.current) {
      document.removeEventListener("click", clickListenerRef.current);
      clickListenerRef.current = null;
    }
    // 선언될 당시의 value를 snapshot하기 때문에 deps 추가
  }, [clickListenerRef]);

  const handleSelectSlot = useCallback(
    (slotInfo, selectedSpaces) => {
      if (!account) return;

      const elements = document.getElementsByClassName(slotInfo.start.toISOString());
      const element = elements[elements.length === 2 ? 1 : 0];

      // 기본 이벤트 속성
      const baseEventProps = createEventObject(selectedSpaces);

      // All Day 이벤트 처리
      // Q: (to. @ellie) slotInfo.bounds와 slotInfo.box가 allDay 이벤트 처리와 어떤 관계가 있기에 allDay 처리 시 조건을 넣은 것인지?
      if (!slotInfo.bounds && !slotInfo.box) {
        /**
         * sloInfo.start : Tue Mar 04 2025 00:00:00 GMT+0900 form
         * slotInfo.end : Wed Mar 05 2025 00:00:00 GMT+0900 form
         */

        const newItem = {
          ...baseEventProps,
          allDay: true,
          start: moment(slotInfo?.start).startOf("day").toDate(),
          end: moment(slotInfo?.end).startOf("day").toDate(),
        };

        // Q1: (to. @ellie) cleanupEventListener 에서 listener 제거하고, 실행할 것하고 다시 제거하는 느낌인가요?
        // Q2: event listener는 handleSelectSlot와 분리해서 정의하지 않은 이유가 궁금합니다.
        // - WHY? handleSelectSlot은 calendar의 slot을 클릭했을 때의 로직처리를 위한 것이고,
        // - 이벤트 리스너는 모든 이벤트에 적용되어야 하기 때문에
        cleanupEventListener();

        clickListenerRef.current = () => {
          const position = calculateModalPosition(element, null, null);

          setCreatingCalendarBlock(newItem);

          setTaskDetail({
            isVisible: true,
            data: newItem,
            modalPosition: position,
            isNewBlock: true,
            handleDataDuplicate: handleDataDuplicate,
            type: "calendar",
          });

          cleanupEventListener();
        };

        document.addEventListener("click", clickListenerRef.current);
        return;
      }

      // 일반 이벤트 처리
      const isMonthView = calendarView === "month";

      // click 시 기본 defaultDuration으로 설정, select(drag)시 slotInfo.end로 설정
      const endDefaultDateTime = moment(slotInfo.start).add(defaultDuration, "minutes").toDate();

      const newItem = isMonthView
        ? {
            ...baseEventProps,
            allDay: true,
            start: moment(slotInfo?.start).format("YYYY-MM-DD"),
            end: moment(slotInfo?.end).format("YYYY-MM-DD"),
          }
        : {
            ...baseEventProps,
            allDay: false,
            start: slotInfo.start,
            end: slotInfo.action === "click" ? endDefaultDateTime : slotInfo.end,
          };

      setCreatingCalendarBlock(newItem);

      if (meetWithAccounts.length > 0) {
        mutateMeetingCode(email);
      }

      setTaskDetail({
        isVisible: true,
        data: newItem,
        modalPosition: calculateModalPosition(element, slotInfo.bounds, slotInfo.box, isMonthView),
        isNewBlock: true,
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, clickListenerRef, calendarView, defaultDuration, meetWithAccounts, createEventObject]
  );

  const handleDragResizeEvent = useCallback(
    (e) => {
      if (!isDragging.current) {
        return;
      }
      const scrollEl = document.querySelector(".rbc-time-content");
      const scrollBound = scrollEl?.getBoundingClientRect();
      if (scrollBound && e.pageY > scrollBound.bottom - 16) {
        scrollEl.scrollTop += 1;
      }
    },
    [isDragging]
  );

  const handleClickShowMore = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect();
      const row = e.target.closest(".rbc-month-row").getBoundingClientRect();
      showMorePopupPos.current = {
        top: row.top - 10,
        left: rect.left + (rect.width + 10) / 2,
        width: rect.width + 30,
      };
      currentMoreTriggerRef.current = e.target;
      setShowMorePopup(!showMorePopup);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMorePopup, showMorePopupPos, currentMoreTriggerRef]
  );

  const handleShowMorePopupPos = useCallback(
    (eventCount) => {
      const eventBlockHeight = 24;
      const showMorePopupPadding = 52;
      const popupHeight = eventBlockHeight * eventCount + 2 * eventCount + showMorePopupPadding;
      const popupWidth = showMorePopupPos.current.width;

      const newPos = {
        top: showMorePopupPos.current.top,
        left: showMorePopupPos.current.left,
        width: showMorePopupPos.current.width,
      };

      // width가 넘친 경우
      if (showMorePopupPos.current.left + popupWidth > window.innerWidth) {
        newPos.left = window.innerWidth - popupWidth - 10;
      }

      // height가 넘친 경우
      if (showMorePopupPos.current.top + popupHeight > window.innerHeight) {
        newPos.top = "auto";
        newPos.bottom = 0;
      }
      showMorePopupPos.current = newPos;
    },
    [showMorePopupPos]
  );
  // #endregion Memoized handlers

  // #region block state
  const myEvents = useMemo(() => {
    return mobaCalendarList
      .filter((task) => filterTasks(selectedSpaceIdList, task?.spaceId))
      .map((event) => ({ ...event }));
  }, [mobaCalendarList, selectedSpaceIdList]);

  // NOTE Show More Popup에 전달되는 이벤트 값이 변경되면 동기화되도록 설정
  const synchronizedShowMoreBlocks = useMemo(() => {
    if (!showMoreEvents) return null;

    return {
      ...showMoreEvents,
      events: showMoreEvents.events.map(
        (event) => myEvents.find((e) => e.id === event.id) || event
      ),
    };
  }, [showMoreEvents, myEvents]);

  const RBCTotalBlocks = useMemo(() => {
    // 모든 이벤트의 복사본을 생성하여 Object.freeze()로 동결되지 않은 객체를 사용
    const mergedBlocks = [...myEvents, ...meetWithEvents];
    // null 포함 가능하여 처리
    if (creatingCalendarBlock) {
      mergedBlocks.push(creatingCalendarBlock);
    }
    return mergedBlocks;
  }, [myEvents, meetWithEvents, creatingCalendarBlock]);
  // #endregion block state

  return (
    <div
      ref={calendarRef}
      style={{ width: "100%", height: "100%" }}
      onMouseDown={() => {
        isDragging.current = true;
      }}
      onMouseUp={() => {
        isDragging.current = false;
      }}
    >
      {myEvents && (
        <DragAndDropCalendar
          defaultDate={new Date()}
          components={{
            toolbar: CalendarHeader,
            event: (props) => (
              <CustomEvent
                {...props}
                localizer={localizer}
              />
            ),
            eventWrapper: (props) => {
              return (
                <CustomEventWrapper
                  {...props}
                  onClick={(e, data) => {
                    !data.isMeetWith && clickTaskDetail(e, data);
                  }}
                  onDataDuplicate={handleDataDuplicate}
                />
              );
            },
            timeGutterHeader: MyTimeGutterHeader,
            timeGutterWrapper: (props) => (
              <MyTimeGutterWrapper
                {...props}
                localizer={localizer}
              />
            ),
            day: {
              header: MyDayHeader,
            },
            week: {
              header: MyDayWeekHeader,
            },
            day3: {
              header: MyDayWeekHeader,
            },
          }}
          defaultView={
            Object.values(CalendarViewType).find(
              (viewType) => viewType.type === loadFromLocalStorage("calendarViewType")
            )?.type ?? Views.DAY
          }
          localizer={localizer}
          views={views}
          formats={CustomFormat}
          dragFromOutsideItem={handleDragFromOutsideItem}
          eventPropGetter={eventPropGetter}
          dayPropGetter={(date) => {
            const dayOfWeek = localizer.format(new Date(date), "ddd");
            if (dayOfWeek === "Sun" || dayOfWeek === "Sat") {
              return { className: "weekend" };
            }
          }}
          slotPropGetter={handleSlotPropGetter}
          events={RBCTotalBlocks}
          onDropFromOutside={handleOnDropFromOutside}
          onDragOver={handleDragOver}
          onEventDrop={moveEvent}
          onDragStart={(event) => {
            // 이미 freezing된 객체를 직접 수정하지 않고 새 객체를 생성해 Recoil 상태로 관리
            if (event.event.blockType === BasicBlock.TASK) {
              // 완전히 새로운 객체를 생성하여 사용
              const eventCopy = { ...event.event, dragType: DragKind.CALENDAR_TASK };
              setDraggedEvent(eventCopy);
            }

            if (event.action === "resize" && event.direction === "DOWN") {
              document.addEventListener("mousemove", handleDragResizeEvent);
            }
          }}
          onEventResize={handleOnEventResize}
          onNavigate={handleNavigate}
          onSelectSlot={(e) => handleSelectSlot(e, selectedSpaceIdList)}
          resizable
          onView={handleView}
          showMultiDayTimes={true}
          selectable={!taskDetail.isVisible}
          step={15}
          timeslots={4}
          dayLayoutAlgorithm={(params) => {
            return overlap({ ...params, minimumStartDifference: 15 });
          }}
          tooltipAccessor={null}
          messages={{
            showMore: (total) => (
              <ShowMoreTrigger
                ref={triggerRef}
                onClick={handleClickShowMore}
              >
                + {total} more
              </ShowMoreTrigger>
            ),
          }}
          popup={false}
          showAllEvents={false}
          doShowMoreDrillDown={false}
          onShowMore={(events, date) => {
            handleShowMorePopupPos(events.length);
            setShowMoreEvents({ events, date });
          }}
          draggableAccessor={(event) => !event.isMeetWith}
        />
      )}
      {showMorePopup &&
        createPortal(
          <ShowMorePopup
            ref={targetRef}
            date={synchronizedShowMoreBlocks.date}
            events={synchronizedShowMoreBlocks.events}
            onClose={() => setShowMorePopup(false)}
            style={showMorePopupPos.current}
          >
            {(event) => (
              <CustomEventWrapper
                key={event.id}
                event={event}
                onClick={(e, data, rect) => {
                  if (data.isMeetWith) return;
                  clickTaskDetail(e, data, rect);
                }}
                onDataDuplicate={handleDataDuplicate}
                taskDetailId={taskDetail.data?.id}
                taskDetailIsVisible={taskDetail.isVisible}
                taskDetailIsNewBlock={taskDetail.isNewBlock}
              >
                <CustomEvent
                  event={event}
                  localizer={localizer}
                  taskDetailId={taskDetail.data?.id}
                  taskDetailIsVisible={taskDetail.isVisible}
                />
              </CustomEventWrapper>
            )}
          </ShowMorePopup>,
          document.body
        )}
    </div>
  );
};
const DragAndDropCalendar = withDragAndDrop(Calendar);

const CustomFormat = {
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, "HH", culture),
  eventTimeRangeFormat: () => "",
  dayFormat: (date, culture, localizer) => localizer.format(date, "D ddd", culture),
  dateFormat: (date, culture, localizer) => localizer.format(date, "D", culture),
};
