import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inboxQueryKeys } from "./queries";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../recoil/toast/toastState";
import { useUpdateInboxBlockMutationOptions } from "./mutations";
import { BlockType } from "../../types/block/type";
import { calendarQueryKeys } from "../calendar/queries";

// inbox 삭제 mutation 훅
export const useDeleteInboxBlockMutation = (
  invalidateQueryKey: ReturnType<typeof inboxQueryKeys.inbox>
) => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toastState);
  return useMutation({
    ...useUpdateInboxBlockMutationOptions(),
    onMutate: async ({ id, data }: { id: number | string; data?: BlockType }) => {
      await queryClient.cancelQueries({ queryKey: invalidateQueryKey });
      const previousData = queryClient.getQueryData<BlockType[]>(invalidateQueryKey);
      if (previousData) {
        queryClient.setQueryData<BlockType[]>(
          invalidateQueryKey,
          previousData.filter((block) => block.id !== id)
        );
      }
      return { previousData };
    },
    onError: (err, variables, context) => {
      setToast({
        type: "Error",
        isVisible: true,
        message: "Failed to delete. Please retry",
      });
      // 에러 발생 시 이전 데이터로 롤백
      if (context?.previousData) {
        queryClient.setQueryData(invalidateQueryKey, context.previousData);
      }
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
    onSuccess: (data, variables, context) => {
      // 반복인 경우에만 invalidate 처리(inbox 전체)
      const isRecurringBlock =
        (variables.data?.recurrence && variables.data?.recurrence?.length > 0) ||
        Boolean(variables.data?.originalId);

      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      }

      setToast({
        type: "Delete",
        isVisible: true,
        message: "Event has been deleted",
      });
    },
  });
};
