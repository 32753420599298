import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApi from "../../../services/auth/useApi";
import { spaceQueryKeys } from "../queries";
import { SpaceStatus } from "../../../types/space/enum";

interface ReorderSpacesPayload {
  ids: number[];
}

export const spaceOrderQueryKey = () => ["space-order"];

export const reorderSpacesApi = async (
  api: ReturnType<typeof useApi>,
  payload: ReorderSpacesPayload
): Promise<void> => {
  await api.post("/spaces/orders", payload);
};

export const useReorderSpacesMutation = (
  options?: UseMutationOptions<void, AxiosError, ReorderSpacesPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ReorderSpacesPayload>({
    mutationFn: (payload) => reorderSpacesApi(api, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceOrderQueryKey() });
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
    },
    ...options,
  });
};
