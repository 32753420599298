import {
  BlockVisibility,
  BlockVisibilityType,
  RecurringOptionType,
  Transparency,
  TransparencyType,
  Visibility,
  VisibilityType,
} from "../../../types/block/enum";
import {
  BlockResponseType,
  BlockType,
  CreateBlockRequestType,
  CreateBlockType,
  UpdateBlockRequestType,
  UpdateBlockType,
  UpdateRecurrenceBlockRequestType,
  UpdateRecurrenceBlockType,
} from "../../../types/block/type";

import moment from "moment";

const SERVER_VISIBILITY_MAP = {
  [BlockVisibility.PUBLIC]: {
    visibility: Visibility.PUBLIC,
    transparency: Transparency.OPAQUE,
  },
  [BlockVisibility.DEEPWORK]: {
    visibility: Visibility.PRIVATE,
    transparency: Transparency.OPAQUE,
  },
  [BlockVisibility.INVISIBLE]: {
    visibility: Visibility.PRIVATE,
    transparency: Transparency.TRANSPARENT,
  },
} as const;

const CLIENT_VISIBILITY_MAP = {
  [`${Visibility.PUBLIC}_${Transparency.OPAQUE}`]: BlockVisibility.PUBLIC,
  [`${Visibility.PUBLIC}_${Transparency.TRANSPARENT}`]: BlockVisibility.PUBLIC,
  [`${Visibility.PRIVATE}_${Transparency.OPAQUE}`]: BlockVisibility.DEEPWORK,
  [`${Visibility.PRIVATE}_${Transparency.TRANSPARENT}`]: BlockVisibility.INVISIBLE,
} as const;

export const toServerVisibility = (clientVisibility: BlockVisibilityType) =>
  SERVER_VISIBILITY_MAP[clientVisibility];

export const toClientVisibility = (visibility: VisibilityType, transparency: TransparencyType) =>
  CLIENT_VISIBILITY_MAP[`${visibility}_${transparency}`];

export const convertServerToClientBlockType = ({
  serverBlock,
  defaultVisibility,
}: {
  serverBlock: BlockResponseType;
  defaultVisibility: BlockVisibilityType;
}): BlockType => {
  const blockVisibility = toServerVisibility(defaultVisibility);

  const defaultDetail = {
    allDay: undefined,
    hangoutLink: undefined,
    meetingCode: undefined,
    linkData: undefined,
    attendees: undefined,
    location: undefined,
    priority: undefined,
  };

  // allDay인 경우 00:00:00인 Date객체로 변환
  const formatClientBlockDateTime = ({ date, isAllDay }: { date: string; isAllDay: boolean }) => {
    if (isAllDay) {
      const [year, month, day] = date.split("-").map(Number);
      return new Date(year, month - 1, day, 0, 0, 0, 0);
    }
    return new Date(date);
  };

  const convertOriginalStart = ({
    originalStart,
    isAllDay,
  }: {
    originalStart: string;
    isAllDay: boolean;
  }) => {
    if (isAllDay) {
      return formatClientBlockDateTime({ date: originalStart.split("T")[0], isAllDay });
    }
    return new Date(originalStart);
  };

  return {
    ...serverBlock,
    ...defaultDetail,
    ...serverBlock.detail,
    visibility: serverBlock?.visibility ?? blockVisibility?.visibility ?? Visibility.PUBLIC,
    transparency: serverBlock?.transparency ?? blockVisibility?.transparency ?? Transparency.OPAQUE,
    start: serverBlock.start
      ? formatClientBlockDateTime({
          date: serverBlock.start,
          isAllDay: Boolean(serverBlock.detail?.allDay),
        })
      : null,
    end: serverBlock.end
      ? formatClientBlockDateTime({
          date: serverBlock.end,
          isAllDay: Boolean(serverBlock.detail?.allDay),
        })
      : null,
    originalStart: serverBlock.originalStart
      ? convertOriginalStart({
          originalStart: serverBlock.originalStart,
          isAllDay: Boolean(serverBlock.detail?.allDay),
        })
      : null,
    subBlocks:
      serverBlock.subBlocks?.length > 0
        ? serverBlock.subBlocks.map((block) =>
            convertServerToClientBlockType({ serverBlock: block, defaultVisibility })
          )
        : [],
  };
};

export const convertClientToServerBlockType = <
  B extends boolean,
  T extends B extends true ? UpdateBlockType | UpdateRecurrenceBlockType : CreateBlockType,
>({
  clientBlock,
  isUpdate,
}: {
  clientBlock: T;
  isUpdate: B;
}): B extends true
  ? T extends { type: RecurringOptionType }
    ? UpdateRecurrenceBlockRequestType
    : UpdateBlockRequestType
  : CreateBlockRequestType => {
  const { allDay, hangoutLink, meetingCode, linkData, attendees, location, priority, ...rest } =
    clientBlock;

  // NOTE undefined인 경우 삭제되도록 처리
  const filteredLinkData = linkData?.map(({ isLoading, ...rest }) => rest);

  const detail = {
    allDay,
    hangoutLink,
    meetingCode,
    linkData: filteredLinkData,
    attendees,
    location,
    priority,
  };

  const filteredDetail = Object.entries(detail)
    .filter(([_, value]) => value !== undefined && value !== null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const formatServerBlockDateTime = ({ time, isAllDay }: { time: Date; isAllDay: boolean }) => {
    const date = moment(time);
    if (isAllDay) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    return date.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };

  const baseBlock = {
    ...rest,
    // detailfeild에 아무런 값이 없는 경우 포함되지 않도록 설정
    ...(Object.keys(filteredDetail).length > 0 && { detail: filteredDetail }),
    start: clientBlock.start
      ? formatServerBlockDateTime({ time: clientBlock.start, isAllDay: Boolean(allDay) })
      : clientBlock.start,
    end: clientBlock.end
      ? formatServerBlockDateTime({ time: clientBlock.end, isAllDay: Boolean(allDay) })
      : clientBlock.start,
    originalStart:
      "originalStart" in clientBlock
        ? clientBlock.originalStart
          ? formatServerBlockDateTime({ time: clientBlock.originalStart, isAllDay: false })
          : clientBlock.originalStart
        : undefined,
  };

  if ("zone" in baseBlock) {
    delete baseBlock.zone;
  }

  return baseBlock as unknown as B extends true
    ? T extends { type: RecurringOptionType }
      ? UpdateRecurrenceBlockRequestType
      : UpdateBlockRequestType
    : CreateBlockRequestType;
};
