import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKeys } from "../queries";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";

import { SpaceIdType, SpaceType } from "../../../types/space";

import { SpaceStatus } from "../../../types/space/enum";
import { inboxQueryKeys } from "../../inbox/queries";
import { InboxBlockType } from "../../../types/block/BlockType";
import { BlockRequestQueryTypeParam, BlockSort, Operation } from "../../../types/block/enum";
import { ItemStatus } from "../../../types/block/enum";
import { getDayBoundariesISOString } from "../../../utils/date-format/dateUtils";
import { BlockType } from "../../../types/block/type";
import { logger } from "../../../utils/logger";
import { noSpaceIdSelector } from "../../../recoil/spaces/inProgressSpaceListState";

export const trashSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: SpaceIdType
): Promise<void> => {
  await api.patch(`/spaces/${projectId}`, {
    itemStatus: SpaceStatus.Deleted,
  });
};

export const useMoveToTrashSpaceMutation = (
  options?: UseMutationOptions<void, AxiosError, SpaceIdType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const setSelectedSpaceIdList = useSetRecoilState(selectedSpaceIdListState);
  const noSpaceId = useRecoilValue(noSpaceIdSelector);

  return useMutation<void, AxiosError, SpaceIdType>({
    mutationFn: (spaceId: SpaceIdType) => trashSpaceApi(api, spaceId),
    onMutate: async (spaceId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
      await queryClient.cancelQueries({
        queryKey: spaceQueryKeys.status(SpaceStatus.Completed),
      });
      await queryClient.cancelQueries({
        queryKey: spaceQueryKeys.status(SpaceStatus.Deleted),
      });

      setSelectedSpaceIdList((prev) => {
        if (!prev) return null;
        return prev.filter((id) => id !== spaceId);
      });

      // general
      queryClient.setQueryData(
        inboxQueryKeys.inbox({
          queryType: BlockRequestQueryTypeParam.INBOX,
          itemStatus: ItemStatus.IN_PROGRESS,
          startOp: Operation.NOT,
          endOp: Operation.NOT,
        }),
        (prev: BlockType[]) => {
          if (!prev) return [];
          return prev.map((block) => {
            if (block.spaceId === spaceId) {
              return { ...block, spaceId: noSpaceId };
            }
            return block;
          });
        }
      );

      // planned
      const TODAY = new Date();
      const { START_OF_DAY } = getDayBoundariesISOString({ day: TODAY, isAllday: false });
      queryClient.setQueryData(
        inboxQueryKeys.inbox({
          queryType: BlockRequestQueryTypeParam.TASK,
          itemStatus: ItemStatus.IN_PROGRESS,
          end: START_OF_DAY,
          endOp: Operation.GREATER_THAN,
        }),
        (prev: InboxBlockType[]) => {
          if (!prev) return [];
          return prev.map((block) => {
            if (block.spaceId === spaceId) {
              return { ...block, spaceId: noSpaceId };
            }
            return block;
          });
        }
      );

      // overdue
      queryClient.setQueryData(
        inboxQueryKeys.inbox({
          queryType: BlockRequestQueryTypeParam.TASK,
          itemStatus: ItemStatus.IN_PROGRESS,
          end: START_OF_DAY,
          endOp: Operation.LESS_THAN_OR_EQUAL,
          sortType: BlockSort.OVERDUE,
        }),
        (prev: InboxBlockType[]) => {
          if (!prev) return [];
          return prev.map((block) => {
            if (block.spaceId === spaceId) {
              return { ...block, spaceId: noSpaceId };
            }
            return block;
          });
        }
      );

      // completed
      queryClient.setQueryData(
        inboxQueryKeys.inbox({
          queryType: BlockRequestQueryTypeParam.TASK,
          itemStatus: ItemStatus.COMPLETED,
          sortType: BlockSort.COMPLETE,
        }),
        (prev: BlockType[]) => {
          if (!prev) return [];
          return prev.map((block) => {
            if (block.spaceId === spaceId) {
              return { ...block, spaceId: noSpaceId };
            }
            return block;
          });
        }
      );
      // NOTE: return 값 쓰이는 곳 없음
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.all });
      // inbox는 recoil state가 없어요
      queryClient.invalidateQueries({
        queryKey: inboxQueryKeys.all,
      });
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.all });
      queryClient.invalidateQueries({
        queryKey: inboxQueryKeys.all,
      });
      logger.error(error);
    },
    ...options,
  });
};
