import { CreateBlockType } from "../../types/block/type";
import { BasicBlock, ItemStatus } from "../../types/block/enum";

const createInboxBlockPayload = (
  newTaskName: string,
  defaultVisibility: string
): CreateBlockType => {
  return {
    title: newTaskName,
    visibility: defaultVisibility === "public" ? "public" : "private",
    transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
    itemStatus: ItemStatus.IN_PROGRESS,
    blockType: BasicBlock.TASK, // 240802 새로 추가된 프로퍼티
  };
};

export default createInboxBlockPayload;
