import React from "react";
import { SideBarGroupSection } from "./SideBarGroupSection";
import { SideBarMenuButton } from "./SideBarMenuButton";
import { NewFeatureIcon, ProductFeedbackIcon, SettingsIcon, TrashIcon } from "../../../icons";
import { juneTrack } from "../../../utils/june/analytics";
import { JUNE_EVENT } from "../../../hooks/june/juneEvent";

interface SideBarActionSectionProps {
  // 휴지통 상태 파악해서 열릴 때만 June 이벤트 호출하기 위한 용도
  isTrashPopupOpen: boolean;
  isExpanded: boolean;
  onClickSettings: () => void;
  onClickTrash: () => void;
  trashToggleButtonRef: React.RefObject<HTMLButtonElement>;
}

export const SideBarActionSection: React.FC<SideBarActionSectionProps> = ({
  isTrashPopupOpen,
  isExpanded,
  onClickSettings,
  onClickTrash,
  trashToggleButtonRef,
}) => {
  const handleClickFeedback = () => {
    juneTrack(JUNE_EVENT.CLICK_FEEDBACK);
    const url = "https://feedback.moba.works/";
    window.open(url, "_blank");
  };

  const handleClickNewFeature = () => {
    juneTrack(JUNE_EVENT.CLICK_UPDATE);
    const url = "https://feedback.moba.works/changelog";
    window.open(url, "_blank");
  };

  return (
    <SideBarGroupSection
      isExpanded={isExpanded}
      flex="0 0 auto"
    >
      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"New Feature"}
        icon={NewFeatureIcon}
        onClick={handleClickNewFeature}
        tooltipLabel={"New Feature"}
        isBrandColorHoverItem={true}
      />

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Product Feedback"}
        icon={ProductFeedbackIcon}
        onClick={handleClickFeedback}
        tooltipLabel={"Product Feedback"}
        isBrandColorHoverItem={true}
      />

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Settings"}
        icon={SettingsIcon}
        onClick={onClickSettings}
        tooltipLabel={"Settings"}
      />

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Trash"}
        icon={TrashIcon}
        onClick={() => {
          if (isTrashPopupOpen === false) {
            // 닫힌 상태에서 열려고 할 때에만 이벤트 호출
            juneTrack(JUNE_EVENT.VIEW_TRASH, { type: "space" });
          }
          onClickTrash();
        }}
        ref={trashToggleButtonRef}
        tooltipLabel={"Trash"}
      />
    </SideBarGroupSection>
  );
};
