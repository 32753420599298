import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";
import styles from "./style.module.css";

import useApi from "../../services/auth/useApi";

import { ReactComponent as GoogleLoginIcon } from "../../assets/Login/google-login-icon.svg";
import { ReactComponent as VerticalLine } from "../../assets/Login/signup-vertical-line.svg";
import { juneIdentify, juneTrack } from "../../utils/june/analytics";
import { ApiPathComposer, AppPathComposer } from "../../api/genPath";
import { TOKEN_FAIL_PARAM, TOKEN_TYPE } from "../../constants/indexV2";
import { ResponseUser, USER_ROLE } from "../../types/account";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";

// NOTE: Login과 Join `index.ts` 통합할 준비되었음, button의 text만 다름, 다음 scope에 통합
export default function Login() {
  const [token, setToken] = useRecoilState(tokenState);
  const instance = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  // 토큰을 listen하고 토큰이 있는 경우 redirect 처리
  useEffect(() => {
    if (token.accessToken !== null) {
      handleNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Check tokens from URL when page is loaded by server redirect after authentication
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const mAccessToken = urlSearchParams.get(TOKEN_TYPE.M_ACCESS_TOKEN);
    const mRefreshToken = urlSearchParams.get(TOKEN_TYPE.M_REFRESH_TOKEN);
    const accessToken = urlSearchParams.get(TOKEN_TYPE.ACCESS_TOKEN);
    const failCode = urlSearchParams.get(TOKEN_FAIL_PARAM.FAIL_CODE);

    // Redirect 접근이 아닌 경우 추가 처리 없이 page render
    if (!accessToken && !failCode) {
      return;
    }

    // 구글 인증이 실패한 경우 login으로 이동
    if (failCode) {
      navigate(AppPathComposer.login);
      return;
    }

    if (accessToken) {
      // token을 assign하면 위의 useEffect가 실행되어 페이지 이동
      setToken({
        mAccessToken: mAccessToken,
        mRefreshToken: mRefreshToken,
        accessToken: accessToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGoogleLogin() {
    const authUrl = new URL(ApiPathComposer.googleSignIn, process.env.REACT_APP_SERVER_V2);
    // 추후에 exception 처리가 가능한 assign method 사용
    window.location.assign(authUrl);
  }

  // 사용자 정보를 당겨와서 believer인지 trial인지 확인하여 페이지 이동
  async function handleNavigation() {
    const response = await instance.get<ResponseUser>(ApiPathComposer.me);
    // TODO: 에러처리

    juneIdentify(response.data.email, response.data.role);

    let path: string;
    // NOTE: @brandonwie INVITEE는 추후 적용
    switch (response.data.role) {
      case USER_ROLE.BELIEVER:
        path = AppPathComposer.main;
        if (!response.data.onboarding) {
          juneTrack(JUNE_EVENT.SIGNUP, { type: "google" });
        }
        break;
      case USER_ROLE.TRIAL:
        path = AppPathComposer.joinWaitList;
        break;
      default:
        throw new Error("Invalid user role");
    }

    if (document.startViewTransition) {
      document.startViewTransition(() => {
        navigate(path);
      });
    } else {
      navigate(path);
    }
  }

  return (
    <>
      <div className={styles["wrap"]}>
        <div className={styles["body"]}>
          <div className={styles["title"]}>
            Try Moba
            <VerticalLine style={{ margin: "15% 0" }} />
          </div>
          <button
            className={styles["gsi-material-button"]}
            onClick={handleGoogleLogin}
          >
            <div className={styles["gsi-material-button-state"]}></div>
            <div className={styles["gsi-material-button-content-wrapper"]}>
              <div className={styles["gsi-material-button-icon"]}>
                <GoogleLoginIcon />
              </div>
              <span className={styles["gsi-material-button-contents"]}>Log in with Google</span>
              <span style={{ display: "none" }}>Log in with Google</span>
            </div>
          </button>
        </div>
      </div>
      {/* @brandonwie Ted에게 popup은 이제 없다고 전달 받았는데 코드는 일단 남겨둠, 다음 scope에 확실하게 fix되고 삭제 */}
      {/* {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <div className={styles.closeButton} onClick={handleClosePopup}></div>
            <div className={styles.popupBody}>
              <p>There is no account associated with that email.</p>
              <p>
                Please try with another one,{"\n"} or you can{" "}
                <a href="/join">create a new account</a> instead.
              </p>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
