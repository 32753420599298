import { RecurringOption } from "../../types/block/enum";

export const recurringOptions = [
  { value: RecurringOption.THIS, label: "This event" },
  { value: RecurringOption.THISANDAFTER, label: "This event and following events" },
  { value: RecurringOption.ALL, label: "All events" },
] as const;

export const recurringOptionDisplayMap = {
  [RecurringOption.THIS]: "This event",
  [RecurringOption.THISANDAFTER]: "This event and following events",
  [RecurringOption.ALL]: "All events",
} as const;
