import { atom } from "recoil";
import { BlockType } from "../../types/block/type";

// WARN: 삭제요망, legacy에서 사용하던 state로 더 이상 active component에서 사용하지 않음
export const inboxTaskListState = atom<BlockType[]>({
  key: "inboxTaskListState",
  default: [],
});

// Inbox List에서 UI를 미리그리기 위한 전역 Array
export const inboxDraggableListState = atom<BlockType[]>({
  key: "inboxDraggableListState",
  default: [],
});
