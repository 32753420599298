/** June event tracking */
export const JUNE_EVENT = {
  // Auth & General
  SIGNUP: "signup",
  CLICK_FEEDBACK: "click_feedback",
  CLICK_UPDATE: "click_update",

  // Space Actions
  CREATE_SPACE: "create_space",
  UPDATE_SPACE: "update_space",
  DELETE_SPACE: "delete_space",
  COMPLETE_SPACE: "complete_space",
  RESTORE_SPACE: "restore_space",
  VIEW_SPACE_LIST: "view_space_list",
  VIEW_SPACE_DETAIL: "view_space_detail",
  CLICK_SPACE_FILTER: "click_space_filter",

  // Block Actions
  CREATE_BLOCK: "create_block",
  DUPLICATE_BLOCK: "duplicate_block",
  UPDATE_BLOCK: "update_block",
  DELETE_BLOCK: "delete_block",
  RESTORE_BLOCK: "restore_block",
  MOVE_BLOCK: "move_block",
  RESIZE_BLOCK: "resize_block",
  VIEW_BLOCK_DETAIL: "view_block_detail",

  // Task Actions
  DONE_TASK: "done_task",
  UNDONE_TASK: "undone_task",
  MOVE_TASK: "move_task",

  // Calendar Actions
  CHANGE_CALENDAR_VIEW: "change_calendar_view",
  CLICK_TODAY: "click_today",
  BLOCK_TIME: "block_time",

  // Wiki & Meeting
  CLICK_WIKI_STATUS: "click_wiki_status",
  CLICK_MEET_WITH: "click_meet_with",

  // Notes & Links
  ADD_NOTE: "add_note",
  ADD_LINK: "add_link",
  CLICK_LINK: "click_link",

  // Divider Actions
  CREATE_DIVIDER: "create_divider",
  DUPLICATE_DIVIDER: "duplicate_divider",
  DELETE_DIVIDER: "delete_divider",

  // Integration Actions
  INTEGRATE_TOOL: "integrate_tool",
  VIEW_INTEGRATION: "view_integration",
  CLICK_INTEGRATION: "click_integration",
  RECONNECT_INTEGRATION: "reconnect_integration",

  // Trash Actions
  VIEW_TRASH: "view_trash",
  CLICK_EMPTY: "click_empty",
} as const;
export type JuneEvent = (typeof JUNE_EVENT)[keyof typeof JUNE_EVENT];

// June 이벤트에서 사용하는 위치들
export const JUNE_LOCATION = {
  INBOX: "inbox",
  TODAY_INBOX: "today_inbox",
  CALENDAR: "calendar",
  TRASH: "trash",
  SIDEBAR: "sidebar",
  SPACE_FILTER: "space_filter",
  WIKI: "wiki",
} as const;
export type JuneLocation = (typeof JUNE_LOCATION)[keyof typeof JUNE_LOCATION];
