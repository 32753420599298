import { BlockType, CreateBlockType } from "../../../types/block/type";

const clientOnlyFields: Array<keyof BlockType> = [
  "id",
  "gcalId",
  "creator",
  "createdAt",
  "updatedAt",
  "subBlocks",
  "previewNote",
  "displayOrder",
  "itemLastStatus",
];

const recurringOnlyFields: Array<keyof BlockType> = ["originalId", "originalStart"];

export const removeClientOnlyFields = (
  blockData: BlockType,
  isRecurringBlock: boolean
): CreateBlockType => {
  const filteredData = { ...blockData };

  clientOnlyFields.forEach((field) => {
    delete filteredData[field];
  });

  if (!isRecurringBlock) {
    recurringOnlyFields.forEach((field) => {
      delete filteredData[field];
    });
  }

  return filteredData;
};
