import { BlockType } from "../../types/block/type";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useInboxGeneralQueryOptions } from "./queries";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { initialCreatingCalendarBlock } from "./utils/initialCreatingCalendarBlock";
import {
  useDuplicateInboxBlockMutationOptions,
  useReorderInboxBlockMutationOptions,
} from "./mutations";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import { calendarQueryKeys } from "../calendar/queries";

export const useDuplicateInboxBlockMutation = () => {
  const queryClient = useQueryClient();
  const inboxGeneralQueryKey = useInboxGeneralQueryOptions({}).queryKey;
  const { isSameInboxQueryKey, findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();
  const useReorderInboxBlock = useMutation(useReorderInboxBlockMutationOptions());

  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  return useMutation({
    ...useDuplicateInboxBlockMutationOptions(),

    onMutate: async ({ payload, originalBlock }) => {
      const creatingCalendarBlock = {
        ...initialCreatingCalendarBlock,
        ...payload,
      };

      // 캘린더 영역 전역상태 업데이트
      setMobaCalendarList((prev) => [...prev, creatingCalendarBlock]);

      // 쿼리 캐시 업데이트
      const currentQueryKey = findUpdateInboxQueryKey(creatingCalendarBlock);
      if (!currentQueryKey) return;

      await queryClient.cancelQueries({ queryKey: currentQueryKey });
      const previousData = queryClient.getQueryData<BlockType[]>(currentQueryKey);
      if (previousData) {
        queryClient.setQueryData(currentQueryKey, (oldData: BlockType[]) => {
          const index = oldData.findIndex((block) => block.id === originalBlock.id);
          let newDataArray;
          if (index === -1) {
            newDataArray = [creatingCalendarBlock, ...oldData];
          } else {
            newDataArray = [...oldData];
            newDataArray.splice(index + 1, 0, creatingCalendarBlock);
          }
          return newDataArray;
        });

        return { previousData, currentQueryKey };
      }
    },
    onSuccess: (newData) => {
      const newClientBlock = convertServerToClientBlockType({
        serverBlock: newData,
        defaultVisibility,
      });

      const currentQueryKey = findUpdateInboxQueryKey(newClientBlock);
      if (!currentQueryKey) return;

      // 캘린더 전역상태 업데이트
      setMobaCalendarList((prev) => {
        return prev.map((block) => (block.id ? block : newClientBlock));
      });
      // inbox에서 임시 생성 블록 제거 및 갱신
      queryClient.setQueryData(currentQueryKey, (oldData: BlockType[] | undefined) => {
        if (!oldData) return [newClientBlock];
        const newDataArray = oldData.map((block) => (block.id ? block : newClientBlock));

        // inbox-general인 경우 순서 api 호출
        const isInboxGeneralQueryKey = isSameInboxQueryKey(currentQueryKey, inboxGeneralQueryKey);
        if (isInboxGeneralQueryKey) {
          const blockIds = newDataArray.map((block) => block.id);
          useReorderInboxBlock.mutate({ ids: blockIds });
        }

        return newDataArray;
      });
    },
    onError: (error, variables, context) => {
      if (context?.previousData && context?.currentQueryKey) {
        queryClient.setQueryData(context.currentQueryKey, context.previousData);
      }
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
  });
};
