import { useEffect, useState } from "react";

import useApi from "../../services/auth/useApi";

import ActionMenuModal from "../Modal/ActionMenuModal";
import styles from "./ProjectItem.module.css";

import { useSetRecoilState } from "recoil";
import { inProgressSpaceListState } from "../../recoil/spaces/inProgressSpaceListState";
import { juneTrack, useJuneTrackCall } from "../../utils/june/analytics";
import { Tooltip } from "@chakra-ui/react";
import { SpaceStatus } from "../../types/space/enum";
import { useMarkSpaceDoneMutation } from "../../react-query/space/status/useMarkSpaceDoneMutation";
import { useMarkSpaceUndoneMutation } from "../../react-query/space/status/useMarkSpaceUndoneMutation";
import { useUpdateSpaceMutation } from "../../react-query/space/core/useUpdateSpaceMutation";
import { useMoveToTrashSpaceMutation } from "../../react-query/space/deletion/useMoveToTrashSpaceMutation";
import { JUNE_EVENT, JUNE_LOCATION } from "../../hooks/june/juneEvent";

function BookmarkFavicon({ index, projectRowData }) {
  const [iconPos, setIconPos] = useState(-100);

  const handleIconEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setIconPos(-100);
  };

  // NOTE link로 이동시키는 함수
  const handleMoveLink = () => {
    const url = projectRowData.link;
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  return (
    <Tooltip
      label={projectRowData.title}
      position={"bottom"}
    >
      <div
        className={styles["resource-item"]}
        onMouseEnter={handleIconEnter}
        onMouseLeave={handleIconLeave}
        onClick={(e) => {
          e.stopPropagation();
          handleMoveLink();
        }}
        style={{
          minWidth: "40px",
          minHeight: "40px",
        }}
      >
        <div
          className={styles["resource-item-favicon"]}
          style={{
            backgroundImage: `url(https://www.google.com/s2/favicons?sz=64&domain_url=${projectRowData.link})`,
          }}
        ></div>
      </div>
    </Tooltip>
  );
}

export default function ProjectItem({ projectRowData, onClick }) {
  const [projectData, setProjectData] = useState(null);

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isDone, setIsDone] = useState(false);
  const [isActionMenuModalOn, setIsActionMenuModalOn] = useState(false);
  const updateProjectList = useSetRecoilState(inProgressSpaceListState);
  const trackCall = useJuneTrackCall();
  const { mutate: archiveSpace } = useMarkSpaceDoneMutation();
  const { mutate: revertSpace } = useMarkSpaceUndoneMutation();
  const { mutate: trashSpace } = useMoveToTrashSpaceMutation({
    onSuccess: () => {
      juneTrack(JUNE_EVENT.DELETE_SPACE, { location: JUNE_LOCATION.WIKI });
    },
  });
  const { mutate: updateSpace } = useUpdateSpaceMutation();

  const handleProjectDetailClick = () => {
    onClick(true, projectRowData);
  };

  const handleItemContextMenu = (e) => {
    e.preventDefault();

    const { clientX, clientY } = e;
    setModalPosition({ x: clientX, y: clientY });
    setIsActionMenuModalOn(true);
  };

  const handleProcessIsDone = () => {
    setIsActionMenuModalOn(false);
    handleArchivedOrUnArchived();
    trackCall("complete_space", { location: "wiki" });
  };

  const handleArchivedOrUnArchived = () => {
    isDone ? revertSpace(projectRowData.id) : archiveSpace(projectRowData.id);
  };

  const handleSpaceDelete = () => {
    setIsActionMenuModalOn(false);

    trashSpace(projectRowData.id);
  };

  const handleChangeColor = (colorValue) => {
    setProjectData((current) => {
      let newItem = { ...current, color: colorValue };
      return newItem;
    });

    updateProjectList((current) => {
      return current.map((project) => {
        if (project.id === projectRowData.id) {
          return { ...project, color: colorValue };
        }
        return project;
      });
    });

    setIsActionMenuModalOn(false);

    updateSpace(
      {
        id: projectRowData.id,
        payload: { color: colorValue },
      },
      {
        onSuccess: () => {
          trackCall("update_space", { type: "color", location: "wiki" });
        },
      }
    );
  };

  const handleClose = () => {
    setIsActionMenuModalOn(false);
  };

  useEffect(() => {
    setProjectData(projectRowData);
    setIsDone(projectRowData.itemStatus === SpaceStatus.Completed);
  }, [projectRowData]);

  return (
    <>
      <div
        className={styles.project__item}
        style={{
          border: `1px solid ${projectData?.color}`,
          background: `${projectData?.color}0D`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleProjectDetailClick();
        }}
        onContextMenu={handleItemContextMenu}
      >
        <div className={styles.projectTitleDescriptionArea}>
          <div className={styles.project__title}>{projectData?.title}</div>
          <div className={styles.project__description}>{projectData?.description}</div>
        </div>
        <div className={styles.project__bookmark__area}>
          <div className={styles["setting-property-resource"]}>
            {projectData &&
              projectData.bookmarks?.length > 0 &&
              projectData?.bookmarks?.map((item, index) => (
                <BookmarkFavicon
                  key={index}
                  projectRowData={item}
                />
              ))}
          </div>
        </div>
      </div>
      {isActionMenuModalOn && (
        <ActionMenuModal
          x={modalPosition.x}
          y={modalPosition.y}
          onProcessDone={handleProcessIsDone}
          onDelete={handleSpaceDelete}
          onClose={handleClose}
          onChangeColor={handleChangeColor}
          isDone={isDone}
          projectColor={projectRowData.color}
        />
      )}
    </>
  );
}
