import React, { useState } from "react";

import { Box, Center, Divider, Flex } from "@chakra-ui/react";
import { createPortal } from "react-dom";

import { SpaceMenuItemPopover } from "../../../ProjectFilter/SideBarMenu/SpaceMenuItemPopover";
import { TaskDividerReorderDragLayer } from "./TaskDividerReorderDragLayer";
import { hexToRgba } from "../../../../utils/styles/color";
import { useInboxDragAndDrop } from "../../../../hooks/inbox/useInboxDragAndDrop";
import { BlockType } from "../../../../types/block/type";

interface TaskDividerBlockProps {
  data: BlockType;
  onDelete: () => void;
  onDuplicate: (e: React.MouseEvent) => void;
  index: number;
  onReorder: (taskIdList: number[]) => void;
  isSelected: boolean;
  onSelect: (id: number | null) => void;
}

const TaskDividerBlock = ({
  data,
  onDelete,
  onDuplicate,
  index,
  onReorder,
  isSelected,
  onSelect,
}: TaskDividerBlockProps) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [prevIndex, setPrevIndex] = useState(index);
  const handleMenuClose = () => {
    onSelect(null);
    setMenuVisible(false);
  };
  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onSelect(data.id);
    setMenuVisible(true);
  };

  const { ref, isDragging: shouldShowTaskPreview } = useInboxDragAndDrop({
    index,
    data,
    onReorder,
    prevIndex,
    setPrevIndex,
  });

  return (
    <Box
      borderRadius={"6px"}
      bg={shouldShowTaskPreview || isSelected ? "rgba(255, 255, 255, 0.04)" : "initial"}
      w={"full"}
      border={isSelected ? "1px solid rgba(255, 255, 255, 0.08)" : "1px solid transparent"}
    >
      <Flex ref={ref} opacity={shouldShowTaskPreview ? 0 : 1} cursor={"pointer"}>
        <SpaceMenuItemPopover
          menuVisible={menuVisible}
          handleMenuClose={handleMenuClose}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          deleteLabel={"Delete divider"}
          duplicateLabel={"Duplicate divider"}
          offset={[15, -15]}
        >
          <Center
            w={"full"}
            cursor={"pointer"}
            py={2}
            px={0.5}
            borderRadius={6}
            _hover={{
              bg: hexToRgba("#ffffff", 0.04),
            }}
            onContextMenu={handleRightClick}
          >
            <Divider color={hexToRgba("#ffffff", 0.24)} />
          </Center>
        </SpaceMenuItemPopover>
      </Flex>
      {shouldShowTaskPreview &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <TaskDividerReorderDragLayer />
          </div>,
          document.body
        )}
    </Box>
  );
};

export default TaskDividerBlock;
