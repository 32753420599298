import { BlockType } from "../../types/block/type";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { useUpdateCalendarBlockMutationOptions } from "./mutations";
import { inboxQueryKeys } from "../inbox/queries";
import { calendarQueryKeys } from "./queries";
import { logger } from "../../utils/logger";
import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { ItemStatus, ItemStatusType } from "../../types/block/enum";
import { toastState } from "../../recoil/toast/toastState";

export const useUpdateCalendarCheckboxMutation = () => {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const queryClient = useQueryClient();
  const { findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();
  const setToast = useSetRecoilState(toastState);

  return useMutation({
    ...useUpdateCalendarBlockMutationOptions(),
    onMutate: async ({ prevData, payload }) => {
      /** calendar optimistic update with recoil state */
      if (!prevData.itemStatus || !payload.itemStatus) {
        logger.error(
          `Block mutation flew without itemStatus when process checkbox event: ${JSON.stringify(
            payload
          )}`
        );
      } else {
        // update mobaCalendarList only if both itemStatuses are not undefined
        // - WHY? checkbox 업데이트 로직인데 itemStatus가 없는 경우에는 optimistic update하는 것이 모순
        setMobaCalendarList((prevState) => {
          return prevState.map((block) => {
            // find target and do optimistic update
            if (block.id === prevData.id) {
              return {
                ...block,
                itemStatus: payload.itemStatus as ItemStatusType,
              };
            }
            return block;
          });
        });
      }

      // 기존 inbox영역 낙관적 업데이트(블록 제거)
      /** inbox optimistic update with queryData */
      const prevInboxQueryKey = findUpdateInboxQueryKey(prevData);
      if (prevInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: prevInboxQueryKey });
        const previousData = queryClient.getQueryData<BlockType[]>(prevInboxQueryKey);
        if (previousData) {
          queryClient.setQueryData<BlockType[]>(
            prevInboxQueryKey,
            previousData.filter((block) => block.id !== prevData?.id)
          );
        }
      }
      // 이동된 inbox영역 낙관적 업데이트(블록 추가)
      const updateData = { ...prevData, ...payload, completedAt: new Date().toISOString() };
      const updateInboxQueryKey = findUpdateInboxQueryKey(updateData);

      if (updateInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        const previousData = queryClient.getQueryData<BlockType[]>(updateInboxQueryKey);
        if (previousData) {
          queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, [updateData, ...previousData]);
        }
      }
    },
    onSuccess: (data, variables, context) => {
      // 반복인 경우에만 invalidate 처리
      const isRecurringBlock = Boolean(variables.prevData.originalId);
      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      }
    },
    onError: (error, variables, context) => {
      // TODO: 에러 시 낙관적 업데이트 취소 필요, 시간 상 invalidate로 대체
      setToast({
        type: "Error",
        isVisible: true,
        message: `Failed to ${variables.payload.itemStatus === ItemStatus.COMPLETED ? "complete" : "undo"}. Please retry`,
      });
      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
  });
};
