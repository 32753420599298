import { useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilValue } from "recoil";
import { CalendarViewType } from "../../constants";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { calendarViewState } from "../../recoil/calendar/calendarState";

import "./CustomEvent.css";

import { getSpaceColor } from "../../services/space/space.service";
import { inProgressAndCompletedSpaceListState } from "../../recoil/spaces/inProgressSpaceListState";
import { DEFAULT_SPACE_COLOR } from "../../constants/space";

import { hexToRgba } from "../../utils/styles/color";
import { timeFormatState } from "../../recoil/calendar/settingCalendar";

import { AttendResponse, BasicBlock } from "../../types/block/enum";
import { isEventDone, isNotDoneTask, isTaskDone } from "../../utils/calendar/blockStatusUtils";
import { CalendarBlockContainer } from "./CalendarBlockContainer";
import { CalendarBlockBackgroundWithBorder } from "./CalendarBlockBackgroundWithBorder";
import CalendarBlockMainContent from "./CalendarBlockMainContent";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";

/**
 * MyCalendar.js -> CustomEventWrapper 컴포넌트에 사용
 *
 * @param {Object} props - 컴포넌트 props
 * @param {Object} props.event - 이벤트 데이터 객체
 * @param {Object} props.localizer - react-big-calendar의 localizer 객체
 * @param {number | undefined} props.taskDetailId - taskDetail state에서 가져온 현재 선택된 task의 ID
 * @param {boolean} props.taskDetailIsVisible - taskDetail의 팝업이 보이는지 여부
 */
export function CustomEvent({ event, localizer }) {
  // 전역 상태 값들 (calendar view, project list 등)
  const calendarView = useRecoilValue(calendarViewState);
  // @brandonwie 이제 spaceList는 completed까지 포함
  // - WHY? 완료된(아카이스된) 스페이스도 색상을 표시해야 하기 때문
  const inProgressAndCompletedSpaces = useRecoilValue(inProgressAndCompletedSpaceListState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const taskDetail = useRecoilValue(taskPopupState);
  const taskDetailId = taskDetail?.data?.id;
  const taskDetailIsVisible = taskDetail?.isVisible;

  // 로컬 refs와 상태들
  const eventRef = useRef(null);
  const tooltipRef = useRef(null);

  const [eventHover, setEventHover] = useState(false);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState(null);

  // 이벤트 응답 상태 체크 (회의 초대 응답 여부)
  const shouldCheckRsvp =
    event.blockType === BasicBlock.EVENT && event.attendees && event.attendees?.length > 0;
  const userResponse = event.attendees?.find((attendee) => attendee?.self)?.responseStatus;
  const notGoing = shouldCheckRsvp && userResponse === AttendResponse.DECLINED;
  const timeFormat = useRecoilValue(timeFormatState);

  /**
   * 일정 완료 상태 계산
   * - Task와 Event의 Done 상태 로직이 다르므로 별도 함수로 계산
   */
  const taskIsDone = useMemo(
    () => isTaskDone({ blockType: event.blockType, itemStatus: event.itemStatus }),
    [event.blockType, event.itemStatus]
  );
  const eventIsDone = useMemo(
    () => isEventDone({ blockType: event.blockType, end: event.end, allDay: event.allDay }),
    [event.blockType, event.allDay, event.end]
  );
  const notDoneTask = useMemo(() => isNotDoneTask(event, localizer), [event, localizer]);

  /**
   * 이벤트 시작/종료 시간 차이 계산 (밀리초 단위)
   */
  const getDateTimeDiffMSec = (startDate, endDate) => {
    return new Date(endDate).getTime() - new Date(startDate).getTime();
  };

  // 이벤트 지속 시간(분) 계산
  const eventMinutes = getDateTimeDiffMSec(event.start, event.end) / (60 * 1000);

  // 스페이스 색상 계산
  const spaceColor = useMemo(() => {
    return getSpaceColor(inProgressAndCompletedSpaces, event.spaceId, event.color);
  }, [inProgressAndCompletedSpaces, event.spaceId, event.color]);

  // getBlockStyle은 그냥 나중에 빼는 것으로
  const getBlockStyle = useCallback(
    (
      isDone,
      event,
      meetWithAccounts,
      projectList,
      eventHover,
      taskDetailId,
      taskDetailIsVisible
    ) => {
      const shouldCheckRsvp =
        event.blockType === BasicBlock.EVENT && event.attendees && event.attendees?.length > 0;
      const userResponse = event.attendees?.find((attendee) => attendee?.self)?.responseStatus;
      const pendingRsvp =
        shouldCheckRsvp &&
        (userResponse === AttendResponse.NEEDS_ACTION || userResponse === undefined);
      const tentativeRsvp = shouldCheckRsvp && userResponse === AttendResponse.TENTATIVE;
      const hoverOrAcvitve = eventHover || (taskDetailIsVisible && taskDetailId === event.id);

      const hexColor =
        meetWithAccounts.length > 0
          ? event.isMeetWith
            ? event.backgroundColor
            : DEFAULT_SPACE_COLOR
          : getSpaceColor(projectList, event.spaceId, event.color);
      const borderStyle = pendingRsvp ? `dashed` : `solid`;

      let alpha = event.blockType === BasicBlock.TASK ? 0.05 : 0.15;

      if (isDone) {
        alpha = event.blockType === BasicBlock.TASK ? 0.03 : 0.08;
      }

      if (hoverOrAcvitve) {
        if (isDone) {
          alpha = event.blockType === BasicBlock.TASK ? 0.13 : 0.18;
        } else {
          alpha = event.blockType === BasicBlock.TASK ? 0.15 : 0.25;
        }
      }

      if (meetWithAccounts.length > 0 && event.isMeetWith) {
        return {
          borderColor: "#ffffff",
          backgroundColor: hexColor,
        };
      }
      if (tentativeRsvp) {
        const rgbaColor = hexToRgba(hexColor, 0.1);
        const darkerColor = hexToRgba(hexColor, 0.05);

        return {
          borderColor: hexColor,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundImage: `repeating-linear-gradient(135deg, ${rgbaColor}, ${rgbaColor} 7px, ${darkerColor} 7px, ${darkerColor} 14px)`,
        };
      }

      return {
        borderColor: hexColor,
        borderStyle: borderStyle,
        borderWidth: "1px",
        backgroundColor: hexToRgba(hexColor, alpha),
      };
    },
    []
  );

  const eventStyle = getBlockStyle(
    taskIsDone || eventIsDone || notGoing,
    event,
    meetWithAccounts,
    inProgressAndCompletedSpaces,
    eventHover,
    taskDetailId,
    taskDetailIsVisible
  );

  /**
   * 제목 tooltip 처리 함수
   *
   * @param {Event} e - 이벤트 객체
   * @description
   * 제목이 컨테이너를 벗어날 경우 tooltip 표시
   * tooltip 위치 계산 및 스타일 설정
   */
  const handleTitleTooltip = (e) => {
    // 내용이 넘치지 않으면 tooltip 표시하지 않음
    if (
      e.target.clientWidth >= e.target.scrollWidth &&
      e.target.clientHeight >= e.target.scrollHeight
    ) {
      return;
    }

    // 캘린더 element 위치 가져오기
    const calendarEl = document.querySelector(`.rbc-calendar`);
    const calendarBound = calendarEl.getBoundingClientRect();

    const style = {};
    const eventRefBound = eventRef?.current?.getBoundingClientRect();
    const tooltipBound = tooltipRef?.current?.getBoundingClientRect();

    // tooltip 너비 계산 및 기본 위치 설정
    const tooltipMaxWidth = calendarBound.width - 20;
    const tooltipWidth =
      tooltipBound.width > tooltipMaxWidth ? tooltipMaxWidth : tooltipBound.width;
    const defaultLeft = eventRefBound.left + eventRefBound.width / 2 - tooltipWidth / 2;

    style.maxWidth = tooltipMaxWidth;
    style.top = eventRefBound.top - 22 - 5;
    style.left = defaultLeft;

    // tooltip이 화면 왼쪽 경계를 벗어나는 경우 조정
    if (defaultLeft < calendarBound.left) {
      style.left = calendarBound.left + 10;
    }

    // tooltip이 화면 오른쪽 경계를 벗어나는 경우 조정
    if (defaultLeft + tooltipWidth > calendarBound.right) {
      style.left = calendarBound.right - tooltipWidth - 10;
    }

    setTooltipStyle(style);
    setIsTitleOverflow(true);
  };

  /**
   * 이벤트 컨테이너 크기 클래스 계산 (메모이제이션)
   * - 이벤트 지속 시간에 따라 다른 크기 적용
   */
  const getEventContainerSizeClass = useMemo(() => {
    if (calendarView === CalendarViewType.MONTH.type || event?.allDay) {
      return "smallEvent";
    }
    if (eventMinutes <= 15) {
      return "smallEvent";
    }
    if (eventMinutes <= 30) {
      return "mediumEvent";
    }
    if (eventMinutes >= 45) {
      return "largeEvent";
    }
    return "";
  }, [calendarView, eventMinutes, event]);

  // NOTE: 드래그 불가능한 블록이 드래그 중일 때 화면에 미리보기 되는 부분 방지(근본적 원인 해결필요)
  if (!event.blockType) return null;

  return (
    <>
      <CalendarBlockContainer
        ref={eventRef}
        isTaskDone={taskIsDone}
        isEventDone={eventIsDone}
        isNotGoing={notGoing}
        isNotDoneTask={notDoneTask}
        isMeetWith={event.isMeetWith}
        containerSize={getEventContainerSizeClass}
        onMouseEnter={() => setEventHover(true)}
        onMouseLeave={() => setEventHover(false)}
        eventStyle={eventStyle}
        event={event}
      >
        <CalendarBlockBackgroundWithBorder
          backgroundColor={eventStyle.backgroundColor}
          backgroundImage={eventStyle.backgroundImage}
          containerSize={getEventContainerSizeClass}
          borderColor={eventStyle.borderColor}
          borderStyle={eventStyle.borderStyle}
          borderWidth={eventStyle.borderWidth}
          isTaskDone={taskIsDone}
          isEventDone={eventIsDone}
          isNotGoing={notGoing}
          isNotDoneTask={notDoneTask}
          isMeetWith={event.isMeetWith}
          event={event}
        >
          <CalendarBlockMainContent
            event={event}
            calendarView={calendarView}
            timeFormat={timeFormat}
            containerSize={getEventContainerSizeClass}
            handleTitleTooltip={handleTitleTooltip}
            setIsTitleOverflow={setIsTitleOverflow}
            spaceColor={spaceColor}
          />
        </CalendarBlockBackgroundWithBorder>
      </CalendarBlockContainer>

      {/*
        tooltip 렌더링:
        - 월간 뷰일 경우 포털로 body에 직접 렌더링
        - 그 외의 경우 컴포넌트 내부에 렌더링
      */}
      {calendarView === CalendarViewType.MONTH.type ? (
        createPortal(
          <div
            ref={tooltipRef}
            className="event-tooltip"
            style={{
              left: tooltipStyle && tooltipStyle.left,
              top: tooltipStyle && tooltipStyle.top,
              maxWidth: tooltipStyle && tooltipStyle.maxWidth,
              opacity: isTitleOverflow && 1,
              height: isTitleOverflow && "auto",
              padding: isTitleOverflow && "4px",
            }}
          >
            {event.title ? event.title : `New ${event.blockType}`}
          </div>,
          document.querySelector("#event-tooltip")
        )
      ) : (
        <div
          ref={tooltipRef}
          className="event-tooltip"
          style={{
            left: tooltipStyle && tooltipStyle.left,
            top: tooltipStyle && tooltipStyle.top,
            maxWidth: tooltipStyle && tooltipStyle.maxWidth,
            opacity: isTitleOverflow && 1,
            height: isTitleOverflow && "auto",
            padding: isTitleOverflow && "4px",
          }}
        >
          {event.title ? event.title : `New ${event.blockType}`}
        </div>
      )}
    </>
  );
}

export default CustomEvent;
