import { useEffect, useState } from "react";

import { useSetRecoilState } from "recoil";

import { useJuneTrackCall } from "../../../utils/june/analytics";

import { AutoExpandingTextarea } from "./AutoExpandingTextarea";
import LinkAddModal from "../../../views/TaskDetail/Link/LinkAddModal";
import ToolTip from "../Tooltip";

import useApi from "../../../services/auth/useApi";
import { useUpdateSpaceMutation } from "../../../react-query/space/core/useUpdateSpaceMutation";

import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";

import styles from "../ProjectCreate.module.css";

import bookmarkDefaultImg from "../../../assets/Wiki/project-detail-bookmark-add.png";
import { useQueryClient } from "@tanstack/react-query";
import { LINK_FIELD_NAME } from "../../../constants/block/LinkData";
import { spaceQueryKeys } from "../../../react-query/space/queries";

export function ProjectInfo({ data }) {
  const api = useApi();
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);
  const [iconPos, setIconPos] = useState(-100);
  const [projectInfo, setProjectInfo] = useState({
    createdAt: null,
    creator: null,
    id: null,
    title: null,
    spaceId: null,
    description: null,
    bookmarks: [],
  });
  const [isResourceAddModal, setIsResourceAddModal] = useState(false);
  const trackCall = useJuneTrackCall();
  const updateProjectList = useSetRecoilState(inProgressSpaceListState);
  const { mutate: updateSpace } = useUpdateSpaceMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      setProjectInfo({
        createdAt: data.createdAt || null,
        creator: data.creator || null,
        id: data.id || null,
        title: data.title || null,
        spaceId: data.spaceId || null,
        description: data.description || null,
        bookmarks: data.bookmarks || null,
      });
    }
  }, [data]);

  const handleDataChange = (field, value) => {
    const newData = { [field]: value };

    updateProjectList((current) => {
      const updatedList = current.map((project) => {
        if (project.id === projectInfo.id) {
          return { ...project, [field]: value };
        }
        return project;
      });
      return updatedList;
    });

    // TODO API 및 쿼리로 수정 필요
    api.patch("spaces/" + projectInfo.id, newData).then((res) => {
      if (field === "title") {
        trackCall("update_space", { type: "title", location: "wiki" });
      } else if (field === "description" && projectInfo.description !== value) {
        // NOTE 값이 변경될 때에만 동작하도록 설정
        trackCall("update_space", {
          type: "description",
          location: "wiki",
        });
      }
    });
  };

  const handleResourceAdd = async () => {
    setIsResourceAddModal(true);
  };

  const updateBookmark = (key, value) => {
    trackCall("update_space", { type: "knowledge", location: "wiki" });

    if (key === LINK_FIELD_NAME) {
      const newItem = {
        bookmarks: [
          { title: value.name || "No Title", link: value.url },
          ...(projectInfo.bookmarks ? projectInfo.bookmarks : []),
        ],
      };

      setProjectInfo((current) => ({
        ...current,
        bookmarks: [
          { title: value.name || "No Title", link: value.url },
          ...(current.bookmarks ? current.bookmarks : []),
        ],
      }));

      updateSpace(
        { id: projectInfo.id, payload: newItem },
        { onSuccess: () => queryClient.invalidateQueries(spaceQueryKeys.byId(projectInfo.id)) }
      );
    }
  };

  const handleBlur = (event) => {
    handleDataChange("title", event.target.value);
  };

  const onChange = (event) => {
    setProjectInfo((current) => ({
      ...current,
      title: event.target.value,
    }));
  };

  const handleIconEnter = (index) => (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setVisibleTooltipIndex(index);
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setVisibleTooltipIndex(null);
    setIconPos(-100);
  };

  const handleMove = (url) => () => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  // NOTE 북마크 삭제 함수
  const handleDeleteBookmark = (index) => {
    // 북마크 배열 복사
    const updatedBookmarks = [...projectInfo.bookmarks];

    // 전달받은 인덱스의 항목 삭제
    updatedBookmarks.splice(index, 1);

    setProjectInfo((current) => ({
      ...current,
      bookmarks: updatedBookmarks,
    }));

    // 업데이트된 북마크 배열로 스페이스 정보 업데이트
    updateSpace(
      { id: projectInfo.id, payload: { bookmarks: updatedBookmarks } },
      {
        onSuccess: () => queryClient.invalidateQueries(spaceQueryKeys.byId(projectInfo.id)),
      }
    );
  };
  return (
    <>
      <div className={styles.project__info}>
        <input
          type="text"
          className={styles.projectUntitle}
          placeholder="Untitled Project"
          value={projectInfo?.title}
          onChange={onChange}
          onBlur={handleBlur}
        />

        <AutoExpandingTextarea
          field="description"
          dataChange={handleDataChange}
          description={projectInfo?.description}
        />
        <div className={styles.project__line}></div>
        <span className={styles.bookmark__title}>Bookmarks</span>

        <div className={styles["setting-property-resource"]}>
          {projectInfo &&
            projectInfo.bookmarks &&
            projectInfo.bookmarks.map((item, index) => (
              <div
                key={index}
                className={styles["resource-item"]}
                onMouseEnter={handleIconEnter(index)}
                onMouseLeave={handleIconLeave}
                onClick={handleMove(item?.link)}
              >
                <div
                  className={styles["resource-item-favicon"]}
                  style={{
                    backgroundImage: `url(https://www.google.com/s2/favicons?sz=64&domain_url=${item.link})`,
                  }}
                ></div>
                <div
                  className={styles["resource-item-delete"]}
                  style={{
                    visibility: `${visibleTooltipIndex === index ? "visible" : "hidden"}`,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteBookmark(index);
                  }}
                ></div>
                <ToolTip
                  key={index}
                  text={item.title}
                  isVisible={visibleTooltipIndex === index}
                  style={{ top: `${iconPos}px` }}
                />
              </div>
            ))}
          <div
            className={styles["resource-item"]}
            onClick={handleResourceAdd}
          >
            <img
              className={styles.bookmark__default}
              src={bookmarkDefaultImg}
            />
          </div>
        </div>
      </div>
      {isResourceAddModal && (
        <LinkAddModal
          onSave={(key, value) => updateBookmark(key, value)}
          onClose={() => setIsResourceAddModal(false)}
        />
      )}
    </>
  );
}
