// taskDetail에서 선택한 시간 포멧팅
export default function formatTime(date, timeFormat) {
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  const formatted12Hours = hours % 12 || 12;

  const formatted12HoursStr = formatted12Hours.toString();
  const formatted24HoursStr = hours.toString();
  const minutesStr = minutes.toString().padStart(2, "0");

  return timeFormat === "12-hour"
    ? `${formatted12HoursStr}:${minutesStr} ${ampm}`
    : `${formatted24HoursStr}:${minutesStr}`;
}
