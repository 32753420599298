import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { CreateSpaceType, SpaceIdType, SpaceType } from "../../../types/space";
import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { SpaceStatus } from "../../../types/space/enum";
import { spaceQueryKeys } from "../queries";

export const createSpaceApi = async (
  api: ReturnType<typeof useApi>,
  payload: CreateSpaceType
): Promise<SpaceType> => {
  const response = await api.post("/spaces", payload);
  return response.data;
};

export const useCreateSpaceMutation = (
  options?: UseMutationOptions<SpaceType, AxiosError, CreateSpaceType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(inProgressSpaceListState);
  const setSelectedSpaceIdList = useSetRecoilState(selectedSpaceIdListState);

  return useMutation<SpaceType, AxiosError, CreateSpaceType>({
    mutationFn: (payload) => createSpaceApi(api, payload),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
    },
    onSuccess: (res: SpaceType) => {
      const newSpaces = projectList ? [res, ...projectList] : [res];
      setProjectList(newSpaces);

      setSelectedSpaceIdList((prev: SpaceIdType[] | null) => {
        if (!prev) return [res.id];
        return [res.id, ...prev];
      });

      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });

      return { projectList };
    },
    ...options,
  });
};
