import React from "react";
import styles from "./TaskRow.module.css";
import { Box, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import InboxSubTaskToggle from "./InboxSubTaskToggle";
import InboxBlockBody from "./InboxBlockBody";

import { BlockType } from "../../../../types/block/type";

interface TaskRowProps {
  data: BlockType;
}

export const TaskRowLayer = React.memo(({ data }: TaskRowProps) => {
  const isTaskRowSelected = true;

  return (
    <Flex w={"full"}>
      <InboxSubTaskToggle />
      <Box
        w={"full"}
        gap={pxToRem(6)}
        border={"1px solid"}
        borderColor={"transparent"}
        borderRadius={"5px"}
        bg={"transparent"}
        _hover={{ bg: "whiteAlpha.100" }}
        _active={{ borderColor: "whiteAlpha.100" }}
      >
        <Flex
          id="inbox-general"
          alignItems={"center"}
          as="div"
          p={0}
          opacity={1}
          bgColor={"initial"}
          cursor={"pointer"}
          border={"none"}
          boxSizing="border-box"
          flexWrap={"nowrap"}
          className={`${isTaskRowSelected && styles.expandSelected}`}
          key={data.id}
        >
          <InboxBlockBody
            data={data}
            isOverdue={false}
            isCompleted={false}
            onClickCheckBox={() => {}}
          />
        </Flex>
      </Box>
    </Flex>
  );
});
