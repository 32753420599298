import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CalendarViewType, MeetWithColors } from "../../constants";
import { calendarViewState } from "../../recoil/calendar/calendarState";
import {
  calendarCurrentMonthState,
  calendarTotalMonthState,
} from "../../recoil/calendar/calendarStateV2";

import { useJuneTrackCall } from "../../utils/june/analytics";
import styles from "./header.module.css";
import useClickOutside from "../../hooks/useClickOutside";
import MeetWithPopup from "../MeetWith/MeetWithPopup";
import { meetWithAccountsState } from "../../recoil/account/accountState";

function CalendarHeaderLeft({ toolbar }) {
  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const { date, view } = toolbar;
  const nowDate = moment(date).format("MMM. YYYY");
  const setCalendarCurrentMonth = useSetRecoilState(calendarCurrentMonthState);
  const setCalendarTotalMonth = useSetRecoilState(calendarTotalMonthState);

  const [isShowToday, setIsShowToday] = useState(false);
  const trackCall = useJuneTrackCall();

  const goToToday = () => {
    const now = new Date();
    toolbar.onNavigate("DATE", now);
    calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);
    trackCall("click_today");
    setCalendarCurrentMonth(moment(new Date(now)).format("YYYY-MM"));
  };

  const goToNext = () => {
    const { date } = toolbar;
    const nextDate = moment(date).add(0, view).toDate();
    toolbar.onNavigate("NEXT", nextDate);
    const formattedNextMonth = moment(nextDate).add(1, view).format("YYYY-MM");

    setCalendarCurrentMonth((prev) => {
      if (prev !== formattedNextMonth) return formattedNextMonth;
      return prev;
    });
    setCalendarTotalMonth((prev) => {
      if (!prev.includes(formattedNextMonth)) {
        return [...prev, formattedNextMonth];
      }
      return prev;
    });
  };

  const goToPrev = () => {
    const { date } = toolbar;
    const prevDate = moment(date).subtract(0, view).toDate();
    toolbar.onNavigate("PREV", prevDate);
    const formattedPrevMonth = moment(prevDate).subtract(1, view).format("YYYY-MM");
    setCalendarCurrentMonth((prev) => {
      if (prev !== formattedPrevMonth) return formattedPrevMonth;
      return prev;
    });

    setCalendarTotalMonth((prev) => {
      if (!prev.includes(formattedPrevMonth)) {
        return [formattedPrevMonth, ...prev];
      }
      return prev;
    });
  };

  useEffect(() => {
    const normalEl = document.querySelector(`.rbc-today`);
    const monthlyEl = document.querySelector(`.rbc-now`);
    normalEl || monthlyEl ? setIsShowToday(false) : setIsShowToday(true);
  }, [toolbar]);

  return (
    <div className={styles["header-left"]}>
      {isShowToday && (
        <div
          className={styles["today"]}
          onClick={goToToday}
        >
          Today
        </div>
      )}
      <div className={styles["month-ctr"]}>
        <div
          className={styles["prev"]}
          onClick={goToPrev}
        ></div>
        <span>{nowDate}</span>
        <div
          className={styles["next"]}
          onClick={goToNext}
        ></div>
      </div>
    </div>
  );
}

function MeetWithButton() {
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);
  const [isActive, setIsActive] = useState(false);
  const trackCall = useJuneTrackCall();

  const { targetRef, triggerRef } = useClickOutside(() => {
    setIsActive(false);
  });

  return (
    <div className={styles.meetWithContainer}>
      <button
        ref={triggerRef}
        className={`${styles.meetWithButton} ${isActive && styles.meetWithActive}`}
        onClick={(e) => {
          trackCall("click_meet_with");
          setIsActive(!isActive);
        }}
      >
        {meetWithAccounts.length > 0 ? (
          <div className={styles.meetWithBadgeWrapper}>
            <span> +{meetWithAccounts.length}</span>
            <span className={styles.meetWithBadge}>
              {meetWithAccounts.map((account, idx) =>
                idx < 5 ? (
                  <span
                    key={`meetWithButton_${account.email}`}
                    style={{
                      backgroundColor: MeetWithColors[idx % MeetWithColors.length],
                    }}
                  ></span>
                ) : (
                  idx === 5 && (
                    <span
                      key="badgeMore"
                      className={styles.badgeMore}
                    ></span>
                  )
                )
              )}
            </span>
          </div>
        ) : (
          "Meet"
        )}
      </button>
      {isActive && (
        <MeetWithPopup
          onClose={() => setIsActive(false)}
          ref={targetRef}
        />
      )}
    </div>
  );
}

function CalendarViewButton({ toolbar }) {
  const [isOpenViewTypeList, setIsOpenViewTypeList] = useState(false);

  const { targetRef, triggerRef } = useClickOutside(() => {
    setIsOpenViewTypeList(false);
  });
  const { view } = toolbar;

  return (
    <div className={styles["header-right"]}>
      <div className={styles["view-ctr"]}>
        <button
          ref={triggerRef}
          onClick={(e) => {
            setIsOpenViewTypeList(!isOpenViewTypeList);
          }}
        >
          {Object.values(CalendarViewType).find((viewType) => viewType.type === view).label}
        </button>
      </div>
      {isOpenViewTypeList && (
        <CalendarViewPopup
          ref={targetRef}
          toolbar={toolbar}
          onClose={() => setIsOpenViewTypeList(false)}
        />
      )}
    </div>
  );
}

function CalendarViewPopup({ toolbar, onClose, ...props }, ref) {
  const { view } = toolbar;
  const setView = (type) => {
    toolbar.onView(type);
    onClose();
  };

  return (
    <div
      className={styles.viewTypeListWrapper}
      ref={ref}
      {...props}
    >
      <ul className={styles.viewTypeList}>
        {Object.values(CalendarViewType).map((viewType) => {
          return (
            <li
              onClick={() => setView(viewType.type)}
              key={viewType.type}
            >
              <span
                className={`${styles.checkedIcon} ${viewType.type === view && styles.checked}`}
              ></span>
              <span>{viewType.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
CalendarViewPopup = forwardRef(CalendarViewPopup);

export default function CalendarHeader(toolbar) {
  return (
    <div className={styles["header"]}>
      <CalendarHeaderLeft toolbar={toolbar} />
      <MeetWithButton />
      <CalendarViewButton toolbar={toolbar} />
    </div>
  );
}
