import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Main from "./views/Main";
import Login from "./views/Login";
import Join from "./views/Join";
import JoinWaitList from "./views/JoinWaitList";
import { createPortal } from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./themes";
import { Fonts } from "./themes/fonts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/* `/main`으로 routing되기 전에 block의 동기화 작업 완료 응답을 받은 뒤 진입합니다. */
// - @brandonwie 이전 implementation 공유용으로 일단 놔둘게요
// const mainLoader: LoaderFunction = async () => {
//   const tokenState: TokenState | null = loadFromLocalStorage<TokenState>("tokenState");
//
//   if (!tokenState || !tokenState.accessToken) {
//     logger.warn("No token found in local storage");
//     return redirect("/login");
//   }
//   try {
//     await axiosClient.post(ApiPathComposer.blockSync);
//   } catch (error) {
//     // TODO: Add logging like Sentry
//     logger.error("Failed to sync blocks...\nContinue to main page without sync.", { error });
//   }
//
//   return null;
// };

// Create router with routes configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/join",
    element: <Join />,
  },
  {
    path: "/joinWaitList",
    element: <JoinWaitList />,
  },
  {
    path: "/main",
    element: <Main />,
    // loader: mainLoader,
  },
]);

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={customTheme}>
          <Fonts /> {/*chakra-ui의 fonts setting using @font-face */}
          <div className="App">
            <RouterProvider router={router} />
          </div>
          {createPortal(<div id="event-tooltip" />, document.body)}
          {createPortal(<div id="popup-root" />, document.body)}
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
