import _ from "lodash";
import { BlockLinkType } from "../../../types/block/type";

export const normalizeValue = <T>(value?: T | null, defaultValue: T = "" as any): T =>
  value === undefined || value === null ? defaultValue : value;

export const normalizeArray = <T>(value?: T[] | null): T[] =>
  value === undefined || value === null ? [] : value;

export const parseDate = (date: Date | string | null | undefined): Date | null => {
  return date ? new Date(date) : null;
};

export const isChangedBlockProperty = <T>(value1: T, value2: T): boolean => {
  return !_.isEqual(value1, value2);
};

export const isLinkEmpty = (links: BlockLinkType[]): boolean => {
  if (links.length === 0) {
    return true;
  }

  return links.every((link) => !link.url);
};
