import { BasicBlock, BasicBlockType } from "../../../types/block/enum";
import { logger } from "../../logger";

export const getBlockTypeFlags = (blockType: BasicBlockType) => {
  if (!blockType) {
    logger.warn(`Block type not found while getting block type flags - blockType: ${blockType}`);
  }
  return {
    isTask: blockType === BasicBlock.TASK,
    isEvent: blockType === BasicBlock.EVENT,
    isDivider: blockType === BasicBlock.DIVIDER,
  };
};
