import { useState } from "react";
import styles from "./TrashPopup.module.css";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import trashRestore from "../../../assets/Trash/trash-restore.png";

// CHECK: export 해서 다른 곳에서 사용할 수 있는지 확인
interface SpaceData {
  id: number;
  title: string;
  color: string;
}

interface TrashSpaceRowProps {
  data: SpaceData;
  onDeleteClick: (id: number) => void;
  onRestoreClick: (id: number) => void;
}

export const TrashSpaceRow: React.FC<TrashSpaceRowProps> = ({
  data,
  onDeleteClick,
  onRestoreClick,
}) => {
  const [rowIcon, setRowIcon] = useState<boolean>(true);

  return (
    <div className={styles.project__item}>
      <div
        className={styles.project__icon}
        style={{
          // Q: 어떤 schema에 영향을 받는 것인지 모르겠음, empty string의 경우에는 어떻게 처리되는지?
          backgroundColor: `${data.color}${!rowIcon ? "26" : ""}`,
          boxShadow: `0 0 0 2px ${data.color} inset`,
        }}
        onClick={() => setRowIcon(!rowIcon)}
      />

      {/* Title */}
      <div className={styles.project__title}>{data.title}</div>

      {/* Restore  Button */}
      <div role="button" className={styles.project__delete} onClick={() => onRestoreClick(data.id)}>
        <img className={styles.project__delete__img} src={trashRestore} alt="restore" />
      </div>

      {/* Delete  Button */}
      <div role="button" className={styles.project__delete} onClick={() => onDeleteClick(data.id)}>
        <img className={styles.project__delete} src={trashDeleteImg} alt="trash" />
      </div>
    </div>
  );
};
