import { BasicBlock, BasicBlockType, ItemStatus, ItemStatusType } from "../../types/block/enum";
import moment from "moment";
import { BlockType } from "../../types/block/type";

// TODO @9ellie 클라이언트 타입 정해지면 수정
interface BlockStatusEvent {
  blockType: BasicBlockType;
  itemStatus: ItemStatusType;
  end: string | Date;
  start: string | Date;
  allDay?: boolean;
  isMeetWith?: boolean;
}

interface TaskDoneCheckProps extends Pick<BlockType, "blockType" | "itemStatus"> {}
interface EventDoneCheckProps extends Pick<BlockType, "blockType" | "end" | "allDay"> {}

export const isTaskDone = ({ blockType, itemStatus }: TaskDoneCheckProps) =>
  blockType === BasicBlock.TASK && itemStatus === ItemStatus.COMPLETED;

// TOOD @9ellie 클라이언트 타입 정해지면 수정 (detail -> allDay만 전달하도록)
export const isEventDone = ({ blockType, end, allDay }: EventDoneCheckProps) => {
  if (blockType !== BasicBlock.EVENT) {
    return false;
  }

  if (allDay) {
    const eventEndDate = moment(end).format("YYYYMMDD");
    const todayDate = moment().format("YYYYMMDD");
    return moment(eventEndDate).isBefore(todayDate);
  }

  return moment().isAfter(moment(end));
};

// TODO @9ellie 클라이언트 타입 정해지면 수정
export const isNotDoneTask = (event: BlockStatusEvent, localizer: any) => {
  if (event.isMeetWith) return false;

  const getDateTimeDiffMSec = (startDate: Date, endDate: Date) => {
    return endDate.getTime() - startDate.getTime();
  };

  const allDayDiff =
    localizer.format(new Date(event.end), "YYYYMMDD") ===
    localizer.format(new Date(event.start), "YYYYMMDD")
      ? 0
      : 1;

  const notDone = event.allDay
    ? localizer.format(new Date(event.end), "YYYYMMDD") - localizer.format(new Date(), "YYYYMMDD") <
      allDayDiff
    : getDateTimeDiffMSec(new Date(), new Date(event.end)) < 0;

  return (
    event.blockType === BasicBlock.TASK && event.itemStatus !== ItemStatus.COMPLETED && notDone
  );
};
