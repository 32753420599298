import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useRecoilValue } from "recoil";

import styles from "./SpaceAddModal.module.css";

import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { getNextAvailableSpaceColor } from "../../../services/space/space.service";

export default function SpaceAddModal({ onSave, onClose }) {
  const spaceList = useRecoilValue(inProgressSpaceListState);
  const mainRef = useRef(null);
  const inputRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
    function handleClickOutside(event) {
      event.stopPropagation();
      if (backgroundRef.current && backgroundRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCreate = () => {
    onSave(inputRef.current.value);
    onClose();
  };

  // TODO @9ellie SpaceAddModal에서 enter시 taskDetail도 닫히는 이슈 있음
  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleCreate();
      onClose();
    } else if (e.key === "Escape") {
      onClose();
    }
  };

  const handleBackgroundClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return createPortal(
    <div className={styles["wrap"]} ref={backgroundRef} onMouseDown={handleBackgroundClick}>
      <div
        className={styles["main"]}
        ref={mainRef}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation}
      >
        <div className={styles["header"]}>
          <span className={styles["header-title"]}>Add a space</span>
          <span className={styles["header-close"]} onClick={onClose}>
            X
          </span>
        </div>
        <div className={styles["body"]}>
          <div
            className={styles["body-icon"]}
            style={{
              backgroundColor: `${getNextAvailableSpaceColor(spaceList)}`,
            }}
          ></div>
          <input
            ref={inputRef}
            className={styles["body-input"]}
            onKeyDown={handleInputKeyDown}
          ></input>
        </div>
        <div className={styles["footer"]} onClick={handleCreate}>
          Create
        </div>
      </div>
    </div>,
    document.body
  );
}
