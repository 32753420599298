import React, { forwardRef } from "react";
import { Button, ButtonProps, Icon, Text } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

import { DefaultRSVPOption, RSVPOptions } from "../../../constants/block/RSVP";
import { AttendResponseType } from "../../../types/block/enum";

interface RSVPButtonProps extends ButtonProps {
  menuVisible: boolean;
  currentValue?: AttendResponseType;
}

export const RSVPButton = forwardRef<HTMLButtonElement, RSVPButtonProps>(
  ({ menuVisible, currentValue, ...props }, ref) => {
    const currentRSVPResponse =
      RSVPOptions.find((option) => option.value === currentValue) ?? DefaultRSVPOption;
    const { icon, label } = currentRSVPResponse;

    return (
      <Button
        ref={ref} // Pass the ref for Chakra compatibility
        h={pxToRem(30)}
        bgColor={menuVisible ? hexToRgba("#ffffff", 0.08) : "transparent"}
        minW={"initial"}
        borderRadius={pxToRem(5)}
        border={"1px solid"}
        borderColor={hexToRgba("#ffffff", 0.16)}
        gap={1.5}
        px={2}
        py={1}
        boxSizing={"border-box"}
        _hover={{
          bgColor: hexToRgba("#ffffff", 0.08),
        }}
        _active={{}}
        {...props}
      >
        <Icon as={icon} w={pxToRem(14)} h={pxToRem(14)} />
        <Text color={"white"} textStyle={"body1"}>
          {label}
        </Text>
      </Button>
    );
  }
);
