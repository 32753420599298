import { AxiosInstance } from "axios";
import {
  BlockRequestParamType,
  BlockResponseType,
  CreateBlockRequestType,
  UpdateBlockRequestType,
  UpdateRecurrenceBlockRequestType,
} from "../types/block/type";
import { ApiPathComposer } from "../api/genPath";

class BlockAPI {
  // `getBlocks`만 argument를 axios와 동일하게 받을 수 있도록 변경
  getBlocks(AxiosInstance: AxiosInstance, params: BlockRequestParamType) {
    return AxiosInstance.get<BlockResponseType[]>(ApiPathComposer.blocks, { params });
  }
  createBlock(AxiosInstance: AxiosInstance, payload: CreateBlockRequestType) {
    return AxiosInstance.post<BlockResponseType>(ApiPathComposer.blocks, payload);
  }
  getBlockById(AxiosInstance: AxiosInstance, id: number) {
    return AxiosInstance.get<BlockResponseType>(ApiPathComposer.blockWithId(id));
  }
  updateBlockById(AxiosInstance: AxiosInstance, id: number, payload: UpdateBlockRequestType) {
    return AxiosInstance.patch(ApiPathComposer.blockWithId(id), payload);
  }
  deleteBlockById(AxiosInstance: AxiosInstance, id: number) {
    return AxiosInstance.delete(ApiPathComposer.blockWithId(id));
  }
  updateBlocksOrder(AxiosInstance: AxiosInstance, payload: { ids: number[] }) {
    return AxiosInstance.post(ApiPathComposer.blockOrder, payload);
  }
  updateRecurrenceBlockById(
    AxiosInstance: AxiosInstance,
    id: number,
    payload: UpdateRecurrenceBlockRequestType
  ) {
    return AxiosInstance.post(ApiPathComposer.recurrenceBlockWithId(id), payload);
  }
}

const blockAPI = new BlockAPI();
export default blockAPI;
