import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUpdateCalendarBlockMutationOptions } from "./mutations";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { BlockType } from "../../types/block/type";
import { inboxQueryKeys } from "../inbox/queries";
import { calendarQueryKeys } from "./queries";

// move, resize, inbox -> calendar dnd 에서 사용하는 mutation
export const useUpdateTimeCalendarBlockMutation = () => {
  const queryClient = useQueryClient();
  const { findUpdateInboxQueryKey, isSameInboxQueryKey } = useFindUpdateInboxQueryKey();

  return useMutation({
    ...useUpdateCalendarBlockMutationOptions(),
    onMutate: async ({ payload, prevData }) => {
      // 쿼리키 찾아서 같은지 비교
      const updateData = { ...prevData, ...payload };
      const prevInboxQueryKey = findUpdateInboxQueryKey(prevData);
      const updateInboxQueryKey = findUpdateInboxQueryKey(updateData);

      // inbox에 해당하는 쿼리키가 없으면 === TASK 블록이 아니면 리턴
      if (!prevInboxQueryKey || !updateInboxQueryKey) {
        return;
      }

      const isSame = isSameInboxQueryKey(prevInboxQueryKey, updateInboxQueryKey);
      // 같으면 updateData 업데이트
      if (isSame) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, (oldData) => {
          if (!oldData) return [updateData];
          return oldData.map((block) => (block.id === updateData.id ? updateData : block));
        });
        return;
      }

      // 다르면 이전 쿼리키에서는 삭제, 변경된 쿼리키에는 추가
      // 기존 inbox영역 낙관적 업데이트(블록 제거)
      await queryClient.cancelQueries({ queryKey: prevInboxQueryKey });
      queryClient.setQueryData<BlockType[]>(prevInboxQueryKey, (oldData) => {
        if (!oldData) return [];
        return oldData.filter((block) => block.id !== prevData.id);
      });

      // 이동된 inbox영역 낙관적 업데이트(블록 추가)
      await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
      queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, (oldData) => {
        if (!oldData) return [updateData];
        return [updateData, ...oldData];
      });
    },
    onSuccess: (data, variables, context) => {
      // 반복인 경우에만 invalidate 처리(calendar, inbox 전체)
      const isRecurringBlock =
        (variables.prevData.recurrence && variables.prevData.recurrence?.length > 0) ||
        Boolean(variables.prevData.originalId);

      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      }
    },
    onError: (error, variables, context) => {
      // invalidate 처리(calendar, inbox 전체)
      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
  });
};
