import { useRef, useEffect, useCallback } from "react";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useSetRecoilState } from "recoil";
import { draggedEventState } from "../../recoil/calendar/calendarStateV2";
import { BlockType } from "../../types/block/type";
import { BasicBlock, DragAccept, DragKind } from "../../types/block/enum";

export interface InboxDragItem {
  dragType: string;
  data: BlockType;
  index: number;
}

interface useDragAndDropProps {
  data: BlockType;
  index: number;
}

export const useInboxToCalendarDragAndDrop = ({ index, data }: useDragAndDropProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const setDraggedEvent = useSetRecoilState(draggedEventState);

  const getDragItem = useCallback(() => {
    const dragItem = {
      dragType: DragKind.INBOX_CALENDAR_ITEM,
      data,
      index,
    };

    if (data.blockType !== BasicBlock.DIVIDER) {
      setDraggedEvent({
        ...data,
        dragType: DragKind.INBOX_CALENDAR_ITEM,
        index: index,
      });
    }
    return dragItem;
  }, [data, index, setDraggedEvent]);

  const [draggingItem, drag, preview] = useDrag({
    type: DragAccept.INBOX,
    item: getDragItem,
    collect: useCallback((monitor: DragSourceMonitor) => {
      const item = monitor.getItem() as { dragType: string };
      return {
        isDragging:
          monitor.isDragging() && (!item || item.dragType === DragKind.INBOX_CALENDAR_ITEM),
      };
    }, []),
    // end: useCallback(() => {
    //   setDraggedEvent(null);
    // }, [setDraggedEvent]),
  });
  const { isDragging = false } = draggingItem ?? {};
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(ref);

  // const shouldShowPreview = useMemo(
  //   () => item && item.index === index && item.dragType === DragKind.INBOX_CALENDAR_ITEM,
  //   [item?.index, item?.dragType, index]
  // );

  return { ref, isDragging };
};
