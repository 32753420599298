import { RRule, rrulestr, datetime } from "rrule";
import moment from "moment-timezone";

const generateInstanceId = (start, isAllday, recurringEventId) => {
  if (!start) {
    console.error("instanceStart가 없습니다.");
    return null;
  }

  const startTimeConvertToDateType = start instanceof Date ? start : new Date(start);

  const convertedDateTimeSuffix = isAllday
    ? moment(startTimeConvertToDateType).format("YYYYMMDD")
    : moment.tz(startTimeConvertToDateType, "utc").format("YYYYMMDDTHHmmss[Z]"); // Z는 시간대 정보 포함

  // 최종 ID 생성
  return `${recurringEventId}_${convertedDateTimeSuffix}`;
};

export const generateRecurringEventId = (baseRecurringEvent) => {
  // baseRecurringEvent: id or id_R{yyyyMMdd} 형태
  // 따라서 id_R{yyyyMMdd} 형태의 경우 id 만 남기도록 수정
  let baseRecurringEventId = baseRecurringEvent.id.toString();
  if (baseRecurringEventId.includes("_")) {
    baseRecurringEventId = baseRecurringEventId.split("_")[0];
  }
  return baseRecurringEventId;
};

// 반복 이벤트를 개별 인스턴스로 확장
export const expandRecurringEvent = (event, recurringStart, recurringEnd) => {
  const isAllDay = event.allDay;
  const rruleString = event.recurrence ? event.recurrence[0] : null;
  if (!rruleString) return [];

  const rule = rrulestr(rruleString);

  // 시작과 끝 날짜 처리
  const baseEventStart = new Date(event.start);
  const timezoneOffset = baseEventStart.getTimezoneOffset();
  const offsetHours = -timezoneOffset / 60;

  // 시작과 끝 날짜에 시간대 차이만큼 시간 추가
  // Why? 현재 사용하는 라이브러리가 타임존을 무시하고 있음
  const recurringStartDateTime = new Date(
    new Date(recurringStart).getTime() + offsetHours * 60 * 60 * 1000
  );
  const recurringEndDateTime = new Date(
    new Date(recurringEnd).getTime() + offsetHours * 60 * 60 * 1000
  );
  const recurringUntilDateTime =
    rule.options.until && rule.options.until.getTime() + offsetHours * 60 * 60 * 1000;

  // 유효 시작일: 원본 시작일과 요청된 시작일 중 더 늦은 날짜
  const effectiveStartDate = new Date(
    Math.max(baseEventStart.getTime(), recurringStartDateTime.getTime())
  );
  // 유효 종료일: until과 요청된 종료일 중 더 이른 날짜
  const effectiveEndDate = recurringUntilDateTime
    ? new Date(Math.min(recurringUntilDateTime, recurringEndDateTime.getTime()))
    : recurringEndDateTime;

  // NOTE Timezone 처리
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventTimeZone = browserTimeZone;

  if (isAllDay) {
    const recurringBaseEventStartDate = new Date(event.start);
    const recurringBaseEventEndDate = new Date(event.end);
    const dayDiff =
      (recurringBaseEventEndDate.getTime() - recurringBaseEventStartDate.getTime()) /
      (1000 * 60 * 60 * 24);

    // until이 처음 룰을 만들 때 값으로 들어가면 시간이 맞지 않아서 타임존 반영 값으로 수정
    const updatedRRule = new RRule({
      ...rule.origOptions,
      until: recurringUntilDateTime ? new Date(recurringUntilDateTime) : null,
      dtstart: datetime(
        recurringBaseEventStartDate.getFullYear(),
        recurringBaseEventStartDate.getMonth() + 1,
        recurringBaseEventStartDate.getDate(),
        0,
        0
      ),
      tzid: eventTimeZone,
    });

    try {
      const recurredDates = updatedRRule.between(effectiveStartDate, effectiveEndDate, true);

      const finalizedRecurredDates = recurredDates.map((rawNewStartDate, index) => {
        const newRecurringEventId = generateRecurringEventId(event);
        const newId = generateInstanceId(rawNewStartDate, true, newRecurringEventId);
        // 시작일, 종료일 00:00:00 처리
        const newStartDate = moment(rawNewStartDate).startOf("day").toDate();
        const newEndDate = moment(rawNewStartDate).add(dayDiff, "days").startOf("day").toDate();

        return {
          ...event,
          id: newId,
          start: newStartDate,
          end: newEndDate,
          originalId: event.originalId ? event.originalId : event.id, // originalId가 없으면 모체인 경우라 id 넣어줌
          originalStart: null, // 가상블록은 originalStart가 없으므로 null로 초기값 처리
        };
      });

      return finalizedRecurredDates;
    } catch (error) {
      console.error("AllDay RRULE 처리 중 오류 발생:", error);
      return [];
    }
  }

  try {
    const recurringBaseEventStartDateTime = new Date(event.start);
    const recurringBaseEventEndDateTime = new Date(event.end);
    const timeDifference =
      recurringBaseEventEndDateTime.getTime() - recurringBaseEventStartDateTime.getTime();

    const utcOffsetMinutes = recurringBaseEventStartDateTime.getTimezoneOffset();
    const utcOffsetHours = -utcOffsetMinutes / 60;
    const dtstart = datetime(
      recurringBaseEventStartDateTime.getFullYear(),
      recurringBaseEventStartDateTime.getMonth() + 1,
      recurringBaseEventStartDateTime.getDate(),
      recurringBaseEventStartDateTime.getHours(),
      recurringBaseEventStartDateTime.getMinutes()
    );

    // until이 처음 룰을 만들 때 값으로 들어가면 시간이 맞지 않아서 타임존 반영 값으로 수정
    const updatedRRule = new RRule({
      ...rule.origOptions,
      until: recurringUntilDateTime ? new Date(recurringUntilDateTime) : null,
      dtstart,
      tzid: eventTimeZone,
    });

    const recurredDates = updatedRRule.between(effectiveStartDate, effectiveEndDate, true);

    const finalizedRecurredDateTimes = recurredDates.map((rawNewStartTime, index) => {
      const tunedNewStartTimeInLocal = moment
        .tz(rawNewStartTime, eventTimeZone)
        .subtract(utcOffsetHours, "hours")
        .toDate();

      const newRecurringEventId = generateRecurringEventId(event);
      const newId = generateInstanceId(tunedNewStartTimeInLocal, false, newRecurringEventId);
      const newEndTimeInLocal = new Date(tunedNewStartTimeInLocal.getTime() + timeDifference);

      return {
        ...event,
        id: newId,
        start: tunedNewStartTimeInLocal,
        end: newEndTimeInLocal,
        originalId: event.originalId ? event.originalId : event.id, // originalId가 없으면 모체인 경우라 id 넣어줌
        originalStart: null, // 가상블록은 originalStart가 없으므로 null로 초기값 처리
      };
    });

    return finalizedRecurredDateTimes;
  } catch (error) {
    console.error("RRULE 처리 중 오류 발생:", error, {
      event,
      recurringStart,
      recurringEnd,
      rule: rule.origOptions,
    });
    return [];
  }
};
