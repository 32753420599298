import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { loadFromLocalStorage, saveToLocalStorage } from "../utils/localStorage/localStorage";
import { TokenState } from "../recoil/auth/tokenState";

// 큐 항목 타입
interface QueueItem {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}

// 토큰 갱신 관리 변수
let isRefreshing = false;
let failedQueue: QueueItem[] = [];

// 토큰 갱신 관리 큐
function processQueue(error: Error | null, token: string | null = null) {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
}

// 현재 토큰 상태 가져오기
function getTokenState(): TokenState {
  const storedToken = loadFromLocalStorage<TokenState>("tokenState");
  if (!storedToken) {
    return {
      mAccessToken: null,
      mRefreshToken: null,
      accessToken: null,
    };
  }
  return storedToken;
}

// 토큰 상태 업데이트
function updateTokenState(newToken: TokenState): void {
  saveToLocalStorage("tokenState", newToken);
}

// 토큰 갱신 함수
async function refreshAccessToken(): Promise<TokenState> {
  const currentToken = getTokenState();

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_V2}auth/refresh`, {
      mAccessToken: currentToken.mAccessToken,
      mRefreshToken: currentToken.mRefreshToken,
    });

    const newToken: TokenState = {
      accessToken: currentToken.accessToken,
      mAccessToken: response.data.mAccessToken,
      mRefreshToken: response.data.mRefreshToken,
    };

    updateTokenState(newToken);

    return newToken;
  } catch (error) {
    console.error("토큰 갱신 중 오류 발생:", error);

    updateTokenState({
      mAccessToken: null,
      mRefreshToken: null,
      accessToken: null,
    });

    window.location.href = "/";
    throw error;
  }
}

// Axios 인스턴스 생성
const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_V2,
});

// 요청 인터셉터를 사용하여 Authorization 헤더 추가
axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const currentToken = getTokenState();

    if (!currentToken.mAccessToken) {
      updateTokenState({
        mAccessToken: null,
        mRefreshToken: null,
        accessToken: null,
      });

      window.location.href = "/";

      return Promise.reject(new Error("No access token available"));
    }

    config.headers.Authorization = `Bearer ${currentToken.mAccessToken}`;

    return config;
  },
  (error) => Promise.reject(error)
);

// 응답 인터셉터를 사용하여 토큰 갱신 처리
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error.response?.status;

    // 401 또는 444 에러이고 재시도가 아직 이루어지지 않은 경우
    if ((status === 401 || status === 444) && !originalRequest._retry) {
      if (isRefreshing) {
        // 이미 토큰 갱신 중이면 대기열에 추가
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = "Bearer " + token;
            }
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshAccessToken();
        processQueue(null, newToken.mAccessToken);

        if (originalRequest.headers) {
          originalRequest.headers["Authorization"] = "Bearer " + newToken.mAccessToken;
        }

        return axios(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError as Error, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
