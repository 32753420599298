import moment from "moment";
import { useEffect, useRef, useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import DatePicker from "react-datepicker";

import { juneTrack, useJuneTrackCall } from "../../utils/june/analytics";
import formatDate from "../../utils/common/dateTime/formatDate";
import formatDateTime from "../../utils/common/dateTime/formatDateTime";
import formatTime from "../../utils/common/dateTime/formatTime";
import formatTimeInterval from "../../utils/common/dateTime/formatTimeInterval";

import { timeFormatState } from "../../recoil/calendar/settingCalendar";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";

import { taskDetailHeightState } from "../../recoil/taskDetail/taskDetailHeightState";

import TaskDetailTitle from "./Title/TaskDetailTitle";
import DateModal from "./DateTime/DateModal";
import GuestModal from "./Guest/GuestModal";
import SpaceModal from "./Space/SpaceModal";
import LinkModal from "./Link/LinkModal";
import TaskNote from "./Note/TaskNote";
import VideoModal from "./Video/VideoModal";
import NotePreview from "./Note/NotePreview";

import gmailIcon from "../../assets/TaskDetail/integration-gmail-icon.svg";
import jiraIcon from "../../assets/TaskDetail/integration-jira-icon.svg";
import slackIcon from "../../assets/TaskDetail/integration-slack-icon.svg";

import clsx from "clsx";

import "react-datepicker/dist/react-datepicker.css";
import "./DateTime/CustomDatePicker.css";

import styles from "./TaskSetting.module.css";
import { inProgressAndCompletedSpaceListState } from "../../recoil/spaces/inProgressSpaceListState";
import { getSpaceColor } from "../../services/space/space.service";
import { getBlockTypeFlags } from "../../utils/common/block/getBlockTypeFlags";
import { ItemStatus, RecurringOption } from "../../types/block/enum";
import { LINK_FIELD_NAME } from "../../constants/block/LinkData";
import { useUpdateCalendarCheckboxMutation } from "../../react-query/calendar/useUpdateCalendarCheckboxMutation";
import { toastState } from "../../recoil/toast/toastState";
import { logger } from "../../utils/logger";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";

function isValidationBuffMode(data) {
  if (data === null) {
    logger.info("Data is null while validating buff mode");
    return false;
  }

  const isValidLink =
    data.hangoutLink && typeof data.hangoutLink === "string" && data.hangoutLink.trim() !== "";
  const hasAttendees = Array.isArray(data.attendees) && data.attendees.length > 0;

  return isValidLink || hasAttendees;
}

function areDatesSameDay(dateString1, dateString2) {
  // 왜 false return하는지 아래 NOTE 참고
  if (!dateString1 || !dateString2) {
    logger.info("Either dateString1 or dateString2 is null: ", dateString1, dateString2);
    return false;
  }

  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  // NOTE: undefined가 들어올 경우 NaN으로 아래 값들이 처리되어 false로 처리됨 (NaN === NaN -> false)
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  return year1 === year2 && month1 === month2 && day1 === day2;
}

export default function TaskSetting({
  initialData,
  titleRef,
  expand,
  onSave,
  onClose,
  guestError,
  setGuestError,
  setVisibilityType,
  isChangeNote,
  isDisabled,
  // NOTE: 지울 때 이 component를 사용하는 JS 파일에서도 꼭 확인해서 같이 지우세요
  // WARN: not being used
  onChangeLocation,
  // WARN: not being used
  currentLocation,
  linkMoreModalRef,
  videoCreateModalRef,
  repeatModalRef,
  isTimeStart,
  setIsTimeStart,
  isTitleFocused,
  setIsTitleFocused,
  projectDropdownRef,
  isDateStart,
  setIsDateStart,
  isDateEnd,
  setIsDateEnd,
  isTimeEnd,
  setIsTimeEnd,
  startTimeRef,
  startDateRef,
  endTimeRef,
  endDateRef,
  guestDropdownRef,
  // WARN: not being used
  locationDropdownRef,
  repeatAddModalRef,
  mutateMeetingCode,
  isPendingMeetingCode,
  meetingCode,
  setIsModalNoteClicked,
  isModalNoteClicked,
  localTitle,
  handleChangeLocalTitle,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [isDone, setIsDone] = useState(taskDetail.data?.itemStatus === ItemStatus.COMPLETED);
  const nowDate = new Date();
  const minutes = nowDate.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;
  nowDate.setMinutes(roundedMinutes);

  const [isSameDay, setIsSameDay] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [dateTitle, setDateTitle] = useState("Date/Time");
  // TODO : replace this locationLocation with real data
  const [isAllDay, setIsAllDay] = useState(null);
  const [dateInterval, setDateInterval] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const parentDivRef = useRef(null);
  const [isDatePropertyModal, setIsDatePropertyModal] = useState(null);
  const [isBuffMode, setIsBuffMode] = useState(null);
  const [integration, setIntegration] = useState(null);

  const setToast = useSetRecoilState(toastState);
  const taskDetailHeight = useRecoilValue(taskDetailHeightState);

  const [inProgressAndCompletedSpaceList, setInProgressAndCompletedSpaceList] = useRecoilState(
    inProgressAndCompletedSpaceListState
  );
  const defaultVisibility = useRecoilValue(visibilityState);
  const [noteModalHeight, setNoteModalHeight] = useState(taskDetailHeight - 40 - 2 - 42);
  const [hover, setHover] = useState(false);

  // TODO 여기 클라이언트 상태 업데이트 필요
  const { mutate: updateBlockItemStatus } = useUpdateCalendarCheckboxMutation();

  const { spaceId } = taskDetail.data;
  const { isTask, isEvent } = getBlockTypeFlags(taskDetail.data?.blockType);

  const trackCall = useJuneTrackCall();

  const getDateTimeDiffMSec = (startDate, endDate) => {
    return new Date(endDate).getTime() - new Date(startDate).getTime();
  };
  const timeFormat = useRecoilValue(timeFormatState);

  useEffect(() => {
    refreshDate(dateStart, dateEnd, timeStart, timeEnd);
  }, [timeStart, timeEnd, dateStart, dateEnd, setIsModalNoteClicked]);

  useEffect(() => {
    refreshDate(dateStart, dateEnd, timeStart, timeEnd);
  }, [isDatePropertyModal, isAllDay]);

  const refreshDate = useCallback(
    (dateStart, dateEnd, timeStart, timeEnd) => {
      if (dateStart != null && dateEnd != null && timeStart != null && timeEnd != null) {
        const start = new Date(dateStart.getTime());
        start.setHours(timeStart.getHours(), timeStart.getMinutes(), 0);
        const end = new Date(dateEnd.getTime());
        end.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0);

        setTaskDetail((prev) => {
          if (isAllDay) {
            return {
              ...prev,
              data: {
                ...prev.data,
                allDay: true,
                start: moment(start).startOf("day"),
                // NOTE allday일 경우 마지막 end date +1일 해서 저장.
                end: moment(end).add(1, "days").startOf("day"),
              },
            };
          } else {
            return {
              ...prev,
              data: {
                ...prev.data,
                allDay: false,
                start: start,
                end: end,
              },
            };
          }
        });

        let newTitle;
        if (isAllDay) {
          newTitle = formatDate(start) + " - " + formatDate(end);
          setDateInterval(null);
        } else {
          if (formatDate(start) != formatDate(end)) {
            newTitle = formatDateTime(start, timeFormat) + " - " + formatTime(end, timeFormat);

            const timeDiffMilliseconds = Math.abs(end - start);
            const hours = Math.floor(timeDiffMilliseconds / (60 * 60 * 1000));
            if (hours > 24) {
              setDateInterval(null);
            } else {
              setDateInterval(formatTimeInterval(start, end));
            }
          } else {
            newTitle = formatDateTime(start, timeFormat) + " - " + formatTime(end, timeFormat);
            setDateInterval(formatTimeInterval(start, end));
          }
        }
        setDateTitle(newTitle);
      }
    },
    [isAllDay, dateStart, dateEnd, timeStart, timeEnd]
  );

  useEffect(() => {
    setNoteModalHeight(taskDetailHeight - 40 - 2 - 42);
  }, [isModalNoteClicked]);

  useEffect(() => {
    if (isTitleFocused && taskDetail.isNewBlock) {
      titleRef?.current?.focus();
    }

    // 타이틀이 없는 경우 타이틀 포커스
    if (isTitleFocused && !taskDetail.data?.title) {
      titleRef?.current?.focus();
    }
  }, [isTitleFocused]);

  useEffect(() => {
    if (taskDetail.isNewBlock) {
      titleRef?.current?.focus();
    }

    setIsAllDay(taskDetail.data?.allDay);

    // COMMENT: 이 로직이 comment out 된 이유
    if (taskDetail.data?.hangoutLink === null && taskDetail.data?.attendees !== null) {
      // function fetchEventData(retryCount = 0) {
      //   api
      //     .get("events/" + data.id, {
      //       headers: { "X-Requester": data.creator },
      //     })
      //     .then((res) => {
      //       if (res.data.event?.hangoutLink != null) {
      //         setTaskDetail((prev) => ({
      //           ...prev,
      //           data: {
      //             ...prev.data,
      //             hangoutLink: res.data.event.hangoutLink,
      //           },
      //         }));
      //         setIsBuffMode(!isValidationBuffMode(data));
      //         // 게스트는 있지만 구글밋링크가 없는 이벤트의 경우 구글밋링크를 넣어준다.
      //         if (taskDetail.handleEventChange) {
      //           // Calendar에서 이벤트 클릭해서 온경우
      //           const newData = {
      //             ...data,
      //             hangoutLink: res.data.event.hangoutLink,
      //           };
      //           taskDetail.handleEventChange(newData);
      //         }
      //       } else if (retryCount < 2) {
      //         // retryCount가 2보다 작을 때만 재시도
      //         setTimeout(() => fetchEventData(retryCount + 1), 2000); // 2초 후에 retryCount를 1 증가시켜 재시도
      //       }
      //     });
      // }
      // fetchEventData();
    }

    setIsBuffMode(!isValidationBuffMode(taskDetail.data));

    if (taskDetail.data?.allDay) {
      // isAllday일때만 타는 로직
      // - start, end가 없는 경우에는 false 반환하여 else로 떨어짐
      if (areDatesSameDay(taskDetail.data?.start, taskDetail.data?.end)) {
        setDateStart(new Date(taskDetail.data?.start));
        setDateEnd(new Date(taskDetail.data?.end));
        setTimeStart(new Date(taskDetail.data?.start));
        setTimeEnd(new Date(taskDetail.data?.end));
      } else {
        // NOTE allday일 경우 마지막 end date는 포함되지 않음.
        setDateStart(taskDetail.data?.start ? new Date(taskDetail.data?.start) : null);
        // NOTE 맨 처음 allday의 date 가져올 때 end는 +1일 되어있으므로 -1 해서 사용
        setDateEnd(
          taskDetail.data?.end ? moment(taskDetail.data?.end).subtract(1, "days").toDate() : null
        );
        setTimeStart(taskDetail.data?.start ? new Date(taskDetail.data?.start) : null);
        setTimeEnd(taskDetail.data?.end ? new Date(taskDetail.data?.end) : null);
      }
    } else {
      setDateStart(taskDetail.data?.start ? new Date(taskDetail.data?.start) : null);
      setDateEnd(taskDetail.data?.end ? new Date(taskDetail.data?.end) : null);
      setTimeStart(taskDetail.data?.start ? new Date(taskDetail.data?.start) : null);
      setTimeEnd(taskDetail.data?.end ? new Date(taskDetail.data?.end) : null);
    }

    if (taskDetail.data?.integration != null) {
      setIntegration(taskDetail.data?.integration);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (startDateRef.current && !startDateRef.current.contains(event.target)) {
        setIsDateStart(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startDateRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (endDateRef.current && !endDateRef.current.contains(event.target)) {
        setIsDateEnd(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endDateRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (startTimeRef.current && !startTimeRef.current.contains(event.target)) {
        setIsTimeStart(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startTimeRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (endTimeRef.current && !endTimeRef.current.contains(event.target)) {
        setIsTimeEnd(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endTimeRef]);

  useEffect(() => {
    handleResizeHeight();
  }, [setIsTitleFocused]);

  const handleSetModalPosition = (event) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      x: rect.left + window.scrollX,
      y: rect.top + rect.height + 5 + window.scrollY,
    });
  };

  useEffect(() => {}, [setModalPosition, expand]);

  const handleCheckbox = () => {
    logger.info(`handleCheckbox called in TaskSetting.`);
    if (isDisabled) {
      return;
    }
    // 닫히면서 checked/unchecked 상태 잠깐 보여주기 위한 것으로 이해
    setIsDone((prev) => !prev);

    if (!initialData?.id) {
      logger.info(`Updating block with id: ${initialData?.id} in TaskSetting.`);
    }

    const hasRecurrenceRules = initialData?.recurrence?.length > 0;
    const isRecurringInstance = Boolean(initialData.originalId);
    const isRecurringBlock = hasRecurrenceRules || isRecurringInstance;

    // 반복이면 id, payload가 다름
    // this로 반복이벤트 수정시에는 꼭 originalStart를 넣어줘야함
    const payload = { itemStatus: isDone ? ItemStatus.IN_PROGRESS : ItemStatus.COMPLETED };
    const updatePayload = isRecurringBlock
      ? { ...payload, type: RecurringOption.THIS, originalStart: initialData?.start }
      : payload;

    juneTrack(
      updatePayload.itemStatus === ItemStatus.IN_PROGRESS
        ? JUNE_EVENT.UNDONE_TASK
        : JUNE_EVENT.DONE_TASK,
      {
        location: "block_detail",
      }
    );

    updateBlockItemStatus({
      id: initialData?.id,
      payload: updatePayload,
      prevData: initialData,
    });

    onClose();
  };

  const handlePropertyBtn = (e = null, name) => {
    if (name !== "project") {
      if (e && e.target !== e.currentTarget) return;
    }
  };

  // TODO: linkData 같은 경우에만 배열로 처리
  const updateTaskConfig = (key, value) => {
    // if (isEnter) return;
    if (key === LINK_FIELD_NAME) {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: [...(prevState.data?.[key] ?? []), value],
        },
      }));
    } else {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
      }));
    }
  };

  const handleResizeHeight = () => {
    if (titleRef.current) {
      titleRef.current.style.height = "auto"; //height 초기화

      if (titleRef.current.scrollHeight < 98) {
        titleRef.current.style.height = titleRef.current.scrollHeight + "px";
      } else {
        titleRef.current.style.height = "98px";
      }
    }
  };

  const handleTimeStartChange = (e) => {
    const dateTimeDiffMSec = getDateTimeDiffMSec(timeStart, timeEnd);
    setTimeEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));
    setDateEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));

    setIsTimeStart(!isTimeStart);
    setTimeStart(e);
    setDateStart(e);
  };

  const handleTimeEndChange = (e) => {
    setIsTimeEnd(!isTimeEnd);
    setTimeEnd(e);
    setDateEnd(e);
  };

  const handleTimeStart = (e) => {
    // WARN: non-strict equal comparison은 지양해야 함
    if (e.currentTarget != e.target) return;

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.x - parentDivRect.x;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsTimeStart(!isTimeStart);
    setIsDateEnd(false);
    setIsDateStart(false);
    setIsTimeEnd(false);
  };

  const handleTimeEnd = (e) => {
    // WARN: non-strict equal comparison은 지양해야 함
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.x - parentDivRect.x;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsTimeEnd(!isTimeEnd);
    setIsDateStart(false);
    setIsTimeStart(false);
    setIsDateEnd(false);
  };

  const handleDateStart = (e) => {
    logger.info(e.currentTarget === e.target);
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.left - parentDivRect.left;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsDateStart(!isDateStart);
    setIsTimeStart(false);
    setIsDateEnd(false);
    setIsTimeEnd(false);
  };
  const handleDateEnd = (e) => {
    // WARN: non-strict equal comparison은 지양해야 함
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.left - parentDivRect.left;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsDateEnd(!isDateEnd);
    setIsDateStart(false);
    setIsTimeEnd(false);
    setIsTimeStart(false);
  };

  const handleDateStartChange = (e) => {
    // WARN: non-strict equal comparison은 지양해야 함
    if (formatDate(e) == formatDate(dateEnd)) setIsSameDay(true);
    const dateTimeDiffMSec = getDateTimeDiffMSec(dateStart, dateEnd);
    setDateEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));
    setTimeEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));

    setIsDateStart(!isDateStart);
    setDateStart(e);
    setTimeStart(e);
  };

  const handleDateEndChange = (e) => {
    setIsDateEnd(!isDateEnd);
    setDateEnd(e);
    setTimeEnd(e);
  };

  const handleBuffModeChange = (isBuffMode) => {
    setIsBuffMode(isBuffMode);
    if (!isBuffMode) {
      // NOTE 게스트 있을 시(미팅 시) 기본값은 public
      setVisibilityType("public");
    } else {
      setVisibilityType(defaultVisibility);
    }
  };

  const handleDateClear = () => {
    setDateStart(null);
    setDateEnd(null);
    setTaskDetail((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        start: null,
        end: null,
        recurrence: [],
      },
    }));
  };

  const getBackgroundImgUrl = () => {
    switch (integration.provider) {
      case "gmail":
        return gmailIcon;
      case "jira":
        return jiraIcon;
      case "slack":
        return slackIcon;
      default:
        break;
    }
  };

  const handleMoveIntegration = () => {
    trackCall("click_integration", {
      location: taskDetail.data?.blockType,
      type: integration.provider,
    });
    window.open(integration.link, "_blank");
  };

  const style = {
    backgroundColor: isDisabled
      ? "#242626"
      : hover
        ? `${getSpaceColor(inProgressAndCompletedSpaceList, spaceId)}`
        : `${getSpaceColor(inProgressAndCompletedSpaceList, spaceId)}${isDone ? "" : "26"} `,
    boxShadow: `0 0 0 1px ${isDisabled ? "#484D4D" : getSpaceColor(inProgressAndCompletedSpaceList, spaceId)} inset`,
    cursor: isDisabled ? "not-allowed" : "pointer",
  };
  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  return isModalNoteClicked ? (
    <div
      style={{ height: noteModalHeight }}
      className={styles.modalNote}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <TaskNote
        isChangeNote={isChangeNote}
        onSave={onSave}
        expand={expand}
        localTitle={localTitle}
      />
    </div>
  ) : (
    <>
      <div className={clsx({ [styles["setting"]]: !expand, [styles["setting--expand"]]: expand })}>
        <div
          className={clsx(styles["setting-state"], {
            [styles["setting-state--expand"]]: expand,
          })}
        >
          {isTask && (
            <div
              className={`${styles["setting-state-checkbox"]}
                ${styles[`${isDone ? "checkboxCompleted" : null}`]}
                ${isDisabled ? styles.disabled : ""}`}
              style={style}
              onClick={handleCheckbox}
              onMouseEnter={handleIconEnter}
              onMouseLeave={handleIconLeave}
            ></div>
          )}

          <TaskDetailTitle
            titleText={localTitle}
            blockType={taskDetail.data?.blockType}
            isTitleFocused={isTitleFocused}
            isFullPage={expand}
            titleRef={titleRef}
            setIsTitleFocused={setIsTitleFocused}
            handleChangeLocalTitle={handleChangeLocalTitle}
            handleResizeHeight={handleResizeHeight}
            onSave={onSave}
            updateTaskConfig={updateTaskConfig}
            isNewTask={taskDetail?.isNewBlock ?? false}
          />
        </div>
        <div
          className={clsx(styles["setting-property"], {
            [styles["setting-property--expand"]]: expand,
          })}
        >
          <SpaceModal
            expand={expand}
            projectDropdownRef={projectDropdownRef}
          />
          <DateModal
            isDateStart={isDateStart}
            isDateEnd={isDateEnd}
            isTimeStart={isTimeStart}
            isTimeEnd={isTimeEnd}
            startTimeRef={startTimeRef}
            startDateRef={startDateRef}
            endTimeRef={endTimeRef}
            endDateRef={endDateRef}
            handleTimeStartChange={handleTimeStartChange}
            handleTimeEndChange={handleTimeEndChange}
            getDateTimeDiffMSec={getDateTimeDiffMSec}
            handleDateStart={handleDateStart}
            handleDateEnd={handleDateEnd}
            handleDateStartChange={handleDateStartChange}
            handleDateEndChange={handleDateEndChange}
            dateInterval={dateInterval}
            dateTitle={dateTitle}
            isSameDay={isSameDay}
            setDateStart={setDateStart}
            dateStart={dateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            setTimeStart={setTimeStart}
            timeStart={timeStart}
            setTimeEnd={setTimeEnd}
            timeEnd={timeEnd}
            handleTimeStart={handleTimeStart}
            handleTimeEnd={handleTimeEnd}
            isAllDay={isAllDay}
            setIsAllDay={setIsAllDay}
            isDatePropertyModal={isDatePropertyModal}
            setIsDatePropertyModal={setIsDatePropertyModal}
            guestError={guestError}
            setGuestError={setGuestError}
            handleDateClear={handleDateClear}
            setIsDateStart={setIsDateStart}
            setIsDateEnd={setIsDateEnd}
            setIsTimeStart={setIsTimeStart}
            setIsTimeEnd={setIsTimeEnd}
            onCloseRepeat={() => handlePropertyBtn(null)}
            expand={expand}
            refreshDate={refreshDate}
            handleSetModalPosition={handleSetModalPosition}
            repeatModalRef={repeatModalRef}
            repeatAddModalRef={repeatAddModalRef}
          />

          {isTimeStart &&
            createPortal(
              <div
                className={`${styles["datepickerTimeWrap"]} ${"datepickerTimeWrap"}`}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={startTimeRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={timeStart}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat={timeFormat === "24-hour" ? "HH:mm" : "h:mm aaa"}
                  inline
                  onChange={handleTimeStartChange}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>,
              document.body
            )}
          {isDateStart &&
            createPortal(
              <div
                className={styles["datepicker-wrap"]}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={startDateRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={dateStart}
                  selectsStart
                  onChange={handleDateStartChange}
                  startDate={dateStart}
                  endDate={dateEnd}
                  inline
                />
              </div>,
              document.body
            )}

          {isTimeEnd &&
            createPortal(
              <div
                className={`${styles["datepickerTimeWrap"]} ${"datepickerTimeWrap"}`}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={endTimeRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={timeEnd}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat={timeFormat === "24-hour" ? "HH:mm" : "h:mm aaa"}
                  onChange={handleTimeEndChange}
                  inline
                  filterTime={(time) => {
                    const dateTimeDiffMsec = getDateTimeDiffMSec(timeStart, time);
                    return dateTimeDiffMsec > 0;
                  }}
                />
              </div>,
              document.body
            )}

          {isDateEnd &&
            createPortal(
              <div
                className={styles["datepicker-wrap"]}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={endDateRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={dateEnd}
                  selectsEnd
                  onChange={handleDateEndChange}
                  inline
                  startDate={dateStart}
                  endDate={dateEnd}
                  minDate={dateStart}
                  filterDate={(date) => {
                    if (isAllDay) return true;

                    const isSameDay =
                      new Date(dateStart).toDateString() === new Date(date).toDateString();
                    if (isSameDay) {
                      const endDate = new Date(date).toDateString();
                      const endTime = new Date(dateEnd).toTimeString();
                      const nextDateTime = new Date(`${endDate} ${endTime}`);
                      const dateTimeDiffMsec = getDateTimeDiffMSec(dateStart, nextDateTime);
                      return dateTimeDiffMsec > 0;
                    }

                    const dateTimeDiffMsec = getDateTimeDiffMSec(dateStart, date);
                    return dateTimeDiffMsec > 0;
                  }}
                />
              </div>,
              document.body
            )}
          {isEvent && (
            <>
              <GuestModal
                onClose={() => handlePropertyBtn(null)}
                setIsBuffMode={handleBuffModeChange}
                expand={expand}
                guestDropdownRef={guestDropdownRef}
                mutateMeetingCode={mutateMeetingCode}
              />
              {/* Notice: Location search가 미흡한 부분이 있어, 이를 보완할 때가지 당분간 comment out*/}
              {/*<LocationMenu*/}
              {/*  locationDropdownRef={locationDropdownRef}*/}
              {/*  location={currentLocation}*/}
              {/*  onChangeLocation={onChangeLocation}*/}
              {/*  expand={expand}*/}
              {/*/>*/}
              <VideoModal
                expand={expand}
                isBuffMode={isBuffMode}
                videoCreateModalRef={videoCreateModalRef}
                mutateMeetingCode={mutateMeetingCode}
                isPendingMeetingCode={isPendingMeetingCode}
                meetingCode={meetingCode}
              />
            </>
          )}

          <LinkModal
            updateTaskConfig={updateTaskConfig}
            expand={expand}
            linkMoreModalRef={linkMoreModalRef}
            integration={integration}
            handleMoveIntegration={handleMoveIntegration}
            getBackgroundImgUrl={getBackgroundImgUrl}
          />
        </div>
        {/* expand일 때 선택 탭 */}

        {expand && (
          <div className={styles.select_tab}>
            <button className={styles["select_tab--btn"]}>
              <span>Notes</span>
            </button>
          </div>
        )}
        <NotePreview
          expand={expand}
          isChangeNote={isChangeNote}
          onSave={onSave}
          localTitle={localTitle}
          noteData={taskDetail.data?.note}
          setIsModalNoteClicked={setIsModalNoteClicked}
        />
      </div>
    </>
  );
}
