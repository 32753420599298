import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode, memo } from "react";
import { getBlockOpacity, getBorderClassName } from "../../utils/calendar/calendarBlockUtils";
import { BlockType } from "../../types/block/type";

interface CalendarBlockBackgroundWithBorderProps extends BoxProps {
  backgroundColor?: string;
  backgroundImage?: string;
  containerSize?: "smallEvent" | "mediumEvent" | "largeEvent";
  borderColor?: string;
  borderStyle?: string;
  borderWidth?: string;
  isTaskDone?: boolean;
  isEventDone?: boolean;
  isNotGoing?: boolean;
  isNotDoneTask?: boolean;
  isMeetWith?: boolean;
  children: ReactNode;
  event: BlockType;
}

const CalendarBlockBackgroundWithBorderComponent = ({
  backgroundColor,
  backgroundImage,
  containerSize,
  borderColor,
  borderStyle,
  borderWidth,
  isTaskDone = false,
  isEventDone = false,
  isNotGoing = false,
  isNotDoneTask = false,
  isMeetWith = false,
  children,
  ...props
}: CalendarBlockBackgroundWithBorderProps) => {
  const opacity = getBlockOpacity({ isTaskDone, isEventDone, isNotGoing, isMeetWith });

  return (
    <Box
      className="wrap"
      height="100%"
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      gap="4px"
      boxSizing="border-box"
      border="1px solid transparent"
      borderRadius="5px"
      position="relative"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      padding={containerSize === "smallEvent" ? "0.5px 6px" : "4px 6px"}
      alignItems={containerSize === "smallEvent" ? "center" : "flex-start"}
      lineHeight={containerSize === "smallEvent" ? "1.38" : "1.2"}
      sx={{
        container: "eventWrapper / inline-size",
      }}
      {...props}
    >
      {/* 통합된 border 엘리먼트 */}
      <Box
        className={getBorderClassName({ isNotDoneTask })}
        position="absolute"
        top="-1px"
        left="-1px"
        right="-1px"
        bottom="-1px"
        borderRadius="5px"
        opacity={isNotDoneTask ? undefined : opacity}
        border="1px solid transparent"
        borderColor={borderColor}
        borderStyle={borderStyle}
        borderWidth={borderWidth}
        display="block"
        sx={{
          "&.notDoneTaskBorder": {
            animation: "reminder 0.8s infinite alternate",
          },
        }}
      />

      {children}
    </Box>
  );
};

export const CalendarBlockBackgroundWithBorder = memo(
  CalendarBlockBackgroundWithBorderComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.backgroundColor === nextProps.backgroundColor &&
      prevProps.backgroundImage === nextProps.backgroundImage &&
      prevProps.containerSize === nextProps.containerSize &&
      prevProps.borderColor === nextProps.borderColor &&
      prevProps.borderStyle === nextProps.borderStyle &&
      prevProps.borderWidth === nextProps.borderWidth &&
      prevProps.isTaskDone === nextProps.isTaskDone &&
      prevProps.isEventDone === nextProps.isEventDone &&
      prevProps.isNotGoing === nextProps.isNotGoing &&
      prevProps.isNotDoneTask === nextProps.isNotDoneTask &&
      prevProps.isMeetWith === nextProps.isMeetWith &&
      prevProps.event === nextProps.event
    );
  }
);
