import { pxToRem } from "../../../utils/styles/size";
import { useRef, useState } from "react";
import createInboxBlockPayload from "../../../services/inbox/createInboxBlockPayload";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { addSelectedSpace, extractSpaceId } from "../../../services/space/space.service";
import {
  selectedSpaceIdListState,
  selectedSpaceIdListStateSelector,
} from "../../../recoil/spaces/selectedSpaceIdListState";

import { Flex, Textarea } from "@chakra-ui/react";
import { InboxHeaderTab } from "../../../types/inbox/inbox-headers";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { isDividerInput } from "../../../services/divider/divider.service";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { isValidUrl } from "../../../utils/common/block/links/isValidUrl";
import { MODAL_OFFSET_Y, MODAL_OFFSET_X } from "../../../constants/block/InboxInput";
import { SpaceIdType } from "../../../types/space";
import { BasicBlock } from "../../../types/block/enum";
import { CreateBlockType } from "../../../types/block/type";
import { InboxTab } from "../../../types/inbox/enum";
import { getDayBoundaries } from "../../../utils/date-format/dateUtils";
import { visibilityState } from "../../../recoil/calendar/settingCalendarV2";
import {
  convertServerToClientBlockType,
  toServerVisibility,
} from "../../../utils/common/block/type-converter";
import { noSpaceIdSelector } from "../../../recoil/spaces/inProgressSpaceListState";
import { useCreateInboxBlockMutation } from "../../../react-query/inbox/useCreateInboxBlockMutation";

interface InboxInputProps {
  activeTab: InboxHeaderTab;
  handleSaveTaskSelectedId: (id: number | null) => void;
}

const InboxInput = ({ activeTab, handleSaveTaskSelectedId }: InboxInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isBlurredWithText, setIsBlurredWithText] = useState(false);

  const noSpaceId = useRecoilValue(noSpaceIdSelector);
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);
  const setSelectedSpaces = useSetRecoilState(selectedSpaceIdListState);
  const setTaskDetail = useSetRecoilState(taskPopupState);
  const defaultVisibility = useRecoilValue(visibilityState);

  const createInboxBlockMutate = useCreateInboxBlockMutation(activeTab);

  const trackCall = useJuneTrackCall();

  const getTextareaPosition = () => {
    const element = textareaRef.current;
    if (!element) return null;

    const rect = element.getBoundingClientRect();
    return {
      bottom: rect.bottom,
      right: rect.right,
      absoluteBottom: rect.bottom + window.scrollY,
      absoluteRight: rect.right + window.scrollX,
    };
  };

  const resetTaskTitleValue = () => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
  };

  const handleTaskChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleResizeHeight();

    if (activeTab === InboxTab.INBOX && isDividerInput(e.target.value)) {
      resetTaskTitleValue();
      handleCreateDivider();
    }
  };

  const handleBlur = () => {
    if (textareaRef.current?.value.trim() !== "") {
      setIsBlurredWithText(true);
    } else {
      setIsBlurredWithText(false);
    }
  };

  const handleFocus = () => {
    setIsBlurredWithText(false);
  };

  const handleAddLink = (
    trimmedTaskName: string,

    defaultVisibility: any,
    inProgressSelectedSpaceIdList: SpaceIdType[],
    activeTab: string
  ) => {
    const newItem: CreateBlockType = {
      ...createInboxBlockPayload("", defaultVisibility),
      ...extractSpaceId(inProgressSelectedSpaceIdList),

      linkData: [
        {
          url: trimmedTaskName,
          name: "",
          isLoading: true,
        },
      ],
    };

    const position = getTextareaPosition();
    if (activeTab === InboxTab.INBOX) {
      createInboxBlockMutate.mutate(newItem, {
        onSuccess: (data) => {
          const clientConvertedData = convertServerToClientBlockType({
            serverBlock: data,
            defaultVisibility,
          });
          handleSaveTaskSelectedId(clientConvertedData.id);

          setTaskDetail((prev) => ({
            ...prev,
            isVisible: true,
            data: clientConvertedData,
            modalPosition: {
              x: position?.right ? position.right + MODAL_OFFSET_X : 0,
              y: position?.bottom ? position.bottom + MODAL_OFFSET_Y : 0,
            },

            type: "inbox",
          }));
        },
      });

      resetInboxInput();

      return;
    }

    if (activeTab === InboxTab.TODAY) {
      const TODAY = new Date();
      const { START_OF_DAY, END_OF_DAY } = getDayBoundaries({ day: TODAY, isAllday: true });
      const calendarNewItem: CreateBlockType = {
        ...newItem,
        allDay: true,
        start: START_OF_DAY,
        end: END_OF_DAY,
      };

      createInboxBlockMutate.mutate(calendarNewItem, {
        onSuccess: (data) => {
          const clientConvertedData = convertServerToClientBlockType({
            serverBlock: data,
            defaultVisibility,
          });
          handleSaveTaskSelectedId(clientConvertedData.id);

          setTaskDetail((prev) => ({
            ...prev,
            isVisible: true,
            data: clientConvertedData,
            modalPosition: {
              x: position?.right ? position.right + MODAL_OFFSET_X : 0,
              y: position?.bottom ? position.bottom + MODAL_OFFSET_Y : 0,
            },
            type: "inbox",
          }));
        },
      });

      // link로 추가 시에만 inboxInput 포커스 해제
      if (textareaRef.current) {
        textareaRef.current.blur();
      }
      resetInboxInput();

      return;
    }
  };

  const handleCreateDivider = () => {
    const newItem: CreateBlockType = {
      title: "",
      blockType: BasicBlock.DIVIDER,
      transparency: null,
      visibility: null,
    };
    createInboxBlockMutate.mutate(newItem, {
      onSuccess: () => {
        trackCall("create_divider", { location: "inbox" });
      },
    });
  };

  const handleCreateTodayBlock = (newItem: CreateBlockType) => {
    const TODAY = new Date();
    const { START_OF_DAY, END_OF_DAY } = getDayBoundaries({ day: TODAY, isAllday: true });

    const calendarNewItem = {
      ...newItem,
      allDay: true,
      start: START_OF_DAY,
      end: END_OF_DAY,
    };

    createInboxBlockMutate.mutate(calendarNewItem);
  };

  const resetInboxInput = () => {
    resetTaskTitleValue();
    textareaRef.current && (textareaRef.current.style.height = "37px");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Escape") {
      resetTaskTitleValue();
      if (textareaRef.current) {
        textareaRef.current.style.height = "37px";
        textareaRef.current.blur();
      }
      return;
    }

    if (e.key === "Enter") {
      if (e.shiftKey) return;
      // NOTE textarea에서 엔터 입력 시 새로운 라인 생성되는 기본동작 막기
      e.preventDefault();

      const trimmedTaskName = (textareaRef.current?.value ?? "").trim();
      if (!trimmedTaskName) return;

      const isUrl = isValidUrl(trimmedTaskName);

      const { visibility, transparency } = toServerVisibility(defaultVisibility);

      const newItem: CreateBlockType = {
        title: isUrl ? "No title" : trimmedTaskName,
        blockType: BasicBlock.TASK,
        spaceId: selectedSpaceIdList.length === 1 ? selectedSpaceIdList[0] : undefined,
        visibility,
        transparency,
      };

      // NOTE 기획: 2개 이상 필터 선택되어 있을 시 생성하는 블록의 space filter 켜지도록
      setSelectedSpaces((prev) => {
        return addSelectedSpace(prev, newItem.spaceId ?? noSpaceId);
      });

      // TODO: link 추가 시 이후 로직 수정 필요(handleAddLink)
      if (isUrl) {
        return handleAddLink(trimmedTaskName, defaultVisibility, selectedSpaceIdList, activeTab);
      }

      activeTab === InboxTab.INBOX
        ? createInboxBlockMutate.mutate(newItem)
        : handleCreateTodayBlock(newItem);
      resetInboxInput();
    }
  };

  const handleResizeHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "37px";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight >= 37 && scrollHeight <= 74) {
      textarea.style.height = `${scrollHeight}px`;
    } else if (scrollHeight > 74) {
      textarea.style.height = "74px";
    }
  };

  return (
    <Flex pt={2}>
      <Textarea
        ref={textareaRef}
        placeholder="+ Add a Task"
        padding={pxToRem(8)}
        flexShrink="0"
        boxSizing="border-box"
        h={pxToRem(37)}
        border={"none"}
        color={"white"}
        // TODO textStyle 적용 안되는 이슈 있음
        textStyle={"body1"}
        fontSize={pxToRem(14)}
        fontWeight={400}
        lineHeight={"150%"}
        _placeholder={{
          color: "#7C7D7D",
          transition: "color 0.3s ease",
          fontSize: pxToRem(14),
          fontWeight: 400,
          lineHeight: "150%",
        }}
        backgroundColor={isBlurredWithText ? "rgba(0, 0, 0, 0.20)" : "initial"}
        _focus={{
          backgroundColor: "rgba(0, 0, 0, 0.20)",
          boxShadow: "none",
          caretColor: "#69E4FF",
          _placeholder: { color: "transparent" },
        }}
        _active={{
          outline: "none",
          border: "none",
          caretColor: "#69E4FF",
          backgroundColor: "rgba(0, 0, 0, 0.20)",
          _placeholder: { color: "transparent" },
        }}
        _hover={{
          backgroundColor: "whiteAlpha.100",
          _placeholder: { color: "#fff" },
          _active: {
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            _placeholder: { color: "transparent" },
          },
          _focus: {
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            _placeholder: { color: "transparent" },
          },
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleTaskChange}
        onKeyDown={handleKeyDown}
        resize="none"
        rows={1}
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      />
    </Flex>
  );
};

export default InboxInput;
