import { atom, selector } from "recoil";
import { LocalStoragePrefix } from "../../constants/storage";
import { localStorageEffect } from "../localStorage/localStorageEffect";
import { inProgressSpaceListState } from "./inProgressSpaceListState";
import { getAllSpaceIds, getNormalSpaceIds } from "../../services/space/space.service";
import { SpaceIdType } from "../../types/space";

const localStorageKey = `${LocalStoragePrefix}_selectedSpaceIdListState`;

/*
 * 선택된 Space ID 리스트를 저장하는 Atom
 * - `null`: 기본값으로 선택된 Space가 없는 상태
 * - LocalStorageEffect: 브라우저의 Local Storage와 동기화
 */
export const selectedSpaceIdListState = atom<SpaceIdType[] | null>({
  key: "selectedSpaceIdListState",
  default: null,
  effects: [localStorageEffect<SpaceIdType[] | null>(localStorageKey, null)],
});

/*
 * 선택된 Space ID 리스트를 반환하는 Selector
 * - 선택된 Space ID가 있으면 그대로 반환
 * - 없으면 프로젝트 목록에서 모든 Space ID를 가져와 반환
 * - 단 Divider는 제외하고 반환함.
 */
export const selectedSpaceIdListStateSelector = selector({
  key: "selectedSpaceIdListStateSelector",
  get: ({ get }) => {
    const selectedSpaces = get(selectedSpaceIdListState);
    const projectList = get(inProgressSpaceListState) || [];
    if (selectedSpaces) {
      return selectedSpaces;
    }
    const allSpaceIds = getAllSpaceIds(projectList);
    return [...allSpaceIds];
  },
});

/*
 * 선택된 Space ID 리스트 중 진행 중(In Progress) 상태만 반환하는 Selector
 * selectedSpaceIdListStateSelector에서 No Space를 제외한 SpaceIdList
 */
export const selectedNormalOnlySpaceIdListSelector = selector({
  key: "selectedNormalOnlySpaceIdListSelector",
  get: ({ get }) => {
    const selectedSpaceIds = get(selectedSpaceIdListStateSelector);
    const spaceList = get(inProgressSpaceListState) || [];
    const normalSpaceIds = getNormalSpaceIds(selectedSpaceIds, spaceList);
    return Array.from(normalSpaceIds);
  },
});
