import { PopupActionType, PopupActions } from "../../types/block/popup";
import { AttendeeType, BlockType } from "../../types/block/type";
import { isChangedBlockProperty } from "../../utils/common/block/checkBlockChangeUtils";
import { areDateTimeEqual } from "../../utils/common/dateTime/areDateTimeEqual";
import { CANCELED } from "./block.service";

export const shouldShowGuestPopupWhenUpdate = (
  blockData: BlockType,
  initialTitle: string,
  initialStart: Date,
  initialEnd: Date,
  initialGuest: AttendeeType[]
) => {
  if (!blockData.attendees?.length) {
    return false;
  }

  if (!blockData.start || !blockData.end) {
    return false; // start 또는 end가 없으면 false 반환
  }

  if (!blockData.attendees) {
    return false; // start 또는 end가 없으면 false 반환
  }

  const hasTitleChanged = initialTitle !== blockData.title;

  const hasDateTimeChanged =
    !areDateTimeEqual(new Date(initialStart), new Date(blockData!.start)) ||
    !areDateTimeEqual(new Date(initialEnd), new Date(blockData!.end));

  const hasAttendeesChanged = isChangedBlockProperty(
    initialGuest ?? [],
    blockData?.attendees ?? []
  );

  return hasTitleChanged || hasDateTimeChanged || hasAttendeesChanged;
};

interface AttendeesNotificationProps {
  blockData: BlockType;
  openGuestPopup: (blockData: BlockType, action: PopupActionType) => Promise<any>;
  initialTitle: string;
  initialStart?: Date | null;
  initialEnd?: Date | null;
  initialGuest: AttendeeType[] | null | undefined;
}

export const handleAttendeesNotification = async ({
  blockData,
  openGuestPopup,
  initialTitle,
  initialStart,
  initialEnd,
  initialGuest,
}: AttendeesNotificationProps) => {
  if (!initialStart || !initialEnd) {
    return null;
  }

  if (
    !shouldShowGuestPopupWhenUpdate(
      blockData,
      initialTitle,
      initialStart,
      initialEnd,
      initialGuest ?? []
    )
  ) {
    return null;
  }

  return await openGuestPopup(blockData, PopupActions.EDIT);
};
