import { Box, BoxProps } from "@chakra-ui/react";
import { forwardRef, MouseEventHandler, memo } from "react";
import { BlockType } from "../../types/block/type";

interface CalendarBlockContainerProps extends BoxProps {
  children: React.ReactNode;
  isTaskDone?: boolean;
  isEventDone?: boolean;
  isNotGoing?: boolean;
  isNotDoneTask?: boolean;
  isMeetWith?: boolean;
  containerSize?: string;
  eventStyle: {
    borderColor: string;
    borderStyle?: "solid" | "dashed";
    borderWidth?: string;
    backgroundColor: string;
    backgroundImage?: string;
  };
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  event: BlockType;
}

const CalendarBlockContainerComponent = forwardRef<HTMLDivElement, CalendarBlockContainerProps>(
  (
    {
      children,
      isTaskDone,
      isEventDone,
      isNotGoing,
      isNotDoneTask,
      isMeetWith,
      containerSize,
      onMouseEnter,
      onMouseLeave,
      event,
      eventStyle,
      ...props
    },
    ref
  ) => {
    // 블록 상태에 따른 스타일 계산
    const getStatusStyles = (): Record<string, any> => {
      if (isTaskDone || isEventDone || isNotGoing) {
        return {
          "& .eventBorder": {
            opacity: 0.2,
          },
          "& .priorityButton, & .meetLinkWrapper, & .checkbox, & .contents, & .iconForVisibility, & .integrationWrapper":
            {
              opacity: 0.6,
            },
          "& .title": {
            opacity: 0.6,
            textDecoration: "line-through",
          },
        };
      }
      return {};
    };

    return (
      <Box
        ref={ref}
        className={`event_wrapper ${containerSize || ""}`}
        height={containerSize === "smallEvent" ? "19px" : "calc(100% - 1px)"}
        minHeight={containerSize === "smallEvent" ? "19px" : "auto"}
        bg="#242626"
        borderRadius="5px"
        position="relative"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          // 미트윗 컨테이너 스타일
          ...(isMeetWith && {
            ".eventBorder": {
              opacity: 0.08,
            },
          }),
          // 완료되지 않은 태스크 스타일
          ...(isNotDoneTask && {
            ".eventBorder": {
              animation: "reminder 0.8s infinite alternate",
            },
          }),
          // 상태에 따른 스타일
          ...getStatusStyles(),
          // 반응형 스타일
          "@container eventWrapper (max-width: 85px)": {
            ".calendar-block-icons": {
              display: "none",
            },
          },
          "@container eventWrapper (max-width: 36px)": {
            ".largeEvent .title, .title, .timeDuration": {
              display: "none",
            },
          },
          "@container eventWrapper (max-width: 14px)": {
            ".checkbox, .meetLinkWrapper": {
              display: "none",
            },
          },
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

CalendarBlockContainerComponent.displayName = "CalendarBlockContainer";

export const CalendarBlockContainer = memo(
  CalendarBlockContainerComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.isTaskDone === nextProps.isTaskDone &&
      prevProps.isEventDone === nextProps.isEventDone &&
      prevProps.isNotGoing === nextProps.isNotGoing &&
      prevProps.isNotDoneTask === nextProps.isNotDoneTask &&
      prevProps.isMeetWith === nextProps.isMeetWith &&
      prevProps.containerSize === nextProps.containerSize &&
      prevProps.event === nextProps.event &&
      prevProps.eventStyle === nextProps.eventStyle
    );
  }
);
