import { BlockType } from "../../../types/block/type";

// 반복 블록 중 completed 인 블록중에 가장 미래의 start 날짜 블록을 추출
export const extractLatestBlocksByOriginalId = (blocks: BlockType[]) => {
  // 1. originalId가 있는 항목들만 필터링
  const blocksWithOriginalId = blocks.filter((block) => block.originalId);
  if (blocksWithOriginalId.length === 0) return [];

  // 2. 유니크한 originalId 목록 추출
  const uniqueOriginalIds = Array.from(
    new Set(blocksWithOriginalId.map((block) => block.originalId))
  );

  // 3. 각 originalId 그룹별로 가장 미래의 start 날짜를 가진 항목 찾기
  const latestBlocks = uniqueOriginalIds.map((originalId) => {
    // 현재 originalId를 가진 모든 블록 찾기
    const sameOriginalBlocks = blocksWithOriginalId.filter(
      (block) => block.originalId === originalId
    );

    // start 날짜를 기준으로 정렬하여 가장 미래의 항목 선택
    return sameOriginalBlocks.sort((a, b) => {
      if (!a.start || !b.start) return 0;
      const dateA = new Date(a.start).getTime();
      const dateB = new Date(b.start).getTime();
      return dateB - dateA; // 내림차순 정렬
    })[0];
  });

  return latestBlocks;
};
