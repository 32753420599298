import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import clsx from "clsx";

import { recurringPopupState } from "../../../recoil/popup/recurringPopupState";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";
import styles from "./RecurringPopup.module.css";
import FocusTrap from "focus-trap-react";
import UserCancelledPopupError from "../../../errors/UserCancelledPopupError";
import { RecurringOption } from "../../../types/block/enum";
import RadioBtn from "../../../compoenets/RadioBtn";
import { recurringOptions, recurringOptionDisplayMap } from "../../../constants/block/Recurring";
import { CANCELED } from "../../../services/taskDetail/block.service";

function RecurringPopup({ recurringPopupRef }) {
  const [popup, setPopup] = useRecoilState(recurringPopupState);

  const firstOptionRef = useRef(null);
  const secondOptionRef = useRef(null);
  const thirdOptionRef = useRef(null);
  const closeButtonRef = useRef(null);
  const okButtonRef = useRef(null);

  useEffect(() => {
    if (popup.isVisible) {
      setPopup((prev) => ({
        ...prev,
        messageType: popup.isCurrentOptionEnabled
          ? RecurringOption.THIS
          : RecurringOption.THISANDAFTER,
      }));
    }
  }, [popup.isVisible, popup.isCurrentOptionEnabled]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      if (key === "ArrowRight" || key === "ArrowLeft") {
        event.preventDefault();
        const focusableElements = [
          ...(popup.isCurrentOptionEnabled ? [firstOptionRef.current] : []),
          secondOptionRef.current,
          ...(popup.isAllEnabled ? [thirdOptionRef.current] : []),
          closeButtonRef.current,
          okButtonRef.current,
        ];

        const currentFocusedIndex = focusableElements.indexOf(document.activeElement);
        const nextIndex =
          key === "ArrowRight"
            ? (currentFocusedIndex + 1) % focusableElements.length
            : (currentFocusedIndex - 1 + focusableElements.length) % focusableElements.length;
        focusableElements[nextIndex].focus();
      } else if (key === "Enter") {
        document.activeElement.click();
      } else if (key === "Escape") {
        handleClose(event);
      }
    };

    if (popup.isVisible) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [popup.isVisible, popup.isAllEnabled, popup.isCurrentOptionEnabled]);

  if (!popup.isVisible) return null;

  const closePopup = (clickType, result) => {
    setPopup((prev) => ({
      ...prev,
      messageType: RecurringOption.THIS,
      isVisible: false,
      clickType,
    }));

    popup.resolve(result); // Promise resolve
  };

  const handleClose = (event) => {
    event.stopPropagation();
    closePopup("cancel", CANCELED);
    setPopup((prev) => ({ ...prev, messageType: "cancel" }));
  };

  const handleOk = (event) => {
    event.stopPropagation();
    closePopup("ok", popup.messageType); // resolve with selected option
  };

  const handleOptionChange = (label) => {
    const option = recurringOptions.find((opt) => opt.label === label);
    const optionValue = option ? option.value : "";

    setPopup((prev) => ({ ...prev, messageType: optionValue }));
  };

  return ReactDOM.createPortal(
    <FocusTrap>
      <div
        className={styles.modalContainer}
        ref={recurringPopupRef}
      >
        <div className={styles.modalHeader}>
          <div
            className={clsx(styles.headerTitle, {
              [styles.deleteHeaderTitle]: popup.type === "delete",
            })}
          >
            {popup.headerTitle}
          </div>
          <div
            className={styles.headerClose}
            onClick={handleClose}
          >
            <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
          </div>
        </div>
        <div className={styles.repeatMessageConatiner}>
          {/* 상수 배열을 사용하여 조건부 렌더링 */}
          {recurringOptions.map((option) => {
            // NOTE 반복 옵션 표시 여부 결정, 조건에 맞지 않으면 null 반환
            if (option.value === RecurringOption.THIS && !popup.isCurrentOptionEnabled) return null;
            if (
              option.value === RecurringOption.ALL &&
              !(popup.isAllEnabled || popup.type === "delete")
            )
              return null;

            // NOTE 옵션 값에 따라 ref 매핑
            const refMap = {
              [RecurringOption.THIS]: firstOptionRef,
              [RecurringOption.THISANDAFTER]: secondOptionRef,
              [RecurringOption.ALL]: thirdOptionRef,
            };
            const optionRef = refMap[option.value] || null;

            return (
              <div
                key={option.value}
                className={styles.radioContainer}
                ref={optionRef}
              >
                <RadioBtn
                  onClick={handleOptionChange}
                  name="recurringOption"
                  label={recurringOptionDisplayMap[option.value]}
                  checked={popup.messageType === option.value}
                />
              </div>
            );
          })}
        </div>

        <div className={styles.buttonArea}>
          <div className={styles.buttonLeftArea}></div>
          <div className={styles.buttonRightArea}>
            <button
              className={styles.modalCloseButton}
              onClick={handleClose}
              ref={closeButtonRef}
            >
              {popup.closeButtonTitle}
            </button>
            <button
              className={styles.modalOkButton}
              onClick={handleOk}
              ref={okButtonRef}
            >
              {popup.okButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.getElementById("popup-root")
  );
}

export default RecurringPopup;
