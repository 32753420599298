import React, { useState } from "react";
import { RSVPButtonPopover } from "./RSVPButtonPopover";

import { RSVPButton } from "./RSVPButton";
import { AttendResponseType } from "../../../types/block/enum";

interface RSVPMenuProps {
  currentValue: AttendResponseType;
  onChangeValue: (rsvpResponse: AttendResponseType) => void;
}

export const RSVPMenu: React.FC<RSVPMenuProps> = ({ currentValue, onChangeValue }) => {
  const [isRSVPOpen, setIsRSVPOpen] = useState(false);

  const handleRSVPOpen = () => setIsRSVPOpen(true);
  const handleRSVPClose = () => setIsRSVPOpen(false);

  return (
    <RSVPButtonPopover
      menuVisible={isRSVPOpen}
      onMenuClose={handleRSVPClose}
      currentValue={currentValue}
      onChange={onChangeValue}
      onClickButton={handleRSVPOpen}
    >
      <RSVPButton menuVisible={isRSVPOpen} onClick={handleRSVPOpen} currentValue={currentValue} />
    </RSVPButtonPopover>
  );
};
