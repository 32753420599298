import { atom, selector } from "recoil";
import { BlockPopupState } from "../../types/block/BlockType";

// TODO: 이름 block 관련으로 바꿔야 함
// - 그런데 이건 block data 이외에 다른 많은 정보를 가지고 있음
export const taskPopupState = atom<BlockPopupState>({
  key: "taskPopupState",
  default: {
    isVisible: false,
    data: null,
    modalPosition: { x: 0, y: 0 },
    targetRect: null,
    onClose: null,
    // TODO: isNewBlock reference들 없어지면 삭제
    isNewBlock: false,
    isDuplicateBlock: false,
    handleDataDuplicate: null,
    type: "", // inbox or calendar,
    handleEventDelete: null,
    handleEventChange: null,
    modalHeight: undefined,
  },
});

export const taskPopupVisibleSelector = selector({
  key: "taskPopupVisibleSelector",
  get: ({ get }) => {
    const taskPopup = get(taskPopupState);
    return taskPopup.isVisible;
  },
});
