import { BlockType } from "../../types/block/type";

export const organizeBlocksByDate = (blocks: BlockType[]): { [key: string]: BlockType[] } => {
  const blocksByDate: { [key: string]: BlockType[] } = {};

  blocks.forEach((block) => {
    if (!block.start || !block.end) return;
    const isAllDay = Boolean(block?.allDay);

    const startDateTime = new Date(block.start);
    const endDateTime = new Date(block.end);

    // startDateTime을 KST 기준으로 날짜를 추출 (타임존 반영된 Date 객체 사용)
    const startDay = startDateTime.getDate();
    const startMonth = startDateTime.getMonth();
    const startYear = startDateTime.getFullYear();

    if (!isAllDay) {
      // 하루 이내 이벤트
      const dateKey = `${startYear}-${String(startMonth + 1).padStart(2, "0")}-${String(startDay).padStart(2, "0")}`;

      if (!blocksByDate[dateKey]) {
        blocksByDate[dateKey] = [];
      }
      blocksByDate[dateKey].push({ ...block });
    } else {
      // 종일 또는 여러 날에 걸친 이벤트
      let currentDate = new Date(startDateTime);

      while (currentDate < endDateTime) {
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const dateKey = `${currentYear}-${String(currentMonth + 1).padStart(2, "0")}-${String(currentDay).padStart(2, "0")}`;

        if (!blocksByDate[dateKey]) {
          blocksByDate[dateKey] = [];
        }
        blocksByDate[dateKey].push({ ...block });

        // 날짜를 하루씩 증가
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
  });

  return blocksByDate;
};

// 유틸리티 함수: 이벤트 정렬
export const sortBlocksByStartTime = (events: {
  [key: string]: BlockType[];
}): { [key: string]: BlockType[] } => {
  const sortedEvents = { ...events };

  Object.keys(sortedEvents).forEach((date) => {
    sortedEvents[date].sort((a, b) => {
      const aTime = new Date(String(a.start)).getTime();
      const bTime = new Date(String(b.start)).getTime();
      return aTime - bTime;
    });
  });

  return sortedEvents;
};
