import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../../utils/styles/size";
import { useStandardDateTimeToTime } from "../../../../hooks/time-formatting/useStandardDateTimeToTime";
import { standardDateTimeToDate } from "../../../../utils/date-format/standardDateTimeToDate";

interface InboxBlockDateTimeProps {
  isOverdue: boolean;
  isTodayGeneral: boolean;
  isAllday: boolean;
  startDateTime: string;
  endDateTime: string;
}
const InboxBlockDateTime = ({
  isOverdue,
  isTodayGeneral,
  isAllday,
  startDateTime,
  endDateTime,
}: InboxBlockDateTimeProps) => {
  const standardDateTimeToTime = useStandardDateTimeToTime();

  const calcDisplayDate = (startDateTime: string, endDateTime: string) => {
    const startDate = standardDateTimeToDate(startDateTime);
    const endDate = standardDateTimeToDate(endDateTime);

    return startDate !== endDate
      ? `${startDate} - ${endDate}` // 날짜가 다르면 날짜 범위 표시
      : startDate;
  };

  const renderDateTime = () => {
    if (isTodayGeneral) {
      // NOTE Today General에서만 시간 표시
      if (isAllday) return;

      return standardDateTimeToTime(startDateTime);
    }

    // 나머지는 allday 포함 모든 날짜 있는 블록은 날짜 표시
    return calcDisplayDate(startDateTime, endDateTime);
  };

  return (
    <Box
      padding={"0 2px"}
      flexShrink={0}
      alignSelf={"flex-start"}
      justifyItems={"center"}
      width={"max-content"}
    >
      <Flex
        backgroundColor={"rgba(0, 0, 0, 0.2)"}
        borderRadius={"3px"}
        px={pxToRem(2)}
        h={pxToRem(14)}
      >
        <Text
          color={isOverdue ? "#FF6464" : "#abadad"}
          fontSize={pxToRem(10)}
          fontStyle="normal"
          fontWeight={400}
          lineHeight="140%"
        >
          {renderDateTime()}
        </Text>
      </Flex>
    </Box>
  );
};

export default InboxBlockDateTime;
