import { useRecoilValue } from "recoil";
import styles from "./TrashPopup.module.css";
import iconForVisibility from "../../../utils/taskDetail/visibility/iconForVisibility";
import trashRestore from "../../../assets/Trash/trash-restore.png";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { getSpaceColor } from "../../../services/space/space.service";
import { BlockType } from "../../../types/block/type";
import { ItemStatus } from "../../../types/block/enum";

interface TrashTaskRowProps {
  index: number; // 사용되지 않고 있음, 실제 caller에서 들어오는지 확인 필요
  data: BlockType;
  onDeleteClick: (id: number) => void;
  onRestoreClick: (blockData: BlockType) => void;
}

export const TrashTaskRow: React.FC<TrashTaskRowProps> = ({
  data,
  onDeleteClick,
  onRestoreClick,
}) => {
  const projectList = useRecoilValue(inProgressSpaceListState);
  const isCompleted = data.itemLastStatus === ItemStatus.COMPLETED;
  const spaceId = typeof data.spaceId === "number" ? data.spaceId : null;

  const handleDeleteClick = (): void => {
    onDeleteClick(data.id);
  };

  const handleRestoreClick = (): void => {
    onRestoreClick(data);
  };

  return (
    <div className={styles.project__item}>
      <div
        className={`${styles.taskBoxBodyIcon} ${isCompleted ? styles.taskListBodyIconDone : ""}`}
        style={{
          backgroundColor: `${getSpaceColor(projectList, spaceId)}${isCompleted ? "" : "26"}`,
          boxShadow: `0 0 0 2px ${getSpaceColor(projectList, spaceId)} inset`,
        }}
      ></div>
      <div className={styles.taskListBodyTitle}>{data.title}</div>
      {data.visibility && (
        <div className={styles.visibilityIcon}>
          {iconForVisibility(data.visibility, data.transparency)}
        </div>
      )}

      <div
        className={styles.project__delete}
        onClick={handleRestoreClick}
      >
        <img
          className={styles.project__delete__img}
          src={trashRestore}
          alt="restore"
        />
      </div>

      <div
        className={styles.project__delete}
        onClick={handleDeleteClick}
      >
        <img
          className={styles.project__delete}
          src={trashDeleteImg}
          alt="delete"
        />
      </div>
    </div>
  );
};
