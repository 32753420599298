import {
  Analytics,
  AnalyticsBrowser,
  Callback,
  EventProperties,
  SegmentEvent,
} from "@june-so/analytics-next";

const analytics: AnalyticsBrowser = AnalyticsBrowser.load({
  writeKey: "H7kWfAp9CYMNfnTV",
});

// NOTE: 훅으로 사용할 필요없어서 대체하려고 하는데, 연결된 reference들이 많아서 일단 아래 추가 선언 하나 더 해둠, 다음 scope에 정리
// analytics.track(event, [properties], [options], [callback]);
// https://www.june.so/docs/tracking/web/react
export function useJuneTrackCall() {
  // full feature를 사용하기 위해서는 아래처럼 들어가는 것이 좀 더 이상적이겠지만, ASIS code와의 consistency를 유지하기로 결정
  // return (...args: Parameters<Analytics["track"]>) => {
  //   analytics.track(...args);
  // };

  return (
    eventName: string | SegmentEvent,
    eventProperties?: EventProperties | Callback | undefined
  ) => {
    analytics.track(eventName, eventProperties);
  };
}

export function juneTrack(...args: Parameters<Analytics["track"]>) {
  return analytics.track(...args);
}

export function juneIdentify(email: string, subscriptionStatus: string) {
  // @brandonwie
  // Q. email은 있지만 subscriptionStatus가 empty string인 경우 기록할 필요가 없는지?
  // WHY? `subscriptionStatus`가 empty string인 경우에는 아래 condition을 통과하지 않기 때문
  if (email && subscriptionStatus) {
    analytics.identify(email, {
      email: email,
      // custom key
      subscription_status: subscriptionStatus,
    });
  }
}
