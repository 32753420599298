import { useMutation, useQueryClient } from "@tanstack/react-query";
import { calendarQueryKeys } from "../calendar/queries";
import { BlockType, UpdateBlockType } from "../../types/block/type";
import { useUpdateCalendarBlockMutationOptions } from "../calendar/mutations";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { inboxQueryKeys } from "../inbox/queries";

// useUpdateTimeCalendarBlockMutation이랑 거의 유사한 동작 => 추후 합칠 수 있을 듯
// 차이점은 taskDetail에서는 event -> task, task -> event 로 변경되는 경우가 있어서 추가적으로 처리
export const useSelectBlockUpdateMutation = (handleClose: () => {}) => {
  const queryClient = useQueryClient();
  const { findUpdateInboxQueryKey, isSameInboxQueryKey } = useFindUpdateInboxQueryKey();

  const updateCalendarBlockMutate = useMutation({
    ...useUpdateCalendarBlockMutationOptions(),
    onMutate: async ({ payload, prevData }) => {
      handleClose();

      // 쿼리키 찾아서 같은지 비교
      const updateData = { ...prevData, ...payload };
      const prevInboxQueryKey = findUpdateInboxQueryKey(prevData);
      const updateInboxQueryKey = findUpdateInboxQueryKey(updateData);

      // inbox에 해당하는 쿼리키가 없으면 === TASK 블록이 아니면 리턴
      if (!prevInboxQueryKey && !updateInboxQueryKey) {
        return;
      }

      const isSame = isSameInboxQueryKey(prevInboxQueryKey, updateInboxQueryKey);
      // 같으면 updateData 업데이트
      if (isSame && updateInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, (oldData) => {
          if (!oldData) return [updateData];
          return oldData.map((block) => (block.id === updateData.id ? updateData : block));
        });
        return;
      }

      // 다르면 이전 쿼리키에서는 삭제, 변경된 쿼리키에는 추가
      // 기존 inbox영역 낙관적 업데이트(블록 제거)
      if (prevInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: prevInboxQueryKey });
        queryClient.setQueryData<BlockType[]>(prevInboxQueryKey, (oldData) => {
          if (!oldData) return [];
          return oldData.filter((block) => block.id !== prevData.id);
        });
      }

      // 이동된 inbox영역 낙관적 업데이트(블록 추가)
      if (updateInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, (oldData) => {
          if (!oldData) return [updateData];
          return [updateData, ...oldData];
        });
      }
    },
    onSuccess: (data, variables, context) => {
      // 반복인 경우에만 invalidate 처리(calendar, inbox 전체)
      const isRecurringBlock =
        (variables.prevData.recurrence && variables.prevData.recurrence?.length > 0) ||
        Boolean(variables.prevData.originalId);

      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      }
    },
    onError: (error, variables, context) => {
      // invalidate 처리(calendar, inbox 전체)
      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      throw error;
    },
  });

  const selectAndCallProperApi = async (
    id: number,
    payload: UpdateBlockType,
    prevData: BlockType
  ): Promise<any> => {
    const result = await updateCalendarBlockMutate.mutateAsync({
      id: id,
      payload,
      prevData: prevData,
    });

    return result;
  };

  return { selectAndCallProperApi };
};
