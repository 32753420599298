import { atom } from "recoil";
import { localStorageEffect } from "../../utils/localStorage/localStorageEffect";
import { loadFromLocalStorage } from "../../utils/localStorage/localStorage";

const getInitialTokenState = () => {
  const savedTokenState = loadFromLocalStorage("tokenState");
  if (savedTokenState) {
    return savedTokenState;
  } else {
    return {
      mAccessToken: null,
      mRefreshToken: null,
      accessToken: null,
    };
  }
};

export interface TokenState {
  mAccessToken: string | null;
  mRefreshToken: string | null;
  accessToken: string | null;
}

export const tokenState = atom<TokenState>({
  key: "tokenState",
  default: getInitialTokenState(),
  effects: [localStorageEffect("tokenState")],
});
