import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUpdateCalendarBlockMutationOptions } from "./mutations";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";
import { BlockType } from "../../types/block/type";
import { calendarQueryKeys } from "./queries";
import { inboxQueryKeys } from "../inbox/queries";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../recoil/toast/toastState";
import { juneTrack } from "../../utils/june/analytics";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";

export const useDeleteCalendarBlockMutation = ({ location }: { location: string }) => {
  const queryClient = useQueryClient();
  const { findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();
  const setToast = useSetRecoilState(toastState);

  return useMutation({
    ...useUpdateCalendarBlockMutationOptions(),
    onMutate: async ({ id, payload, prevData }) => {
      const updateInboxQueryKey = findUpdateInboxQueryKey(prevData);

      if (updateInboxQueryKey) {
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        const previousData = queryClient.getQueryData<BlockType[]>(updateInboxQueryKey);
        if (previousData) {
          queryClient.setQueryData(
            updateInboxQueryKey,
            previousData.filter((block) => block.id !== prevData.id)
          );
        }
        return { previousData, updateInboxQueryKey };
      }
    },
    onSuccess: (data, variables, context) => {
      setToast({
        type: "Delete",
        isVisible: true,
        message: "Event has been deleted",
      });

      // 반복인 경우에만 invalidate 처리(inbox 전체)
      const isRecurringBlock =
        (variables.prevData.recurrence && variables.prevData.recurrence?.length > 0) ||
        Boolean(variables.prevData.originalId);

      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
      }
    },
    onError: (err, variables, context) => {
      setToast({
        type: "Error",
        isVisible: true,
        message: "Failed to delete. Please retry",
      });
      if (context?.previousData) {
        queryClient.setQueryData(context.updateInboxQueryKey, context.previousData);
      }
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
    },
  });
};
