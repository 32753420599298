import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKeys } from "../queries";
import { useRecoilState } from "recoil";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { SpaceIdType } from "../../../types/space";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { SpaceStatus } from "../../../types/space/enum";

export const restoreTrashSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: SpaceIdType
): Promise<void> => {
  await api.patch(`/spaces/${projectId}`, {
    itemStatus: SpaceStatus.InProgress,
  });
};

export const useRestoreTrashedSpaceMutation = (
  options?: UseMutationOptions<void, AxiosError, SpaceIdType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);
  const [projectList, setProjectList] = useRecoilState(inProgressSpaceListState);
  const trackCall = useJuneTrackCall();

  return useMutation<void, AxiosError, SpaceIdType>({
    mutationFn: (projectId: SpaceIdType) => restoreTrashSpaceApi(api, projectId),
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });

      const updatedSpaces = selectedSpaces ? [...selectedSpaces, projectId] : [projectId];
      setSelectedSpaces(updatedSpaces);

      if (projectList) {
        const updatedProjectList = projectList.map((project) =>
          project.id === projectId ? { ...project, status: SpaceStatus.InProgress } : project
        );
        setProjectList(updatedProjectList);
      }

      return { projectId, previousSelectedSpaces: selectedSpaces };
    },

    onSuccess: () => {
      trackCall("restore_space", { type: "deleted_items" });
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.all });
    },
    ...options,
  });
};
