import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import { getMonthStartAndEndAsISO } from "../utils/date-format/dateUtils";
import { calendarTotalMonthState } from "../recoil/calendar/calendarStateV2";
import blockAPI from "../api/BlockAPI";
import useApi from "../services/auth/useApi";
import { calendarQueryKeys } from "../react-query/calendar/queries";
import { BlockRequestQueryTypeParam, Operation } from "../types/block/enum";

/**
 * @param {object | undefined} props
 * @param {boolean | undefined} props.enabled - 쿼리 실행 여부
 */
const useFetchCalendarEvents = ({ enabled = true }) => {
  const api = useApi();
  const calendarTotalMonth = useRecoilValue(calendarTotalMonthState);
  const queryClient = useQueryClient();

  const invalidateCalendarEvents = async () => {
    await queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
  };
  const combineDataWithoutDuplicates = (results) => {
    const map = new Map();

    results?.forEach((result) => {
      if (result.status === "success") {
        result?.data?.forEach((item) => {
          map.set(item.id, item);
        });
      }
    });
    return Array.from(map.values());
  };

  const result = useQueries({
    queries: calendarTotalMonth?.map((month) => {
      const { start, end } = getMonthStartAndEndAsISO(month);
      const params = {
        queryType: BlockRequestQueryTypeParam.CALENDAR,
        start: start,
        end: end,
        startOp: Operation.LESS_THAN,
        endOp: Operation.GREATER_THAN,
      };
      return {
        queryKey: calendarQueryKeys.yearAndMonth(month),
        queryFn: () => blockAPI.getBlocks(api, params).then((res) => res.data),
        retry: 1,
        staleTime: 5 * 60 * 1000, // 5분
        gcTime: Infinity,
        refetchOnWindowFocus: true,
        enabled,
      };
    }),
    combine: (results) => {
      const allSuccessful = results.every((result) => result.status === "success");
      const combinedData = allSuccessful ? combineDataWithoutDuplicates(results) : [];
      return {
        data: combinedData,
        pending: results.some((result) => result.isLoading),
      };
    },
  });

  return { result, invalidateCalendarEvents };
};

export default useFetchCalendarEvents;
