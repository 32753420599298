import { Flex } from "@chakra-ui/react";
import InboxHeaderTabButton from "./InboxHeaderTabButton";
import InboxHeaderFilterButton from "./InboxHeaderFilterButton";

import { pxToRem } from "../../../utils/styles/size";
import { HandleTabToggleVisibility } from "..";
import { InboxHeaderTab, ToggleVisibilityByTab } from "../../../types/inbox/inbox-headers";
import {
  useInboxOverdueQueryOptions,
  useInboxPlannedQueryOptions,
  useTodayGeneralQueryOptions,
  useInboxGeneralQueryOptions,
} from "../../../react-query/inbox/queries";
import { useQuery } from "@tanstack/react-query";
import { BasicBlock } from "../../../types/block/enum";
import { useMemo } from "react";

const useInboxQueries = ({
  hasBlocksSynchronizedWithGoogle,
}: {
  /** 백엔드가 보내는 block들과 Google Calendar 동기화 여부 */
  hasBlocksSynchronizedWithGoogle: boolean;
}) => {
  const inboxGeneralQuery = useQuery({
    ...useInboxGeneralQueryOptions({}),
    // NOTE: InboxGeneral은 gcal sync의 영향을 받지 않음
  });

  const inboxPlannedQuery = useQuery({
    ...useInboxPlannedQueryOptions(),
    enabled: hasBlocksSynchronizedWithGoogle,
  });

  const inboxOverdueQuery = useQuery({
    ...useInboxOverdueQueryOptions(),
    enabled: hasBlocksSynchronizedWithGoogle,
  });

  const todayGeneralQuery = useQuery({
    ...useTodayGeneralQueryOptions(),
    enabled: hasBlocksSynchronizedWithGoogle,
  });

  return {
    inboxGeneralQuery,
    inboxPlannedQuery,
    inboxOverdueQuery,
    todayGeneralQuery,
  };
};

interface InboxHeaderProps {
  handleChangeActiveTab: (event: React.MouseEvent<HTMLButtonElement>) => void;
  activeTab: InboxHeaderTab;
  onToggleVisibility: HandleTabToggleVisibility;
  toggleVisibility: ToggleVisibilityByTab;
  /** 백엔드가 보내는 block들과 Google Calendar 동기화 여부 */
  hasBlocksSynchronizedWithGoogle: boolean;
}

const InboxHeader = ({
  handleChangeActiveTab,
  activeTab,
  onToggleVisibility,
  toggleVisibility,
  hasBlocksSynchronizedWithGoogle,
}: InboxHeaderProps) => {
  const { inboxGeneralQuery, inboxPlannedQuery, inboxOverdueQuery, todayGeneralQuery } =
    useInboxQueries({ hasBlocksSynchronizedWithGoogle });

  const inboxTotalCount = useMemo(() => {
    const inboxGeneralTaskBlocks = inboxGeneralQuery.data?.filter(
      (task) => task.blockType === BasicBlock.TASK
    );
    let totalInboxCount = inboxGeneralTaskBlocks?.length ?? 0;
    totalInboxCount += inboxPlannedQuery.data?.length ?? 0;
    totalInboxCount += inboxOverdueQuery.data?.length ?? 0;
    return totalInboxCount;
  }, [inboxGeneralQuery.data, inboxPlannedQuery.data, inboxOverdueQuery.data]);

  const todayTotalCount = useMemo(() => {
    let totalTodayCount = todayGeneralQuery.data?.length ?? 0;
    totalTodayCount += inboxOverdueQuery.data?.length ?? 0;
    return totalTodayCount;
  }, [todayGeneralQuery.data, inboxOverdueQuery.data]);

  return (
    <Flex
      w={"full"}
      h={pxToRem(50)}
      padding={"0 10px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      flexShrink={0}
    >
      <Flex gap={pxToRem(10)}>
        <InboxHeaderTabButton
          title="Inbox"
          count={inboxTotalCount}
          onClick={handleChangeActiveTab}
          activeTab={activeTab}
        />
        <InboxHeaderTabButton
          title="Today"
          count={todayTotalCount}
          onClick={handleChangeActiveTab}
          activeTab={activeTab}
        />
      </Flex>

      <InboxHeaderFilterButton
        activeTab={activeTab}
        onToggleVisibility={onToggleVisibility}
        toggleVisibility={toggleVisibility}
      />
    </Flex>
  );
};
export default InboxHeader;
