export default function findAttendByEmail(dataArray, targetEmail) {
  const foundUser = dataArray?.find((user) => user?.email === targetEmail);
  if (foundUser) {
    return {
      name: foundUser.name,
      imgUrl: foundUser.photoUrl,
    };
  } else {
    return {
      name: targetEmail,
      imgUrl: null,
    };
  }
}
