import { createIcon } from "@chakra-ui/react";

export const InboxAddIcon = createIcon({
  displayName: "InboxAddIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.5H4V7.5H12V8.5Z"
        fill="#7C7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 12L7.5 4L8.5 4L8.5 12L7.5 12Z"
        fill={"currentColor"}
      />
    </>
  ),
});

export const TimeBlockCheckIcon = createIcon({
  displayName: "TimeBlockCheckIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      {/* TODO 추후 사용 시 fill "currentColor로 변경" */}
      <rect
        x="0.5"
        y="0.5"
        width="16"
        height="16"
        rx="2.5"
        fill="#667EFF"
        fill-opacity="0.2"
      />
      <rect
        x="0.5"
        y="0.5"
        width="16"
        height="16"
        rx="2.5"
        stroke="#667EFF"
      />
    </>
  ),
});

export const TimeBlockCheckIconDone = createIcon({
  displayName: "TimeBlockCheckIconDone",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="check">
        {/* TODO 추후 사용 시 fill "currentColor로 변경" */}
        <path
          id="Vector 1543 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7072 5.70718L7.00008 12.4143L2.79297 8.20718L4.20718 6.79297L7.00008 9.58586L12.293 4.29297L13.7072 5.70718Z"
          fill="#1A1C1C"
        />
      </g>
    </>
  ),
});

export const ToggleIcon = createIcon({
  displayName: "ToggleIcon",
  viewBox: "0 0 12 12",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23484 9L4.70451 8.46967L7.43934 5.73483L4.70451 3L5.23484 2.46967L8.5 5.73483L5.23484 9Z"
      fill="#ABADAD"
    />
  ),
});
