import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import {
  RequestPayloadCreateSpace,
  RequestPayloadDeleteSpace,
  RequestPayloadUpdateSpace,
  RequestPayloadUpdateSpaceOrder,
  SpaceRequests,
} from "./requests";
import { SpaceType } from "../../types/space";
import { ResponseError } from "../../api/types";
import { spaceQueryKeys } from "./queries";
import { SpaceStatus } from "../../types/space/enum";

interface SpaceMutationOptions<
  /** Response data type */
  TData = unknown,
  TError = ResponseError,
  /** Mutation에 넘겨지는 값 */
  TVariables = void,
  /** Context로 전달되는 값 */
  TContext = unknown,
> {
  /** axios request config */
  config?: AxiosRequestConfig;
  /** `mutationFn`과 `onMutate`를 제외한 mutation 옵션 */
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn" | "onMutate"
  >;
}

/**
 * Space 생성을 위한 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 새로운 Space 생성
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정 * @returns Space 생성을 위한 React Query mutation hook
 */
export const useCreateSpaceMutation = ({
  config,
  options,
}: SpaceMutationOptions<SpaceType, ResponseError, RequestPayloadCreateSpace["data"]>) => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await SpaceRequests.createSpace({ data, config });
      return response.data;
    },
    ...options,
  });
};

/**
 * Space 수정을 위한 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 기존 Space 수정
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns Space 수정을 위한 React Query mutation hook
 */
export const useUpdateSpaceMutation = ({
  config,
  options,
}: SpaceMutationOptions<
  SpaceType,
  ResponseError,
  { id: RequestPayloadUpdateSpace["id"]; data: RequestPayloadUpdateSpace["data"] }
>) => {
  return useMutation({
    mutationFn: async ({ id, data }) => {
      const response = await SpaceRequests.updateSpaceById({ id, data, config });
      return response.data;
    },
    ...options,
  });
};

/**
 * Space 삭제를 위한 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 Space 삭제
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns Space 삭제를 위한 React Query mutation hook
 */
export const useDeleteSpaceMutation = ({
  config,
  options,
}: SpaceMutationOptions<void, ResponseError, RequestPayloadDeleteSpace["id"]>) => {
  return useMutation({
    mutationFn: async (id) => {
      const response = await SpaceRequests.deleteSpaceById({ id, config });
      return response.data;
    },

    ...options,
  });
};

/**
 * Space 순서 변경을 위한 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 Space 목록의 순서 변경
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns Space 순서 변경을 위한 React Query mutation hook
 */
export const useUpdateSpaceOrderMutation = ({
  config,
  options,
}: SpaceMutationOptions<void, ResponseError, RequestPayloadUpdateSpaceOrder["data"]>) => {
  return useMutation({
    mutationFn: async (data) => {
      await SpaceRequests.updateSpaceOrder({
        data,
        config,
      });
      return;
    },
    ...options,
  });
};

/**
 * 다중 Space 삭제를 위한 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 여러 Space를 삭제
 * - Promise.allSettled를 사용하여 각 삭제 작업의 성공/실패를 추적
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns 다중 Space 삭제를 위한 React Query mutation hook
 */
export const useDeleteSpacesMutation = ({
  config,
  options,
}: SpaceMutationOptions<
  { successIds: number[]; failedIds: number[] },
  ResponseError,
  number[],
  { previousDeletedSpaces: SpaceType[] } // 롤백을 위해 이전 삭제된 spaces 데이터 반환용
>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids) => {
      const successIds: number[] = [];
      const failedIds: number[] = [];

      for (const id of ids) {
        try {
          await SpaceRequests.deleteSpaceById({ id, config });
          successIds.push(id);
        } catch (error) {
          failedIds.push(id);
        }
      }

      return { successIds, failedIds };
    },
    onMutate: async (ids) => {
      await queryClient.cancelQueries({
        queryKey: spaceQueryKeys.getAllByParams({ itemStatus: SpaceStatus.Deleted }),
      });

      let previousDeletedSpaces: SpaceType[] = [];

      queryClient.setQueryData(
        spaceQueryKeys.getAllByParams({ itemStatus: SpaceStatus.Deleted }),
        (oldData?: SpaceType[]) => {
          if (!oldData) return [];
          previousDeletedSpaces = oldData;
          return oldData.filter((space) => !ids.includes(space.id));
        }
      );

      return { previousDeletedSpaces };
    },
    ...options,
  });
};
