import { ItemStatus } from "../../types/block/enum";
import { expandRecurringEvent } from "./convertRruletoDates";

// 이벤트를 유형별로 분류하는 함수
export const categorizeEventsByRecurrence = (events) => {
  return {
    // 업데이트된 반복 이벤트(반복 이벤트 중 this로 수정된 이벤트)
    // this로 수정된 이벤트의 originalId는 원블록의 id와 같음
    recurrenceUpdatedBlocks: events.filter((block) => block.originalId && !block.recurrence),

    // 기본 반복 이벤트(가상 반복 블록을 만들어야하는 케이스)
    recurrenceBlocks: events.filter((block) => block.recurrence),

    // 일반 이벤트
    normalBlocks: events.filter(
      (block) => !block.recurrence && !block.originalId && block.itemStatus !== ItemStatus.DELETED
    ),
  };
};

// 블록 매칭 조건 확인
const isMatchingRecurringInstanceBlock = (instance, updatedBlocks) => {
  // this로 수정한 경우에는 originalId가 원블록의 id인 경우도 있고, 진짜 모체의 id인 경우도 있음
  const convertedInstanceId = Number(instance.id.toString().split("_")[0]);
  const isSameId =
    instance.originalId === updatedBlocks.originalId ||
    convertedInstanceId === updatedBlocks.originalId;
  const isSameStartTime =
    new Date(instance.start).getTime() === new Date(updatedBlocks.originalStart).getTime();

  return instance.originalId && isSameId && isSameStartTime;
};

// 업데이트된 블록과 매칭
const findMatchingUpdatedBlock = (instance, updatedBlocks) => {
  return updatedBlocks.find((block) => isMatchingRecurringInstanceBlock(instance, block));
};

// 캘린더 반복 블록 관리 함수
export const manageCalendarRecurringEvents = (
  recurrenceVirtualBlocks,
  recurrenceUpdatedBlocks,
  recurringEnd
) => {
  const expandedRecurrenceBlocks = recurrenceVirtualBlocks.flatMap((block) =>
    expandRecurringEvent(block, block.start, recurringEnd)
  );
  const processedBlocks = expandedRecurrenceBlocks
    .map((instance) => {
      const matchingUpdate = findMatchingUpdatedBlock(instance, recurrenceUpdatedBlocks);
      return matchingUpdate
        ? {
            ...matchingUpdate,
            originalId: instance.originalId, // 진짜 모체의 id로 적용(all처리 시 사용)
            recurrence: instance.recurrence,
          }
        : instance;
    })
    .filter((event) => event.itemStatus !== ItemStatus.DELETED);

  return processedBlocks;
};

// inbox 반복 공통 로직을 처리하는 기본 함수
export const processInboxRecurringEvents = (
  recurrenceVirtualBlocks,
  recurrenceUpdatedBlocks,
  recurringStart = "",
  recurringEnd
) => {
  const expandedRecurrenceBlocks = recurrenceVirtualBlocks.map((block) => {
    const filteredExpandedRecurrenceBlocks = expandRecurringEvent(
      block,
      recurringStart ? recurringStart : block.start,
      recurringEnd
    )
      .map((instance) => {
        const matchingUpdate = findMatchingUpdatedBlock(instance, recurrenceUpdatedBlocks);
        return matchingUpdate
          ? {
              ...matchingUpdate,
              recurrence: instance.recurrence,
            }
          : instance;
      })
      .filter((block) => block.itemStatus === ItemStatus.IN_PROGRESS);

    return filteredExpandedRecurrenceBlocks;
  });

  return expandedRecurrenceBlocks.flat().filter((block) => block);
};
