import { useEffect, useState, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { ProjectInfo } from "./Project/ProjectInfo";
import { TimeLine } from "./Timeline/TimelineSection/TimelineSection";
import { createSpacePayload } from "../../services/space/space.service";
import { useCreateSpaceMutation } from "../../react-query/space/core/useCreateSpaceMutation";
import { inProgressSpaceListState } from "../../recoil/spaces/inProgressSpaceListState";
import {
  selectedSpaceIdListState,
  selectedSpaceIdListStateSelector,
} from "../../recoil/spaces/selectedSpaceIdListState";
import styles from "./ProjectCreate.module.css";
import projectBackIcon from "../../assets/Wiki/project-back-icon.png";
import { useQuery } from "@tanstack/react-query";
import { useGetSpaceByIdQueryOptions } from "../../react-query/space/queries";
import { DEFAULT_SPACE_COLOR } from "../../constants/space";

export default function WikiDetail({ onDismissClick, selectedSpaceId }) {
  const [spaceDetailInfo, setSpaceDetailInfo] = useState(null);
  const [dotColor, setDotColor] = useState("");
  const [calendarTaskEvent, setCalendarTaskEvent] = useState(null);

  // NOTE 임시로 사용하는 변수, 첫 렌더링 시 새 프로젝트 생성 여부 확인
  const initializedRef = useRef(false);

  const inProgressSpaceList = useRecoilValue(inProgressSpaceListState);
  const selectedInProgressSpaceidList = useRecoilValue(selectedSpaceIdListStateSelector);
  const setSelectedSpaceIdList = useSetRecoilState(selectedSpaceIdListState);

  const queryOptions = useGetSpaceByIdQueryOptions(selectedSpaceId);
  const spaceDetailQuery = useQuery(queryOptions);

  const { mutate: createSpace } = useCreateSpaceMutation();

  const trackCall = useJuneTrackCall();

  const handleAddProject = () => {
    const newItem = createSpacePayload("Untitled Space", inProgressSpaceList);
    createSpace(newItem, {
      onSuccess: (res) => {
        setSelectedSpaceIdList([res.id, ...selectedInProgressSpaceidList]);
        setSpaceDetailInfo(res);
        setDotColor(res.color || DEFAULT_SPACE_COLOR);
        trackCall("create_space", { location: "space_filter" });
      },
    });
  };

  // 첫 렌더링 시 새 프로젝트 생성 (필요한 경우)
  useEffect(() => {
    if (!selectedSpaceId && !initializedRef.current) {
      initializedRef.current = true;
      handleAddProject();
    }
  }, [selectedSpaceId, handleAddProject]);

  // 쿼리 데이터 변경 시 상태 업데이트
  useEffect(() => {
    if (spaceDetailQuery.data && !initializedRef.current) {
      initializedRef.current = true;

      const data = spaceDetailQuery.data;

      setSpaceDetailInfo((prev) => (prev?.id !== data.id ? data : prev));

      if (dotColor !== data.color) {
        setDotColor(data.color || DEFAULT_SPACE_COLOR);
      }

      setCalendarTaskEvent((prev) => (prev !== data.blocks ? data.blocks : prev));

      if (!initializedRef.current) {
        trackCall("view_space_detail");
      }
    }
  }, [spaceDetailQuery.data, dotColor]);

  return (
    <div className={styles.project__create__area}>
      <div
        className={styles.header}
        onClick={onDismissClick}
      >
        <img
          className={styles.header__button}
          src={projectBackIcon}
          alt="Back"
        />
      </div>
      <div className={styles.project__body}>
        {spaceDetailInfo && (
          <ProjectInfo
            key={`spaceInfo-${spaceDetailInfo.id}`}
            data={spaceDetailInfo}
          />
        )}
        {dotColor && calendarTaskEvent && (
          <TimeLine
            key={`timeline-${spaceDetailInfo?.id}`}
            dotColor={dotColor}
            wikiBlockData={calendarTaskEvent}
          />
        )}
      </div>
    </div>
  );
}
