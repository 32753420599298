import { ItemStatus } from "../../../types/block/enum";

export const initialCreatingCalendarBlock = {
  id: 0,
  creator: "",
  createdAt: "",
  updatedAt: "",
  subBlocks: [],
  previewNote: "",
  displayOrder: 0,
  itemStatus: ItemStatus.IN_PROGRESS,
  zone: "",
};
