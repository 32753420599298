import { useState } from "react";
import WikiList from "./WikiList";
import { SpaceType } from "../../types/space";
import WikiDetail from "./WikiDetail";
import { useRecoilState } from "recoil";
import { selectedWikiSpaceState } from "../../recoil/view/viewMode";

// TODO 이름 Wiki로 변경 예정(브랜든 작업 끝나고)
const WikiWrapper = () => {
  const [showCreateProject, setShowCreateProject] = useState<{
    isShow: boolean;
    spaceInfo: SpaceType | null;
  }>({
    isShow: false,
    spaceInfo: null,
  });

  // NOTE: @brandonwie Wiki가 다른 route를 가지고 있지 않으므로 block gcal sync는 여기 적용하지 않음

  const [selectedWikiSpaceId, setSelectedWikiSpaceId] = useRecoilState(selectedWikiSpaceState);

  const handleShowCreateProject = (isShow: boolean, spaceInfo: SpaceType | null = null) => {
    setShowCreateProject({ isShow, spaceInfo });

    const currentSelectedSpaceId = spaceInfo?.id ?? selectedWikiSpaceId;

    if (currentSelectedSpaceId) {
      setSelectedWikiSpaceId(currentSelectedSpaceId);
      return;
    }
  };

  const handleDismiss = () => {
    setSelectedWikiSpaceId(null);
    setShowCreateProject({ isShow: false, spaceInfo: null });
  };

  return !showCreateProject.isShow && !selectedWikiSpaceId ? (
    <WikiList onProjectAddClick={handleShowCreateProject} />
  ) : (
    <WikiDetail
      onDismissClick={handleDismiss}
      selectedSpaceId={showCreateProject.spaceInfo?.id ?? selectedWikiSpaceId}
    />
  );
};

export default WikiWrapper;
