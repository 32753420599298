import PropertyTitle from "../Common/PropertyTitle";
import TaskNote from "./TaskNote";
import { ReactComponent as NoteIcon } from "../../../assets/TaskDetail/note-icon.svg";
import styles from "./NotePreview.module.css";
import { BlockNoteType } from "../../../types/block/type";
import { isNoteEmpty } from "../../../services/taskDetail/taskDetail.service";

interface NotePreviewProps {
  expand: boolean;
  isChangeNote: boolean;
  noteData: BlockNoteType[] | undefined;
  onSave: () => void;
  setIsModalNoteClicked: React.Dispatch<React.SetStateAction<boolean>>;
  localTitle: string;
}

/**
 * NOTE 리팩토링 시 참고사항
 *
 * 노트의 경우 taskDetail 내에서
 * 1. 모달 노트
 *   1-1. 기본 화면에서 노트 미리보기
 *    1) 노트 내용이 없는 경우 (Add a note 버튼)
 *    2) 노트 내용이 있는 경우 (preview Note)
 *   1-2. 노트 모달 full 수정 화면
 * 2. full page 노트
 */

export default function NotePreview({
  expand,
  isChangeNote,
  noteData,
  onSave,
  localTitle,
  setIsModalNoteClicked,
}: NotePreviewProps) {
  const parsedPreviewText =
    !expand && noteData && noteData.length > 0
      ? noteData
          .slice(0, 3)
          .map((item) => item.content?.[0]?.text ?? "")
          .join("\n")
      : "";

  return (
    <>
      {!expand ? (
        <div
          className={styles["property--note"]}
          onClick={() => {
            setIsModalNoteClicked((prev) => !prev);
          }}
        >
          <PropertyTitle
            Icon={NoteIcon}
            label="Notes"
            expand={expand}
          />
          {isNoteEmpty(noteData) ? (
            <div className={styles["property__empty_title"]}>
              <button>Add a note</button>
            </div>
          ) : (
            <div className={styles["property--note__contents"]}>
              <span className={styles["setting-note-mini"]}>{parsedPreviewText}</span>
            </div>
          )}
        </div>
      ) : (
        <div className={styles["setting-note"]}>
          <TaskNote
            isChangeNote={isChangeNote}
            onSave={onSave}
            expand={expand}
            localTitle={localTitle}
          />
        </div>
      )}
    </>
  );
}
