import { Flex, IconButton } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import iconForVisibility from "../../../utils/taskDetail/visibility/iconForVisibility";
import { priorityOptions } from "../../../constants/block/TaskPriority";
import { getBasicStyles } from "../../../views/TaskDetail/Header/PriorityButton";
import { PriorityType, TransparencyType, VisibilityType } from "../../../types/block/enum";

interface CalendarBlockIconSectionProps {
  visibility?: VisibilityType;
  transparency?: TransparencyType;
  priority?: PriorityType;
}

const CalendarBlockIconSection = ({
  visibility,
  transparency,
  priority,
}: CalendarBlockIconSectionProps) => {
  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === priority;
  })?.icon;
  return (
    <Flex gap={1} alignItems="flex-start" py={0.5}>
      {visibility === "private" && (
        <Flex alignItems="center">{iconForVisibility(visibility, transparency)}</Flex>
      )}

      {currentPriorityIcon && (
        <Flex alignItems="center">
          <IconButton
            icon={currentPriorityIcon}
            boxSizing={"border-box"}
            aria-label={`Priority`}
            verticalAlign={"start"}
            p={0}
            flexShrink={1}
            h={pxToRem(14)}
            w={pxToRem(14)}
            variant="outline"
            fontSize={pxToRem(14)}
            borderRadius={pxToRem(3)}
            minWidth={"initial"}
            boxSize={pxToRem(14)}
            _active={{}}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            cursor={"initial"}
            _hover={{ bgColor: "black" }}
            {...getBasicStyles(false, priority)}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CalendarBlockIconSection;
