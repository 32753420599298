import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SpaceType } from "../../../types/space";
import useApi from "../../../services/auth/useApi";
import { SpaceKindType, SpaceStatus, SpaceStatusType } from "../../../types/space/enum";
import { spaceQueryKeys } from "../queries";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { inProgressSpaceListState } from "../../../recoil/spaces/inProgressSpaceListState";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";

export const fetchSpaceApi = async (
  api: ReturnType<typeof useApi>,
  status?: SpaceStatusType,
  spaceType?: SpaceKindType
): Promise<SpaceType[]> => {
  const queryParams = new URLSearchParams();
  if (status) queryParams.append("itemStatus", status);
  if (spaceType) queryParams.append("spaceType", spaceType);

  const requestUrl = `/spaces${queryParams.toString() ? `?${queryParams.toString()}` : ""}`;
  const response = await api.get<SpaceType[]>(requestUrl);
  return response.data;
};

export const useFetchSpaceQuery = (options?: UseQueryOptions<SpaceType[], AxiosError>) => {
  const api = useApi();
  const setProjectList = useSetRecoilState(inProgressSpaceListState);
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);

  const query = useQuery<SpaceType[], AxiosError>({
    queryKey: spaceQueryKeys.status(SpaceStatus.InProgress),
    queryFn: () => fetchSpaceApi(api, SpaceStatus.InProgress),
    ...options,
  });

  useEffect(() => {
    if (query.isSuccess && query.data && selectedSpaces === null) {
      const newSelectedSpaces = query.data.map((space) => space.id);
      setSelectedSpaces([...newSelectedSpaces]);
    }
  }, [query.data, query.isSuccess, selectedSpaces, setSelectedSpaces]);

  useEffect(() => {
    if (query.isSuccess && query.data) {
      setProjectList(query.data);
    }
  }, [query.data, query.isSuccess, setProjectList]);

  return query;
};

export const useFetchArchivedSpaceQuery = (options?: UseQueryOptions<SpaceType[], AxiosError>) => {
  const api = useApi();

  const query = useQuery<SpaceType[], AxiosError>({
    queryKey: spaceQueryKeys.status(SpaceStatus.Completed),
    queryFn: () => fetchSpaceApi(api, SpaceStatus.Completed),
    ...options,
  });
  return query;
};

export const useFetchDeletedSpaceQuery = (options?: UseQueryOptions<SpaceType[], AxiosError>) => {
  const api = useApi();

  const query = useQuery<SpaceType[], AxiosError>({
    queryKey: spaceQueryKeys.status(SpaceStatus.Deleted),
    queryFn: () => fetchSpaceApi(api, SpaceStatus.Deleted),
    ...options,
  });
  return query;
};
