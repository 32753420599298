import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApiPathComposer } from "../api/genPath";
import axiosClient from "../api/axiosClient";
import { logger } from "../utils/logger";
import { ResponseError } from "../api/types";
import { AxiosRequestConfig } from "axios";

interface ResponseSync {
  sync: boolean;
}

interface PostBlockSyncPayload {
  data?: never;
  config?: AxiosRequestConfig;
}

export async function postBlockSync(payload?: PostBlockSyncPayload) {
  try {
    await axiosClient.post(ApiPathComposer.blockSync, payload?.data, payload?.config);
    // NOTE: return 값은 쓰지 않고, isLoading 상태를 이용해 fetch 시점을 잡습니다.
    return { sync: true };
  } catch (err) {
    logger.error(`Failed to sync blocks...\n`, err);
    return { sync: false };
  }
}

interface UseBlockSyncQueryPayload {
  data?: never;
  config?: AxiosRequestConfig;
  options?: Omit<UseQueryOptions<ResponseSync, ResponseError>, "queryKey" | "queryFn">;
}

/** 백엔드에 sync 요청을 보내 block들을 Google Calendar에 동기화합니다. */
export function useBlockSync(payload?: UseBlockSyncQueryPayload) {
  return useQuery<ResponseSync, ResponseError>({
    queryKey: ["sync"],
    queryFn: () => postBlockSync({ data: payload?.data, config: payload?.config }),
    ...payload?.options,
  });
}
