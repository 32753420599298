import { useState } from "react";
import useApi from "../../../services/auth/useApi";
import styles from "./TrashPopup.module.css";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import { juneTrack } from "../../../utils/june/analytics";
import { useRestoreTrashedSpaceMutation } from "../../../react-query/space/deletion/useRestoreTrashedSpaceMutation";
import { TrashSpaceRow } from "./TrashSpaceRow";
import { useQueryClient } from "@tanstack/react-query";
import { ItemStatus } from "../../../types/block/enum";
import { TrashTaskRow } from "./TrashTaskRow";
import { BlockType } from "../../../types/block/type";
import {
  useRestoreBlockMutation,
  useDeleteBlockMutation,
  useDeleteBlocksMutation,
} from "../../../react-query/block/mutations";
import { JUNE_EVENT, JUNE_LOCATION } from "../../../hooks/june/juneEvent";
import { inboxQueryKeys } from "../../../react-query/inbox/queries";
import { SpaceStatus } from "../../../types/space/enum";
import { AxiosError } from "axios";
import { calendarQueryKeys } from "../../../react-query/calendar/queries";
import { ResponseError } from "../../../api/types";
import { spaceQueryKeys, useGetSpaces } from "../../../react-query/space/queries";
import { blockQueryKey, useGetBlocksQuery } from "../../../react-query/block/queries";
import {
  useDeleteSpaceMutation,
  useDeleteSpacesMutation,
} from "../../../react-query/space/mutations";
import { logger } from "../../../utils/logger";

interface TrashPopupProps {
  onClose: () => void;
}

const TrashPopup: React.FC<TrashPopupProps> = () => {
  // hooks
  const instance = useApi();
  const queryClient = useQueryClient();

  // states
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const tabNameList = ["Spaces", "Tasks"];

  /* space */
  // Get all spaces in trash
  const { data: spacesInTrash, refetch: refetchDeletedSpaces } = useGetSpaces({
    payload: {
      itemStatus: SpaceStatus.Deleted,
    },
  });
  // Delete a space
  const { mutate: deleteSpace } = useDeleteSpaceMutation({});
  // Restore a space
  // NOTE: ASIS대로 유지, 건드리지 않았음
  const { mutate: restoreSpace } = useRestoreTrashedSpaceMutation();
  // Delete all spaces
  const { mutate: deleteSpaces } = useDeleteSpacesMutation({});

  /** Blocks */
  // Get all deleted blocks
  const { data: blocksInTrash, refetch: refetchDeletedBlocks } = useGetBlocksQuery({
    instance,
    payload: {
      itemStatus: ItemStatus.DELETED,
    },
  });
  // Restore a block
  const { mutate: restoreBlock } = useRestoreBlockMutation({
    instance,
  });
  // Delete a block
  const { mutate: deleteBlock } = useDeleteBlockMutation({
    instance,
  });
  // Delete all blocks
  const { mutate: deleteBlocks } = useDeleteBlocksMutation({
    instance,
  });

  function tabClickHandler(activeIndex: number) {
    setActiveIndex(activeIndex);
    juneTrack(JUNE_EVENT.VIEW_TRASH, { type: activeIndex === 0 ? "space" : "task" });
  }

  /** Space 1개 복구 */
  function handleRestoreSpace(projectId: number) {
    restoreSpace(projectId);
  }

  /** Task 1개 복구 */
  function handleRestoreTask(blockData: BlockType) {
    // 이전 state가 없으면 `inProgress`로 복구
    restoreBlock(
      {
        id: blockData.id,
        data: { itemStatus: blockData.itemLastStatus ?? ItemStatus.IN_PROGRESS },
      },
      {
        onSuccess: (responseData) => {
          refetchDeletedBlocks();

          // 복구된 data fetching
          // TODO: 좀 더 세분화하여 invalidate
          if (responseData.start) {
            queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
          }

          queryClient.invalidateQueries({
            queryKey: inboxQueryKeys.all,
          });

          juneTrack(JUNE_EVENT.RESTORE_BLOCK);
        },
        onError: (error: ResponseError) => {
          console.error(error);
        },
      }
    );
  }

  /** Space 1개 삭제 */
  function handleDeleteSpace(spaceId: number) {
    deleteSpace(spaceId, {
      onSuccess: () => {
        refetchDeletedSpaces();

        juneTrack(JUNE_EVENT.DELETE_SPACE, {
          location: JUNE_LOCATION.TRASH,
        });
      },
    });
  }

  /** Task 1개 삭제 */
  function handleDeleteTask(blockId: number) {
    deleteBlock(blockId, {
      onSuccess: () => {
        refetchDeletedBlocks();

        juneTrack(JUNE_EVENT.DELETE_BLOCK, {
          type: "task",
          location: JUNE_LOCATION.TRASH,
        });
      },
      onError: (error) => {
        logger.error("Failed to delete task:", error);
      },
    });
  }

  /** Spaces tab 전체 삭제 */
  function handleDeleteAllSpaces() {
    const spaceIds = spacesInTrash?.map((space) => space.id);

    if (spaceIds && spaceIds.length > 0) {
      deleteSpaces(spaceIds, {
        onSuccess: ({ successIds, failedIds }) => {
          if (successIds.length > 0) {
            queryClient.invalidateQueries({
              queryKey: spaceQueryKeys.status(SpaceStatus.Deleted),
            });

            juneTrack(JUNE_EVENT.CLICK_EMPTY, {
              type: "space",
            });
          }

          if (failedIds.length > 0) {
            logger.error(
              `Failed to delete ${failedIds.length}/${spaceIds.length} spaces:`,
              failedIds
            );
          }
        },
        onError: (error: AxiosError) => {
          logger.error("Failed to process delete requests:", error);
        },
      });
    }
  }

  /** Tasks tab 전체 삭제 */
  function handleDeleteAllBlocks() {
    if (!blocksInTrash) return;

    const blockIds = blocksInTrash.map((block: BlockType) => block.id);

    deleteBlocks(blockIds, {
      onError: (error, _, context) => {
        // TODO: 에러 핸들링
        console.error("Failed to process delete requests:", error);
        // 에러가 발생한 경우 이전 삭제된 blocks 데이터 복구
        if (context?.previousDeleteBlocks) {
          queryClient.setQueryData<BlockType[]>(
            blockQueryKey.getAllByParams({ itemStatus: ItemStatus.DELETED }),
            context.previousDeleteBlocks
          );
        }
      },
      onSuccess: (data: { successIds: number[]; failedIds: number[] }) => {
        const { successIds, failedIds } = data;
        if (successIds.length > 0) {
          // success items > 0 경우에만 refetch
          refetchDeletedBlocks();

          juneTrack(JUNE_EVENT.CLICK_EMPTY, {
            type: "task",
          });
        }

        if (failedIds.length > 0) {
          console.error(
            `Failed to delete ${failedIds.length}/${blockIds.length} blocks:`,
            failedIds
          );
        }
      },
    });
  }

  function handleEmpty(currentIndex: number) {
    switch (currentIndex) {
      case 0: // spaces
        handleDeleteAllSpaces();
        break;
      case 1: // tasks
        handleDeleteAllBlocks();
        break;
      default:
        throw new Error("Invalid tab index");
    }
  }

  return (
    <div className={styles.trash}>
      <ul>
        {tabNameList.map((tabData, index) => {
          return (
            <li
              key={index}
              className={`${activeIndex === index ? styles.tap__active : styles.tap__inactive}`}
              onClick={() => tabClickHandler(index)}
            >
              {tabData}
            </li>
          );
        })}
      </ul>
      <div className={styles.tap__contents}>
        {activeIndex === 0 &&
          spacesInTrash?.map((spaceData, index) => (
            <TrashSpaceRow
              key={index}
              data={{
                id: spaceData.id,
                title: spaceData.title,
                color: spaceData.color,
              }}
              onDeleteClick={handleDeleteSpace}
              onRestoreClick={handleRestoreSpace}
            />
          ))}

        {activeIndex === 1 &&
          blocksInTrash?.map((block: BlockType, index: number) => (
            <TrashTaskRow
              key={index}
              index={index}
              data={block}
              onDeleteClick={handleDeleteTask}
              onRestoreClick={handleRestoreTask}
            />
          ))}
      </div>
      <div className={styles.trash__guide}>
        <span className={styles.trash__guide__text}>
          Your history is available here for 30 days.
        </span>
        <button
          className={styles.trash__guide__empty}
          onClick={() => handleEmpty(activeIndex)}
        >
          <img
            className={styles.project__delete}
            src={trashDeleteImg}
            alt="empty trash"
          />
          <span className={styles.trash__guide__empty__text}>Empty</span>
        </button>
      </div>
    </div>
  );
};

export default TrashPopup;
