import { BlockType } from "../../../types/block/type";
import { getDayBoundariesISOString } from "../../../utils/date-format/dateUtils";

// Today 조회 시 사용
export const filterBlocksByToDayRange = (
  data: BlockType[],
  selectedSpaceIdList: number[] | null
) => {
  const { START_OF_DAY, END_OF_DAY } = getDayBoundariesISOString({
    day: new Date(),
    isAllday: true,
  });
  const dayStart = new Date(START_OF_DAY).getTime();
  const dayEnd = new Date(END_OF_DAY).getTime();

  return data.filter((block) => {
    if (!block.start || !block.end) return false;

    const blockStart = new Date(block.start).getTime();
    const blockEnd = new Date(block.end).getTime();

    const isToday = blockStart < dayEnd && blockEnd > dayStart;
    // 새로 생성된 블록은 필터링 적용 안함
    const isNewBlock = block.id === 0;
    const isSelectedSpaceBlock = block.spaceId && selectedSpaceIdList?.includes(block?.spaceId);

    return isToday && (isNewBlock || isSelectedSpaceBlock);
  });
};
