import { Flex, IconButton } from "@chakra-ui/react";
import { PriorityType, TransparencyType, VisibilityType } from "../../types/block/enum";
import iconForVisibility from "../../utils/taskDetail/visibility/iconForVisibility";
import { pxToRem } from "../../utils/styles/size";
import { CalendarViewType } from "../../constants/calendarViewType";
import { priorityOptions } from "../../constants/block/TaskPriority";
import { getBasicStyles } from "../../views/TaskDetail/Header/PriorityButton";

interface CalendarIconsProps {
  visibility: VisibilityType | null;
  transparency: TransparencyType | null;
  priority?: PriorityType;
  // TODO @9ellie 추후 enum으로
  containerSize: "smallEvent" | "largeEvent" | "mediumEvent";
  calendarView: string;

  isAllDay: boolean;
}

const CalendarIcons = ({
  visibility,
  transparency,
  priority,
  calendarView,
  containerSize,
  isAllDay,
}: CalendarIconsProps) => {
  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === priority;
  })?.icon;

  return (
    <Flex
      className="calendar-block-icons"
      justifyContent={containerSize !== "smallEvent" ? "initial" : "center"}
      gap={pxToRem(4)}
      py={0.5}
    >
      {/* Visibility Icon */}
      {visibility === "private" && (
        <Flex alignItems="center">{iconForVisibility(visibility, transparency)}</Flex>
      )}

      {/* Priority Icon */}
      {currentPriorityIcon && (
        <Flex alignItems="center">
          <IconButton
            icon={currentPriorityIcon}
            boxSizing={"border-box"}
            aria-label={`Priority`}
            verticalAlign={"start"}
            p={0}
            flexShrink={1}
            h={pxToRem(14)}
            w={pxToRem(14)}
            variant="outline"
            fontSize={pxToRem(14)}
            borderRadius={pxToRem(3)}
            minWidth={"initial"}
            boxSize={pxToRem(14)}
            _active={{}}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            cursor={"initial"}
            _hover={{ bgColor: "black" }}
            {...getBasicStyles(false, priority)}
          />
        </Flex>
      )}
    </Flex>
  );
};
export default CalendarIcons;
