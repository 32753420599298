import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useRecoilValue, useSetRecoilState } from "recoil";

import { juneIdentify } from "../utils/june/analytics";
import { loadFromLocalStorage, saveToLocalStorage } from "../utils/localStorage/localStorage";

import { CalendarViewType } from "../constants";

import useApi from "../services/auth/useApi";
import Settings from "../compoenets/Settings";

import Toast from "./Toast";
import GuestPopup from "./Popup/GuestPopup/GuestPopup";
import RecurringPopup from "./Popup/RecurringPopup/RecurringPopup";

import styles from "./Main.module.css";
import { SpaceSideBar } from "../compoenets/ProjectFilter/SideBarMenu/SpaceSideBar";
import { viewModeState } from "../recoil/view/viewMode";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { accountState } from "../recoil/account/accountStateV2";
import { spaceQueryKeys } from "../react-query/space/queries";
import { TaskDetailWrapper } from "./TaskDetailWrapper";
import { pxToRem } from "../utils/styles/size";
import ChangeLogPopup from "./Popup/ChangelogPopup/ChangeLogPopup";
import { useGoogleContactsQueryOptions } from "../react-query/contact/queries";
import { ApiPathComposer, AppPathComposer } from "../api/genPath";
import { USER_ROLE } from "../types/account";
import { RightMainContents } from "../compoenets/RightMainContents";
import { TaskInboxWithResizable } from "../compoenets/mainComponents/TaskInboxWithResizable";
import { useBlockSync } from "../react-query/useBlockSync";

export default function Main() {
  const api = useApi();
  const navigate = useNavigate();
  const setAccountData = useSetRecoilState(accountState);
  const viewMode = useRecoilValue(viewModeState);

  const [showSettingsPopup, setShowSettingsPopup] = useState(null);

  const fetchProjectsData = () => {
    queryClient.invalidateQueries({ queryKey: spaceQueryKeys.all });
  };

  /** disclosures */
  const {
    isOpen: isSpaceSidebarExpanded,
    onToggle: onToggleSpaceSidebar,
    onOpen: onOpenSpaceSidebar,
  } = useDisclosure();

  const guestPopupRef = useRef(null);
  const recurringPopupRef = useRef(null);

  /** react-query */
  const queryClient = useQueryClient();
  const googleContactOptions = useGoogleContactsQueryOptions(); // 최상위 레벨에서 훅 호출
  /** Server로부터 block들이 Google Calendar와 동기화되었는지 여부 */
  const { isLoading } = useBlockSync({});
  /** 일단 variable 이름은 직관적으로 당겨올 준비가 됐다는 의미에서 hasBlocksSynchronizedWithGoogle로 정의 */
  const hasBlocksSynchronizedWithGoogle = !isLoading;

  useEffect(() => {
    queryClient.prefetchQuery(googleContactOptions);
  }, []);

  useEffect(() => {
    // TODO 이 부분도 추후 Main 외 다른 영역으로 이동 필요
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSettingsPopup = (settingState) => {
    setShowSettingsPopup(settingState);
  };

  // TODO: me 정보를 가져오는 함수가 수많은 하위 컴포넌트에 전달되고 있음, 어떤 식으로 처리되는지 파악 필요!
  async function loadData() {
    try {
      const response = await api.get(ApiPathComposer.me);

      if (response.data.role === USER_ROLE.TRIAL) {
        navigate(AppPathComposer.joinWaitList);
      }

      handleAccountInfo(response.data);
      fetchProjectsData();
    } catch (err) {
      console.error("Failed to load data:", err);
    }
  }

  // NOTE 계정 정보 가져오기
  function handleAccountInfo(accountInfo) {
    juneIdentify(accountInfo.email, accountInfo.role);
    setAccountData(accountInfo);
  }

  useEffect(() => {
    if (!loadFromLocalStorage("calendarViewType")) {
      saveToLocalStorage("calendarViewType", CalendarViewType.WEEK.type);
    }
  }, []);

  return (
    <div className={styles["main"]}>
      <SpaceSideBar
        isExpanded={isSpaceSidebarExpanded}
        onToggle={onToggleSpaceSidebar}
        onOpen={onOpenSpaceSidebar}
        onClickSettings={() => toggleSettingsPopup("Account")}
      />
      <Flex
        flex={1}
        bg="#1a1c1c"
        h="100%"
        p={pxToRem(7)}
        gap={2}
      >
        {viewMode === "calendar" && (
          <TaskInboxWithResizable
            hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
          />
        )}

        <RightMainContents hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle} />
        {showSettingsPopup && (
          <Settings
            settingState={showSettingsPopup}
            onClose={() => toggleSettingsPopup(null)}
            loadData={loadData}
          />
        )}
      </Flex>

      {/* {isIntegrationModalOpen && (
        <Integration
          inboxRef={inboxRef}
          settingState={(settingState) => toggleSettingsPopup(settingState)}
          isIntegrationModalOpen={isIntegrationModalOpen}
          setIsIntegrationModalOpen={setIsIntegrationModalOpen}
          integrationBtnRef={integrationBtnRef}
          isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
        />
      )} */}

      <TaskDetailWrapper
        guestPopupRef={guestPopupRef}
        recurringPopupRef={recurringPopupRef}
      />

      <Toast />
      <GuestPopup guestPopupRef={guestPopupRef} />
      <RecurringPopup recurringPopupRef={recurringPopupRef} />
      <ChangeLogPopup />
    </div>
  );
}
