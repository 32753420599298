import { useCallback } from "react";

import {
  categorizeEventsByRecurrence,
  manageCalendarRecurringEvents,
} from "../../utils/calendar/initializeDataUtils";

import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { useRecoilValue } from "recoil";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";

export const useInitialCalendarBlocks = () => {
  const defaultVisibility = useRecoilValue(visibilityState);

  const processInitialCalendarBlocks = useCallback(
    (events, recurringEnd) => {
      const formattedEvents = events.map((event) =>
        convertServerToClientBlockType({ serverBlock: event, defaultVisibility })
      );

      // 2. 이벤트 분류
      const categorizedEvents = categorizeEventsByRecurrence(formattedEvents);

      // 3. 반복 이벤트 처리
      const recurringEvents = manageCalendarRecurringEvents(
        categorizedEvents.recurrenceBlocks,
        categorizedEvents.recurrenceUpdatedBlocks,
        recurringEnd
      );

      return [...categorizedEvents.normalBlocks, ...recurringEvents];
    },
    [defaultVisibility]
  );

  return { processInitialCalendarBlocks };
};
