import { useDrop } from "react-dnd";
import { useRecoilValue } from "recoil";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import styles from "./styles.module.css";

import { showMorePopupState } from "../../recoil/calendar/calendarState";
import { MyCalendar } from "./MyCalendar";
import { DragAccept } from "../../types/block/enum";

/**
 * @param {object} props
 * @param {boolean} props.hasBlocksSynchronizedWithGoogle - 백엔드가 보내는 block들과 Google Calendar 동기화 여부
 */
export default function CalendarCustom({ hasBlocksSynchronizedWithGoogle }) {
  const showMorePopup = useRecoilValue(showMorePopupState);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  const [, drop] = useDrop({
    accept: [DragAccept.INBOX, DragAccept.MORE_TASK],
  });

  return (
    <div
      ref={drop}
      className={styles["calendar-custom"]}
    >
      {showMorePopup && (
        <div
          className={styles.overlayView}
          onClick={handleOverlayClick}
        ></div>
      )}
      <MyCalendar hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle} />
    </div>
  );
}
