import { atom } from "recoil";

export type ViewModeType = "calendar" | "wiki";

export const viewModeState = atom<ViewModeType>({
  key: "viewModeState",
  default: "calendar",
});

export const selectedWikiSpaceState = atom<number | null>({
  key: "selectedWikiSpaceState",
  default: null,
});
