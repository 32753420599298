import { Flex, Text } from "@chakra-ui/react";
import { memo } from "react";
import { BasicBlock } from "../../types/block/enum";
import CalendarIcons from "./CalendarIcons";
import CalendarTitle from "./CalendarTitle";
import formatTime from "../../utils/common/dateTime/formatTime";
import formatTimeInterval from "../../utils/common/dateTime/formatTimeInterval";
import { CalendarHangoutLink } from "./CalendarHangoutLink";
import { CalendarCheckBox } from "./CalendarCheckBox";
import { BlockType } from "../../types/block/type";

interface CalendarBlockMainContentProps {
  event: BlockType;
  calendarView: string;
  timeFormat: string;
  handleTitleTooltip: (e: React.MouseEvent) => void;
  setIsTitleOverflow: (isOverflow: boolean) => void;
  handleClick: (e: React.MouseEvent) => void;
  containerSize: "smallEvent" | "largeEvent" | "mediumEvent";
  isAnimationActive: boolean;
  checkboxStyle?: any;
  spaceColor: string;
}

const CalendarBlockMainContent = memo(
  ({
    event,
    calendarView,
    timeFormat,
    containerSize,
    handleTitleTooltip,
    setIsTitleOverflow,
    spaceColor,
  }: CalendarBlockMainContentProps) => {
    if (event.isMeetWith) {
      return (
        <Text
          onMouseOver={handleTitleTooltip}
          onMouseLeave={() => setIsTitleOverflow(false)}
          fontFamily="Pretendard Variable"
          fontWeight="400"
          fontSize="13px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          lineHeight="1.4"
          position="relative"
        >
          {event.title ? event.title : `busy`}
        </Text>
      );
    }

    return (
      <>
        {event.hangoutLink ? (
          <CalendarHangoutLink hangoutLink={event.hangoutLink} />
        ) : (
          event.blockType === BasicBlock.TASK && (
            <CalendarCheckBox
              block={event}
              spaceColor={spaceColor}
            />
          )
        )}

        <Flex
          flex="1"
          overflow="hidden"
          flexDirection="column"
          h={containerSize !== "largeEvent" ? "100%" : "auto"}
        >
          <CalendarTitle
            title={event.title}
            blockType={event.blockType}
            onMouseOver={handleTitleTooltip}
            onMouseLeave={() => setIsTitleOverflow(false)}
            containerSize={containerSize}
          />

          {containerSize !== "smallEvent" && (
            <Text
              className="timeDuration"
              fontSize="10px"
              lineHeight="1.3"
              opacity="0.4"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {`${formatTime(event.start, timeFormat)} - ${formatTime(event.end, timeFormat)} / ${formatTimeInterval(new Date(event.start ?? ""), new Date(event.end ?? ""))}`}
            </Text>
          )}
        </Flex>

        <CalendarIcons
          visibility={event.visibility}
          transparency={event.transparency}
          priority={event.priority}
          calendarView={calendarView}
          containerSize={containerSize}
          isAllDay={event.allDay ?? false}
        />
      </>
    );
  }
);

// 표시 이름 설정 (디버깅용)
CalendarBlockMainContent.displayName = "CalendarBlockMainContent";

export default CalendarBlockMainContent;
