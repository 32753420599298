import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BlockResponseType, BlockType, UpdateBlockRequestType } from "../../types/block/type";
import { BlockRequests } from "./requests";
import { ResponseError } from "../../api/types";
import { blockQueryKey } from "./queries";
import { ItemStatus } from "../../types/block/enum";

interface BlockMutationOptions<
  /** Response data type */
  TData = unknown,
  /** Error type */
  TError = ResponseError,
  /** Mutation에 넘겨지는 값 */
  TVariables = void,
  /** Context로 전달되는 값 */
  TContext = unknown,
> {
  /** axios instance */
  // TODO: instance 주입 방식 삭제 필요
  instance: AxiosInstance;
  /** axios request config */
  config?: AxiosRequestConfig;
  /** `mutationFn`과 `onMutate`를 제외한 mutation 옵션 */
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn" | "onMutate"
  >;
}

/**
 * Block을 복구하는 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 Block을 이전 상태로 복구
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns Block 복구를 위한 React Query mutation hook
 */
export const useRestoreBlockMutation = ({
  instance,
  config,
  options,
}: BlockMutationOptions<
  BlockResponseType,
  ResponseError,
  { id: number; data: UpdateBlockRequestType }
>) => {
  return useMutation({
    mutationFn: async ({ id, data }) => {
      const response = await BlockRequests.restoreBlock({ instance, id, data, config });
      return response.data;
    },
    ...options,
  });
};

/**
 * 단일 Block을 삭제하는 mutation hook
 * @author brandonwie
 * @description
 * - React Query를 사용하여 ID로 단일 Block 삭제
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi hook 참고)
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns Block 삭제를 위한 React Query mutation hook
 */
export const useDeleteBlockMutation = ({
  instance,
  config,
  options,
}: BlockMutationOptions<void, ResponseError, number, { previousBlocks: BlockType[] }>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      await BlockRequests.deleteBlockById({ instance, id, config });
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({
        queryKey: blockQueryKey.getById(id),
      });
      // Optimistic UI 업데이트
      let previousBlocks: BlockType[] = [];

      queryClient.setQueryData<BlockType[]>(
        blockQueryKey.getAllByParams({ itemStatus: ItemStatus.DELETED }),
        (oldData?: BlockType[]) => {
          if (!oldData) return [];
          previousBlocks = oldData;
          return oldData.filter((block) => block.id !== id);
        }
      );

      return { previousBlocks };
    },
    ...options,
  });
};

/**
 * 다중 Block 삭제를 위한 mutation hook (부분 실패 처리 포함)
 * @author brandonwie
 * @description
 * - React Query를 사용하여 여러 Block 삭제
 * - 성공 및 실패한 삭제 작업을 추적하여 부분 실패 기록
 * - ASIS 컨벤션을 따라 Axios 인스턴스를 주입받도록 처리
 * - 참고: useApi hook으로 인해 컴포넌트 마운트 이후에만 사용 가능 (useApi.js 참고)
 * - 0319 Optimistic Update 처리 추가
 * @param options Axios 인스턴스와 React Query 옵션을 포함한 설정
 * @returns 성공 및 실패한 Block ID 배열을 반환하는 React Query mutation hook
 */
export const useDeleteBlocksMutation = ({
  instance,
  config,
  options,
}: BlockMutationOptions<
  { successIds: number[]; failedIds: number[] },
  ResponseError,
  number[],
  { previousDeleteBlocks: BlockType[] } // 롤백을 위해 이전 삭제된 blocks 데이터 반환용
>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ids) => {
      const successIds: number[] = [];
      const failedIds: number[] = [];

      for (const id of ids) {
        try {
          await BlockRequests.deleteBlockById({ instance, id, config });
          successIds.push(id);
          // Google API 제한을 피하기 위해 sync하게 처리
        } catch (error) {
          failedIds.push(id);
          console.error(`Failed to delete block ${id}:`, error);
        }
      }

      return { successIds, failedIds };
    },
    onMutate: async (ids) => {
      await queryClient.cancelQueries({
        queryKey: blockQueryKey.getAllByParams({ itemStatus: ItemStatus.DELETED }),
      });

      let previousDeleteBlocks: BlockType[] = [];

      queryClient.setQueryData(
        blockQueryKey.getAllByParams({ itemStatus: ItemStatus.DELETED }),
        (oldData?: BlockType[]) => {
          if (!oldData) return [];
          previousDeleteBlocks = oldData;
          return oldData.filter((block) => !ids.includes(block.id));
        }
      );

      return { previousDeleteBlocks };
    },
    ...options,
  });
};
