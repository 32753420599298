import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./LinkAddModal.module.css";
import { LINK_FIELD_NAME } from "../../../constants/block/LinkData";
import { logger } from "../../../utils/logger";

export default function LinkAddModal({ selectedItem, onSave, onClose }) {
  const mainRef = useRef(null);

  /** input 값 state 쓰지 않고 처리 */
  const inputNameRef = useRef(null);
  const inputUrlRef = useRef(null);

  const fileSelectRef = useRef(null);
  const [headerMenu, setHeaderMenu] = useState(LINK_FIELD_NAME);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSave = async () => {
    if (!inputUrlRef.current.value) {
      onClose();
      return;
    }

    onSave(
      LINK_FIELD_NAME,
      {
        name: inputNameRef.current.value.trim() || "No Title",
        url: inputUrlRef.current.value.trim(),
        isLoading: true,
      },
      selectedItem ? selectedItem.index : undefined
    );

    onClose();
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      // 한글 입력 시 Enter 이벤트 중복 발생 방지
      // - 한글을 compose하는 동안에도 event가 dispatch될 수 있기 때문에 composition이 끝난 뒤 처리
      // REF: https://w3c.github.io/uievents/#events-compositionevents
      if (e.nativeEvent.isComposing) return;
      handleSave();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      onClose();
    }
  };

  const handleFileSelect = () => {
    fileSelectRef.current.click();
  };

  const handleFileCancel = () => {
    if (fileSelectRef.current) {
      fileSelectRef.current.value = null;
    }
    setSelectedFile(null);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleInputKeyDown);

    inputUrlRef?.current?.focus();
    function handleClickOutside(event) {
      if (mainRef.current && !mainRef.current.contains(event.target)) {
        onClose();
      }
    }
    if (mainRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("keydown", handleInputKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return createPortal(
    <div
      className={styles["wrap"]}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div
        className={styles["main"]}
        ref={mainRef}
      >
        <div className={styles["header"]}>
          <div
            className={`${styles["header-title"]} ${
              headerMenu === "file" && styles["header-title-file"]
            }`}
          >
            <span
              className={`${headerMenu !== LINK_FIELD_NAME && styles["header-off"]}`}
              onClick={() => {
                setHeaderMenu(LINK_FIELD_NAME);
                setSelectedFile(null);
              }}
            >
              Link
            </span>
            {/* <span className={`${headerMenu!="file" && styles['header-off']}`} onClick={()=>setHeaderMenu("file")}>File</span> */}
          </div>
          <span
            className={styles["header-close"]}
            onClick={onClose}
          >
            X
          </span>
        </div>
        <div className={styles["body"]}>
          {headerMenu === LINK_FIELD_NAME && (
            <>
              <input
                ref={inputUrlRef}
                placeholder="URL"
                className={styles["body-input-url"]}
                onKeyDown={handleInputKeyDown}
              />
              <input
                ref={inputNameRef}
                placeholder="Page Name"
                className={styles["body-input-name"]}
                onKeyDown={handleInputKeyDown}
              />
            </>
          )}
          {headerMenu === "file" && (
            <>
              {selectedFile ? (
                <div className={styles["body-file-btn"]}>
                  <div className={styles["body-file-icon"]}></div>
                  <span className={styles["body-file-title"]}>{selectedFile.name}</span>
                  <button
                    className={styles["body-file-cancel"]}
                    onClick={handleFileCancel}
                  >
                    X
                  </button>
                </div>
              ) : (
                <>
                  <div
                    className={styles["body-fileSelect-btn"]}
                    onClick={handleFileSelect}
                  >
                    Choose a file
                  </div>
                  <input
                    type="file"
                    ref={fileSelectRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  ></input>
                </>
              )}
            </>
          )}
        </div>
        <div
          className={styles["footer"]}
          onClick={handleSave}
        >
          Save
        </div>
      </div>
    </div>,
    document.body
  );
}
