// NOTE SpaceType이 있는 관계로 SpaceKind로 명명
export const SpaceKind = {
  Default: "default",
  Normal: "normal",
  Divider: "divider",
} as const;
export type SpaceKindType = (typeof SpaceKind)[keyof typeof SpaceKind];

export const SpaceStatus = {
  InProgress: "inProgress",
  Completed: "completed",
  Deleted: "deleted",
} as const;
export type SpaceStatusType = (typeof SpaceStatus)[keyof typeof SpaceStatus];

export const DividerTitle = {
  DividerTitle: "---",
} as const;
export type DividerTitleType = (typeof DividerTitle)[keyof typeof DividerTitle];
