import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";

import useApi from "../../services/auth/useApi";
import styles from "./Account.module.css";

import invitationCompletedCloseIcon from "../../assets/Settings/invitation-completed-close-icon.png";
import invitationCompletedIcon from "../../assets/Settings/invitation-completed-icon.png";
import invitationFailIcon from "../../assets/Settings/invitation-fail-icon.png";
import profileDefaultIcon from "../../assets/Settings/profile-default-icon.png";

import { accountState } from "../../recoil/account/accountStateV2";
import { ApiPathComposer, AppPathComposer } from "../../api/genPath";

function MyProfile({ onDelete, loginData }) {
  const [token, setToken] = useRecoilState(tokenState);
  const api = useApi();
  const navigate = useNavigate();

  // NOTE: 인증 상태를 담당하는 HOC가 하나 있어야 하지 않나 의견
  // - WHY? 인증 redirect를 여기저기서 처리하고 있음
  // - 일단 logic 자체는 ASIS대로 유지
  useEffect(() => {
    if (token.accessToken === null) {
      if (document.startViewTransition) {
        document.startViewTransition(() => {
          navigate(AppPathComposer.main);
        });
      } else {
        navigate(AppPathComposer.main);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  async function handleLogoutClick() {
    // TODO: 에러 처리
    await api.post(ApiPathComposer.logout);

    setToken({
      mAccessToken: null,
      mRefreshToken: null,
      accessToken: null,
    });
  }

  const toggleDeleteAccountPopup = () => {
    onDelete();
  };

  return (
    <div className={styles.profile__total__area}>
      <div className={styles.profile__area}>
        <img
          src={loginData.photo}
          alt="profile"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = profileDefaultIcon;
          }}
        />
        <div className={styles.profile__text__area}>
          <span className={styles.profile__nickname}>
            {loginData.givenName} {loginData.familyName}
          </span>
          <span className={styles.profile__email}>
            {loginData.emails.length > 0 ? loginData.emails[0] : null}
          </span>
        </div>
        <div className={styles.profile__btns__area}>
          <button className={styles.logout__button} onClick={handleLogoutClick}>
            Logout
          </button>
        </div>
      </div>
      <button className={styles.delete__button} onClick={toggleDeleteAccountPopup}>
        Delete account
      </button>
    </div>
  );
}

// @brandonwie 03/14/2025 사용되지 않는 components comment out
// #region 사용되지 않는 components
// function InvitationPending({ onInviteClick }) {
//   return (
//     <div className={styles.invitation__list__item__send} onClick={onInviteClick}>
//       <img className={styles.dot} src={dotImg} alt="dot" />
//       <div className={styles.text}>Invite</div>
//       <img className={styles.plus} src={plusImg} alt="plus" />
//     </div>
//   );
// }
//
// function InvitationInProgress({ onEmailSent, onCompletedToast, onFailToast }) {
//   const [inviteeEmail, setInviteeEmail] = useState("");
//   const api = useApi();
//   const [isShowInvatationPopup, setIsShowInvatationPopup] = useState(false);
//
//   const onChange = (e) => {
//     setInviteeEmail(e.target.value);
//   };
//
//   const handleKeyPress = (e) => {
//     const trimInviteeEmail = inviteeEmail.trim();
//
//     function isEmailValid(email) {
//       const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
//       return regex.test(email);
//     }
//
//     if (e.key === "Enter" || e.type === "click") {
//       if (isEmailValid(trimInviteeEmail)) {
//         setIsShowInvatationPopup((current) => !current);
//       } else {
//         onFailToast(true);
//       }
//     }
//   };
//
//   const handleClosePopup = () => {
//     onCompletedToast(true);
//     setIsShowInvatationPopup(false);
//   };
//
//   return (
//     <>
//       <div className={styles.invitation__list__item__progress}>
//         <img className={styles.dot} src={dotImg} alt="dot" />
//         <input
//           value={inviteeEmail}
//           type="text"
//           className={styles.invitaion__input}
//           onChange={onChange}
//           onKeyDown={handleKeyPress}
//         />
//         <div className={styles.text} onClick={handleKeyPress}>
//           Invite
//         </div>
//       </div>
//       {isShowInvatationPopup ? (
//         <InvitationPopup
//           inviteeEmail={inviteeEmail}
//           handleClosePopup={handleClosePopup}
//           onEmailSent={onEmailSent}
//         />
//       ) : null}
//     </>
//   );
// }
// #endregion 사용되지 않는 component

// @brandonwie 03/14/2025 사용하지 않는 method comment out
// function InvitationCompleted({ email }) {
//   return (
//     <div className={styles.invitation__list__item__completed}>
//       <img className={styles.dot} src={completedDotImg} alt="completeDot" />
//       <div className={styles.text}>{email}</div>
//     </div>
//   );
// }

function Believer({ loginData, onReLoadLoginData, onCompletedToast, onFailToast }) {
  // @brandonwie 03/14/2025 사용하지 않는 부분 comment out
  // const inviteesCount = loginData.invitees.length;

  //   const [invitationStatus, setInvitationStatus] = useState([]);
  //
  //   useEffect(() => {
  //     if (inviteesCount === 0) {
  //       setInvitationStatus(["pending", "pending"]);
  //     } else if (inviteesCount === 1) {
  //       setInvitationStatus(["completed", "pending"]);
  //     } else {
  //       setInvitationStatus(["completed", "completed"]);
  //     }
  //   }, [loginData.invitees]);

  // const handleInviteClick = (index) => {
  //   const updatedStatus = [...invitationStatus];
  //   updatedStatus[index] = "inProgress";
  //   setInvitationStatus(updatedStatus);
  // };

  return (
    <div className={styles.believer__contents__area}>
      <div className={styles.believer__title}>Believer</div>
      <div className={styles.believer__guide__text__area}>
        <span className={styles.believer__guide__text}>Hey, Believer!</span>
        <span className={styles.believer__guide__text} style={{ marginTop: `7px` }}>
          {"You can invite two people who you think would benefit from MOBA! (Coming soon)"}
        </span>
        {/* <div className={styles.believer__invitation__area}>
          <div className={styles.invitation__title}>Invitation List</div>
          <div className={styles.count__description}>
            {inviteesCount === 2
              ? "You have used up your 2 invitations."
              : `You have ${inviteesCount === 0 ? "2" : "1"} invitation left.`}
          </div>
        </div>
        <div className={styles.invitation__list__area}>
          {invitationStatus.map((status, index) => {
            if (status === "pending") {
              return (
                <InvitationPending
                  key={index}
                  onInviteClick={() => handleInviteClick(index)}
                />
              );
            } else if (status === "inProgress") {
              return (
                <InvitationInProgress
                  key={index}
                  onEmailSent={onReLoadLoginData}
                  onCompletedToast={onCompletedToast}
                  onFailToast={onFailToast}
                />
              );
            } else if (status === "completed") {
              return <InvitationCompleted key={index} email={emails[index]} />;
            }
            return null;
          })}
        </div> */}
      </div>
    </div>
  );
}

function Invitee({ loginData }) {
  return (
    <div className={styles.believer__contents__area}>
      <div className={styles.believer__title}>Invitee</div>
      <div className={styles.believer__guide__text__area}>
        <span className={styles.believer__guide__text}>
          You have been invited by {loginData.invitedBy}!
        </span>
        <span className={styles.believer__guide__text} style={{ marginTop: `7px` }}>
          You can use a private release version of MOBA for free.
        </span>
      </div>
    </div>
  );
}

function Billing({ loginData }) {
  return (
    <div className={styles.billing__area}>
      <div className={styles.billing__title}>Billing</div>
      <div className={styles.billing__description}>
        You will not be charged during private release!
      </div>
      {/* <div className={styles.billing__change__btn}>Change Plan</div> */}
    </div>
  );
}

const Toast = ({ icon, message, showToast, setShowToast, duration }) => {
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [showToast, setShowToast, duration]);

  if (!showToast) {
    return null;
  }

  return (
    <div className={`${styles.toast} ${showToast ? styles.show : ""}`}>
      <img className={styles.icon} src={icon} alt="completed" />
      <span className={styles.message}>{message}</span>
      <img
        className={styles.close}
        src={invitationCompletedCloseIcon}
        alt="close"
        onClick={() => setShowToast(false)}
      />
    </div>
  );
};

export default function Account({ onDelete, onReLoadLoginData }) {
  const accountData = useRecoilValue(accountState);
  const [loginData, setLoginData] = useState(null);
  const [membership, setMembership] = useState(null);
  const [isShowCompletedToast, setIsShowCompletedToast] = useState(false);
  const [isShowFailToast, setIsShowFailToast] = useState(false);

  const handleShowCompletedToast = (isVisible) => {
    setIsShowCompletedToast(isVisible);
  };

  const handleShowFailToast = (isVisible) => {
    setIsShowFailToast(isVisible);
  };

  useEffect(() => {
    if (accountData != null) {
      const invitees = accountData.invitees ?? [];
      const combinedAccounts = [accountData.email];

      setLoginData({
        emails: combinedAccounts,
        familyName: accountData.familyName ?? "",
        givenName: accountData.givenName ?? "",
        photo: accountData.photo,
        invitees: invitees,
        role: accountData.role,
      });
      setMembership(accountData.role);
    }
  }, [accountData]);

  return (
    <>
      <div className={styles.account__contents__area}>
        <div className={styles.account__contents}>
          <span className={styles.account__title}>Account</span>

          {loginData != null && <MyProfile onDelete={onDelete} loginData={loginData} />}

          {membership != null && membership === "believer" && (
            <Believer
              loginData={loginData}
              onReLoadLoginData={onReLoadLoginData}
              onCompletedToast={handleShowCompletedToast}
              onFailToast={handleShowFailToast}
            />
          )}

          {membership != null && membership !== "believer" && <Invitee loginData={loginData} />}

          {/* {
            <Believer
              loginData={loginData}
              onReLoadLoginData={onReLoadLoginData}
              onCompletedToast={handleShowCompletedToast}
              onFailToast={handleShowFailToast}
            />
          } */}
          <Billing loginData={loginData} />
        </div>
      </div>
      {isShowCompletedToast ? (
        <Toast
          icon={invitationCompletedIcon}
          message="The Invitation will be sent soon from Moba."
          showToast={isShowCompletedToast}
          setShowToast={setIsShowCompletedToast}
          duration={3000}
        />
      ) : null}

      {isShowFailToast ? (
        <Toast
          icon={invitationFailIcon}
          message="The email address is incorrect."
          showToast={isShowFailToast}
          setShowToast={setIsShowFailToast}
          duration={3000}
        />
      ) : null}
    </>
  );
}

Account.propTypes = {
  onDelete: PropTypes.func,
  loginData: PropTypes.object,
  onReLoadLoginData: PropTypes.func,
};

Account.defaultProps = {
  onDelete: () => {},
  loginData: {},
  onReLoadLoginData: () => {},
};
