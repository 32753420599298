import useApi from "../../services/auth/useApi";
import {
  ItemStatus,
  Operation,
  BlockRequestQueryTypeParam,
  BlockSort,
} from "../../types/block/enum";
import { BlockRequestParamType, BlockType } from "../../types/block/type";
import blockAPI from "../../api/BlockAPI";
import {
  getDayBoundariesISOString,
  getLastDayOfTwoMonthsLater,
} from "../../utils/date-format/dateUtils";
import { useRecoilValue } from "recoil";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { getBlockTypeFlags } from "../../utils/common/block/getBlockTypeFlags";

import { convertServerToClientBlockType } from "../../utils/common/block/type-converter";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import {
  categorizeEventsByRecurrence,
  processInboxRecurringEvents,
} from "../../utils/calendar/initializeDataUtils";
import { calendarLastMonthSelector } from "../../recoil/calendar/calendarStateV2";
import { filterBlocksByToDayRange } from "./utils/filterBlocksByToDayRange";
import { extractLatestBlocksByOriginalId } from "./utils/extractLatestBlocksByOriginalId";
import { extractFirstBlocksByOriginalId } from "./utils/extractFirstBlocksByOriginalId";

export const inboxQueryKeys = {
  all: ["inbox"] as const,
  inbox: (param: BlockRequestParamType) => [...inboxQueryKeys.all, { ...param }] as const,
} as const;

/**
 * @param {object | undefined} props
 * @param {boolean | undefined} props.enabled - 쿼리 실행 여부
 */
export const useInboxGeneralQueryOptions = ({ enabled = true }: { enabled?: boolean }) => {
  const api = useApi();
  const params = {
    queryType: BlockRequestQueryTypeParam.INBOX,
    itemStatus: ItemStatus.IN_PROGRESS,
    startOp: Operation.NOT,
    endOp: Operation.NOT,
  };

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  return {
    queryKey: inboxQueryKeys.inbox(params),
    queryFn: () =>
      blockAPI.getBlocks(api, params).then((res) => {
        return res.data.map((block) => {
          return convertServerToClientBlockType({
            serverBlock: block,
            defaultVisibility,
          });
        });
      }),
    select: (data: BlockType[]) =>
      data.filter((block) => {
        const { isDivider } = getBlockTypeFlags(block.blockType);
        return isDivider || (block.spaceId && selectedSpaceIdList?.includes(block?.spaceId));
      }),
    enabled,
  };
};

export const useInboxPlannedQueryOptions = () => {
  const TODAY = new Date();
  const { START_OF_DAY } = getDayBoundariesISOString({ day: TODAY, isAllday: false });

  const api = useApi();
  const params = {
    queryType: BlockRequestQueryTypeParam.TASK,
    itemStatus: ItemStatus.IN_PROGRESS,
    end: START_OF_DAY,
    endOp: Operation.GREATER_THAN,
  };

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);
  const defaultVisibility = useRecoilValue(visibilityState);
  const calendarLastMonth = useRecoilValue(calendarLastMonthSelector);

  return {
    queryKey: inboxQueryKeys.inbox(params),
    queryFn: () =>
      blockAPI.getBlocks(api, params).then((res) => {
        const clientData = res.data.map((block) => {
          return convertServerToClientBlockType({
            serverBlock: block,
            defaultVisibility,
          });
        });
        const categorizedEvents = categorizeEventsByRecurrence(clientData);
        const recurringStart = START_OF_DAY;
        const recurringEnd = getLastDayOfTwoMonthsLater(calendarLastMonth ?? START_OF_DAY);
        const recurringEvents = processInboxRecurringEvents(
          categorizedEvents.recurrenceBlocks,
          categorizedEvents.recurrenceUpdatedBlocks,
          recurringStart,
          recurringEnd
        );

        const updatedBlocks = [...categorizedEvents.normalBlocks, ...recurringEvents];
        return updatedBlocks;
      }),
    select: (data: BlockType[]) => {
      const processedBlocks = extractFirstBlocksByOriginalId(data);

      // 3. 필터링 적용
      return processedBlocks.filter((block) => {
        // 새로 생성된 블록은 필터링 적용 안함
        const isNewBlock = block.id === 0;
        if (isNewBlock) {
          return block;
        }
        return block.spaceId && selectedSpaceIdList?.includes(block.spaceId);
      });
    },
  };
};

export const useInboxOverdueQueryOptions = () => {
  const TODAY = new Date();
  const { START_OF_DAY } = getDayBoundariesISOString({ day: TODAY, isAllday: false });

  const api = useApi();
  const params = {
    queryType: BlockRequestQueryTypeParam.TASK,
    itemStatus: ItemStatus.IN_PROGRESS,
    end: START_OF_DAY,
    endOp: Operation.LESS_THAN_OR_EQUAL,
    sortType: BlockSort.OVERDUE,
  };

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  return {
    queryKey: inboxQueryKeys.inbox(params),
    queryFn: () =>
      blockAPI.getBlocks(api, params).then((res) => {
        const clientData = res.data.map((block) => {
          return convertServerToClientBlockType({
            serverBlock: block,
            defaultVisibility,
          });
        });

        const categorizedEvents = categorizeEventsByRecurrence(clientData);
        const recurringStart = "";
        const recurringEnd = START_OF_DAY;

        const recurringEvents = processInboxRecurringEvents(
          categorizedEvents.recurrenceBlocks,
          categorizedEvents.recurrenceUpdatedBlocks,
          recurringStart,
          recurringEnd
        );

        const updatedBlocks = [...categorizedEvents.normalBlocks, ...recurringEvents];

        // start를 기준으로 오름차순
        const sortedBlocks = updatedBlocks.sort((a, b) => {
          return new Date(a.start).getTime() - new Date(b.start).getTime();
        });
        return sortedBlocks;
      }),
    select: (data: BlockType[]) =>
      data.filter((block) => {
        // 새로 생성된 블록은 필터링 적용 안함
        const isNewBlock = block.id === 0;
        if (isNewBlock) {
          return block;
        }
        return block.spaceId && selectedSpaceIdList?.includes(block?.spaceId);
      }),
  };
};

export const useInboxCompletedQueryOptions = () => {
  const api = useApi();
  const params = {
    queryType: BlockRequestQueryTypeParam.TASK,
    itemStatus: ItemStatus.COMPLETED,
    sortType: BlockSort.COMPLETE,
  };

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  return {
    queryKey: inboxQueryKeys.inbox(params),
    queryFn: () =>
      blockAPI.getBlocks(api, params).then((res) => {
        const clientData = res.data.map((block) => {
          return convertServerToClientBlockType({
            serverBlock: block,
            defaultVisibility,
          });
        });
        return clientData;
      }),
    select: (data: BlockType[]) => {
      const recurrenceBlocks = extractLatestBlocksByOriginalId(data);
      const normalBlocks = data.filter((block) => !block.originalId);

      // spaceId 필터링
      const updatedBlocks = [...recurrenceBlocks, ...normalBlocks].filter((block) => {
        return block.spaceId && selectedSpaceIdList?.includes(block?.spaceId);
      });

      // completedAt을 기준으로 내림차순 정렬
      const sortedBlocks = updatedBlocks.sort((a, b) => {
        // completedAt이 없는 경우 가장 뒤로 정렬
        if (!a.completedAt) return 1;
        if (!b.completedAt) return -1;
        return new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime();
      });

      return sortedBlocks;
    },
  };
};

export const useTodayGeneralQueryOptions = () => {
  const inboxPlannedQueryOptions = useInboxPlannedQueryOptions();
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);

  return {
    ...inboxPlannedQueryOptions,
    select: (data: BlockType[]) => {
      return filterBlocksByToDayRange(data, selectedSpaceIdList);
    },
  };
};

export const useTodayCompletedQueryOptions = () => {
  const inboxCompletedOptions = useInboxCompletedQueryOptions();
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);

  return {
    ...inboxCompletedOptions,
    select: (data: BlockType[]) => {
      return filterBlocksByToDayRange(data, selectedSpaceIdList);
    },
  };
};
