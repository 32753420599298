import { CreateSpaceType } from "../../../types/space";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";

import { createSpaceApi } from "../core/useCreateSpaceMutation";
import { SpaceStatus } from "../../../types/space/enum";
import { spaceQueryKeys } from "../queries";

interface DuplicateDividerPayload {
  newDivider: CreateSpaceType;
}

export const useDuplicateDividerMutation = (
  options?: UseMutationOptions<CreateSpaceType, AxiosError, DuplicateDividerPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<CreateSpaceType, AxiosError, DuplicateDividerPayload>({
    mutationFn: async ({ newDivider }: DuplicateDividerPayload) => {
      return createSpaceApi(api, newDivider);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKeys.status(SpaceStatus.InProgress) });
    },
    ...options,
  });
};
