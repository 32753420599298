import { AxiosInstance, AxiosRequestConfig } from "axios";
import blockAPI from "../../api/BlockAPI";
import { ItemStatus } from "../../types/block/enum";
import {
  BlockRequestParamType,
  BlockResponseType,
  UpdateBlockRequestType,
} from "../../types/block/type";
import { ApiPathComposer } from "../../api/genPath";

// TODO: 공용으로 다른 곳에서도 사용할 수 있음
interface BlockRequest {
  // TODO: instance 주입 방식 삭제 필요
  instance: AxiosInstance;
  config?: AxiosRequestConfig;
}

// 일단 제나가 정의해놓은 타입 사용
type BlockQueryParams = BlockRequestParamType;

export interface RequestPayloadGetBlocks extends BlockRequest {
  params: BlockQueryParams;
}

export interface RequestPayloadGetBlockById extends BlockRequest {
  id: number;
}

// 아래는 ASIS types
interface BlockRequestWithId extends BlockRequest {
  id: number;
}

interface BlockRequestWithIds extends BlockRequest {
  ids: number[];
}

interface BlockRequestWithBlockData extends BlockRequest {
  id: number;
  data: UpdateBlockRequestType;
}

/** Pure Axios request functions for the block APIs
 * @author brandonwie
 * @description
 * - React Query 없이 사용할 수 있도록 정의
 * - ASIS convention을 따라 client는 injection 받도록 처리
 * - FYI. 현재 AxiosInstance는 hook으로 처리되어 component mount 이후에 사용 가능 (useApi hook 참고)
 */
class BlockRequests {
  // REVIEW: 우리가 상황에 따른 params 구분이 복잡하다보니까 case를 하나하나 request level부터 만들어 쓰는 과정에서 복잡해지지 않을까 싶어요.
  // 백엔드의 route별로 함수를 구분지으면 어떨까 생각해보았어요. 실제 instance만 주입받지 않는다고 가정하면 복잡하게 쓰지 않아도 사용할 수 있다고 판단했어요.
  static getBlocks({ instance, params, config }: RequestPayloadGetBlocks) {
    return instance.get<BlockResponseType[]>(ApiPathComposer.blocks, {
      ...config,
      params: {
        ...config?.params,
        ...params, // external param state으로 직접 주입한 params가 우선권
      },
    });
  }

  static getBlockById({ instance, id, config }: RequestPayloadGetBlockById) {
    return instance.get(ApiPathComposer.blockWithId(id), config);
  }

  // 아래는 ASIS
  static getCompletedBlocks(args: BlockRequest) {
    return blockAPI.getBlocks(args.instance, { itemStatus: ItemStatus.COMPLETED });
  }

  static getDeletedBlocks(args: BlockRequest) {
    return blockAPI.getBlocks(args.instance, { itemStatus: ItemStatus.DELETED });
  }

  static restoreBlock({ instance, id, data }: BlockRequestWithBlockData) {
    return blockAPI.updateBlockById(instance, id, data);
  }

  static deleteBlockById({ instance, id }: BlockRequestWithId) {
    return blockAPI.deleteBlockById(instance, id);
  }

  static deleteBlocks({ instance, ids }: BlockRequestWithIds) {
    return ids.map((id) => blockAPI.deleteBlockById(instance, id));
  }
}

export { BlockRequests };
