export default function formatDateTimeForJune(date) {
  // NOTE: date가 undefined인 경우에도 그냥 쓰이고 있음, 이 경우 changedDate에서 "Invalid Date" 반환 및 나머지 하위 코드들 NaN 반환하여 오류 발생 - 일단 keep
  const changedDate = new Date(date);

  const padZero = (num) => (num < 10 ? "0" + num : num);

  const year = changedDate.getFullYear();
  const month = padZero(changedDate.getMonth() + 1);
  const day = padZero(changedDate.getDate());
  const hours = padZero(changedDate.getHours());
  const minutes = padZero(changedDate.getMinutes());
  const seconds = padZero(changedDate.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
