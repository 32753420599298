import { Flex, IconButton } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import { priorityOptions } from "../../../../constants/block/TaskPriority";
import { getBasicStyles } from "../../../../views/TaskDetail/Header/PriorityButton";

import InboxBlockDateTime from "./InboxBlockDateTime";
import { BlockType } from "../../../../types/block/type";

interface InboxBlockRightSectionProps {
  data: BlockType;
  isOverdue: boolean;
  isTodayGeneral?: boolean;
}
const InboxBlockRightSection = ({
  isOverdue,
  isTodayGeneral,
  data,
}: InboxBlockRightSectionProps) => {
  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === data?.priority;
  })?.icon;

  return (
    <Flex
      gap={1.5}
      justifyContent={"flex-start"}
      alignItems={"center"}
      py={0.5}
    >
      {data && data.start && data.end && !(isTodayGeneral && data.allDay) && (
        <InboxBlockDateTime
          isOverdue={isOverdue}
          isTodayGeneral={isTodayGeneral ?? false}
          isAllday={data.allDay ?? false}
          startDateTime={new Date(data.start).toString()}
          endDateTime={new Date(data.start).toString()}
        />
      )}
      {currentPriorityIcon ? (
        <IconButton
          icon={currentPriorityIcon}
          boxSizing={"border-box"}
          aria-label={`Priority`}
          p={0}
          flexShrink={1}
          h={"full"}
          w={"full"}
          variant="outline"
          fontSize={pxToRem(14)}
          borderRadius={pxToRem(3)}
          minWidth={"initial"}
          boxSize={pxToRem(14)}
          _active={{}}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          cursor={"initial"}
          _hover={{ bgColor: "black" }}
          {...getBasicStyles(false, data.priority)}
        />
      ) : null}
    </Flex>
  );
};

export default InboxBlockRightSection;
