import { Text } from "@chakra-ui/react";
import { memo } from "react";
import { BasicBlockType } from "../../types/block/enum";
import { pxToRem } from "../../utils/styles/size";

interface CalendarTitleProps {
  title: string;
  blockType: BasicBlockType;
  onMouseOver: (e: React.MouseEvent) => void;
  onMouseLeave: () => void;
  containerSize: "smallEvent" | "largeEvent" | "mediumEvent";
}

const CalendarTitle = memo(
  ({ title, blockType, onMouseOver, onMouseLeave, containerSize }: CalendarTitleProps) => (
    <Text
      className="title"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      fontFamily="Pretendard Variable"
      fontWeight={400}
      fontSize={pxToRem(13)}
      whiteSpace={containerSize !== "smallEvent" ? "normal" : "nowrap"}
      textOverflow="ellipsis"
      noOfLines={containerSize !== "largeEvent" ? 1 : 2}
      overflow="hidden"
      lineHeight={containerSize !== ("smallEvent" || "mediumEvent") ? 1.2 : 1.4}
      position="relative"
      flex={containerSize !== "smallEvent" ? 1 : "unset"}
      display={containerSize !== "smallEvent" ? "-webkit-box" : "block"}
    >
      {title || `New ${blockType}`}
    </Text>
  )
);

// 디버깅을 위한 displayName 설정
CalendarTitle.displayName = "CalendarTitle";

export default CalendarTitle;
