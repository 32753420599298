import { useQueries } from "@tanstack/react-query";

import { getThreeMonthsStartAndEndAsISO } from "../utils/date-format/dateUtils";
import useApi from "../services/auth/useApi";

export const calendarKey = {
  all: ["meetWith"],
  calendarEvent: (params: Record<string, any>) => [...calendarKey.all, params],
};

export const useCalendarEventQueries = ({
  emailList,
  enabled = true,
}: {
  emailList: string[];
  enabled?: boolean;
}) => {
  const api = useApi();
  const { timeMin, timeMax } = getThreeMonthsStartAndEndAsISO(new Date());

  const params = (email: string) => ({
    timeMin,
    timeMax,
    email: email,
  });

  return useQueries({
    queries: emailList.map((email) => ({
      queryKey: calendarKey.calendarEvent(params(email)),
      queryFn: () =>
        api
          .get(`/contacts/blocks`, {
            params: params(email),
          })
          .then((res) => res.data),
      retry: 0,
      enabled,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        error: results.map((result) => result.error),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};
