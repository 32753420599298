import {
  defaultDurationState,
  startOfWeekState,
  timeFormatState,
} from "../../recoil/calendar/settingCalendar";
import { visibilityState } from "../../recoil/calendar/settingCalendarV2";
import useToggle from "../../utils/settings/useToggle";
import styles from "./Calendar.module.css";
import ToggleSwitch from "./ToggleSwitch";

const Calendar = () => {
  const [startOfWeek, toggleStartOfWeek] = useToggle(startOfWeekState);
  const [timeFormat, toggleTimeFormat] = useToggle(timeFormatState);
  const [defaultDuration, toggleDefaultDuration] = useToggle(defaultDurationState);
  const [visibility, toggleVisibility] = useToggle(visibilityState);

  const settings = [
    {
      title: "Start of week",
      options: ["Sunday", "Monday"],
      value: startOfWeek,
      toggle: toggleStartOfWeek,
    },
    {
      title: "Time format",
      options: ["12-hour", "24-hour"],
      value: timeFormat,
      toggle: toggleTimeFormat,
    },
    {
      title: "Default duration",
      options: ["15", "30", "60"],
      value: defaultDuration,
      toggle: toggleDefaultDuration,
    },
    {
      title: "Visibility",
      options: ["public", "deepwork", "invisible"],
      value: visibility,
      toggle: toggleVisibility,
    },
  ];

  return (
    <div className={styles.calendar__contents}>
      <span className={styles.title}>Calendar</span>
      <div className={styles.calendar__setting__list}>
        {settings.map((item) => (
          <ToggleSwitch
            key={item.title}
            title={item.title}
            options={item.options}
            selectedOption={item.value}
            onOptionChange={item.toggle}
          />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
