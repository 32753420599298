import { AxiosResponse } from "axios";
import { BlockType, UpdateBlockType, UpdateRecurrenceBlockType } from "../../types/block/type";
import { UseMutationResult } from "@tanstack/react-query";
import { ItemStatus, RecurringOption } from "../../types/block/enum";
import useHandleClientBlockStateChange from "../block/useHandleClientBlockStateChange";
import { juneTrack } from "../../utils/june/analytics";
import { JUNE_EVENT } from "../june/juneEvent";

type UpdateMutationParams = {
  id: number | string;
  payload: UpdateBlockType | UpdateRecurrenceBlockType;
  data?: BlockType;
};

type UpdateMutationResponse = AxiosResponse<any, any>;

type UpdateMutation = UseMutationResult<
  UpdateMutationResponse,
  Error,
  UpdateMutationParams,
  unknown
>;

const useHandleUpdateInboxCheckbox = () => {
  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();

  /**
   * 인박스 블록의 경우 해당 함수에서 june 체크박스 처리하도록 설정
   * WHY? 클릭한 블록의 위치를 추적하기 위해 location을 상위에서 1dpeth로 받아서 처리
   * @param block
   * @param updateMutation
   * @param location
   */
  const handleClickCheckBox = (
    block: BlockType,
    updateMutation: UpdateMutation,
    location: string
  ) => {
    const isDone = block.itemStatus === ItemStatus.COMPLETED;
    const newStatus = isDone ? ItemStatus.IN_PROGRESS : ItemStatus.COMPLETED;
    const updateData = {
      itemStatus: newStatus,
      allDay: block.allDay,
      hangoutLink: block.hangoutLink,
      meetingCode: block.meetingCode,
      linkData: block.linkData,
      attendees: block.attendees,
      location: block.location,
      priority: block.priority,
    };
    const isRecurringBlock =
      (block.recurrence && block.recurrence.length > 0) || Boolean(block.originalId);
    const updateBlock = {
      ...block,
      ...updateData,
    };
    // 반복 이벤트 this 수정시에는 꼭 originalStart를 넣어줘야함
    const updatePayload = isRecurringBlock
      ? {
          ...updateData,
          type: RecurringOption.THIS,
          originalStart: updateBlock.originalStart ?? updateBlock.start,
        }
      : updateData;

    isRecurringBlock
      ? handleClientRecurringBlockChange({
          prevBlockData: block,
          updateBlockData: updateBlock,
        })
      : handleClientBasicBlockChange(updateBlock);

    updateMutation.mutate({
      id: updateBlock.id,
      payload: updatePayload,
      data: updateBlock,
    });

    juneTrack(newStatus === ItemStatus.COMPLETED ? JUNE_EVENT.DONE_TASK : JUNE_EVENT.UNDONE_TASK, {
      location,
    });
  };

  return { handleClickCheckBox };
};

export default useHandleUpdateInboxCheckbox;
