import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { UseMutateFunction } from "@tanstack/react-query";

import PropertyTitle from "../Common/PropertyTitle";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";

import { ReactComponent as VideoIcon } from "../../../assets/TaskDetail/video-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../../assets/TaskDetail/duplicate-icon.svg";
import { ReactComponent as GoogleMeetIcon } from "../../../assets/TaskDetail/google-meet-icon.svg";
import { ReactComponent as EnterLinkIcon } from "../../../assets/TaskDetail/enter-link.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";
import LoadingComponent from "../../../compoenets/Common/LoadingComponent";

import styles from "./VideoModal.module.css";
import { accountState } from "../../../recoil/account/accountStateV2";

interface VideoModalProps {
  expand: boolean;
  videoLink: string;
  videoCreateModalRef: React.RefObject<HTMLDivElement>;
  mutateMeetingCode: UseMutateFunction<string, Error, string, unknown>;
  isPendingMeetingCode: boolean;
  meetingCode: string;
}

export default function VideoModal({
  expand,
  videoCreateModalRef,
  mutateMeetingCode,
  isPendingMeetingCode,
  meetingCode,
}: VideoModalProps) {
  const account = useRecoilValue(accountState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [isVideoCreateModalOpen, setIsVideoCreateModalOpen] = useState(false);

  const elementRef = useRef(null);

  const { data } = taskDetail;

  const handleCreateVideoCall = (linkName: string) => {
    if (linkName === "google meet") {
      mutateMeetingCode(account?.email ?? "");
    }
  };

  const getVideoItemPosition = () => {
    if (elementRef.current !== null) {
      const element = elementRef.current as HTMLElement;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.bottom + 5 + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
      };
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      videoCreateModalRef.current &&
      !videoCreateModalRef.current.contains(event.target as Node)
    ) {
      // 모달 외부 클릭 감지
      setIsVideoCreateModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [videoCreateModalRef]);

  const handleEnterGoogleMeetLink = () => {
    if (data != null && (data as any).hangoutLink != null) {
      window.open((data as any).hangoutLink, "_blank");
    }
  };

  const handleDeleteLink = () => {
    setTaskDetail({
      ...taskDetail,
      data: { ...(taskDetail.data as any), hangoutLink: null, meetingCode: "" },
    });
  };

  const handleCloseVideoCreateModalOnEscape = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "Escape") {
      setIsVideoCreateModalOpen(false);

      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }
  };

  return (
    <div
      className={styles["property--video"]}
      onKeyDown={handleCloseVideoCreateModalOnEscape}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <PropertyTitle Icon={VideoIcon} label="Video" expand={expand} />
      <div className={styles["property--video__contents"]} ref={elementRef}>
        {data && (data as any).hangoutLink ? (
          <div className={styles["property--video__contents-container"]}>
            <div className={styles["property--video__link"]} onClick={handleEnterGoogleMeetLink}>
              <GoogleMeetIcon />
              <span>Google meet</span>
              <button className={styles["property--video__enter"]}>
                <EnterLinkIcon />
              </button>
            </div>
            <button
              className={styles["property--video__copy"]}
              onClick={async (e) => {
                if (data != null && (data as any).hangoutLink != null) {
                  await navigator.clipboard.writeText((data as any).hangoutLink);
                } else if (meetingCode != null) {
                  await navigator.clipboard.writeText(`https://meet.google.com/${meetingCode}`);
                }
              }}
            >
              <DuplicateIcon className={styles["property--video__copy-icon"]} />
              <div className={styles.iconDescription}>Copy link</div>
            </button>
            <div className={styles.selectedGuestRowDelete} onClick={handleDeleteLink}>
              <div className={styles.iconDescription}>Delete</div>
              <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
            </div>
          </div>
        ) : (
          <div className={styles["property--video__contents-container"]}>
            {isPendingMeetingCode ? (
              <LoadingComponent
                size="24px"
                strokeWidth="4px"
                padding="3px"
                justifyContent="start"
              />
            ) : (
              <button
                className={styles.property__empty_title}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsVideoCreateModalOpen((prev) => !prev);
                }}
              >
                <span>Add video call</span>
              </button>
            )}
          </div>
        )}
        {isVideoCreateModalOpen &&
          createPortal(
            <div
              className={styles["modal"]}
              style={getVideoItemPosition()}
              ref={videoCreateModalRef}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleCreateVideoCall("google meet");
                  setIsVideoCreateModalOpen(false);
                }}
              >
                Google meet
              </button>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
}
