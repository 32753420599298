/**
 * @author brandonwie
 */
export const USER_ROLE = {
  TRIAL: "trial",
  INVITEE: "invitee",
  BELIEVER: "believer",
} as const;
/** Type representing possible user roles */
export type UserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE];

/** `GET /auth/me` 응답 타입 */
export interface ResponseUser {
  id: number;
  email: string;
  familyName: string | null;
  givenName: string | null;
  invitees: string[];
  onboarding: boolean;
  photo: string;
  role: UserRole;
  createdAt: string; // ISO string format (date-time)
  featureCheckedAt: string | null;
}
