import { BlockType } from "../../../types/block/type";

export const extractFirstBlocksByOriginalId = (blocks: BlockType[]) => {
  // originalId가 업으면 그대로 리턴
  const blocksWithOriginalId = blocks.filter((block) => block.originalId);
  if (blocksWithOriginalId.length === 0) return blocks;

  // 반복 이벤트 처리: originalId가 같은 것들 중 첫 번째만 선택
  const processedBlocks: BlockType[] = [];
  const originalIdMap = new Map<number, BlockType[]>();

  // 1. originalId가 있는 블록들을 그룹화
  blocks.forEach((block) => {
    if (block.originalId) {
      const id = block.originalId;
      if (!originalIdMap.has(id)) {
        originalIdMap.set(id, []);
      }
      originalIdMap.get(id)?.push(block);
    } else {
      // originalId가 없는 블록은 그대로 추가
      processedBlocks.push(block);
    }
  });

  // 2. 각 그룹에서 첫 번째 블록만 선택
  originalIdMap.forEach((blocks) => {
    // 첫 번째 블록만 추가
    if (blocks.length > 0) {
      processedBlocks.push(blocks[0]);
    }
  });
  return processedBlocks;
};
