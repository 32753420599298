import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useRecoilState } from "recoil";
import { inboxDraggableListState } from "../../recoil/taskList/inboxTaskListState";
import { draggedEventState } from "../../recoil/calendar/calendarStateV2";

import { BlockType } from "../../types/block/type";
import { BasicBlock, DragAccept, DragKind } from "../../types/block/enum";
import { getBlockTypeFlags } from "../../utils/common/block/getBlockTypeFlags";

export interface InboxDragItem {
  dragType: string;
  data: BlockType;
  index: number;
}

interface useDragAndDropProps {
  prevIndex: number;
  setPrevIndex: (newIndex: number) => void;
  data: BlockType;
  index: number;
  onReorder: (orderId: number[]) => void;
}

export const useInboxDragAndDrop = ({
  prevIndex,
  setPrevIndex,
  index,
  data,
  onReorder,
}: useDragAndDropProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [inboxDraggableList, setInboxDraggableList] = useRecoilState(inboxDraggableListState);
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);

  const isListDragging = useMemo(() => draggedEvent !== null, [draggedEvent]);
  const { isDivider } = getBlockTypeFlags(data.blockType);

  const getDragItem = useCallback(() => {
    if (!isDivider) {
      setDraggedEvent({
        ...data,
        dragType: DragKind.INBOX_ITEM,
        index: index,
      });
    }

    const dragItem = { dragType: DragKind.INBOX_ITEM, data, index };

    return dragItem;
  }, [data, index, isDivider, setDraggedEvent]);

  const [draggingItem, drag, preview] = useDrag({
    type: DragAccept.INBOX,
    item: getDragItem,
    collect: useCallback((monitor: DragSourceMonitor) => {
      const item = monitor.getItem() as { dragType: string };
      if (item && item.dragType !== DragKind.INBOX_ITEM) {
        return;
      }

      return {
        isDragging: monitor.isDragging(),
        item: monitor.getItem() as InboxDragItem,
      };
    }, []),

    // end: () => {
    //   setDraggedEvent(null);
    // },
  });
  const { item, isDragging = false } = draggingItem ?? {};

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    const transformValue = 100;
    if (prevIndex !== index && isListDragging && !isDragging) {
      const animation = ref.current?.animate(
        [
          { transform: `translateY(${(prevIndex - index) * transformValue}%)` },
          { transform: "translateY(0%)" },
        ],
        {
          duration: 150,
          easing: "ease-in-out",
        }
      );
      if (animation) {
        animation.onfinish = () => {
          setPrevIndex(index);
        };
      }
    } else {
      // 드래그가 아닌 경우는 애니메이션 없이 바로 업데이트
      setPrevIndex(index);
    }
  }, [index, prevIndex, isDragging, isListDragging]);

  const [, drop] = useDrop({
    accept: DragAccept.INBOX,
    hover: (item: InboxDragItem, monitor: any) => {
      if (!ref.current || isDragging) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const dragIndex = item.index;
      const hoverIndex = index;
      const isDragItemDivider = item.data.blockType === BasicBlock.DIVIDER;

      // 디바이더는 작은 높이를 가졌기 때문에, 이동 감도를 줄이기 위해 70% threshold 설정
      // 일반 항목은 50% 기준으로 처리 (절반을 넘어가면 moveProject 트리거)
      const threshold = isDragItemDivider ? hoverHeight * 0.7 : hoverHeight / 2;

      if (
        (dragIndex < hoverIndex && hoverClientY < threshold) ||
        (dragIndex > hoverIndex && hoverClientY > hoverHeight - threshold)
      ) {
        return;
      }

      moveInboxList(dragIndex, hoverIndex);
    },
    drop() {
      if (inboxDraggableList) {
        onReorder(inboxDraggableList.map((block) => block.id));
      }
    },
  });

  const moveInboxList = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === hoverIndex || !item || !inboxDraggableList) {
        return;
      }

      const updatedList = [...inboxDraggableList];
      const [movedItem] = updatedList.splice(dragIndex, 1); // 드래그된 아이템 삭제
      updatedList.splice(hoverIndex, 0, movedItem);
      setInboxDraggableList(updatedList);
      item.index = hoverIndex;
    },
    [item, inboxDraggableList]
  );

  drag(drop(ref));
  // const shouldShowPreview = item && item.index === index && item.dragType === DragKind.INBOX_ITEM;

  return { ref, isDragging };
};
