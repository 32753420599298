import { Flex } from "@chakra-ui/react";
import { InboxBlockPositionType, InboxBlockStatusType } from "../../../../types/block/BlockType";
import InboxBlock from "../InboxBlock/InboxBlock";
import { BlockType } from "../../../../types/block/type";
import {
  InboxTab,
  InboxTabType,
  InboxToggleTitle,
  InboxToggleTitleType,
} from "../../../../types/inbox/enum";
import {
  useInboxCompletedQueryOptions,
  useInboxOverdueQueryOptions,
  useInboxPlannedQueryOptions,
  useTodayCompletedQueryOptions,
} from "../../../../react-query/inbox/queries";
import InboxToggle from "./Toggle/InboxToggle";
import { useQuery } from "@tanstack/react-query";
import { BasicBlock } from "../../../../types/block/enum";
import useHandleJuneBlockData from "../../../../hooks/june/useHandleJuneBlockdata";
import useHandleInboxBlockDelete from "../../../../hooks/inbox/useHandleInboxBlockDelete";
import { useDeleteInboxBlockMutation } from "../../../../react-query/inbox/useDeleteInboxBlockMutation";
import { useUpdateInboxCheckboxMutation } from "../../../../react-query/inbox/useUpdateInboxCheckboxMutation";
import useHandleUpdateInboxCheckbox from "../../../../hooks/inbox/useHandleUpdateInboxCheckbox";
import { juneTrack } from "../../../../utils/june/analytics";
import { JUNE_EVENT } from "../../../../hooks/june/juneEvent";

interface InboxToggleListProps {
  activeTab: InboxTabType;
  title: InboxToggleTitleType;
  onDataDuplicate: (e: React.MouseEvent, data: BlockType) => void;
  taskSelectedId: number | null;
  onSelect: (id: number | null) => void;
  onClickInboxBlock: (
    e: React.MouseEvent,
    data: BlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
  /** 백엔드가 보내는 block들과 Google Calendar 동기화 여부 */
  hasBlocksSynchronizedWithGoogle: boolean;
}

const InboxToggleList = ({
  title,
  activeTab,
  onDataDuplicate,
  taskSelectedId,
  onSelect,
  onClickInboxBlock,
  hasBlocksSynchronizedWithGoogle,
}: InboxToggleListProps) => {
  const handleJuneBlockData = useHandleJuneBlockData();

  const inboxToggleQueryOptions: Record<
    InboxTabType,
    Partial<Record<InboxToggleTitleType, any>>
  > = {
    [InboxTab.INBOX]: {
      [InboxToggleTitle.PLANNED]: useInboxPlannedQueryOptions(),
      [InboxToggleTitle.OVERDUE]: useInboxOverdueQueryOptions(),
      [InboxToggleTitle.COMPLETED]: useInboxCompletedQueryOptions(),
    },
    [InboxTab.TODAY]: {
      [InboxToggleTitle.OVERDUE]: useInboxOverdueQueryOptions(),
      [InboxToggleTitle.COMPLETED]: useTodayCompletedQueryOptions(),
    },
  };

  const inboxToggleQueryOption = inboxToggleQueryOptions[activeTab][title];
  const useInboxToggleQuery = useQuery<BlockType[]>({
    ...inboxToggleQueryOption,
    enabled: hasBlocksSynchronizedWithGoogle,
  });

  const useDeleteInboxBlock = useDeleteInboxBlockMutation(inboxToggleQueryOption.queryKey);
  const useUpdateInboxCheckbox = useUpdateInboxCheckboxMutation(inboxToggleQueryOption.queryKey);
  const { handleDeleteBlock } = useHandleInboxBlockDelete();
  const { handleClickCheckBox } = useHandleUpdateInboxCheckbox();

  const handleDelete = (block: BlockType) => {
    handleDeleteBlock(block, useDeleteInboxBlock);
    juneTrack(JUNE_EVENT.DELETE_BLOCK, {
      location: activeTab === "Inbox" ? "inbox" : "today_inbox",
      blockType: BasicBlock.TASK,
    });
  };

  const onClickCheckBox = (e: React.MouseEvent, data: BlockType) => {
    e.stopPropagation();
    handleClickCheckBox(
      data,
      useUpdateInboxCheckbox,
      activeTab === "Inbox" ? "inbox" : "today_inbox"
    );
  };

  if (!useInboxToggleQuery.data) return null;

  return (
    <InboxToggle
      title={title}
      number={useInboxToggleQuery.data.length ?? 0}
      onToggle={() => {}}
    >
      <Flex
        flexDirection="column"
        gap={2}
      >
        {useInboxToggleQuery.data.map((task, index) => (
          <InboxBlock
            data={task}
            index={index}
            key={index}
            onDataDuplicate={onDataDuplicate}
            onDataDelete={handleDelete}
            status={title as InboxBlockStatusType}
            position={InboxBlockPositionType.Toggle}
            isSelected={taskSelectedId === task.id}
            onSelect={onSelect}
            onClickBlock={onClickInboxBlock}
            onClickCheckBox={onClickCheckBox}
          />
        ))}
      </Flex>
    </InboxToggle>
  );
};
export default InboxToggleList;
