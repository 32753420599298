import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { RecurringOption, RecurringOptionType } from "../../types/block/enum";
import { BlockType } from "../../types/block/type";

function useHandleClientBlockStateDelete() {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  const handleClientBlockDelete = ({
    updateBlockData,
    recurringOption,
  }: {
    updateBlockData: BlockType;
    recurringOption?: RecurringOptionType;
  }) => {
    if (recurringOption === RecurringOption.ALL) {
      const { originalId } = updateBlockData;
      setMobaCalendarList((current) => {
        const updatedEvents = current.filter((event) => {
          const isRecurringBlock = event.originalId === originalId; // 같은 모체의 반복 인스턴트인 경우

          return !isRecurringBlock;
        });
        return updatedEvents;
      });
      return;
    }

    if (recurringOption === RecurringOption.THISANDAFTER) {
      const { start, originalId, originalStart } = updateBlockData;
      const eventStartDate = originalStart ? originalStart : start;

      if (!eventStartDate) return;

      const selectedEventStart = new Date(eventStartDate).getTime();

      setMobaCalendarList((current) => {
        const updatedEvents = current.filter((event) => {
          if (!event.start) return event;

          const eventStart = new Date(event.start).getTime();
          const isRecurringBlock = event.originalId === originalId; // 같은 모체의 반복 인스턴트인 경우
          const isAfterSelectedEvent = selectedEventStart <= eventStart;

          return !(isRecurringBlock && isAfterSelectedEvent);
        });
        return updatedEvents;
      });
      return;
    }

    setMobaCalendarList((prev) => prev.filter((block) => block.id !== updateBlockData.id));
  };

  return { handleClientBlockDelete };
}

export default useHandleClientBlockStateDelete;
