import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inboxQueryKeys } from "./queries";
import { useUpdateInboxBlockMutationOptions } from "./mutations";
import { BlockType } from "../../types/block/type";
import { calendarQueryKeys } from "../calendar/queries";
import useFindUpdateInboxQueryKey from "../../hooks/inbox/useFindUpdateInboxQueryKey";

// inbox checkbox 업데이트 mutation 훅
// inbox checkbox 업데이트는 해당 영역 + 이동된 영역 모두 업데이트 해야함
export const useUpdateInboxCheckboxMutation = (
  invalidateQueryKey: ReturnType<typeof inboxQueryKeys.inbox>
) => {
  const queryClient = useQueryClient();
  const { findUpdateInboxQueryKey } = useFindUpdateInboxQueryKey();

  return useMutation({
    ...useUpdateInboxBlockMutationOptions(),
    onMutate: async ({ data }: { data?: BlockType }) => {
      // 수정한 inbox 영역 낙관적 업데이트(블록 제거)
      await queryClient.cancelQueries({ queryKey: invalidateQueryKey });
      const previousData = queryClient.getQueryData<BlockType[]>(invalidateQueryKey);
      if (previousData) {
        queryClient.setQueryData<BlockType[]>(
          invalidateQueryKey,
          previousData.filter((block) => block.id !== data?.id)
        );
      }

      // 이동된 영역 낙관적 업데이트(블록 추가)
      const updateInboxQueryKey = data && findUpdateInboxQueryKey(data);

      if (updateInboxQueryKey) {
        const updateData = { ...data, completedAt: new Date().toISOString() };
        await queryClient.cancelQueries({ queryKey: updateInboxQueryKey });
        const previousData = queryClient.getQueryData<BlockType[]>(updateInboxQueryKey);
        if (previousData) {
          queryClient.setQueryData<BlockType[]>(updateInboxQueryKey, [updateData, ...previousData]);
        }
      }

      return { previousData };
    },
    onSuccess: (data, variables, context) => {
      // 반복인 경우에만 invalidate 처리
      const isRecurringBlock = Boolean(variables.data?.originalId);
      if (isRecurringBlock) {
        queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
        queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      }
    },
    onError: (err, variables, context) => {
      // 에러 발생 시 이전 데이터로 롤백
      if (context?.previousData) {
        queryClient.setQueryData(invalidateQueryKey, context.previousData);
      }
      queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all });
      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.all });
    },
  });
};
