import { Box, Icon, Link } from "@chakra-ui/react";
// NOTE GoogleMeetIcon의 경우 svg 요소가 복잡해 svg 파일 자체로 사용
import { ReactComponent as GoogleMeetIcon } from "../../assets/Main/icon-meet.svg";
import { GoogleMeetHoverIcon } from "../../icons";

interface CalendarHangoutLinkProps {
  hangoutLink: string;
}

export const CalendarHangoutLink = ({ hangoutLink }: CalendarHangoutLinkProps) => {
  return (
    <Link
      href={hangoutLink}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
      position="relative"
      display="block"
      w="14px"
      h="14px"
      m="2px 0"
      _hover={{
        ".blockLinkIcon": {
          display: "flex",
        },
      }}
    >
      <Box
        className="blockLinkIcon"
        display="none"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        w="14px"
        h="14px"
        top="0"
        left="0"
        borderRadius="3px"
        border="1px solid rgba(255, 255, 255, 0.4)"
        bg="rgba(0, 0, 0, 0.7)"
        boxSizing="border-box"
        zIndex="100"
      >
        <Icon as={GoogleMeetHoverIcon} w="8px" h="8px" />
      </Box>
      <Box
        position="absolute"
        top="0"
        left="0"
        minW="14px"
        minH="14px"
        borderRadius="3px"
        bgImage="url(/assets/Main/icon-meet.svg)" // 절대 경로로 시도
        bgRepeat="no-repeat"
        bgPosition="center"
        bgSize="contain"
      >
        <Icon as={GoogleMeetIcon} w="14px" h="14px" />
      </Box>
    </Link>
  );
};
