import { Flex } from "@chakra-ui/react";

import InboxBlockSummary from "./InboxBlockSummary";
import InboxBlockCheckbox from "./InboxBlockCheckbox";

import { pxToRem } from "../../../../utils/styles/size";
import InboxBlockRightSection from "./InboxBlockRightSection";
import { BlockType } from "../../../../types/block/type";
import { ItemStatus } from "../../../../types/block/enum";

interface InboxBlockBodyProps {
  data: BlockType;
  isOverdue: boolean;
  isTodayGeneral?: boolean;
  isCompleted: boolean;
  onClickCheckBox: (e: React.MouseEvent, data: BlockType) => void;
}

const InboxBlockBody = ({
  data,
  isOverdue,
  isTodayGeneral,
  isCompleted,
  onClickCheckBox,
}: InboxBlockBodyProps) => {
  return (
    <Flex
      borderRadius={pxToRem(5)}
      padding={`${pxToRem(6)} ${pxToRem(6)} ${pxToRem(6)} ${pxToRem(2)} `}
      alignItems={"flex-start"}
      flex={1}
      gap={pxToRem(6)}
      id={"inbox-block-body"}
      minWidth={0}
    >
      <InboxBlockCheckbox
        isCompleted={data?.itemStatus === ItemStatus.COMPLETED}
        projectId={data?.spaceId}
        onClick={(e) => onClickCheckBox(e, data)}
      />
      <InboxBlockSummary title={data.title ?? "No title"} isCompleted={isCompleted} />
      <InboxBlockRightSection data={data} isOverdue={isOverdue} isTodayGeneral={isTodayGeneral} />
    </Flex>
  );
};

export default InboxBlockBody;
