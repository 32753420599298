import { atom } from "recoil";

export const guestPopupState = atom({
  key: "guestPopupState",
  default: {
    type: "", // delete, update, etc.
    headerTitle: "",
    isVisible: false,
    clickType: "", // ok, cancel, discard
    message: "",
    okButtonTitle: "",
    dontSendButtonTitle: "",
    discardButtonTitle: "",
    isDataDuplicateWithGuest: false,
    data: null,
    resolve: null, // Promise resolve 함수
    reject: null, // Promise reject 함수
  },
});
