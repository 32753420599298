import moment from "moment";

export const getLastDayOfTwoMonthsLater = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM");
  const lastDay = date.add(2, "months").endOf("month").toISOString();
  return lastDay;
};

/**
 * Create a Date object for the first day of a given month
 * @param monthStr Format: 'YYYY-MM'
 */
const createFirstDayOfMonth = (monthStr: string): Date => {
  const momentDate = moment(monthStr, "YYYY-MM");
  return momentDate.startOf("month").toDate();
};

/**
 * Create a Date object for the first day of the next month
 * @param monthStr Format: 'YYYY-MM'
 */
const createFirstDayOfNextMonth = (monthStr: string): Date => {
  const momentDate = moment(monthStr, "YYYY-MM");
  return momentDate.add(1, "month").startOf("month").toDate();
};

/**
 * Convert a Date object to ISO string
 */
export const convertDateToISOString = (date: Date): string => {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};

/**
 * Get the start and end dates as ISO strings for a given month
 * @param monthStr Format: 'YYYY-MM' (e.g., '2024-02')
 * 캘린더 데이터 fetch 용도, start, end가 시간 상 거꾸로 되어있음.
 */
export const getMonthStartAndEndAsISO = (monthStr: string) => {
  const start = createFirstDayOfNextMonth(monthStr); // 다음달 1일 (3월 1일)
  const end = createFirstDayOfMonth(monthStr); // 현재달 1일 (2월 1일)

  return {
    start: convertDateToISOString(start),
    end: convertDateToISOString(end),
  };
};

/**
 * MeetWith 데이터 fetch 용도
 * @param today Date(오늘 날짜)
 * 오늘 기준으로 1개월 전후 데이터를 가져오는 로직
 * 이때, 지난 달 1일부터 2달 뒤 1일까지 데이터를 가져옴.
 */

export const getThreeMonthsStartAndEndAsISO = (today: Date) => {
  const lastMonth = moment(today).subtract(1, "months").format("YYYY-MM");
  const twoMonthsLater = moment(today).add(1, "months").format("YYYY-MM");
  const start = createFirstDayOfMonth(lastMonth); // 현재달 1일
  const end = createFirstDayOfNextMonth(twoMonthsLater); // 2달뒤 1일

  return { timeMin: convertDateToISOString(start), timeMax: convertDateToISOString(end) };
};
export const getDayStart = (day: Date | string) => {
  const START_OF_DAY = new Date(new Date(day).setHours(0, 0, 0, 0));
  return START_OF_DAY;
};

export const getDayEnd = (day: Date | string) => {
  const END_OF_DAY = new Date(new Date(day).setHours(23, 59, 59));
  return END_OF_DAY;
};

export const getDayBoundaries = ({ day, isAllday }: { day: Date | string; isAllday: boolean }) => {
  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

  const TARGET_DAY = new Date(day);
  const START_OF_DAY = getDayStart(TARGET_DAY);
  const START_OF_TOMORROW = new Date(START_OF_DAY.getTime() + MILLISECONDS_PER_DAY);
  const END_OF_DAY = isAllday ? START_OF_TOMORROW : getDayEnd(TARGET_DAY);

  return {
    START_OF_DAY,
    END_OF_DAY,
  };
};

export const getDayBoundariesISOString = ({ day, isAllday }: { day: Date; isAllday: boolean }) => {
  const { START_OF_DAY, END_OF_DAY } = getDayBoundaries({ day, isAllday });
  return {
    START_OF_DAY: moment(START_OF_DAY).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    END_OF_DAY: moment(END_OF_DAY).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  };
};
