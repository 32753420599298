import React, { forwardRef } from "react";
import { Center, CenterProps, Circle } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

interface SpaceCircleProps extends CenterProps {
  spaceColor: string;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export const SpaceCircle = forwardRef<HTMLDivElement, SpaceCircleProps>(
  ({ spaceColor, isSelected, onClick, ...props }, ref) => {
    return (
      <Center
        p={1.5}
        onClick={onClick}
        borderRadius={"full"}
        cursor={"pointer"}
        ref={ref}
        {...props}
      >
        <Circle
          size={pxToRem(16)}
          bg={isSelected ? hexToRgba(spaceColor, 0.5) : "transparent"}
          border={"1.5px solid"}
          borderColor={spaceColor}
        />
      </Center>
    );
  }
);

SpaceCircle.displayName = "SpaceCircle";
