import React, { useEffect, useState } from "react";
import { SideBarGroupSection } from "./SideBarGroupSection";
import { SpaceItemTitleSection } from "./SpaceItemTitleSection";
import { SpaceSideBarItem } from "./SpaceSideBarItem";
import { usePrevious } from "@chakra-ui/react";
import { useCreateSpaceMutation } from "../../../react-query/space/core/useCreateSpaceMutation";
import { customScrollbar, useScrollBarStyle } from "../../../hooks/useScrollBarStyle";
import { createDividerPayload, createSpacePayload } from "../../../services/space/space.service";
import { useMoveToTrashSpaceMutation } from "../../../react-query/space/deletion/useMoveToTrashSpaceMutation";
import { useMarkSpaceDoneMutation } from "../../../react-query/space/status/useMarkSpaceDoneMutation";
import { useUpdateSpaceMutation } from "../../../react-query/space/core/useUpdateSpaceMutation";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  selectedSpaceIdListState,
  selectedSpaceIdListStateSelector,
} from "../../../recoil/spaces/selectedSpaceIdListState";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { useReorderSpacesMutation } from "../../../react-query/space/core/useReorderSpaceMutation";
import { NoSpaceFilterItem } from "./NoSpaceFilterItem";
import { SpaceIdType, SpaceType } from "../../../types/space";

import {
  _inProgressSpaceListState,
  inProgressAndCompletedSpaceListState,
  spaceTypesSelector,
} from "../../../recoil/spaces/inProgressSpaceListState";

import { SpaceDividerItem } from "./SpaceDividerItem";
import { pxToRem } from "../../../utils/styles/size";
import { useDuplicateDividerMutation } from "../../../react-query/space/divider/useDuplicateDividerMutation";
import { mobaCalendarListState } from "../../../recoil/calendar/mobaCalendarListState";
import { SpaceKind, SpaceStatus } from "../../../types/space/enum";
import { BlockType } from "../../../types/block/type";
import { JUNE_EVENT, JUNE_LOCATION } from "../../../hooks/june/juneEvent";
import { spaceQueryKeys } from "../../../react-query/space/queries";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteSpaceMutation } from "../../../react-query/space/mutations";
import {
  useFetchArchivedSpaceQuery,
  useFetchSpaceQuery,
} from "../../../react-query/space/core/useFetchSpaceQuery";

interface SpacesSectionProps {
  isExpanded: boolean;
  onOpenSidebar: () => void;
}

// types.ts
export type HandleSpaceReorderType = (orderId: SpaceIdType[]) => void;

export const SpacesSection: React.FC<SpacesSectionProps> = ({ isExpanded, onOpenSidebar }) => {
  const queryClient = useQueryClient();

  // 기본 setting을 위한 recoil state
  // - 원래는 useFetchSpaceQuery.ts에서 하던 process을 밖으로 꺼냄
  // 노출에 사용할 아카이브 space를 포함한 상태
  const setInProgressAndCompletedSpaces = useSetRecoilState(inProgressAndCompletedSpaceListState);
  // 진행 중인 space 목록 (NOTE: 첫 세팅에 쓰이는 건  setter를 통해 mutation을 하지 않고 직접 세팅함)
  //- WHY? 아래보면 `setInProgressAndCompletedSpaces`에 직접 inProgressSpaces를 설정해주기 떄문에
  const [inProgressSpaceList, setInProgressSpaceList] = useRecoilState(_inProgressSpaceListState);
  // 선택 중인 space ID 목록

  const selectedInProgressSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);

  const setSelectedSpaceIdListState = useSetRecoilState(selectedSpaceIdListState);
  const inProgressSpaceListWithoutDivider = useRecoilValue(
    spaceTypesSelector([SpaceKind.Default, SpaceKind.Normal])
  );

  const inProgressSpacesLength = inProgressSpaceListWithoutDivider?.length ?? 0;
  const selectedInProgressSpaceLength = selectedInProgressSpaceIdList.length;

  const hasInProgressSpaces = inProgressSpacesLength > 0;
  const isAllSelected = selectedInProgressSpaceLength >= inProgressSpacesLength; // add no space filter
  const shouldShowAll = hasInProgressSpaces && !isAllSelected;

  const setSelectedSpaceIdList = useSetRecoilState(selectedSpaceIdListState);

  const { isScrolling } = useScrollBarStyle(sideBarGroupId);
  const prevIsExpanded = usePrevious(isExpanded);
  const [isInputVisible, setInputVisible] = useState(false);
  const trackCall = useJuneTrackCall();
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  const { mutate: createSpace } = useCreateSpaceMutation();
  const { mutate: reorderMutate } = useReorderSpacesMutation();
  const { mutate: moveToTrashSpace } = useMoveToTrashSpaceMutation();
  const { mutate: archiveSpace } = useMarkSpaceDoneMutation();
  const { mutate: updateSpace } = useUpdateSpaceMutation();
  const { mutate: deleteSpace } = useDeleteSpaceMutation({});
  const { mutate: duplicateDivider } = useDuplicateDividerMutation();

  /** Space 목록 조회 */
  // NOTE: 보수하기 쉽게 일단 space 목록 불러오는 곳에서 inProgress, completed 목록 같이 불러옴
  const { isLoading: isLoadingInProgressSpaces } = useFetchSpaceQuery();
  const { data: completedSpaces } = useFetchArchivedSpaceQuery();

  // @brandonwie 구조상 한계로 이렇게 작성되었습니다.
  // 완료된 space와 아카이브된 space를 등록하는 유일한 장소
  useEffect(() => {
    // display 용도의 목록 생성 (WHY? 아카이브는 보이지는 않지만 색상은 표시해줘야 함)
    if (completedSpaces) {
      setInProgressAndCompletedSpaces((prev) => {
        const existingItemsMap = new Map(prev.map((item) => [item.id, item]));

        completedSpaces.forEach((compSpace) => {
          existingItemsMap.set(compSpace.id, compSpace);
        });

        // unique input
        return Array.from(existingItemsMap.values());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSpaces]);

  const handleAddClick = () => {
    onOpenSidebar();
    setInputVisible(true);
  };

  const handleShowAll = () => {
    const allSpaceList = inProgressSpaceList?.map((space) => space.id) ?? [];
    setSelectedSpaceIdList([...allSpaceList]);
  };

  const handleSubmit = (newTitle: string) => {
    const newItem = createSpacePayload(newTitle, inProgressSpaceList);
    setSelectedSpaceIdList([...selectedInProgressSpaceIdList]);
    createSpace(newItem, {
      onSuccess: () => {
        trackCall(JUNE_EVENT.CREATE_SPACE, { location: JUNE_LOCATION.SPACE_FILTER });
      },
    });
    setInputVisible(false);
  };

  const handleClickSpace = (e: React.MouseEvent, id: SpaceIdType) => {
    // NOTE space circle 클릭 시 Space목록으로 이벤트 버블링 방지
    e.stopPropagation();

    trackCall(JUNE_EVENT.CLICK_SPACE_FILTER);
    // 전체선택에서 하나 눌렀을 때(!shouldShowAll) -> 하나만 선택
    if (!shouldShowAll) {
      setSelectedSpaceIdList([id]);
      return;
    }

    // 이미 클릭되어 있던 걸 다시 클릭 시
    if (id && selectedInProgressSpaceIdList?.includes(id)) {
      if (selectedInProgressSpaceIdList.length === 1) {
        // 선택된 space가 1개일 때 -> 다시 전체 선택으로
        handleShowAll();
        return;
      }

      // 선택된 space가 1개가 아닐 때 -> 해당 space 해제
      setSelectedSpaceIdList(selectedInProgressSpaceIdList.filter((spaceId) => spaceId !== id));
    } else {
      // 선택되어 있지 않은 space 선택 시 -> 해당 space 추가
      setSelectedSpaceIdList([...selectedInProgressSpaceIdList, id]);
    }
  };

  const handleArchive = (id: SpaceIdType) => {
    archiveSpace(id, {
      onSuccess: () => {
        trackCall(JUNE_EVENT.COMPLETE_SPACE, { location: JUNE_LOCATION.SPACE_FILTER });
      },
    });
  };

  const handleDeleteSpace = (spaceId: number) => {
    setMobaCalendarList((prev) =>
      prev.map((event: BlockType) => {
        if (event.spaceId && event.spaceId === spaceId) {
          const { spaceId, ...rest } = event;
          return {
            ...rest,
          };
        }
        return event;
      })
    );
  };

  const handleDelete = (id: SpaceIdType) => {
    handleDeleteSpace(id);
    moveToTrashSpace(id, {
      onSuccess: () => {
        trackCall(JUNE_EVENT.DELETE_SPACE, { location: JUNE_LOCATION.SPACE_FILTER });
      },
    });
  };

  const handleUpdate = (id: SpaceIdType, payload: Partial<SpaceType>) =>
    updateSpace({ id, payload });

  const handleCreateDivider = () => {
    const newItem = createDividerPayload();
    createSpace(newItem, {
      onSuccess: () => {
        trackCall(JUNE_EVENT.CREATE_DIVIDER, { location: JUNE_LOCATION.SIDEBAR });
      },
    });
    setInputVisible(false);
  };

  const handleDuplicateDivider = (displayOrder: SpaceIdType) => {
    if (displayOrder === null || undefined) return;
    const newDivider = createDividerPayload(displayOrder);

    duplicateDivider(
      { newDivider },
      {
        onSuccess: () => {
          trackCall(JUNE_EVENT.DUPLICATE_DIVIDER, { location: JUNE_LOCATION.SIDEBAR });
        },
        onError: (err) => {
          console.error("Error duplicating divider:", err);
        },
      }
    );
  };

  const handleDeleteDivider = (selectedDividerId: SpaceIdType) => {
    deleteSpace(selectedDividerId, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: spaceQueryKeys.status(SpaceStatus.InProgress),
        });

        trackCall(JUNE_EVENT.DELETE_DIVIDER, { location: JUNE_LOCATION.SIDEBAR });
      },
    });
  };

  const handleReorder: HandleSpaceReorderType = (orderId: SpaceIdType[]) => {
    reorderMutate({
      ids: orderId,
    });
  };

  // NOTE v2 마이그레이션 하면서 생긴 spacefilter가 모두 해제된 경우 처리
  useEffect(() => {
    // 아직 inProgressSpaceList가 불러와 지지 않은 경우 return
    if (!inProgressSpaceList) return;

    // Normal, Default(no space) 중 켜진 필터가 하나도 없는 경우 (있을 수 없음)
    const onlyInProgressNormalSpace = inProgressSpaceList
      .filter((space) => space.spaceType !== SpaceKind.Divider)
      .map((space) => space.id);

    const isNoneSelected = onlyInProgressNormalSpace.every(
      (id) => !selectedInProgressSpaceIdList.includes(id)
    );

    // 아무것도 선택되지 않은 경우 전체 선택으로 변경
    if (isNoneSelected) {
      setSelectedSpaceIdListState(onlyInProgressNormalSpace);
    }
  }, [inProgressSpaceList]);

  useEffect(() => {
    if (prevIsExpanded && !isExpanded) {
      setInputVisible(false);
    }
  }, [isExpanded, prevIsExpanded]);

  return (
    <SideBarGroupSection
      id={sideBarGroupId}
      w={"full"}
      flex="1 1 auto"
      sx={customScrollbar(isScrolling, isExpanded)}
      overflowY="auto"
      gap={pxToRem(5)}
      isExpanded={isExpanded}
      isScrolling={isScrolling}
    >
      <SpaceItemTitleSection
        isExpanded={isExpanded}
        isInputVisible={isInputVisible}
        setInputVisible={setInputVisible}
        onCreateSpace={handleAddClick}
        shouldShowAll={shouldShowAll}
        onShowAll={handleShowAll}
        onSubmit={handleSubmit}
        onCreateDivider={handleCreateDivider}
      />

      <>
        {!isLoadingInProgressSpaces &&
          inProgressSpaceList?.map((space, index) => {
            if (space.spaceType === SpaceKind.Divider) {
              return (
                <SpaceDividerItem
                  data={space}
                  key={space.id}
                  onDelete={() => handleDeleteDivider(space.id)}
                  onReorder={handleReorder}
                  onDuplicate={() => handleDuplicateDivider(space.displayOrder ?? 0)}
                  index={index}
                />
              );
            }

            if (space.spaceType === SpaceKind.Default) {
              return (
                <NoSpaceFilterItem
                  data={space}
                  key={space.id}
                  isExpanded={isExpanded}
                  index={index}
                  isSelected={selectedInProgressSpaceIdList.includes(space.id)}
                  onClickSpaceCircle={(e: React.MouseEvent) => handleClickSpace(e, space.id)}
                  onReorder={handleReorder}
                />
              );
            }

            return (
              <SpaceSideBarItem
                key={space.id}
                data={space}
                index={index}
                isSelected={selectedInProgressSpaceIdList.includes(space.id)}
                isExpanded={isExpanded}
                onArchive={() => handleArchive(space.id)}
                onDelete={() => handleDelete(space.id)}
                onUpdate={(payload) => handleUpdate(space.id, payload)}
                onClickSpaceCircle={(e: React.MouseEvent) => handleClickSpace(e, space.id)}
                onReorder={handleReorder}
              />
            );
          })}
      </>
    </SideBarGroupSection>
  );
};

const sideBarGroupId = "spaces-section-element";
