import { Box, Flex } from "@chakra-ui/react";
import { ItemStatus, RecurringOption } from "../../types/block/enum";
import { useMemo, useState } from "react";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DEFAULT_SPACE_COLOR } from "../../constants/space";
import useHandleClientBlockStateChange from "../../hooks/block/useHandleClientBlockStateChange";
import { BlockType } from "../../types/block/type";
import { useUpdateCalendarCheckboxMutation } from "../../react-query/calendar/useUpdateCalendarCheckboxMutation";
import { toastState } from "../../recoil/toast/toastState";
import { juneTrack } from "../../utils/june/analytics";
import { JUNE_EVENT } from "../../hooks/june/juneEvent";

interface CalendarCheckBoxProps {
  spaceColor: string;
  block: BlockType;
}

export const CalendarCheckBox = ({ spaceColor, block }: CalendarCheckBoxProps) => {
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const setToast = useSetRecoilState(toastState);

  const { handleClientBasicBlockChange, handleClientRecurringBlockChange } =
    useHandleClientBlockStateChange();
  const updateCalendarCheckbox = useUpdateCalendarCheckboxMutation();

  const baseColor = useMemo(() => {
    return meetWithAccounts.length === 0 && spaceColor ? spaceColor : DEFAULT_SPACE_COLOR;
  }, [spaceColor, meetWithAccounts]);

  const isCompleted = block.itemStatus === ItemStatus.COMPLETED;
  const bgColor = `${baseColor}${isCompleted ? "" : "33"}`;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setIsAnimationActive(true);

    const isDone = block.itemStatus === ItemStatus.COMPLETED;
    const newStatus = isDone ? ItemStatus.IN_PROGRESS : ItemStatus.COMPLETED;

    const convertData = {
      allDay: block.allDay,
      hangoutLink: block.hangoutLink,
      meetingCode: block.meetingCode,
      linkData: block.linkData,
      attendees: block.attendees,
      location: block.location,
      priority: block.priority,
      itemStatus: newStatus,
    };
    const isRecurringBlock =
      (block?.recurrence && block?.recurrence?.length > 0) || block.originalId;

    const updateBlock = {
      ...block,
      ...convertData,
    };
    // this로 반복이벤트 수정시에는 꼭 originalStart를 넣어줘야함
    const updatePayload = isRecurringBlock
      ? { ...convertData, type: RecurringOption.THIS, originalStart: block.start }
      : convertData;

    isRecurringBlock
      ? handleClientRecurringBlockChange({
          prevBlockData: block,
          updateBlockData: updateBlock,
        })
      : handleClientBasicBlockChange(updateBlock);

    updateCalendarCheckbox.mutate(
      {
        id: block.id,
        payload: updatePayload,
        prevData: block,
      },

      {
        onSuccess: () => {
          juneTrack(
            newStatus === ItemStatus.COMPLETED ? JUNE_EVENT.DONE_TASK : JUNE_EVENT.UNDONE_TASK,
            { location: "calendar" }
          );
        },
        onError: (error) => {
          setToast({
            type: "Error",
            isVisible: true,
            message: `Failed to ${isDone ? "undo" : "complete"}. Please retry`,
          });
        },
      }
    );
  };

  return (
    <Flex
      py={0.5}
      flexShrink={0}
      id="checkbox-wrapper"
    >
      <Box
        className={`checkbox ${block.itemStatus === ItemStatus.COMPLETED ? "checkboxCompleted" : ""} ${
          isAnimationActive ? "animationActive" : ""
        }`}
        minW="14px"
        minH="14px"
        borderRadius="3px"
        boxShadow={`0 0 0 1px ${baseColor} inset`}
        position="relative"
        backgroundColor={bgColor}
        _hover={{
          backgroundColor: baseColor, // hover시 완전 불투명 색상
        }}
        onClick={handleClick}
        sx={{
          // hover와 completed & not animation 상태일 때의 체크마크
          "&:hover::before, &.checkboxCompleted:not(.animationActive)::before": {
            boxSizing: "border-box",
            content: '""',
            position: "absolute",
            top: "35%",
            left: "15%",
            borderRight: "2px solid transparent",
            borderBottom: "2px solid transparent",
            transformOrigin: "0% 100%",
            width: "5px",
            height: "9px",
            borderColor: "#1a1c1c",
            transform: "translate3d(0, -0.5em, 0) rotate(45deg)",
          },
          // completed & animation 상태일 때 박스 애니메이션
          "&.checkboxCompleted.animationActive": {
            animation: "shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1) forwards",
          },
          // completed & animation 상태일 때 체크마크 애니메이션
          "&.checkboxCompleted.animationActive::before": {
            boxSizing: "border-box",
            content: '""',
            position: "absolute",
            top: "35%",
            left: "15%",
            borderRight: "2px solid transparent",
            borderBottom: "2px solid transparent",
            transform: "rotate(45deg)",
            transformOrigin: "0% 100%",
            animation: "checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards",
          },
          "@keyframes shrink-bounce": {
            "0%": { transform: "scale(1)" },
            "33%": { transform: "scale(0.85)" },
            "100%": { transform: "scale(1)" },
          },
          "@keyframes checkbox-check": {
            "0%": {
              width: 0,
              height: 0,
              borderColor: "#1a1c1c",
              transform: "translate3d(0, 0, 0) rotate(45deg)",
            },
            "33%": {
              width: "5px",
              height: 0,
              transform: "translate3d(0, 0, 0) rotate(45deg)",
            },
            "100%": {
              width: "5px",
              height: "9px",
              borderColor: "#1a1c1c",
              transform: "translate3d(0, -0.5em, 0) rotate(45deg)",
            },
          },
        }}
      />
    </Flex>
  );
};
