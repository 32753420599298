import { JUNE_EVENT, JUNE_LOCATION } from "../../hooks/june/juneEvent";
import { isNoteEmpty } from "../../services/taskDetail/taskDetail.service";
import { BlockPopupState } from "../../types/block/BlockType";
import { isLinkEmpty } from "../common/block/checkBlockChangeUtils";
import { juneTrack } from "./analytics";
import formatDateTimeForJune from "./formatDateTimeForJune";

const getChangedFieldsAsBoolean = (changedFields: Record<string, any>) => {
  return {
    type: changedFields.hasOwnProperty("blockType"),
    knowledge: changedFields.hasOwnProperty("linkData"),
    note: changedFields.hasOwnProperty("note"),
    space: changedFields.hasOwnProperty("spaceId"),
    repeat: changedFields.hasOwnProperty("recurrence"),

    priority: changedFields.hasOwnProperty("priority"),
  };
};

// 이벤트 추적 관련 로직을 별도 함수로 분리
export const trackBlockEvents = ({
  taskDetail,
  changedFields,
  toggleExpand,
}: {
  taskDetail: BlockPopupState;
  changedFields: Record<string, any>;
  toggleExpand: boolean;
}) => {
  const isNewBlock = taskDetail.isNewBlock;
  // 블록 생성/수정 이벤트 추적
  if (isNewBlock) {
    juneTrack(JUNE_EVENT.CREATE_BLOCK, {
      location: "calendar",
      type: taskDetail.data?.blockType,
      knowledge: taskDetail.data?.linkData ? !isLinkEmpty(taskDetail.data.linkData) : false,
      note: isNoteEmpty(taskDetail.data?.note) ? false : true,
      space: taskDetail.data?.spaceId ? true : false,
      repeat: taskDetail.data?.recurrence ? taskDetail.data.recurrence.length > 0 : false,
      //taskDetail에서 생성의 경우 항상 start, end가 있지만 예외 케이스 대비해서 추가
      startDatetime: taskDetail.data?.start ?? null,
      endDatetime: taskDetail.data?.end ?? null,
      priority: taskDetail.data?.priority,
    });

    juneTrack(JUNE_EVENT.BLOCK_TIME, {
      location: JUNE_LOCATION.CALENDAR,
      startDatTime: formatDateTimeForJune(taskDetail.data?.start),
      endDateTime: formatDateTimeForJune(taskDetail.data?.end),
      allDay: true,
    });
  } else {
    const changedFieldsBoolean = getChangedFieldsAsBoolean(changedFields);
    juneTrack(JUNE_EVENT.UPDATE_BLOCK, {
      location: taskDetail.type,
      ...changedFieldsBoolean,
    });
  }

  // 노트/링크 추가 이벤트 추적
  if (taskDetail.data?.note && !isNoteEmpty(taskDetail.data?.note)) {
    juneTrack(JUNE_EVENT.ADD_NOTE, {
      location: taskDetail.type,
      type: taskDetail.data?.blockType,
      detail_type: toggleExpand ? "full" : "modal",
    });
  }

  if (taskDetail.data?.linkData && !isLinkEmpty(taskDetail.data.linkData)) {
    juneTrack(JUNE_EVENT.ADD_LINK, {
      location: taskDetail.type,
      type: taskDetail.data?.blockType,
      detail_type: toggleExpand ? "full" : "modal",
    });
  }
};
