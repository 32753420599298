import { Box } from "@chakra-ui/react";
import { Resizable } from "re-resizable";
import { TaskInbox } from "../TaskInbox";
import { resizableHandleStyle, resizableStyles } from "../../utils/common/resizableStyles";

/**
 * @param {object} props
 * @param {boolean} props.hasBlocksSynchronizedWithGoogle - 백엔드가 보내는 block들과 Google Calendar 동기화 여부
 */
export function TaskInboxWithResizable({
  hasBlocksSynchronizedWithGoogle,
}: {
  hasBlocksSynchronizedWithGoogle: boolean;
}) {
  const savedWidth = localStorage.getItem("savedWidth") ?? "320px";

  return (
    <Box
      sx={resizableStyles}
      height={"100%"}
    >
      <Resizable
        defaultSize={{
          width: savedWidth,
          height: "100%",
        }}
        minWidth={250}
        maxWidth={1000}
        enable={{
          left: false,
          right: true,
          top: false,
          bottom: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleStyles={{
          right: resizableHandleStyle,
        }}
        handleClasses={{
          right: "custom-resizer-handle-right",
        }}
        onResizeStop={(e, direction, ref, d) => {
          if (ref?.offsetWidth) {
            localStorage.setItem("savedWidth", `${ref.offsetWidth}px`);
          }
        }}
      >
        <TaskInbox hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle} />
      </Resizable>
    </Box>
  );
}
