import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import InboxHeader from "./InboxHeader/InboxHeader";
import TaskList from "./InboxBody/TaskList";
import {
  InboxHeaderTab,
  ToggleVisibility,
  ToggleVisibilityByTab,
} from "../../types/inbox/inbox-headers";
import { pxToRem } from "../../utils/styles/size";

export type HandleTabToggleVisibility = (
  toggle: keyof ToggleVisibility,
  activeTab: InboxHeaderTab
) => void;

/**
 * @param {object} props
 * @param {boolean} props.hasBlocksSynchronizedWithGoogle - 백엔드가 보내는 block들과 Google Calendar 동기화 여부
 */
export function TaskInbox({
  hasBlocksSynchronizedWithGoogle,
}: {
  hasBlocksSynchronizedWithGoogle: boolean;
}) {
  const [activeTab, setActiveTab] = useState<InboxHeaderTab>(
    (localStorage.getItem("activeTab") as InboxHeaderTab | null) || "Inbox"
  );

  const [toggleVisibility, setToggleVisibility] = useState<ToggleVisibilityByTab>({
    Inbox: { overdue: false, completed: false, planned: false },
    Today: { overdue: false, completed: false },
  });

  useEffect(() => {
    const savedToggleVisibility = localStorage.getItem("toggleVisibility");
    if (savedToggleVisibility) {
      setToggleVisibility(JSON.parse(savedToggleVisibility));
    }
  }, []);

  const handleToggleVisibility: HandleTabToggleVisibility = (toggle, activeTab) => {
    if (toggle === "planned" && activeTab === "Today") {
      return;
    }

    setToggleVisibility((prevState) => {
      const newState = {
        ...prevState,
        [activeTab]: {
          ...prevState[activeTab],
          [toggle]: !prevState[activeTab][toggle],
        },
      };

      localStorage.setItem("toggleVisibility", JSON.stringify(newState));

      return newState;
    });
  };

  const handleChangeActiveTab = (event: React.MouseEvent<HTMLButtonElement>) => {
    const tab = event.currentTarget.value as InboxHeaderTab;
    setActiveTab(tab);

    localStorage.setItem("activeTab", tab);
  };

  return (
    <Flex
      w={"100%"}
      h={"full"}
      flexDir={"column"}
      border={"1px solid"}
      borderColor={"#313435"}
      borderRadius={pxToRem(8)}
      backgroundColor={"#242626"}
      userSelect="none"
      sx={{
        "& > *": {
          // 자식 요소들에 대한 스타일
          userSelect: "none",
          "--user-drag": "none",
          WebkitUserDrag: "none",
        },
      }}
    >
      <InboxHeader
        handleChangeActiveTab={handleChangeActiveTab}
        activeTab={activeTab}
        onToggleVisibility={handleToggleVisibility}
        toggleVisibility={toggleVisibility}
        hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
      />

      <TaskList
        toggleVisibility={toggleVisibility}
        activeTab={activeTab}
        hasBlocksSynchronizedWithGoogle={hasBlocksSynchronizedWithGoogle}
      />
    </Flex>
  );
}
