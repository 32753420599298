import React, { useState } from "react";
import { Box, Center, Flex, Text, Tooltip } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";
import { SpaceCircle } from "./SpaceCircle";
import { createPortal } from "react-dom";
import FilterProjectReorderDragLayer from "../FilterProjectReorderDragLayer";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { SpaceType } from "../../../types/space";
import { HandleSpaceReorderType } from "./SpacesSection";

interface NoSpaceFilterItemProps {
  data: SpaceType;
  isExpanded: boolean;
  index: number;
  isSelected: boolean;
  onClickSpaceCircle: (e: React.MouseEvent) => void;
  onReorder: HandleSpaceReorderType;
}

export const NoSpaceFilterItem: React.FC<NoSpaceFilterItemProps> = ({
  isExpanded,
  index,
  data,
  isSelected,
  onClickSpaceCircle,
  onReorder,
}) => {
  const [prevIndex, setPrevIndex] = useState(index);
  const { color } = data;
  const { ref, isDragging, shouldShowPreview } = useDragAndDrop({
    index,
    data,
    onReorder,
    prevIndex,
    setPrevIndex,
  });

  return (
    <Box
      borderRadius={shouldShowPreview ? "6px" : "initial"}
      bg={shouldShowPreview ? "rgba(255, 255, 255, 0.04)" : "initial"}
    >
      <Flex
        ref={ref}
        opacity={shouldShowPreview ? 0 : 1}
        justifyContent={"center"}
        alignItems={"center"}
        cursor={"pointer"}
      >
        {isExpanded ? (
          <Flex
            w={"full"}
            h={pxToRem(36)}
            py={1}
            px={0.5}
            borderRadius="md"
            _hover={{
              bg: hexToRgba("#ffffff", 0.04),
            }}
            bg={"initial"}
            alignItems={"center"}
            cursor={"pointer"}
            onClick={onClickSpaceCircle}
          >
            <SpaceCircle
              spaceColor={color}
              isSelected={isSelected}
              onClick={onClickSpaceCircle}
            />

            <Text
              textStyle={"body1"}
              color={"#D9D9D9"}
              noOfLines={1}
              ml={2}
            >
              No Space
            </Text>
          </Flex>
        ) : (
          <Tooltip
            label={"No Space"}
            placement={"right"}
            closeOnScroll={true}
          >
            <Center
              cursor={"pointer"}
              h={9}
            >
              <SpaceCircle
                w={7}
                h={7}
                spaceColor={color}
                isSelected={isSelected}
                onClick={onClickSpaceCircle}
                my={pxToRem(3)}
                bg={"initial"}
                _hover={{
                  bg: hexToRgba("#ffffff", 0.04),
                }}
              />
            </Center>
          </Tooltip>
        )}
      </Flex>
      {shouldShowPreview &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <FilterProjectReorderDragLayer />
          </div>,
          document.body
        )}
    </Box>
  );
};
