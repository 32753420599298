import { useDrop } from "react-dnd";
import { COMPLETED } from "../../constants/taskStateType";
import { Box } from "@chakra-ui/react";
import { DragAccept } from "../../types/block/enum";

const TaskListEmptyDropArea = ({ onTasksReorderChange, handleUpdateTaskList, listType }) => {
  const [, drop] = useDrop({
    accept: [DragAccept.INBOX, DragAccept.MORE_TASK],
    hover: (item, monitor) => {
      if (listType === "doneTask") {
        const tempItem = { ...item, itemStatus: COMPLETED };
        handleUpdateTaskList([tempItem]);
      } else {
        handleUpdateTaskList([item]);
      }
    },
    drop(props, monitor) {
      onTasksReorderChange();
    },
  });

  return (
    <Box
      ref={drop}
      w="100%"
      h="100%"
      display="flex"
      flexDir="row"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="flex-start"
      gap="8px"
      p="0 8px"
      border="1px solid transparent"
      boxSizing="border-box"
    />
  );
};

export default TaskListEmptyDropArea;
